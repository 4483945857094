import * as types from "./types";
import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import store from "../store";

export const tryFetchModerators = (auth) => (dispatch) => {
    dispatch({ type: types.MODERATORS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/moderators" : "/dap-admin/api/v1/customers/admins/moderators",
        queryParams: {
            pagination: false,
        },
        token: auth.accessToken,
    })
        .then((response) => dispatch({ type: types.MODERATORS_FETCH_SUCCESS, data: response["hydra:member"] }))
        .catch((error) => dispatch({ type: types.MODERATORS_FETCH_FAILURE, data: error }));
};

export const tryAddModerator = (moderator) => (dispatch) => {
    const { auth } = store.getState();

    dispatch({ type: types.ADD_MODERATOR_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/moderators" : "/dap-admin/api/v1/customers/admins/moderators",
        token: auth.accessToken,
        method: "POST",
        data: {
            ...moderator,
            enable: true,
        },
    })
        .then((response) => dispatch({ type: types.ADD_MODERATOR_SUCCESS, data: response }))
        .catch((error) => {
            dispatch({ type: types.ADD_MODERATOR_FAILURE, data: error });
            throw error;
        });
};

export const tryRemoveModerator = (auth, moderatorId) => (dispatch) => {
    dispatch({ type: types.REMOVE_MODERATOR_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/moderators/{id}" : "/dap-admin/api/v1/customers/admins/moderators/{id}",
        uriParams: {
            id: moderatorId,
        },
        token: auth.accessToken,
        method: "DELETE",
    })
        .then(() => dispatch({ type: types.REMOVE_MODERATOR_SUCCESS, data: "/moderators/" + moderatorId }))
        .catch((error) => dispatch({ type: types.REMOVE_MODERATOR_FAILURE, data: error }));
};

export const tryEditModerator = (moderatorId, form) => (dispatch) => {
    const { auth } = store.getState();

    dispatch({ type: types.EDIT_MODERATOR_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/moderators/{id}" : "/dap-admin/api/v1/customers/admins/moderators/{id}",
        uriParams: {
            id: moderatorId,
        },
        token: auth.accessToken,
        method: "PUT",
        data: form,
    })
        .then((response) => dispatch({ type: types.EDIT_MODERATOR_SUCCESS, data: response }))
        .catch((error) => {
            dispatch({ type: types.EDIT_MODERATOR_FAILURE, data: error });
            throw error;
        });
};
