import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { Icon } from "@rdcs/dap-front-library";

const AppointmentRealizedCardContent = ({ type, title, date }) => (
    <>
        <div className='mr-1'>
            <p className='text-uppercase txtSizeExtraSmall'>{type}</p>
            <p className='title-4 txtBold' >{title}</p>
            <p className='txtSizeExtraSmall spo__color--gray-8'>{moment(date).format('DD MMMM YYYY')}</p>
        </div>
        <div className='d-flex flex-column justify-center timkl__color--blue'>
            <Icon name='certificate-check' size={18} />
        </div>
    </>
);

AppointmentRealizedCardContent.propTypes = {
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
};

export default AppointmentRealizedCardContent;