import * as types from "../actions/types";
import { toast } from "react-toastify";

const initialState = {
    profile: null,
    profileLoading: false,
    profileEditLoading: false,
    profileEditError: false
};

function profile(state = initialState, action) {
    switch (action.type) {
        case types.PROFILE_FETCH_REQUESTED: {
            return Object.assign({}, state, { profileLoading: true });
        }
        case types.PROFILE_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                profileLoading: false,
                profile: action.data
            });
        }
        case types.PROFILE_FETCH_FAILURE: {
            return Object.assign({}, state, {
                profileLoading: false,
                profile: null
            });
        }
        case types.PROFILE_EDIT_REQUESTED: {
            return Object.assign({}, state, {
                profileEditLoading: true,
                profileEditError: false
            });
        }
        case types.PROFILE_EDIT_SUCCESS: {
            toast.success("Le profil a été modifié");
            return Object.assign({}, state, {
                profileEditLoading: false,
                profile: action.data,
                profileEditError: false
            });
        }
        case types.PROFILE_EDIT_FAILURE: {
            toast.error("Le profil n'a pas pu être modifié");
            return Object.assign({}, state, {
                profileEditLoading: false,
                profileEditError: action.data
            });
        }
        // USER INFO UPDATE
        case types.PROFILE_EDIT_UPDATE: {
            return Object.assign({}, state, {
                user: {
                    ...state.user,
                    'avatar': action.avatar,
                    'firstName': action.firstName,
                    'lastName': action.lastName,
                }
            });
        }
        default:
            return state;
    }
}

export default profile;
