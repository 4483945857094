import React from "react";
import PropTypes from "prop-types";
import { useHistory, withRouter } from "react-router-dom";

import { connect } from "react-redux";

import { tryFetchCoordinators } from "../../actions/coordinators";
import { tryFetchPotentialPatients, tryEditPotentialPatient } from "../../actions/patients";

import { DefaultLoader, AntdPagination } from "@rdcs/dap-front-library";

import AppLayout from "../../components/Layout/AppLayout";
import usePatientsList from "../../hooks/usePatientsList";

import Header from "./Header";
import HeaderList from "./HeaderList";
import PotentialPatientsItem from "./PotentialPatientsItem";
import moment from "moment";

const breadcrumbs = [{ label: "Nouveaux patients" }];

const PotentialPatientsScreen = ({ auth, coordinators, nbPatients, tryFetchCoordinators, tryFetchPotentialPatients, tryEditPotentialPatient }) => {
    const [{ page, patientsList, coordinatorIsLoading }, { load, changePage, setPatientsList }, { patientsListShouldUpdate }] = usePatientsList();

    const retrievePatients = React.useCallback(
        (filter) => {
            if (patientsListShouldUpdate(filter)) {
                tryFetchPotentialPatients(auth, {
                    page: page,
                    "phoneNumber[exists]": true,
                    "email[exists]": true,
                    "practitioner[exists]": true,
                    "patientAccountCreatedDate[exists]": false,
                    potentialPatientConsent: true,
                    ...filter,
                }).then((potentialPatients) => {
                    for (let patient of potentialPatients) {
                        if (patient.questionnaire !== null) {
                            patient.questionnaire.id = patient.questionnaire["@id"];
                            delete patient.questionnaire["@id"];
                            delete patient.questionnaire["@type"];
                            for (let i = 0; i < patient.questionnaire.answers.length; i++) {
                                patient.questionnaire.answers[i].id = patient.questionnaire.answers[i]["@id"];
                                delete patient.questionnaire.answers[i]["@id"];
                                delete patient.questionnaire.answers[i]["@type"];
                            }
                        }
                    }
                    setPatientsList(potentialPatients);
                });
            }
        },
        [page, patientsListShouldUpdate]
    );

    const history = useHistory();
    const {
        user: { id },
    } = auth;

    const consentCallback = (validationField, dateField, index, patient, consent) => {
        tryEditPotentialPatient(auth, patient.id, {
            firstName: patient.firstName,
            lastName: patient.lastName,
            [validationField]: consent,
            [dateField]: moment().format("YYYY-MM-DD[T]HH:mm:ssZ"),
        })
            .then((potentialPatient) => {
                const patientsListTmp = patientsList.slice();

                if (
                    potentialPatient === true ||
                    (potentialPatient.consentPractitionnerValidated !== null && !potentialPatient.consentPractitionnerValidated) ||
                    (potentialPatient.consentPatientValidated !== null && !potentialPatient.consentPatientValidated)
                ) {
                    patientsListTmp.splice(index, 1);
                } else {
                    patientsListTmp[index] = potentialPatient;
                }
                setPatientsList(patientsListTmp);
            })
            .catch(console.error);
    };

    const consentPractitionner = (index, patient, consent) =>
        consentCallback("consentPractitionnerValidated", "consentPractitionnerDate", index, patient, consent);

    const consentPatient = (index, patient, consent) => consentCallback("consentPatientValidated", "consentPatientValidationDate", index, patient, consent);

    const createNewPatient = (potentialPatient) => history.push(`/patients-potentiels/${potentialPatient.id}/nouveau-patient`);

    React.useEffect(() => {
        if (coordinators.length === 0) {
            tryFetchCoordinators(auth).then(load);
        } else {
            load();
        }

        document.title = "Timkl - Patients potentiels";
    }, []);

    return (
        <AppLayout navActive={1} breadcrumbs={breadcrumbs}>
            <Header id={id} coordinators={coordinators} onChange={retrievePatients} loading={coordinatorIsLoading} />
            {coordinatorIsLoading ? (
                <DefaultLoader color="#CCC" />
            ) : (
                <>
                    {patientsList.length > 0 ? (
                        <ul className="moderationList form__group">
                            <HeaderList />
                            {patientsList.map((patient, i) => (
                                <PotentialPatientsItem
                                    key={i}
                                    patient={patient}
                                    index={i}
                                    consentPatient={consentPatient}
                                    consentPractitionner={consentPractitionner}
                                    createNewPatient={createNewPatient}
                                />
                            ))}
                        </ul>
                    ) : (
                        <p>Aucun résultat</p>
                    )}
                    {(page > 1 || nbPatients > 30) && (
                        <div className="mt-2 text-center">
                            <AntdPagination simple current={page} onChange={changePage} pageSize={30} total={nbPatients} />
                        </div>
                    )}
                </>
            )}
        </AppLayout>
    );
};

PotentialPatientsScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    coordinators: PropTypes.arrayOf(
        PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            departments: PropTypes.arrayOf(
                PropTypes.shape({
                    code: PropTypes.string.isRequired,
                }).isRequired
            ).isRequired,
        })
    ).isRequired,
    nbPatients: PropTypes.number.isRequired,
    tryFetchCoordinators: PropTypes.func.isRequired,
    tryFetchPotentialPatients: PropTypes.func.isRequired,
    tryEditPotentialPatient: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, coordinators: { list: coordinators }, patients: { totalItems: nbPatients } }) => ({
    auth,
    coordinators,
    nbPatients,
});

const mapDispatchToProps = {
    tryFetchCoordinators,
    tryFetchPotentialPatients,
    tryEditPotentialPatient,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PotentialPatientsScreen));
