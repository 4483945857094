import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import { DefaultLoader, PageTitle } from "@rdcs/dap-front-library";

import CoordinatorPatientLayout from "../../components/Coordinator/CoordinatorPatientLayout";
import NoPastQuestionnaire from "../../components/BreakScoreQuestionnaire/NoPastQuestionnaire";
import SinglePastQuestionnaire from "../../components/BreakScoreQuestionnaire/SinglePastQuestionnaire";
import MultiplePastQuestionnaires from "../../components/BreakScoreQuestionnaire/MultiplePastQuestionnaires";

import { try30QuestionnairePatient } from "../../actions/questionnaire";
import Questions from "../../actions/questions";
const { tryFetchBreakScoreQuestions, tryFetchBreakScoreNoInsulinQuestions } = Questions;

import { formatDate } from "../../utils/utils";
import Form from "../../components/BreakScoreQuestionnaire/ForExistingPatient/Form";

const CoordinatorPatientBreakScoreScreen = ({
    breakScoreQuestions,
    breakScoreNoInsulinQuestions,
    try30QuestionnairePatient,
    tryFetchBreakScoreQuestions,
    tryFetchBreakScoreNoInsulinQuestions,
}) => {
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);

    const [isLoading, setIsLoading] = React.useState(true);
    const [questionnaires, setQuestionnaires] = React.useState([]);
    const [isInsulinoTreated, setIsInsulinoTreated] = React.useState(false);
    const [questionnaireList, setQuestionnaireList] = React.useState([]);
    const [fillNewQuestionnaire, setFillNewQuestionnaire] = useState(false);

    React.useEffect(() => {
        tryFetchBreakScoreQuestions();
        tryFetchBreakScoreNoInsulinQuestions();
    }, []);

    React.useEffect(() => {
        if (fillNewQuestionnaire) return;

        setIsLoading(true);
        const dataNotInsulinoTreated = {
            type: "break-score-no-insulin",
            patientId,
        };

        const dataInsulinoTreated = {
            type: "break-score",
            patientId,
        };

        //A patient is supposed to have insulino OR non-insulino questionnaires, but NOT both
        Promise.allSettled([
            try30QuestionnairePatient(dataNotInsulinoTreated).then((resNotInsulino) => {
                if (resNotInsulino["hydra:member"]) {
                    setQuestionnaires(resNotInsulino["hydra:member"]);
                    setIsInsulinoTreated(false);
                }
            }),
            try30QuestionnairePatient(dataInsulinoTreated).then((resInsulino) => {
                if (resInsulino["hydra:member"]) {
                    setIsInsulinoTreated(true);
                    setQuestionnaires(resInsulino["hydra:member"]);
                }
            }),
        ]).then(() => {
            setIsLoading(false);
        });
    }, [patientId, fillNewQuestionnaire]);

    React.useEffect(() => {
        let list = [];

        for (let i = 0; i < questionnaires.length; i++) {
            list.push({
                date: formatDate(questionnaires[i].createdAt),
                score: Math.round(questionnaires[i].score),
            });
        }
        setQuestionnaireList(list);
    }, [questionnaires]);

    if (fillNewQuestionnaire)
        return (
            <PageTitle title="Timkl - Questionnaire de rupture">
                <CoordinatorPatientLayout activeNav="break-score" patientId={patientId}>
                    <Form setFillNewQuestionnaire={setFillNewQuestionnaire} />
                </CoordinatorPatientLayout>
            </PageTitle>
        );

    return (
        <PageTitle title="Timkl - Questionnaire de rupture">
            <CoordinatorPatientLayout activeNav="break-score">
                {isLoading ? (
                    <DefaultLoader color="#CCC" />
                ) : questionnaires.length === 0 && !isLoading ? (
                    <NoPastQuestionnaire setFillNewQuestionnaire={setFillNewQuestionnaire} />
                ) : questionnaires.length === 1 ? (
                    <SinglePastQuestionnaire
                        questionnaire={questionnaires[0]}
                        isInsulinoTreated={isInsulinoTreated}
                        patientId={patientId}
                        breakScoreQuestions={breakScoreQuestions}
                        breakScoreNoInsulinQuestions={breakScoreNoInsulinQuestions}
                        setFillNewQuestionnaire={setFillNewQuestionnaire}
                    />
                ) : (
                    <MultiplePastQuestionnaires
                        questionnaires={questionnaires}
                        questionnaireList={questionnaireList}
                        isInsulinoTreated={isInsulinoTreated}
                        patientId={patientId}
                        breakScoreQuestions={breakScoreQuestions}
                        breakScoreNoInsulinQuestions={breakScoreNoInsulinQuestions}
                        setFillNewQuestionnaire={setFillNewQuestionnaire}
                    />
                )}
            </CoordinatorPatientLayout>
        </PageTitle>
    );
};

CoordinatorPatientBreakScoreScreen.propTypes = {
    breakScoreQuestions: PropTypes.arrayOf(PropTypes.object),
    breakScoreNoInsulinQuestions: PropTypes.arrayOf(PropTypes.object),
    breakScoreAnswers: PropTypes.arrayOf(PropTypes.object),
    singlePatient: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    try30QuestionnairePatient: PropTypes.func.isRequired,
    tryFetchBreakScoreQuestions: PropTypes.func.isRequired,
    tryFetchBreakScoreNoInsulinQuestions: PropTypes.func.isRequired,
};

const mapStateToProps = ({
    questions: { breakScoreQuestions, breakScoreQuestionsLoading, breakScoreQuestionsLoaded, breakScoreAnswers, breakScoreNoInsulinQuestions },
    patients: { singlePatient },
}) => ({
    breakScoreQuestions,
    breakScoreNoInsulinQuestions,
    singlePatient,
    breakScoreAnswers,
    isLoading: breakScoreQuestionsLoading || breakScoreQuestionsLoaded === 0,
});

const mapDispatchToProps = {
    tryFetchBreakScoreQuestions,
    try30QuestionnairePatient,
    tryFetchBreakScoreNoInsulinQuestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoordinatorPatientBreakScoreScreen);
