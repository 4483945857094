import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { tryEditModerator, tryFetchModerators } from "../../../actions/moderators";

import ModeratorForm from "../../../components/Settings/Forms/ModeratorForm";
import SettingsEditFormScreen from "../../../components/Settings/SettingsEditFormScreen";

const EditModeratorScreen = ({
    moderators: { list: moderators, listLoading, editLoading, editError },
    tryEditModerator,
    tryFetchModerators
}) => (
    <SettingsEditFormScreen
        /* Document and page titles */
        documentTitle="Modifier modérateur"
        pageTitle="Modifier un modérateur"
        /* Redux request callback use to add on BDD */
        formRequest={tryEditModerator}
        fetchRequests={tryFetchModerators}
        testFetchRequestsNeeded={moderators.length === 0}
        /* Forms */
        itemForm={ModeratorForm}
        itemFormRequestLoading={editLoading}
        itemFormRequestError={editError}
        itemFormExtraProps={{ moderators }}
        itemFormIsLoading={listLoading}
        itemFormEditedItemPropName="editedModerator"
        /* Items lists to retrieve the current item */
        items={moderators}
        /* Configuration tab name for redirection */
        tab="moderator"
    />
);

EditModeratorScreen.propTypes = {
    moderators: PropTypes.shape({
        list: PropTypes.arrayOf(PropTypes.object).isRequired,
        listLoading: PropTypes.bool.isRequired,
        editLoading: PropTypes.bool.isRequired,
        editError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object
            }),
            PropTypes.bool
        ]).isRequired
    }).isRequired,
    tryEditModerator: PropTypes.func.isRequired,
    tryFetchModerators: PropTypes.func.isRequired
};

const mapStateToProps = ({ moderators }) => ({ moderators });

const mapDispatchToProps = { tryEditModerator, tryFetchModerators };

export default connect(mapStateToProps, mapDispatchToProps)(EditModeratorScreen);
