import React from "react";
import PropTypes from 'prop-types';

import { DefaultLoader } from "@rdcs/dap-front-library";

import CoordinatorFilter from './CoordinatorFilter';

const HeaderCoordinator = ({ title, loading, currentCoordinatorId, coordinators, onChange, order = false }) => (
    <header className="headerPage">
        <div className="headerPage__title">
            <h1 className="title-1">{title}</h1>
        </div>
        <div className="headerPage__actions">
            {loading ? (
                <DefaultLoader color="#CCC" />
            ) : (
                <CoordinatorFilter
                    currentCoordinatorId={currentCoordinatorId}
                    coordinators={coordinators}
                    onChange={onChange}
                    order={order}
                />
            )}
        </div>
    </header>
);

HeaderCoordinator.propTypes = {
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    currentCoordinatorId: PropTypes.number.isRequired,
    coordinators: PropTypes.arrayOf(
        PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            departments: PropTypes.arrayOf(
                PropTypes.shape({
                    code: PropTypes.string.isRequired
                }).isRequired
            ).isRequired
        }).isRequired
    ).isRequired,
    onChange: PropTypes.func,
    order: PropTypes.bool,
};

export default React.memo(HeaderCoordinator);