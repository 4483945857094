import * as types from "./types";
import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";
import store from "../store";

export const tryFetchMedicals = (auth) => (dispatch) => {
    dispatch({ type: types.MEDICALS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/practitioners" : "/dap-admin/api/v1/customers/practitioners",
        queryParams: {
            pagination: false,
            "order[createdAt]": "asc",
        },
        token: auth.accessToken,
    })
        .then((response) => dispatch({ type: types.MEDICALS_FETCH_SUCCESS, data: response["hydra:member"] }))
        .catch((error) => dispatch({ type: types.MEDICALS_FETCH_FAILURE, data: error }));
};

export const tryFetchPractitioners = (filter) => (dispatch) => {
    const {
        auth: { accessToken },
    } = store.getState();

    dispatch({ type: types.PRACTITIONERS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/practitioners" : "/dap-admin/api/v1/customers/practitioners",
        queryParams: {
            discr: filter.type,
            city: filter.city,
            fullName: filter.fullName,
            page: filter.page,
            itemsPerPage: filter.itemsPerPage,
            "order[createdAt]": filter.order ? filter.order : "asc",
        },
        token: accessToken,
    })
        .then((response) => {
            dispatch({ type: types.PRACTITIONERS_FETCH_SUCCESS, data: response });
            return response;
        })
        .catch((error) => dispatch({ type: types.PRACTITIONERS_FETCH_FAILURE, data: error }));
};

export const tryEditMedical = (auth, medical, data) => (dispatch) => {
    let url = "";

    if (medical.roles.includes("ROLE_DOCTOR")) {
        url = isLocalAPI ? "/doctors/{id}" : "/dap-admin/api/v1/customers/practitioners/doctors/{id}";
    } else if (medical.roles.includes("ROLE_NURSE")) {
        url = isLocalAPI ? "/nurses/{id}" : "/dap-admin/api/v1/customers/practitioners/nurses/{id}";
    } else if (medical.roles.includes("ROLE_PHARMACIST")) {
        url = isLocalAPI ? "/pharmacists/{id}" : "/dap-admin/api/v1/customers/practitioners/pharmacists/{id}";
    } else {
        url = isLocalAPI ? "/others/{id}" : "/dap-admin/api/v1/customers/practitioners/others/{id}";
    }
    dispatch({ type: types.MEDICALS_EDIT_REQUESTED });
    return API.request({
        path: url,
        uriParams: {
            id: medical.id,
        },
        method: "PUT",
        token: auth.accessToken,
        data: {
            ...medical,
            avatar: medical.avatar?.["@id"] || null,
            justificatory: medical.justificatory?.["@id"] || null,
            ...data,
        },
    })
        .then((response) => dispatch({ type: types.MEDICALS_EDIT_SUCCESS, data: response }))
        .catch((error) => dispatch({ type: types.MEDICALS_EDIT_FAILURE, data: error }));
};

export const tryAcceptMedicalJustificatory = (auth, medical) => tryEditMedical(auth, medical, { justificatoryValid: true });

export const tryRefuseMedicalJustificatory = (auth, medical) => tryEditMedical(auth, medical, { justificatoryValid: false });

export const tryBanMedical = (auth, medical) => (dispatch) => {
    dispatch({ type: types.MEDICALS_BAN_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/ban_user/{id}" : "/dap-admin/api/v1/customers/ban-user/{id}",
        uriParams: {
            id: medical.id,
        },
        method: "POST",
        token: auth.accessToken,
    })
        .then((response) => dispatch({ type: types.MEDICALS_BAN_SUCCESS, data: response }))
        .catch((error) => dispatch({ type: types.MEDICALS_BAN_FAILURE, data: error }));
};

export const tryCreateMedicalFromRpps = (auth, rppsNumber, email) => (dispatch) => {
    dispatch({ type: types.MEDICALS_CREATE_FROM_RPPS_REQUESTED });
    return API.request({
        method: "POST",
        path: isLocalAPI ? "/create_practitioner_from_rpps" : "/dap-admin/api/v1/customers/practitioners/create-from-rpps",
        token: auth.accessToken,
        data: {
            rppsNumber: rppsNumber,
            email: email,
        },
    })
        .then((res) => {
            dispatch({ type: types.MEDICALS_CREATE_FROM_RPPS_SUCCESS, data: res });
            return res;
        })
        .catch((error) => dispatch({ type: types.MEDICALS_CREATE_FROM_RPPS_FAILURE, data: error }));
};
