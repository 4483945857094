import React from "react";
import PropTypes from "prop-types";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Animated } from "react-animated-css";
import { DefaultLoader, PageTitle, Icon, Button } from "@rdcs/dap-front-library";

import Questions from "../../actions/questions";
import { tryCreateQuestionnairePatient, tryQuestionnairePatient } from "../../actions/questionnaire";

import CoordinatorPatientLayout from "../../components/Coordinator/CoordinatorPatientLayout";
import EpicesForm from "../../components/EpicesForm";
import EpicesScore from "../../components/EpicesForm/epicesScore";

const { tryFetchEpicesQuestions } = Questions;

const CoordinatorPatientEpicesScreen = ({
    epicesQuestions,
    epicesAnswers,
    tryFetchEpicesQuestions,
    tryCreateQuestionnairePatient,
    tryQuestionnairePatient,
}) => {
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);
    const patientIri = React.useMemo(() => `/patients/${patientId}`, [patientId]);

    const [userAnswers, setUserAnswers] = React.useState([]);
    const [answers, setAnswers] = React.useState([]);

    const [questionnaireId, setQuestionnaireId] = React.useState(null);

    const [score, setScore] = React.useState(0);
    const [IsScoreScreen, setIsScoreScreen] = React.useState(false);

    const [isLoadingLocal, setIsLoadingLocal] = React.useState(true);

    const [isFetching, setIsFetching] = React.useState(false);

    React.useEffect(() => {
        setIsLoadingLocal(true);

        const data = {
            patientId: patientId,
            type: "epices",
        };

        Promise.all([tryFetchEpicesQuestions(), tryQuestionnairePatient(data)]);
    }, []);

    React.useEffect(() => {
        setIsLoadingLocal(true);
        setAnswers(
            epicesAnswers.map((item) => {
                return {
                    question: item.question,
                    values: item.values,
                };
            })
        );
        setIsLoadingLocal(false);
    }, [epicesAnswers]);

    function handleSubmit(e) {
        e.preventDefault();
        setIsFetching(true);

        const data = {
            type: "epices",
            answers: userAnswers,
            patient: patientIri,
        };

        tryCreateQuestionnairePatient(data).then((res) => {
            setQuestionnaireId(res.id);
            setScore(parseInt(res.score, 10));
            setIsScoreScreen(true);
            setIsFetching(false);
        });
    }

    return (
        <PageTitle title="Timkl - Points de vigilance">
            <CoordinatorPatientLayout activeNav="questionnaire-epices">
                <div className="informationsList">
                    <div className="page-single-patient">
                        {isLoadingLocal && epicesQuestions ? (
                            <DefaultLoader color="#CCC" />
                        ) : (
                            <Animated animationIn="fadeIn">
                                <>
                                    {IsScoreScreen ? (
                                        <EpicesScore score={score} questionnaireId={questionnaireId} setIsScoreScreen={setIsScoreScreen} />
                                    ) : (
                                        <>
                                            <div className="d-flex flex-column justify-center align-items-center mx-4">
                                                <Icon name="question" size={48} />
                                                <h3 className="beck_title">Questionnaire abrégé de epices</h3>
                                                <div className="header_beck">
                                                    <p className="mt-1">
                                                        L’évaluation du score EPICES a montré la pertinence du score EPICES pour détecter et quantifier la
                                                        précarité :
                                                    </p>
                                                    <p className="mt-1">
                                                        - le score EPICES est lié aux indicateurs de niveau socio-économique, de comportements et de santé,
                                                    </p>
                                                    <p className="mt-1">
                                                        - le score EPICES est quantitatif et des relations « scores dépendantes » sont observées avec tous les
                                                        indicateurs,
                                                    </p>
                                                    <p className="mt-1">- le seuil de 30 est considéré comme le seuil de précarité selon EPICES.</p>
                                                </div>
                                            </div>
                                            <form onSubmit={handleSubmit}>
                                                <EpicesForm
                                                    questions={epicesQuestions}
                                                    answers={answers}
                                                    userAnswers={userAnswers}
                                                    setUserAnswers={setUserAnswers}
                                                />
                                                <div className="d-flex justify-center mt-2">
                                                    <Button
                                                        isLoading={isFetching}
                                                        disabled={userAnswers.length !== epicesQuestions.length}
                                                        className="button__primaryButton"
                                                        type="submit"
                                                    >
                                                        Calculer le score
                                                    </Button>
                                                </div>
                                            </form>
                                        </>
                                    )}
                                </>
                            </Animated>
                        )}
                    </div>
                </div>
            </CoordinatorPatientLayout>
        </PageTitle>
    );
};

CoordinatorPatientEpicesScreen.propTypes = {
    epicesQuestions: PropTypes.arrayOf(PropTypes.object).isRequired,
    epicesAnswers: PropTypes.arrayOf(PropTypes.object).isRequired,
    tryFetchEpicesQuestions: PropTypes.func.isRequired,
    tryCreateQuestionnairePatient: PropTypes.func.isRequired,
    tryQuestionnairePatient: PropTypes.func.isRequired,
};

const mapStateToProps = ({ questions: { epicesQuestions, epicesAnswers } }) => ({
    epicesQuestions,
    epicesAnswers,
});

const mapDispatchToProps = {
    tryFetchEpicesQuestions,
    tryCreateQuestionnairePatient,
    tryQuestionnairePatient,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CoordinatorPatientEpicesScreen));
