import React from "react";
import PropTypes from "prop-types";
import { useHistory, withRouter, Redirect, Link } from "react-router-dom";

import { connect } from "react-redux";

import * as queryString from "query-string";

import { InputPassword, Button, Icon, AdminLayoutAsideAnonymous, AdminLayoutAnonymous, PageTitle } from "@rdcs/dap-front-library";

import { tryResetPassword } from "../../actions/auth";

const ResetPasswordScreen = ({
    auth: { resetPasswordSaved, resetPasswordError, resetPasswordLoading },
    tryResetPassword,
    location
}) => {
    const history = useHistory();

    const token = queryString.parse(history.location.search).token;

    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");

    const onSubmitCallback = event => {
        event.preventDefault();
        tryResetPassword(token, password, confirmPassword);
    };

    if (resetPasswordSaved) {
        return (
            <Redirect
                to={{
                    pathname: "/connexion",
                    state: { from: location }
                }}
            />
        );
    } else if (!token) {
        return (
            <Redirect
                to={{
                    pathname: "/",
                    state: { from: location }
                }}
            />
        );
    } else {
        return (
            <PageTitle title='Timkl - Nouveau mot de passe'>
                <AdminLayoutAnonymous>
                    <div className="grid credentials__container">
                        <AdminLayoutAsideAnonymous />
                        <main className="col-sm-50 credentialsMain__container">
                            <div className="credentialsMain">
                                <form onSubmit={onSubmitCallback}>
                                    <div className="credentials__title">Nouveau mot de passe</div>
                                    <div className="grid grid__padding-10">
                                        <div className="col-md-50">
                                            <InputPassword
                                                label="Votre nouveau mot de passe"
                                                defaultValue={password}
                                                onChange={setPassword}
                                            />
                                        </div>
                                        <div className="col-md-50">
                                            <InputPassword
                                                label="Confirmez votre mot de passe"
                                                defaultValue={confirmPassword}
                                                onChange={setConfirmPassword}
                                            />
                                        </div>
                                    </div>
                                    {resetPasswordError && <div className="my-2 txtError">{resetPasswordError}</div>}
                                    <div className="form__group text-center">
                                        <Link to="/connexion" className="form__back">
                                            <Button type="button" className="button__secondaryButton">
                                                <Icon name="arrow-left" /> Annuler
                                            </Button>
                                        </Link>
                                        <Button
                                            type="submit"
                                            className="button__primaryButton"
                                            isLoading={resetPasswordLoading}
                                        >
                                            Valider
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </main>
                    </div>
                </AdminLayoutAnonymous>
            </PageTitle>
        );
    }
};

ResetPasswordScreen.propTypes = {
    auth: PropTypes.shape({
        resetPasswordSaved: PropTypes.bool.isRequired,
        resetPasswordError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
        resetPasswordLoading: PropTypes.bool.isRequired
    }).isRequired,
    tryResetPassword: PropTypes.func.isRequired,
    location: PropTypes.object
};

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = {
    tryResetPassword
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordScreen));
