import React from "react";

const defaultState = {
    searchFetched: false,
    searchSsn: "",
    disabled: true,
    patient: {
        firstName: "",
        lastName: "",
        email: "",
        dateOfBirth: null,
        sex: null,
        phoneNumber: "",
        alternativePhoneNumber: null,
    }
};

const reducer = (prevState, action) => {
    switch (action.type) {
        case "SEARCH_FETCHED":
            return {
                ...prevState,
                searchFetched: action.searchFetched
            };
        case "SEARCH_SSN":
            return {
                ...prevState,
                searchSsn: action.searchSsn
            };
        case "DISABLED":
            return {
                ...prevState,
                disabled: action.disabled
            };
        case "PATIENT":
            return {
                ...prevState,
                searchFetched: true,
                patient: action.patient
            };
        default:
            return prevState;
    }
};

const useAddPatient = () => {
    const [state, dispatch] = React.useReducer(reducer, defaultState);

    return React.useMemo(
        () => [
            state,
            {
                startSearchFetched: () => dispatch({ type: "SEARCH_FETCHED", searchFetched: false }),

                setSearchSsn: searchSsn => dispatch({ type: "SEARCH_SSN", searchSsn }),

                setDisabled: disabled => dispatch({ type: "DISABLED", disabled }),

                fetchPatient: (firstName, lastName, email, dateOfBirth, sex, phoneNumber, alternativePhoneNumber) =>
                    dispatch({
                        type: "PATIENT",
                        patient: { firstName, lastName, email, dateOfBirth, sex, phoneNumber, alternativePhoneNumber }
                    }),
            }
        ],
        [state, dispatch]
    );
};

export default useAddPatient;
