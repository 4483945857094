import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { tryAddPartner } from "../../../actions/partners";

import PartnerForm from "../../../components/Settings/Forms/PartnerForm";

import SettingsFormScreen from "../../../components/Settings/SettingsFormScreen";

const AddPartnerScreen = ({ partners: { createLoading, createError }, tryAddPartner }) => (
    <SettingsFormScreen
        /* Document and page titles */
        documentTitle="Ajouter partenaire"
        pageTitle="Ajouter un partenaire"
        /* Redux request callback use to add on BDD */
        formRequest={tryAddPartner}
        /* Forms */
        itemForm={PartnerForm}
        itemFormRequestLoading={createLoading}
        itemFormRequestError={createError}
        /* Configuration tab name for redirection */
        tab="partners-list"
    />
);

AddPartnerScreen.propTypes = {
    partners: PropTypes.shape({
        createLoading: PropTypes.bool.isRequired,
        createError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object
            }),
            PropTypes.bool
        ]).isRequired
    }).isRequired,
    tryAddPartner: PropTypes.func.isRequired
};

const mapStateToProps = ({ partners }) => ({ partners });

const mapDispatchToProps = { tryAddPartner };

export default connect(mapStateToProps, mapDispatchToProps)(AddPartnerScreen);
