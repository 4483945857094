import * as types from "../actions/types";
import { toast } from "react-toastify";

const initialState = {
    list: [],
    listLoading: false,
    createLoading: false,
    createError: false,
    editLoading: false,
    editError: false,
    deleteLoading: false,
    deleteError: false,
    swapOrderLoading: false,
};

function taskLabels(state = initialState, action) {
    switch (action.type) {
        // FETCH
        case types.TASK_LABELS_FETCH_REQUESTED: {
            return Object.assign({}, state, { listLoading: true });
        }
        case types.TASK_LABELS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                listLoading: false,
                list: action.data,
            });
        }
        case types.TASK_LABELS_FETCH_FAILURE: {
            return Object.assign({}, state, { listLoading: false, list: [] });
        }
        // ADD
        case types.ADD_TASK_LABEL_REQUESTED: {
            return Object.assign({}, state, {
                createLoading: true,
                createError: false,
            });
        }
        case types.ADD_TASK_LABEL_SUCCESS: {
            toast.success("L'action a été créée");
            return Object.assign({}, state, { createLoading: false });
        }
        case types.ADD_TASK_LABEL_FAILURE: {
            toast.error("L'action n'a pas pu être créée");
            return Object.assign({}, state, {
                createLoading: false,
                createError: action.data,
            });
        }
        // EDIT
        case types.EDIT_TASK_LABEL_REQUESTED: {
            return Object.assign({}, state, {
                editLoading: true,
                editError: false,
            });
        }
        case types.EDIT_TASK_LABEL_SUCCESS: {
            toast.success("L'action a été modifiée");
            return Object.assign({}, state, { editLoading: false });
        }
        case types.EDIT_TASK_LABEL_FAILURE: {
            toast.error("L'action n'a pas pu être modifiée");
            return Object.assign({}, state, {
                editLoading: false,
                editError: action.data,
            });
        }
        // SWAP TaskLabel ORDER
        case types.SWAP_TASK_LABEL_ORDER_REQUESTED: {
            return Object.assign({}, state, { swapOrderLoading: true });
        }
        case types.SWAP_TASK_LABEL_ORDER_SUCCESS: {
            toast.success("L'ordre a été changé");

            return Object.assign({}, state, {
                swapOrderLoading: false,
                list: state.list.map((c) => {
                    if (c.id === action.data.taskLabelOne.id) c.order = action.data.taskLabelOne.order;
                    if (c.id === action.data.taskLabelTwo.id) c.order = action.data.taskLabelTwo.order;
                    return c;
                }),
            });
        }
        case types.SWAP_TASK_LABEL_ORDER_FAILURE: {
            toast.error("L'ordre des actions n'a pas pu être modifié");
            return Object.assign({}, state, { swapOrderLoading: false });
        }
        // TaskLabel delete
        case types.DELETE_TASK_LABEL_REQUESTED: {
            return Object.assign({}, state, { deleteLoading: true, deleteError: false });
        }
        case types.DELETE_TASK_LABEL_SUCCESS: {
            toast.success("L'action a bien été supprimée.");
            return Object.assign({}, state, {
                list: state.list.filter((item) => action.data !== item.id),
                deleteLoading: false,
            });
        }
        case types.DELETE_TASK_LABEL_FAILURE: {
            toast.error("L'action n'a pas pu être supprimée.");
            return Object.assign({}, state, { deleteLoading: false, deleteError: true });
        }
        // DEFAULT
        default:
            return state;
    }
}

export default taskLabels;
