import React from "react";
import PropTypes from "prop-types";
import { withRouter, useParams } from "react-router-dom";

import { connect } from "react-redux";
import { trySinglePatient } from "../../actions/patients";
import SubHeader from "./SubHeader";
import { DefaultLoader } from "@rdcs/dap-front-library";
import AppLayout from "../Layout/AppLayout";

import Header from "./Header";

const breadcrumbs = [{ label: "Facilitateurs", link: "/facilitateurs" }, { label: "Fiche patient" }];

const CoordinatorPatientLayout = ({ patients: { singlePatient }, patientLastDiagnosis, trySinglePatient, onPatientLoaded = () => null, children }) => {
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), []);

    const [initialized, setInitialized] = React.useState(false);

    React.useEffect(() => {
        if (!singlePatient || singlePatient["@id"] !== `/patients/${patientId}`) {
            trySinglePatient(patientId).then(() => setInitialized(true));
        } else {
            onPatientLoaded(singlePatient);
            setInitialized(true);
        }
    }, []);

    return (
        <AppLayout navActive={0} breadcrumbs={breadcrumbs}>
            {!initialized ? (
                <DefaultLoader />
            ) : (
                <>
                    <Header patient={singlePatient} lastDiagnosis={patientLastDiagnosis} />
                    <div className="border top_left_right_radius pb-4">
                        <SubHeader medicalPatient={singlePatient} canAccessStudyQuestion={true} />
                        <main className="dashboardContent">{children}</main>
                    </div>
                </>
            )}
        </AppLayout>
    );
};

CoordinatorPatientLayout.propTypes = {
    patients: PropTypes.shape({
        singlePatient: PropTypes.object,
        singlePatientLoading: PropTypes.bool.isRequired,
    }).isRequired,
    patientLastDiagnosis: PropTypes.object.isRequired,
    trySinglePatient: PropTypes.func.isRequired,
    onPatientLoaded: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

const mapStateToProps = ({ patients, diagnosis: { patientLastDiagnosis } }) => ({
    patients,
    patientLastDiagnosis,
});

const mapDispatchToProps = {
    trySinglePatient,
};

export default React.memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(CoordinatorPatientLayout)));
