import React from "react";
import { useHistory } from "react-router-dom";

const useRedirectIfItemNotExist = (destination, item) => {
    const history = useHistory();
    const [requestEnded, signalRequestEnded] = React.useState(false);

    React.useEffect(() => {
        if (requestEnded && !item) {
            history.push(destination);
        }
    }, [requestEnded, item, destination]);

    return React.useCallback(() => signalRequestEnded(true), [signalRequestEnded]);
};

export default useRedirectIfItemNotExist;
