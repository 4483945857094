import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { tryCreatePatientAppointment, tryFetchPatientAppointments, tryEditPatientAppointment } from "../../../../actions/patients";
import { tryFetchPractitioners } from "../../../../actions/medicals";
import { sendEmailToPartnerForRecommendation, tryFetchCurrentPartnerCheckUps } from "../../../../actions/partners";

import { TypeFilterSection, AppointmentCheckUpRecommended } from "@rdcs/dap-front-library";

import SubTitle from "../../../../components/Coordinator/SubTitle";

import recommendationFilters from "./recommendationFilters";
import LookLink from "../../LookLink";

const RecommendedVisits = ({
    isLoading,
    datas,
    patientId,
    patientIri,
    createAppointmentLoading,
    checkUps,
    checkUpsLoading,
    // diagnosis,
    tryFetchPractitioners,
    tryEditPatientAppointment,
    tryFetchPatientAppointments,
    tryCreatePatientAppointment,
    sendEmailToPartnerForRecommendation,
    tryFetchCurrentPartnerCheckUps,
}) => {
    React.useEffect(() => {
        tryFetchCurrentPartnerCheckUps(patientId);
    }, []);

    return (
        <TypeFilterSection
            title="Visites recommandées"
            icon="prescription"
            subTitle={<SubTitle>Liste de toutes les visites recommandées pour le patient.</SubTitle>}
            filters={recommendationFilters}
            datas={datas}
            onFilter="statusColor"
            className="mb-4"
            isLoading={isLoading && checkUpsLoading}
        >
            {(recoConsultsFiltered) =>
                recoConsultsFiltered.length > 0 ? (
                    <>
                        {recoConsultsFiltered.slice(0, 5).map((recoInstance) => {
                            return (
                                <AppointmentCheckUpRecommended
                                    key={recoInstance.id}
                                    dueAt={recoInstance.dueAt}
                                    statusColor={recoInstance.statusColor}
                                    recommendation={recoInstance.recommendation}
                                    validationNeeded={recoInstance.validationNeeded}
                                    appointment={recoInstance.appointment}
                                    lastAppointment={recoInstance.lastAppointment || null}
                                    showDescription={false}
                                    exams={datas.filter(({ recommendation: { consultationName } }) => consultationName === recoInstance.recommendation.title)}
                                    patientIri={patientIri}
                                    searchPractitionerAction={tryFetchPractitioners}
                                    createAppointmentLoading={createAppointmentLoading}
                                    createAppointmentAction={(appointment) =>
                                        tryCreatePatientAppointment(appointment).then(() => tryFetchPatientAppointments(patientId))}
                                    confirmAppointmentAction={tryEditPatientAppointment}
                                    partnerAction={() => sendEmailToPartnerForRecommendation(recoInstance.recommendation.id, patientId)}
                                    partnerCheckUps={checkUps}
                                />
                            );
                        })}
                        <p className="mt-2">
                            <LookLink link={`/facilitateurs/patient/${patientId}/visites`}>Voir toutes les visites recommandées.</LookLink>
                        </p>
                    </>
                ) : (
                    <p>Aucune visite recommandée</p>
                )
            }
        </TypeFilterSection>
    );
};

RecommendedVisits.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    datas: PropTypes.arrayOf(PropTypes.object).isRequired,
    patientId: PropTypes.number.isRequired,
    patientIri: PropTypes.string.isRequired,
    // diagnosis: PropTypes.arrayOf(PropTypes.object).isRequired,
    createAppointmentLoading: PropTypes.bool.isRequired,
    checkUps: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            patient: PropTypes.string.isRequired,
            partner: PropTypes.string.isRequired,
            createdAt: PropTypes.string.isRequired,
        })
    ).isRequired,
    checkUpsLoading: PropTypes.bool.isRequired,
    tryFetchPractitioners: PropTypes.func.isRequired,
    tryEditPatientAppointment: PropTypes.func.isRequired,
    tryCreatePatientAppointment: PropTypes.func.isRequired,
    tryFetchPatientAppointments: PropTypes.func.isRequired,
    sendEmailToPartnerForRecommendation: PropTypes.func.isRequired,
    tryFetchCurrentPartnerCheckUps: PropTypes.func.isRequired,
};

const mapStateToProps = ({ patients: { createAppointmentLoading }, partners: { checkUps, checkUpsLoading } }) => ({
    createAppointmentLoading,
    checkUps,
    checkUpsLoading,
});

const mapDispatchToProps = {
    tryFetchPractitioners,
    tryEditPatientAppointment,
    tryCreatePatientAppointment,
    tryFetchPatientAppointments,
    sendEmailToPartnerForRecommendation,
    tryFetchCurrentPartnerCheckUps,
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(RecommendedVisits));
