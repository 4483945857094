import React from "react";
import { useHistory } from "react-router-dom";

const useRequestFromFormAndRedirect = (destination, request, ...params) => {
    const history = useHistory();

    return React.useCallback(
        form =>
            request(...params, form)
                .then(() => history.push(destination))
                .catch(() => undefined),
        [history, request, ...params]
    );
};

export default useRequestFromFormAndRedirect;
