import * as types from "./types";
import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";
import store from "../store";

export const tryFetchTaskLabels = (auth) => (dispatch) => {
    dispatch({ type: types.TASK_LABELS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/task_labels" : "/dap-admin/api/v1/customers/patients/tasks-labels",
        queryParams: {
            pagination: false,
        },
        token: auth.accessToken,
    })
        .then((response) => dispatch({ type: types.TASK_LABELS_FETCH_SUCCESS, data: response["hydra:member"] }))
        .catch((error) => dispatch({ type: types.TASK_LABELS_FETCH_FAILURE, data: error }));
};

export const tryAddTaskLabel = (taskLabel) => (dispatch) => {
    const { auth } = store.getState();

    dispatch({ type: types.ADD_TASK_LABEL_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/task_labels" : "/dap-admin/api/v1/customers/patients/tasks-labels",
        token: auth.accessToken,
        method: "POST",
        data: taskLabel,
    })
        .then((response) => {
            dispatch({ type: types.ADD_TASK_LABEL_SUCCESS, data: response });
            return tryFetchTaskLabels(auth);
        })
        .catch((error) => {
            dispatch({ type: types.ADD_TASK_LABEL_FAILURE, data: error });
            throw error;
        });
};

export const tryEditTaskLabel = (taskLabelId, form) => (dispatch) => {
    const { auth } = store.getState();

    dispatch({ type: types.EDIT_TASK_LABEL_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/task_labels/{id}" : "/dap-admin/api/v1/customers/patients/tasks-labels/{id}",
        uriParams: {
            id: taskLabelId,
        },
        token: auth.accessToken,
        method: "PUT",
        data: form,
    })
        .then((response) => {
            dispatch({ type: types.EDIT_TASK_LABEL_SUCCESS, data: response });
            return tryFetchTaskLabels(auth);
        })
        .catch((error) => {
            dispatch({ type: types.EDIT_TASK_LABEL_FAILURE, data: error });
            throw error;
        });
};

export const tryDeleteTaskLabel = (auth, taskLabelId) => (dispatch) => {
    dispatch({ type: types.DELETE_TASK_LABEL_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/task_labels/{id}" : "/dap-admin/api/v1/customers/patients/tasks-labels/{id}",
        uriParams: {
            id: taskLabelId,
        },
        token: auth.accessToken,
        method: "DELETE",
    })
        .then(() => {
            dispatch({ type: types.DELETE_TASK_LABEL_SUCCESS, data: taskLabelId });
            return tryFetchTaskLabels(auth);
        })
        .catch((error) => dispatch({ type: types.DELETE_TASK_LABEL_FAILURE, data: error }));
};

export const trySwapTaskLabelOrder = (auth, taskLabelOne, taskLabelTwo) => (dispatch) => {
    dispatch({ type: types.SWAP_TASK_LABEL_ORDER_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/task_labels/{id}" : "/dap-admin/api/v1/customers/patients/tasks-labels/{id}",
        uriParams: {
            id: taskLabelOne.id,
        },
        token: auth.accessToken,
        method: "PUT",
        data: { order: taskLabelTwo.order },
    })
        .then(() =>
            dispatch({
                type: types.SWAP_TASK_LABEL_ORDER_SUCCESS,
                data: {
                    taskLabelOne: {
                        ...taskLabelOne,
                        order: taskLabelTwo.order,
                    },
                    taskLabelTwo: {
                        ...taskLabelTwo,
                        order: taskLabelOne.order,
                    },
                },
            })
        )
        .catch((error) => dispatch({ type: types.SWAP_TASK_LABEL_ORDER_FAILURE, data: error }));
};
