import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { tryFetchPractitioners } from "../../actions/medicals";
import AppLayout from "../../components/Layout/AppLayout";
import { DefaultLoader, AntdPagination } from "@rdcs/dap-front-library";
import { userCan } from "../../reducers/auth";

import MedicalCard from "./MedicalCard";

const itemsPerPage = 8;

const breadcrumbs = [{ label: "Professionnels de santé inscrits" }];

const MedicalsScreen = ({ medicals, tryFetchPractitioners }) => {
    const [loading, setLoading] = React.useState(true);
    const [currentPage, setCurrentPage] = React.useState(1);

    const fetchPractitioners = () =>
        tryFetchPractitioners({
            page: currentPage,
            itemsPerPage: itemsPerPage,
            orderByjustificatoryValid: true,
            order: "desc",
        }).then(() => setLoading(false));

    React.useEffect(() => {
        document.title = "Timkl - Modération";
    }, []);

    React.useEffect(() => {
        fetchPractitioners();
    }, [currentPage]);

    return (
        <AppLayout navActive={userCan("ROLE_ADMIN") ? 2 : userCan("ROLE_MODERATOR") ? 0 : 2} breadcrumbs={breadcrumbs}>
            <h1 className="title-1">Liste des professionnels de santé inscrits</h1>
            <div>
                {loading ? (
                    <DefaultLoader color="#CCC" />
                ) : medicals.practitionersList.length > 0 ? (
                    <>
                        <div className="moderationList form__group">
                            {medicals.practitionersList.map((medical, i) => (
                                <MedicalCard key={`${i}-${medical.id}`} medical={medical} fetchPractitioners={fetchPractitioners} />
                            ))}
                        </div>
                        <AntdPagination
                            className="mt-3"
                            pageSize={itemsPerPage}
                            current={currentPage}
                            onChange={setCurrentPage}
                            total={medicals.practitionersCount}
                            hideOnSinglePage
                        />
                    </>
                ) : (
                    <p className="mt-2">Il n'y a pas de médecins.</p>
                )}
            </div>
        </AppLayout>
    );
};

MedicalsScreen.propTypes = {
    medicals: PropTypes.object.isRequired,
    tryFetchPractitioners: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    medicals: state.medicals,
});

const mapDispatchToProps = {
    tryFetchPractitioners,
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicalsScreen);
