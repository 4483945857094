import React from "react";
import PropTypes from "prop-types";

import { Button, Icon } from "@rdcs/dap-front-library";
import { PDFDownloadLink } from "@react-pdf/renderer";
import moment from "moment";

import PDFDocument from "./PDFDocument";

const PDFConsults = ({ diagnostics, patient, patient: { firstName, lastName }, hcp, nbOfMonths }) => (
    <PDFDownloadLink
        document={<PDFDocument diagnostics={diagnostics} patient={patient} hcp={hcp} nbOfMonths={nbOfMonths} />}
        fileName={`notes-consultation-${firstName}-${lastName}-${moment(new Date()).format("DD-MM-YYYY")}.pdf`}
    >
        {({ loading }) => (
            <Button className="button__primaryButton button--large d-inline-flex-important" type="button" isLoading={loading}>
                <Icon name={loading ? "pending" : "document-pdf"} size={24} className="mr-1" />
                <p>
                    {loading
                        ? "Chargement du PDF...."
                        : nbOfMonths === 3
                        ? "Télécharger la synthèse des 3 prochains mois"
                        : "Télécharger la synthèse des 12 prochains mois"}
                </p>
            </Button>
        )}
    </PDFDownloadLink>
);

PDFConsults.propTypes = {
    diagnostics: PropTypes.arrayOf(
        PropTypes.shape({
            "@id": PropTypes.string,
            id: PropTypes.number,
            active: PropTypes.bool,
            dueAt: PropTypes.string,
            recommendation: PropTypes.shape({
                "@id": PropTypes.string,
                id: PropTypes.number,
                title: PropTypes.string,
                type: PropTypes.oneOf(["consultation", "information", "examination"]),
            }),
        })
    ),
    patient: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
    }),
    hcp: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
    }),
    nbOfMonths: PropTypes.number.isRequired,
};

export default PDFConsults;
