import "react-app-polyfill/ie11";
import "moment/locale/fr";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
import App from "./App";
import ErrorScreen from "./screens/Utils/ErrorScreen";
import LoadingScreen from "./screens/Utils/LoadingScreen";
import API from "./utils/API";
import { isLocalAPI } from "./utils/utils";

ReactDOM.render(<LoadingScreen />, document.getElementById("root"));

// TODO : Find better way to store these creds in dap-front-library
window.fileRetrieveUrl = isLocalAPI
    ? "http://localhost:81/files/retrieve"
    : window.env.API_URL + "/dap-admin/api/v1/files/retrieve";
window.fileRetrieveHeaders = {
    client_id: window.env.API_CLIENT_ID,
    client_secret: window.env.API_CLIENT_SECRET,
    Authorization: "Bearer " + localStorage.getItem("access_token")
};

API.request({
    path: isLocalAPI ? "/constants" : "/dap-admin/api/v1/settings/constants"
})
    .then(constants => {
        window.CONSTANTS = constants;
        ReactDOM.render(
            <Provider store={store}>
                <App />
            </Provider>,
            document.getElementById("root")
        );
    })
    .catch(() => {
        ReactDOM.render(
            <ErrorScreen title="Erreur" content="Le service est indisponible, veuillez réessayer ultérieurement" />,
            document.getElementById("root")
        );
    });
