import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ onChange, checked }) => 
    <p className='text-center'>
        <input
            type='checkbox'
            checked={checked}
            onChange={ev => onChange(ev.target.checked)}
        />
    </p>;

Checkbox.propTypes = {
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
}

export default Checkbox;