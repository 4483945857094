import * as types from "../actions/types";
import { toast } from "react-toastify";

const initialState = {
    list: [],
    listLoading: false,
    createLoading: false,
    createError: false,
    editLoading: false,
    editError: false,
    deleteLoading: false,
    deleteError: false,
};

function admins(state = initialState, action) {
    switch (action.type) {
        // FETCH
        case types.ADMINS_FETCH_REQUESTED: {
            return Object.assign({}, state, { listLoading: true });
        }
        case types.ADMINS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                listLoading: false,
                list: action.data,
            });
        }
        case types.ADMINS_FETCH_FAILURE: {
            return Object.assign({}, state, { listLoading: false, list: [] });
        }
        // ADD
        case types.ADD_ADMIN_REQUESTED: {
            return Object.assign({}, state, {
                createLoading: true,
                createError: false,
            });
        }
        case types.ADD_ADMIN_SUCCESS: {
            toast.success("L'utilisateur a été créé");
            return Object.assign({}, state, {
                createLoading: false,
                list: [...state.list, action.data],
            });
        }
        case types.ADD_ADMIN_FAILURE: {
            toast.error("L'utilisateur n'a pas pu être créé");
            return Object.assign({}, state, {
                createLoading: false,
                createError: action.data,
            });
        }
        // EDIT
        case types.EDIT_ADMIN_REQUESTED: {
            return Object.assign({}, state, {
                editLoading: true,
                editError: false,
            });
        }
        case types.EDIT_ADMIN_SUCCESS: {
            toast.success("L'utilisateur a été modifié");
            return Object.assign({}, state, {
                editLoading: false,
                list: state.list.map((item) => (item["@id"] === action.data["@id"] ? action.data : item)),
            });
        }
        case types.EDIT_ADMIN_FAILURE: {
            toast.error("L'utilisateur n'a pas pu être modifié");
            return Object.assign({}, state, {
                editLoading: false,
                editError: action.data,
            });
        }
        // REMOVE
        case types.REMOVE_ADMIN_REQUESTED: {
            return Object.assign({}, state, {
                deleteLoading: true,
                deleteError: false,
            });
        }
        case types.REMOVE_ADMIN_SUCCESS: {
            toast.success("L'utilisateur a été supprimé");
            return Object.assign({}, state, {
                deleteLoading: false,
                list: state.list.filter((item) => item["@id"] !== action.data),
            });
        }
        case types.REMOVE_ADMIN_FAILURE: {
            toast.error("L'utilisateur n'a pas pu être supprimé");
            return Object.assign({}, state, {
                deleteLoading: false,
                deleteError: action.data,
            });
        }
        default:
            return state;
    }
}

export default admins;
