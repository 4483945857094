import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { ModerationListItem } from "@rdcs/dap-front-library";

import SettingsTabLayout from "../../../components/Settings/SettingsTabLayout";

const CoordinatorTab = ({ loading, coordinators, onFetch, delete: onDelete, auth }) => (
    <SettingsTabLayout
        title="Facilitateurs"
        icon="pro"
        addButton={{
            link: "/configuration/ajouter-facilitateur",
            label: "Ajouter un facilitateur"
        }}
        isLoading={loading}
    >
        {coordinators.map(coordinator => (
            <ModerationListItem
                key={coordinator["@id"]}
                label={`${coordinator.firstName} ${coordinator.lastName}`}
                info={coordinator.email}
                modifyLink={`/configuration/modifier-facilitateur/${coordinator.id}`}
                onDelete={() => onDelete(auth, coordinator.id).then(() => onFetch(auth))}
                deleteText="Supprimer l'utilisateur"
            />
        ))}
    </SettingsTabLayout>
);

CoordinatorTab.propTypes = {
    loading: PropTypes.bool.isRequired,
    coordinators: PropTypes.arrayOf(PropTypes.object).isRequired,
    onFetch: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps)(CoordinatorTab);
