import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import Questions from "../../../actions/questions";
import { tryCreateQuestionnairePatient } from "../../../actions/questionnaire";
import useState from "../../../screens/BreakScoreQuestionnaireScreen/useState";

import { DefaultLoader } from "@rdcs/dap-front-library";

import Step1 from "./Step1";
import Step2 from "./Step2";

const { tryFetchBreakScoreQuestions, tryFetchBreakScoreNoInsulinQuestions } = Questions;

const BreakScoreForExistingPatient = ({
    tryFetchBreakScoreQuestions,
    tryFetchBreakScoreNoInsulinQuestions,
    questions: { breakScoreQuestions, breakScoreNoInsulinQuestions, breakScoreQuestionsLoading, createLoading },
    tryCreateQuestionnairePatient,
    singlePatient,
    setFillNewQuestionnaire,
}) => {
    const [{ loading, currentStep, patient }, { load, prevStep, nextStepFrom0To1 }] = useState(breakScoreQuestions.length === 0);

    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);
    const patientIri = React.useMemo(() => `/patients/${patientId}`, [patientId]);

    const [isInsulinoQuestions, setInsulinoQuestions] = React.useState();
    const [isNonInsulinoQuestions, setNonInsulinoQuestions] = React.useState();
    const [isInsulino, setInsulino] = React.useState(false);

    React.useEffect(() => {
        if (loading && !breakScoreQuestionsLoading) {
            load();
        }
    }, [breakScoreQuestionsLoading]);

    React.useEffect(() => {
        Promise.allSettled([tryFetchBreakScoreQuestions(), tryFetchBreakScoreNoInsulinQuestions()])
            .then(([breakScoreQuestions, breakScoreNoInsulinQuestions]) => {
                setInsulinoQuestions(breakScoreQuestions.value.filter(({ active }) => active));
                setNonInsulinoQuestions(breakScoreNoInsulinQuestions.value.filter(({ active }) => active));
            })
            .catch(load);
    }, []);

    const onSubmit = async (responses) => {
        const whichQuestions = isInsulino ? breakScoreQuestions : breakScoreNoInsulinQuestions;
        const whichType = isInsulino ? "break-score" : "break-score-no-insulin";

        const answers = whichQuestions.map(({ label }, i) => {
            const responseKey = `response${i}`;
            const response = responses[responseKey];

            return {
                values: [response],
                question: label,
            };
        });

        const params = {
            patient: patientIri,
            type: whichType,
            answers: answers,
        };

        if (params.id) {
            delete params.id;
            delete params.filledBy;
        }
        await tryCreateQuestionnairePatient(params);
        setFillNewQuestionnaire(false);
    };

    const stepRender = React.useMemo(() => {
        if (loading) {
            return <DefaultLoader />;
        }

        switch (currentStep) {
            case 0:
                return <Step1 onSubmit={nextStepFrom0To1} patient={patient} singlePatient={singlePatient} setInsulino={setInsulino} />;
            case 1:
                return (
                    <Step2
                        onSubmit={onSubmit}
                        prevStep={prevStep}
                        questions={isInsulino ? isInsulinoQuestions : isNonInsulinoQuestions}
                        isLoading={createLoading}
                        answers={patient.answers}
                    />
                );
            default:
                return undefined;
        }
    }, [currentStep, loading, createLoading, onSubmit]);

    return stepRender;
};

BreakScoreForExistingPatient.propTypes = {
    questions: PropTypes.object.isRequired,
    tryCreateQuestionnairePatient: PropTypes.func.isRequired,
    tryFetchBreakScoreQuestions: PropTypes.func.isRequired,
    tryFetchBreakScoreNoInsulinQuestions: PropTypes.func.isRequired,
    singlePatient: PropTypes.object.isRequired,
    setFillNewQuestionnaire: PropTypes.func.isRequired,
};

const mapStateToProps = ({ questions, patients: { singlePatient } }) => ({
    questions,
    singlePatient,
});

const mapDispatchToProps = {
    tryCreateQuestionnairePatient,
    tryFetchBreakScoreQuestions,
    tryFetchBreakScoreNoInsulinQuestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(BreakScoreForExistingPatient);
