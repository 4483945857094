import { toast } from "react-toastify";

import * as types from "./types";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import store from "../store";

export const tryFetchThematic = () => (dispatch) => {
    const {
        auth: { accessToken },
        thematic: { datas, loaded },
    } = store.getState();

    if (loaded) {
        return Promise.resolve(datas);
    }

    dispatch({ type: types.THEMATICS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/thematics" : "/dap-admin/api/v1/customers/patients/thematics",
        queryParams: {
            pagination: false,
        },
        token: accessToken,
    })
        .then((response) => {
            dispatch({ type: types.THEMATICS_FETCH_SUCCESS, data: response["hydra:member"] });
            return response["hydra:member"];
        })
        .catch((error) => {
            dispatch({ type: types.THEMATICS_FETCH_FAILURE, data: error });
            throw error;
        });
};

export const tryCreateThematic = (data) => (dispatch) => {
    const {
        auth: { accessToken },
    } = store.getState();

    dispatch({ type: types.THEMATICS_CREATE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/thematics" : "/dap-admin/api/v1/customers/patients/thematics",
        method: "POST",
        token: accessToken,
        data,
    })
        .then((response) => {
            toast.success("La thématique a bien été créée.");
            dispatch({ type: types.THEMATICS_CREATE_SUCCESS, data: response });
            return response;
        })
        .catch((error) => {
            toast.error("Une erreur s'est produite lors de la création de la thématique");
            dispatch({ type: types.THEMATICS_CREATE_FAILURE, data: error });
            throw error;
        });
};

export const tryEditThematic = (id, data) => (dispatch) => {
    const {
        auth: { accessToken },
    } = store.getState();

    dispatch({ type: types.THEMATICS_EDIT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/thematics/{id}" : "/dap-admin/api/v1/customers/patients/thematics/{id}",
        uriParams: {
            id,
        },
        method: "PUT",
        token: accessToken,
        data,
    })
        .then((response) => {
            toast.success("La thématique a bien été modifiée.");
            dispatch({ type: types.THEMATICS_EDIT_SUCCESS, data: response });
            return response;
        })
        .catch((error) => {
            toast.error("Une erreur s'est produite lors de la modification de la thématique");
            dispatch({ type: types.THEMATICS_EDIT_FAILURE, data: error });
            throw error;
        });
};

export const tryDeleteThematic = (id) => (dispatch) => {
    const {
        auth: { accessToken },
    } = store.getState();

    dispatch({ type: types.THEMATICS_DELETE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/thematics/{id}" : "/dap-admin/api/v1/customers/patients/thematics/{id}",
        uriParams: {
            id,
        },
        method: "DELETE",
        token: accessToken,
    })
        .then((response) => {
            toast.success("La thématique a bien été supprimée.");
            dispatch({ type: types.THEMATICS_DELETE_SUCCESS, data: id });
            return response;
        })
        .catch((error) => {
            toast.error("Une erreur s'est produite lors de la suppression de la thématique");
            dispatch({ type: types.THEMATICS_DELETE_FAILURE, data: error });
            throw error;
        });
};
