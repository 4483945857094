import React from "react";
import PropTypes from "prop-types";

import { Select, Button, InputNumber, Icon } from "@rdcs/dap-front-library";
import { Link } from "react-router-dom";
import { constant } from "../../../utils/utils";

const reducer = (prevState, action) => {
    switch (action.type) {
        case "LEVEL_ONE":
            return {
                ...prevState,
                ...(action.levelOne !== ""
                    ? {
                          levelOne: action.levelOne
                      }
                    : {
                          levelOne: null,
                          levelTwo: null,
                          levelThree: null
                      })
            };
        case "LEVEL_TWO":
            return {
                ...prevState,
                ...(action.levelTwo !== ""
                    ? {
                          levelTwo: action.levelTwo
                      }
                    : {
                          levelTwo: null,
                          levelThree: null
                      })
            };
        case "LEVEL_THREE":
            return {
                ...prevState,
                levelThree: action.levelThree || null
            };
        case "ASG_GOAL":
            return {
                ...prevState,
                asgGoal: parseInt(action.asgGoal)
            };
        case "ASG_GOAL_UNIT":
            return {
                ...prevState,
                asgGoalUnit: action.asgGoalUnit
            };
        default:
            return prevState;
    }
};

const TreatmentGroupForm = ({
    editedTreatmentGroup = {
        levelOne: null,
        levelTwo: null,
        levelThree: null,
        asgGoal: null,
        asgGoalUnit: null
    },
    treatments = [],
    errors = {},
    onSubmit,
    location,
    isLoading
}) => {
    const asgGoalUnitsConstant = React.useMemo(() => constant("asgGoalUnits"), []);
    const asgGoalUnitsConstantOptions = React.useMemo(
        () =>
            asgGoalUnitsConstant.map(asgGoalUnit => ({
                label: constant("asgGoalUnits", asgGoalUnit),
                value: asgGoalUnit
            })),
        [asgGoalUnitsConstant]
    );
    const [{ levelOne, levelTwo, levelThree, asgGoal, asgGoalUnit }, dispatch] = React.useReducer(reducer, {
        levelOne: editedTreatmentGroup.levelOne && editedTreatmentGroup.levelOne["@id"],
        levelTwo: editedTreatmentGroup.levelTwo && editedTreatmentGroup.levelTwo["@id"],
        levelThree: editedTreatmentGroup.levelThree && editedTreatmentGroup.levelThree["@id"],
        asgGoal: editedTreatmentGroup.asgGoal,
        asgGoalUnit: editedTreatmentGroup.asgGoalUnit || asgGoalUnitsConstant[0]
    });

    const setLevelOne = levelOne => dispatch({ type: "LEVEL_ONE", levelOne });
    const setLevelTwo = levelTwo => dispatch({ type: "LEVEL_TWO", levelTwo });
    const setLevelThree = levelThree => dispatch({ type: "LEVEL_THREE", levelThree });
    const setAsgGoal = asgGoal => dispatch({ type: "ASG_GOAL", asgGoal });
    const setAsgGoalUnit = asgGoalUnit => dispatch({ type: "ASG_GOAL_UNIT", asgGoalUnit });

    const onSubmitCallback = event => {
        event.preventDefault();
        onSubmit({
            levelOne,
            levelTwo,
            levelThree,
            asgGoal,
            asgGoalUnit
        });
    };

    const getTreatmentOptions = React.useMemo(
        () => [{ label: "--", value: "" }, ...treatments.map(t => ({ label: t.name, value: t["@id"] }))],
        [treatments]
    );

    return (
        <form onSubmit={onSubmitCallback}>
            <div className="grid grid__padding-10">
                <div className="col-33">
                    <Select
                        label="Premier niveau de traitement"
                        error={errors.levelOne}
                        onChange={setLevelOne}
                        defaultValue={levelOne}
                        options={getTreatmentOptions}
                        required
                    />
                </div>
                {levelOne && (
                    <div className="col-33">
                        <Select
                            label="Second niveau de traitement"
                            error={errors.levelTwo}
                            onChange={setLevelTwo}
                            defaultValue={levelTwo}
                            options={getTreatmentOptions}
                        />
                    </div>
                )}
                {levelTwo && (
                    <div className="col-33">
                        <Select
                            label="Troisième niveau de traitement"
                            error={errors.levelThree}
                            onChange={setLevelThree}
                            defaultValue={levelThree}
                            options={getTreatmentOptions}
                        />
                    </div>
                )}
            </div>
            <div className="grid grid__padding-10">
                <div className="col-33">
                    <InputNumber
                        label="Objectif ASG"
                        error={errors.asgGoal}
                        legend="Saisissez un nombre de tests par jour"
                        min={1}
                        defaultValue={asgGoal}
                        onChange={setAsgGoal}
                    />
                </div>
                <div className="col-33">
                    <Select
                        label="Unité de l'objectif"
                        error={errors.asgGoalUnit}
                        onChange={setAsgGoalUnit}
                        defaultValue={asgGoalUnit}
                        options={asgGoalUnitsConstantOptions}
                    />
                </div>
            </div>
            <div className="form__group col-100">
                <Link
                    to={{
                        pathname: "/configuration",
                        state: { from: location },
                        tab: "treatment-groups"
                    }}
                >
                    <Button className="button__secondaryButton">
                        <Icon name="arrow-left" className="mr-1" /> Retour
                    </Button>
                </Link>
                <Button type="submit" className="button__primaryButton" isLoading={isLoading}>
                    Enregistrer
                </Button>
            </div>
        </form>
    );
};

TreatmentGroupForm.propTypes = {
    editedTreatmentGroup: PropTypes.shape({
        levelOne: PropTypes.shape({
            "@id": PropTypes.string.isRequired
        }),
        levelTwo: PropTypes.shape({
            "@id": PropTypes.string.isRequired
        }),
        levelThree: PropTypes.shape({
            "@id": PropTypes.string.isRequired
        }),
        asgGoal: PropTypes.number,
        asgGoalUnit: PropTypes.string
    }),
    treatments: PropTypes.arrayOf(
        PropTypes.shape({
            "@id": PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
        })
    ).isRequired,
    errors: PropTypes.shape({
        levelOne: PropTypes.string,
        levelTwo: PropTypes.string,
        levelThree: PropTypes.string,
        asgGoal: PropTypes.string,
        asgGoalUnit: PropTypes.string
    }),
    onSubmit: PropTypes.func.isRequired,
    location: PropTypes.any,
    isLoading: PropTypes.bool.isRequired
};

export default TreatmentGroupForm;
