import React from "react";
import PropTypes from "prop-types";

import { ModerationListItemSwitch } from "@rdcs/dap-front-library";

import SettingsTabLayout from "../../../components/Settings/SettingsTabLayout";

const BreakScoreNoInsulinQuestionsTab = ({ loading, breakScoreNoInsulinQuestions, activate, swapOrder, isSwapingOrder }) => (
    <SettingsTabLayout
        title="Question de rupture pour patient non-insulinotraité"
        icon="informations"
        addButton={{
            link: "/configuration/ajouter-question-rupture-non-insulinotraite",
            label: "Ajouter une question",
        }}
        isLoading={loading}
    >
        {breakScoreNoInsulinQuestions
            .sort((a, b) => (a.order < b.order ? -1 : 1))
            .map((breakScoreNoInsulinQuestion, index, _breakScoreNoInsulinQuestions) => (
                <ModerationListItemSwitch
                    key={index}
                    label={breakScoreNoInsulinQuestion.label}
                    onChange={(value) =>
                        activate(breakScoreNoInsulinQuestion, value)
                            .then(() => value)
                            .catch(() => !value)
                    }
                    defaultChecked={breakScoreNoInsulinQuestion.active}
                    modifyLink={`/configuration/modifier-question-rupture-non-insulinotraite/${breakScoreNoInsulinQuestion.id}`}
                    swapOrder={{
                        current: breakScoreNoInsulinQuestion,
                        onSwap: (item1, item2) => swapOrder(item1, item2),
                        prev: _breakScoreNoInsulinQuestions[index - 1],
                        next: _breakScoreNoInsulinQuestions[index + 1],
                        isSwapingOrder: isSwapingOrder,
                    }}
                    confirmModal={{
                        title: "Merci de confirmer",
                        body: "Voulez-vous désactiver cette question de rupture ?",
                    }}
                />
            ))}
    </SettingsTabLayout>
);

BreakScoreNoInsulinQuestionsTab.propTypes = {
    loading: PropTypes.bool.isRequired,
    breakScoreNoInsulinQuestions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            active: PropTypes.bool.isRequired,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        })
    ).isRequired,
    activate: PropTypes.func.isRequired,
    swapOrder: PropTypes.func.isRequired,
    isSwapingOrder: PropTypes.bool.isRequired,
};

export default BreakScoreNoInsulinQuestionsTab;
