import React from "react";
import PropTypes from 'prop-types';
import { withRouter, useParams } from "react-router-dom";

import { connect } from "react-redux";
import { trySinglePatient, tryAddPractitionerToPatient, tryEditPatient } from "../../actions/patients";
import { tryCreateMedicalFromRpps } from "../../actions/medicals";

import { Icon, DefaultLoader, ModalSelectPractitioner, Modal, InputEmail, Button } from "@rdcs/dap-front-library";

import CoordinatorPatientLayout from "../../components/Coordinator/CoordinatorPatientLayout";
import PatientsProSanteItem from "../../components/Coordinator/PatientsProSanteItem";
import API from "../../utils/API";
import { isLocalAPI } from "../../utils/utils";

import defaultUserImage from "@rdcs/dap-front-library/static/images/default-user.png";

const arrayOfProfessions = (myArray) => {
    const arrayOfProfessions = [];
    const result = [];

    myArray.forEach(item => {
        if (arrayOfProfessions.indexOf(item.user.profession) === -1 && arrayOfProfessions !== undefined) {
            arrayOfProfessions.push(item.user.profession);
            result.push({
                visuel: defaultUserImage,
                title: item.user.profession
            });
        }
    });
    return result;
}

const EmailModal = React.forwardRef(({
    medicals,
    setEmail,
    handleCreateAndAddPractitioner,
    isLoading,
}, ref) => (
    <Modal ref={ref}>
        <div className="modal__title">Ajouter un professionnel de santé</div>
        <div className="bgNotice radius shadow mb-2 pt-2 pl-3 pb-2 pr-3">
            Ce professionnel n'est pas encore inscrit sur la plateforme
        </div>
        {medicals.createFromRppsError && (
            <div className="bgError radius shadow mb-2 pt-2 pl-3 pb-2 pr-3">
                {medicals.createFromRppsError}
            </div>
        )}
        <InputEmail
            label="Email"
            placeholder="Saisissez l'email du professionnel de santé"
            required
            onChange={setEmail}
        />
        <div className="modal__btnGroup">
            <Button
                className="button button__primaryButton"
                onClick={handleCreateAndAddPractitioner}
                isLoading={isLoading}
            >
                Envoyer l'invitation
            </Button>
        </div>
    </Modal>
));

const CoordinatorPatientProSanteScreen = ({
    auth,
    patients,
    medicals,
    trySinglePatient,
    tryAddPractitionerToPatient,
    tryCreateMedicalFromRpps,
    tryEditPatient
}) => {
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);

    const emailModalRef = React.useRef();

    const [searchRppsLoading, setSearchRppsLoading] = React.useState(false);
    const [createAndAddLoading, setCreateAndAddLoading] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [rpps, setRpps] = React.useState(null);
    
    React.useEffect(() => {
        document.title = "Timkl - Mes Patients";
    }, []);

    const handleCreateAndAddPractitioner = (e) => {
        e.preventDefault();
        setCreateAndAddLoading(true);
        tryCreateMedicalFromRpps(auth, rpps, email).then(res => {
            emailModalRef.current && emailModalRef.current.toggleModal();
            return tryAddPractitionerToPatient(auth, `/users/${res.id}`, `/patients/${patientId}`);
        })
        .then(() => {
            return trySinglePatient(auth, patientId);
        })
        .catch(() => setCreateAndAddLoading(false));
    }

    const handleSearchAndAddPractitioner = (e) => {
        e.preventDefault();
        setSearchRppsLoading(true);
        API.request({
            path: isLocalAPI
                ? "/find_practitioner_by_rpps/{rpps}"
                : "/dap-admin/api/v1/customers/practitioners/find-by-rpps/{rpps}",
            uriParams: {
                rpps: rpps
            },
            token: auth.accessToken
        })
        .then(res => tryAddPractitionerToPatient(auth, `/users/${res.id}`, `/patients/${patientId}`))
        .catch(() => {
            emailModalRef.current && emailModalRef.current.toggleModal();
        })
        .finally(() => setSearchRppsLoading(false));
    }

    const searchInRpps = (params) => {
        const excludedRpps = patients.singlePatient.accessedBy.map(aR => aR.user.rppsNumber);
        const queryParams = {
            discr: params.type,
            page: params.page,
            itemsPerPage: params.itemsPerPage
        };

        if (params.city) {
            queryParams["city"] = params.city;
        }

        if (params.fullName) {
            queryParams["fullName"] = params.fullName;
        }
        
        return API.request({
            token: auth.accessToken,
            path: isLocalAPI ? "/rpps_practitioners" : "/dap-admin/api/v1/customers/practitioners/rpps",
            queryParams: queryParams
        }).then(response => {
            response["hydra:member"] = response["hydra:member"].filter(
                p => excludedRpps.indexOf(p.rppsNumber) === -1
            );
            return response;
        })
        .catch(error => {
            throw new Error(error.message);
        });
    }

    const putPointOfContact = (HCPID) => (
        tryEditPatient(auth, patientId, { pointOfContact: { id: HCPID } })
        .then(() => trySinglePatient(auth, patientId))
    );
    return (
        <CoordinatorPatientLayout activeNav="practitioners">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    <ModalSelectPractitioner
                        labels={{
                            title: "Ajouter un professionnel de santé",
                            buttonModal: "Ajouter",
                            buttonSave: "Ajouter"
                        }}
                        isLoading={patients.addPractitionerLoading || searchRppsLoading}
                        searchAction={searchInRpps}
                        onChange={rppsPractitioner => setRpps(rppsPractitioner && rppsPractitioner.rppsNumber)}
                        onSubmit={handleSearchAndAddPractitioner}
                    />
                    <EmailModal
                        ref={emailModalRef}
                        medicals={medicals}
                        setEmail={setEmail}
                        handleCreateAndAddPractitioner={handleCreateAndAddPractitioner}
                        isLoading={patients.addPractitionerLoading || createAndAddLoading}
                    />
                </div>
            </div>
            {patients.singlePatientLoading || !patients.singlePatient ? (
                <DefaultLoader />
            ) : patients.singlePatient.accessedBy.length === 0 ? (
                <p>Aucun médecin n'est attitré à ce patient</p>
            ) : (
                    arrayOfProfessions(patients.singlePatient.accessedBy)
                    .sort(function(a, b) {
                        if (a.title < b.title) return -1;
                        if (a.title > b.title) return 1;
                        return 0;
                    })
                    .map((item, index) => (
                        <div key={index} className="proSanteList">
                            <div
                                className="proSanteList__photo"
                                style={{ backgroundImage: "url(" + item.visuel + ")" }}
                            >
                                <img src={item.visuel} alt={"Icône de " + item.title} />
                            </div>{" "}
                            <span className="text-2">{item.title}</span>
                            {patients.singlePatient.accessedBy
                                .filter(aR => aR.user.profession === item.title)
                                .map((ar, index) => {
                                    return (
                                        <PatientsProSanteItem
                                            key={index}
                                            accessRight={ar}
                                            pointOfContact={patients.singlePatient.pointOfContact}
                                            onSubmit={HCPID => putPointOfContact(HCPID)}
                                        />
                                    );
                                })}
                        </div>
                    ))
            )}
        </CoordinatorPatientLayout>
    );

}

CoordinatorPatientProSanteScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    patients: PropTypes.object.isRequired,
    medicals: PropTypes.object.isRequired,
    trySinglePatient: PropTypes.func.isRequired,
    tryAddPractitionerToPatient: PropTypes.func.isRequired,
    tryCreateMedicalFromRpps: PropTypes.func.isRequired,
    tryEditPatient: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth, patients, medicals }) => ({ auth, patients, medicals });

const mapDispatchToProps = {
    trySinglePatient,
    tryAddPractitionerToPatient,
    tryCreateMedicalFromRpps,
    tryEditPatient
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CoordinatorPatientProSanteScreen));