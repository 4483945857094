import React from "react";
import PropTypes from "prop-types";

const PatientLastDiagnosticQuestionsItem = ({ answers }) => {
    return (
        <div>
            {answers.map(answer => {
                const { question, value } = answer;
                const { type, label } = question;
                let val = null;

                if (type === "boolean") {
                    val = value === "true" ? "Oui" : "Non";
                } else if (type === "text") {
                    val = value;
                }

                return (
                    <div className="mt-2">
                        <p className="mb-2">{`${label} ${val}`}</p>
                    </div>
                );
            })}
        </div>
    );
};

PatientLastDiagnosticQuestionsItem.propTypes = {
    answers: PropTypes.arrayOf(
        PropTypes.shape({
            "@id": PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            question: PropTypes.shape({
                "@id": PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                type: PropTypes.oneOf(["boolean", "text"])
            })
        })
    )
};

export default PatientLastDiagnosticQuestionsItem;
