import React from "react";
import PropTypes from "prop-types";

import { ModerationListItemSwitch } from "@rdcs/dap-front-library";

import SettingsTabLayout from "../../../components/Settings/SettingsTabLayout";

const StudyQuestionsTab = ({ loading, studyQuestions, activate, swapOrder, isSwapingOrder }) => (
    <SettingsTabLayout
        title="Question pour étude"
        icon="informations"
        addButton={{
            link: "/configuration/ajouter-question-etude",
            label: "Ajouter une question",
        }}
        isLoading={loading}
    >
        {studyQuestions
            .sort((a, b) => (a.order < b.order ? -1 : 1))
            .map((studyQuestion, index, studyQuestions) => (
                <ModerationListItemSwitch
                    key={index}
                    label={studyQuestion.label}
                    onChange={(value) =>
                        activate(studyQuestion, value)
                            .then(() => value)
                            .catch(() => !value)
                    }
                    defaultChecked={studyQuestion.active}
                    modifyLink={`/configuration/modifier-question-etude/${studyQuestion.id}`}
                    swapOrder={{
                        current: studyQuestion,
                        onSwap: (item1, item2) => swapOrder(item1, item2),
                        prev: studyQuestions[index - 1],
                        next: studyQuestions[index + 1],
                        isSwapingOrder: isSwapingOrder,
                    }}
                    confirmModal={{
                        title: "Merci de confirmer",
                        body: "Cette question a peut-être été posée à des patients dans le cadre du questionnaire d'étude, voulez-vous la désactiver ?",
                    }}
                />
            ))}
    </SettingsTabLayout>
);

StudyQuestionsTab.propTypes = {
    loading: PropTypes.bool.isRequired,
    studyQuestions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            active: PropTypes.bool.isRequired,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        })
    ).isRequired,
    activate: PropTypes.func.isRequired,
    swapOrder: PropTypes.func.isRequired,
    isSwapingOrder: PropTypes.bool.isRequired,
};

export default StudyQuestionsTab;
