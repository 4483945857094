import React from "react";

const defaultState = {
    oldPage: -1,
    page: 1,
    patientsList: [],
    coordinatorIsLoading: true,
    filter: {}
};

const reducer = (prevState, action) => {
    switch (action.type) {
        case 'LOAD':
            return {
                ...prevState,
                coordinatorIsLoading: false,
            }
        case 'CHANGE_PAGE':
            return {
                ...prevState,
                oldPage: prevState.page,
                page: action.page,
            }
        case 'FILTER_CHANGED':
            const newState = {
                ...prevState,
                oldPage: prevState.page,
            };

            if (action.filterChanged) {
                newState.page = 1;
                newState.filter = action.filter;
            }

            return newState;
        case 'SET_PATIENTS_LIST':
            return {
                ...prevState,
                patientsList: action.patientsList,
            };
        default:
            return prevState;
    }
}

const useState = () => {
    const [state, dispatch] = React.useReducer(reducer, defaultState);

    const methods = React.useMemo(() => ({
        load: () => dispatch({ type: 'LOAD' }),

        changePage: page => dispatch({ type: 'CHANGE_PAGE', page }),

        setPatientsList: patientsList => dispatch({ type: 'SET_PATIENTS_LIST', patientsList }),
    }), []);

    const stateMethods = React.useMemo(() => ({
        patientsListShouldUpdate: (filter) => {
            const notSamePage = state.oldPage !== state.page;
            const fields = [...new Set([...Object.keys(state.filter), ...Object.keys(filter)])];
            let filterChanged = false;

            for (let i = 0; !filterChanged && i < fields.length; ++i) {
                const field = fields[i];

                if (filter[field] !== state.filter[field]) {
                    filterChanged = true;
                }
            }

            dispatch({ type: 'FILTER_CHANGED', filterChanged, filter });

            if (filterChanged && !notSamePage && state.page !== 1) {
                return false;
            }

            return filterChanged || notSamePage;
        },
    }), [state.page, state.oldPage, state.filter]);

    return [state, methods, stateMethods];
};

export default useState;