import React, { useRef } from "react";
import PropTypes from "prop-types";

import { userCan } from "../../reducers/auth";

import { constant, ImagePreview, Modal, Button, Icon } from "@rdcs/dap-front-library";
import { getInitials } from "../../utils/utils";

import defaultUserImage from "@rdcs/dap-front-library/static/images/default-user.png";

const PatientsProSanteItem = ({ accessRight, pointOfContact, onSubmit }) => {
    const { user } = accessRight;
    const { firstName, lastName, avatar, profession, titre, address, zipcode, city, roles } = user;
    const modalRef = useRef();
    /*
     * 1) Do not show modal if user is already point of contact
     * 2) The target need to be nurse or pharmacist
     * 3) Show only if logged user is coordinator
     */
    const canBePointOfContact =
        pointOfContact &&
        pointOfContact["@id"] !== user["@id"] &&
        roles.some(role => role === "ROLE_NURSE" || role === "ROLE_PHARMACIST") &&
        userCan(["ROLE_COORDINATOR"]);

    return (
        <div className="proSanteList__item">
            <div className="proSanteList__itemVisuel mr-2">
                    <ImagePreview
                        user={user}
                        role={user["@type"]}
                        filename={avatar && avatar.filename}
                        className="proSanteList__itemVisuel-img"
                        fallbackImageUrl={defaultUserImage}
                    />
            </div>
            <div>
                <p className="title-4">
                    {titre ? constant("titres", titre.toLowerCase()) : ""} {firstName + " " + lastName}
                </p>
                <p className="proSanteList__itemSubtitle">
                    {profession + "  ::  " + address + "  ::  " + zipcode + " " + city}
                </p>
                {pointOfContact && pointOfContact["@id"] === user["@id"] && (
                    <p className="proSanteList__itemSubtitle">
                        Ce professionnel de santé est le référent de proximité de ce patient.
                    </p>
                )}
            </div>
            {canBePointOfContact && (
                <div className="proSanteList__item__actions">
                    <Modal
                        buttonClass="moderationListItem__action"
                        buttonContent={
                            <span className="proSanteList__itemActions-iconContainer">
                                <Icon name="actions" className="proSanteList__itemActions-icon" />
                                Ajouter comme référent de proximité
                            </span>
                        }
                        ref={modalRef}
                    >
                        <div className="modal__title">
                            Confirmer l'ajout de {firstName + " " + lastName} comme référent de proximité de ce patient
                            ?
                        </div>
                        <div className="modal__btnGroup">
                            <Button className="button__secondaryButton" onClick={() => modalRef.current.toggleModal()}>
                                Annuler
                            </Button>
                            <Button className="button__primaryButton" onClick={() => onSubmit(user["id"])}>
                                Confirmer
                            </Button>
                        </div>
                    </Modal>
                </div>
            )}
        </div>
    );
};

PatientsProSanteItem.propTypes = {
    accessRight: PropTypes.shape({
        user: PropTypes.shape({
            "@id": PropTypes.string.isRequired,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            avatar: PropTypes.shape({
                filename: PropTypes.string
            }),
            profession: PropTypes.string,
            titre: PropTypes.string,
            address: PropTypes.string,
            zipcode: PropTypes.string,
            city: PropTypes.string,
            roles: PropTypes.arrayOf(PropTypes.string)
        })
    }),
    pointOfContact: PropTypes.shape({
        "@id": PropTypes.string.isRequired
    }),
    onSubmit: PropTypes.func.isRequired
};

export default PatientsProSanteItem;
