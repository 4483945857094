import * as types from "../actions/types";
import { toast } from "react-toastify";

const initialState = {
    list: [],
    listLoading: false,
    practitionersList: [],
    practitionersListLoading: false,
    practitionersCount: 0,
    editLoading: false,
    deleteLoading: false,
    createFromRppsError: null,
    createFromRppsLoading: false
};

function medicals(state = initialState, action) {
    switch (action.type) {
        // FETCH
        case types.MEDICALS_FETCH_REQUESTED: {
            return Object.assign({}, state, { listLoading: true });
        }
        case types.MEDICALS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                listLoading: false,
                list: action.data
            });
        }
        case types.MEDICALS_FETCH_FAILURE: {
            return Object.assign({}, state, { listLoading: false, list: [] });
        }
        // FETCH HEALTH PROFESSIONALS
        case types.PRACTITIONERS_FETCH_REQUESTED: {
            return Object.assign({}, state, {
                practitionersListLoading: true
            });
        }
        case types.PRACTITIONERS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                practitionersListLoading: false,
                practitionersCount: action.data["hydra:totalItems"],
                practitionersList: action.data["hydra:member"] || []
            });
        }
        case types.PRACTITIONERS_FETCH_FAILURE: {
            return Object.assign({}, state, {
                practitionersListLoading: false,
                practitionersCount: 0,
                practitionersList: []
            });
        }
        // EDIT
        case types.MEDICALS_EDIT_REQUESTED: {
            return Object.assign({}, state, { editLoading: true });
        }
        case types.MEDICALS_EDIT_SUCCESS: {
            toast.success("Le médecin a été modifié");
            return Object.assign({}, state, {
                editLoading: false,
                list: state.list.map(medical => {
                    if (medical.id === action.data.id) return action.data;
                    return medical;
                })
            });
        }
        case types.MEDICALS_EDIT_FAILURE: {
            toast.error("Le médecin n'a pas pu être modifié");
            return Object.assign({}, state, { editLoading: false });
        }
        // BAN
        case types.MEDICALS_BAN_REQUESTED: {
            return Object.assign({}, state, { banLoading: true });
        }
        case types.MEDICALS_BAN_SUCCESS: {
            toast.success("Le médecin a été banni");
            return Object.assign({}, state, {
                banLoading: false,
                list: state.list.filter(item => item["@id"] !== action.data)
            });
        }
        case types.MEDICALS_BAN_FAILURE: {
            toast.error("Le médecin n'a pas pu être banni");
            return Object.assign({}, state, { banLoading: false });
        }
        // CREATE FROM RPPS
        case types.MEDICALS_CREATE_FROM_RPPS_REQUESTED: {
            return Object.assign({}, state, { createFromRppsLoading: true, createFromRppsError: null });
        }
        case types.MEDICALS_CREATE_FROM_RPPS_SUCCESS: {
            return Object.assign({}, state, {
                createFromRppsLoading: false
            });
        }
        case types.MEDICALS_CREATE_FROM_RPPS_FAILURE: {
            return Object.assign({}, state, {
                createFromRppsLoading: false,
                createFromRppsError: action.data.body["hydra:description"]
            });
        }
        // DELETE
        case types.MEDICALS_DELETE_REQUESTED: {
            return Object.assign({}, state, { deleteLoading: true });
        }
        case types.MEDICALS_DELETE_SUCCESS: {
            toast.success("Le médecin a été supprimé");
            return Object.assign({}, state, {
                deleteLoading: false,
                list: state.list.filter(item => item["@id"] !== action.data)
            });
        }
        case types.MEDICALS_DELETE_FAILURE: {
            toast.error("Le médecin n'a pas pu être supprimé");
            return Object.assign({}, state, { deleteLoading: false });
        }
        // DEFAULT
        default:
            return state;
    }
}

export default medicals;
