import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { tryAddComplication, tryFetchComplications } from "../../../actions/complications";

import ComplicationForm from "../../../components/Settings/Forms/ComplicationForm";

import SettingsFormScreen from "../../../components/Settings/SettingsFormScreen";

const AddComplicationScreen = ({
    complications: { list: complications, listLoading, createLoading, createError },
    tryAddComplication,
    tryFetchComplications
}) => (
    <SettingsFormScreen
        /* Document and page titles */
        documentTitle="Ajouter complication"
        pageTitle="Ajouter une complication"
        /* Redux request callback use to add on BDD */
        formRequest={tryAddComplication}
        fetchRequests={tryFetchComplications}
        /* Forms */
        itemForm={ComplicationForm}
        itemFormRequestLoading={createLoading}
        itemFormRequestError={createError}
        itemFormExtraProps={{ complications }}
        itemFormIsLoading={listLoading}
        testFetchRequestsNeeded={complications.length === 0}
        /* Configuration tab name for redirection */
        tab="complications"
    />
);

AddComplicationScreen.propTypes = {
    complications: PropTypes.shape({
        list: PropTypes.arrayOf(PropTypes.object).isRequired,
        listLoading: PropTypes.bool.isRequired,
        editLoading: PropTypes.bool.isRequired,
        editError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object
            }),
            PropTypes.bool
        ]).isRequired
    }).isRequired,
    tryAddComplication: PropTypes.func.isRequired,
    tryFetchComplications: PropTypes.func.isRequired
};

const mapStateToProps = ({ complications }) => ({ complications });

const mapDispatchToProps = { tryAddComplication, tryFetchComplications };

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(AddComplicationScreen));
