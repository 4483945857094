import React from "react";
import PropTypes from "prop-types";

import { Button, InputText, Icon } from "@rdcs/dap-front-library";
import { Link } from "react-router-dom";

const PartnerForm = ({
    editedPartner: {
        name: editedPartnerName = "",
    } = {},
    errors,
    onSubmit,
    location,
    isLoading
}) => {
    const [name, setName] = React.useState(editedPartnerName);

    const onSubmitCallback = event => {
        event.preventDefault();
        onSubmit({
            name
        });
    };

    return (
        <form className="form grid grid__padding-10" onSubmit={onSubmitCallback}>
            <div className="col-md-100">
                <InputText
                    label="Nom du partenaire"
                    placeholder="Saisissez le nom du partenaire"
                    name="name"
                    error={errors.name}
                    defaultValue={name}
                    required
                    onChange={setName}
                />
            </div>
            <div className="form__group col-100 text-center">
                <Link
                    to={{
                        pathname: "/configuration",
                        state: { from: location },
                        tab: "partners-list"
                    }}
                >
                    <Button className="button__secondaryButton">
                        <Icon name="arrow-left" className="mr-1" /> Retour
                    </Button>
                </Link>
                <Button type="submit" className="button__primaryButton" isLoading={isLoading}>
                    Enregistrer
                </Button>
            </div>
        </form>
    );
};

PartnerForm.propTypes = {
    editedPartner: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    errors: PropTypes.shape({
        name: PropTypes.string,
    }),
    onSubmit: PropTypes.func.isRequired,
    location: PropTypes.any,
    isLoading: PropTypes.bool.isRequired
};

export default PartnerForm;
