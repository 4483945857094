import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { tryFetchThematic, tryEditThematic } from "../../../actions/thematics";
import ThematicFrom from "../../../components/Settings/Forms/ThematicForm";
import SettingsEditFormScreen from "../../../components/Settings/SettingsEditFormScreen";

const EditThematic = ({ thematic: { datas, fetchLoading, editLoading, editError }, tryFetchThematic, tryEditThematic }) => {
    const formRequest = React.useCallback((id, data) => tryEditThematic(id, data), [tryEditThematic]);

    return (
        <SettingsEditFormScreen
            /* Document and page titles */
            documentTitle="Modifier une thématique"
            pageTitle="Modifier un une thématique"
            /* Redux request callback use to add on BDD */
            formRequest={formRequest}
            fetchRequests={tryFetchThematic}
            testFetchRequestsNeeded={datas.length === 0}
            /* Forms */
            itemForm={ThematicFrom}
            itemFormRequestLoading={editLoading}
            itemFormRequestError={editError}
            itemFormExtraProps={{ thematic: datas }}
            itemFormIsLoading={fetchLoading}
            itemFormEditedItemPropName="editedThematic"
            /* Items lists to retrieve the current item */
            items={datas}
            /* Configuration tab name for redirection */
            tab="thematic"
        />
    );
};

EditThematic.propTypes = {
    thematic: PropTypes.shape({
        datas: PropTypes.arrayOf(PropTypes.object).isRequired,
        fetchLoading: PropTypes.bool.isRequired,
        editLoading: PropTypes.bool.isRequired,
        editError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object,
            }),
            PropTypes.bool,
        ]).isRequired,
    }).isRequired,
    tryFetchThematic: PropTypes.func.isRequired,
    tryEditThematic: PropTypes.func.isRequired,
};

const mapStateToProps = ({ thematic }) => ({ thematic });

const mapDispatchToProps = { tryFetchThematic, tryEditThematic };

export default connect(mapStateToProps, mapDispatchToProps)(EditThematic);
