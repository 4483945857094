import React from "react";
import PropTypes from "prop-types";
import { Redirect, withRouter } from "react-router-dom";

import { connect } from "react-redux";

import { tryLogout } from "../../actions/auth";

const LogoutScreen = ({ location, tryLogout }) => {
    React.useEffect(() => {
        tryLogout();
    }, []);

    return (
        <Redirect
            to={{
                pathname: "/connexion",
                state: { from: location }
            }}
        />
    );
};

LogoutScreen.propTypes = {
    location: PropTypes.any,
    tryLogout: PropTypes.func.isRequired
};

const mapDispatchToProps = {
    tryLogout
};

export default withRouter(connect(null, mapDispatchToProps)(LogoutScreen));
