import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { tryAddCoordinator, tryFetchDepartments } from "../../../actions/coordinators";

import CoordinatorForm from "../../../components/Settings/Forms/CoordinatorForm";

import SettingsFormScreen from "../../../components/Settings/SettingsFormScreen";

const AddCoordinatorScreen = ({
    coordinators: { departments, departmentsLoading, createLoading, createError },
    tryAddCoordinator,
    tryFetchDepartments
}) => (
    <SettingsFormScreen
        /* Document and page titles */
        documentTitle="Ajouter facilitateur"
        pageTitle="Ajouter un facilitateur"
        /* Redux request callback use to add on BDD */
        formRequest={tryAddCoordinator}
        fetchRequests={tryFetchDepartments}
        /* Forms */
        itemForm={CoordinatorForm}
        itemFormRequestLoading={createLoading}
        itemFormRequestError={createError}
        itemFormExtraProps={{ departments }}
        itemFormIsLoading={departmentsLoading}
        testFetchRequestsNeeded={departments.length === 0}
        /* Configuration tab name for redirection */
        tab="coordinator"
    />
);

AddCoordinatorScreen.propTypes = {
    coordinators: PropTypes.shape({
        createLoading: PropTypes.bool.isRequired,
        createError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object
            }),
            PropTypes.bool
        ]).isRequired
    }).isRequired,
    tryAddCoordinator: PropTypes.func.isRequired,
    tryFetchDepartments: PropTypes.func.isRequired
};

const mapStateToProps = ({ coordinators }) => ({ coordinators });

const mapDispatchToProps = { tryAddCoordinator, tryFetchDepartments };

export default connect(mapStateToProps, mapDispatchToProps)(AddCoordinatorScreen);
