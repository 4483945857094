import * as types from "./types";

import { toast } from "react-toastify";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import store from "../store";

const getReport = (access_token, patientId) =>
    API.request({
        path: isLocalAPI ? "/reports" : "/dap-admin/api/v1/customers/patients/reports",
        queryParams: {
            patient: patientId,
        },
        token: access_token,
    });

export const tryFetchReports = (patientId) => (dispatch) => {
    const {
        auth: { accessToken },
        reports: { list, listLoaded, listPatientId },
    } = store.getState();

    if (listLoaded && listPatientId === patientId) {
        return Promise.resolve(list);
    }

    dispatch({ type: types.REPORTS_FETCH_REQUESTED });
    return getReport(accessToken, patientId)
        .then((response) => dispatch({ type: types.REPORTS_FETCH_SUCCESS, data: response["hydra:member"], patientId }))
        .catch((error) => dispatch({ type: types.REPORTS_FETCH_FAILURE, data: error }));
};

export const tryCreateReport = (data) => (dispatch) => {
    const {
        auth: { accessToken },
    } = store.getState();

    dispatch({ type: types.REPORTS_CREATE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/reports" : "/dap-admin/api/v1/customers/patients/reports",
        method: "POST",
        token: accessToken,
        data: data,
    })
        .then((response) => dispatch({ type: types.REPORTS_CREATE_SUCCESS, data: response }))
        .catch((error) => dispatch({ type: types.REPORTS_CREATE_FAILURE, data: error }));
};

export const tryEditReport = (id, form) => (dispatch) => {
    const {
        auth: { access_token },
    } = store.getState();

    dispatch({ type: types.EDIT_REPORT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/reports/{id}" : "/dap-admin/api/v1/customers/patients/reports/{id}",
        uriParams: {
            id,
        },
        token: access_token,
        method: "PUT",
        data: form,
    })
        .then(({ patient: { id } }) => {
            toast.success("Le compte-rendu a été modifié");
            getReport(access_token, id)
                .then((response) => dispatch({ type: types.EDIT_REPORT_SUCCESS, data: response["hydra:member"], patientId: id }))
                .catch(() => {
                    toast.error("Une erreur s'est produite lors de la récupération du compte-rendu.");
                });
        })
        .catch((error) => {
            toast.error("Le compte-rendu n'a pas pu être modifié");
            dispatch({ type: types.EDIT_REPORT_FAILURE, data: error });
            throw error;
        });
};

export const tryRemoveReport = (id) => (dispatch) => {
    const {
        auth: { access_token },
    } = store.getState();

    dispatch({ type: types.REPORT_REMOVE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/reports/{id}" : "/dap-admin/api/v1/customers/patients/reports/{id}",
        uriParams: {
            id,
        },
        token: access_token,
        method: "PUT",
        data: { active: false },
    })
        .then((response) => dispatch({ type: types.REPORT_REMOVE_SUCCESS, data: response }))
        .catch((error) => dispatch({ type: types.REPORT_REMOVE_FAILURE, data: error }));
};
