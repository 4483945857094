import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { tryEditTaskLabel, tryFetchTaskLabels } from "../../../actions/taskLabels";

import TaskLabelForm from "../../../components/Settings/Forms/TaskLabelForm";
import SettingsEditFormScreen from "../../../components/Settings/SettingsEditFormScreen";

const EditTaskLabelScreen = ({
    taskLabels: { list: taskLabels, listLoading, editLoading, editError },
    tryEditTaskLabel,
    tryFetchTaskLabels
}) => (
    <SettingsEditFormScreen
        /* Document and page titles */
        documentTitle="Modifier action"
        pageTitle="Modifier une action"
        /* Redux request callback use to add on BDD */
        formRequest={tryEditTaskLabel}
        fetchRequests={tryFetchTaskLabels}
        testFetchRequestsNeeded={taskLabels.length === 0}
        /* Forms */
        itemForm={TaskLabelForm}
        itemFormRequestLoading={editLoading}
        itemFormRequestError={editError}
        itemFormExtraProps={{ taskLabels }}
        itemFormIsLoading={listLoading}
        itemFormEditedItemPropName="editedTaskLabel"
        /* Items lists to retrieve the current item */
        items={taskLabels}
        /* Configuration tab name for redirection */
        tab="actions"
    />
);

EditTaskLabelScreen.propTypes = {
    taskLabels: PropTypes.shape({
        list: PropTypes.arrayOf(PropTypes.object).isRequired,
        listLoading: PropTypes.bool.isRequired,
        editLoading: PropTypes.bool.isRequired,
        editError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object
            }),
            PropTypes.bool
        ]).isRequired
    }).isRequired,
    tryEditTaskLabel: PropTypes.func.isRequired,
    tryFetchTaskLabels: PropTypes.func.isRequired
};

const mapStateToProps = ({ taskLabels }) => ({ taskLabels });

const mapDispatchToProps = { tryEditTaskLabel, tryFetchTaskLabels };

export default connect(mapStateToProps, mapDispatchToProps)(EditTaskLabelScreen);
