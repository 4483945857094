import * as types from "./types";
import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";
import store from "../store";

export const tryFetchTreatmentGroups = (auth) => (dispatch) => {
    dispatch({ type: types.TREATMENT_GROUPS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/treatment_groups" : "/dap-admin/api/v1/clinical-data/treatment-groups",
        queryParams: {
            pagination: false,
        },
        token: auth.accessToken,
    })
        .then((response) => dispatch({ type: types.TREATMENT_GROUPS_FETCH_SUCCESS, data: response["hydra:member"] }))
        .catch((error) => dispatch({ type: types.TREATMENT_GROUPS_FETCH_FAILURE, data: error }));
};

export const tryFetchTreatments = (auth) => (dispatch) => {
    dispatch({ type: types.TREATMENTS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/treatments" : "/dap-admin/api/v1/clinical-data/treatments",
        queryParams: {
            pagination: false,
        },
        token: auth.accessToken,
    })
        .then((response) => dispatch({ type: types.TREATMENTS_FETCH_SUCCESS, data: response["hydra:member"] }))
        .catch((error) => dispatch({ type: types.TREATMENTS_FETCH_FAILURE, data: error }));
};

export const tryAddTreatmentGroup = (treatment) => (dispatch) => {
    const { auth } = store.getState();

    dispatch({ type: types.ADD_TREATMENT_GROUP_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/treatment_groups" : "/dap-admin/api/v1/clinical-data/treatment-groups",
        token: auth.accessToken,
        method: "POST",
        data: treatment,
    })
        .then((response) => {
            dispatch({ type: types.ADD_TREATMENT_GROUP_SUCCESS, data: response });
            return tryFetchTreatmentGroups(auth);
        })
        .catch((error) => {
            dispatch({ type: types.ADD_TREATMENT_GROUP_FAILURE, data: error });
            throw error;
        });
};

export const tryEditTreatmentGroup = (treatmentId, form) => (dispatch) => {
    const { auth } = store.getState();

    dispatch({ type: types.EDIT_TREATMENT_GROUP_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/treatment_groups/{id}" : "/dap-admin/api/v1/clinical-data/treatment-groups/{id}",
        uriParams: {
            id: treatmentId,
        },
        token: auth.accessToken,
        method: "PUT",
        data: form,
    })
        .then((response) => {
            dispatch({ type: types.EDIT_TREATMENT_GROUP_SUCCESS, data: response });
            return tryFetchTreatmentGroups(auth);
        })
        .catch((error) => {
            dispatch({ type: types.EDIT_TREATMENT_GROUP_FAILURE, data: error });
            throw error;
        });
};

export const tryActivateTreatment = (auth, treatment, active) => (dispatch) => {
    dispatch({ type: types.ACTIVATE_TREATMENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/treatment_groups/{id}" : "/dap-admin/api/v1/clinical-data/treatment-groups/{id}",
        uriParams: {
            id: treatment.id,
        },
        token: auth.accessToken,
        method: "PUT",
        data: { active: active },
    })
        .then((response) => {
            dispatch({ type: types.ACTIVATE_TREATMENT_SUCCESS, data: response });
            return tryFetchTreatmentGroups(auth);
        })
        .catch((error) => {
            dispatch({ type: types.ACTIVATE_TREATMENT_FAILURE, data: error });
            throw error;
        });
};

export const trySwapTreatmentOrder = (auth, treatmentOne, treatmentTwo) => (dispatch) => {
    dispatch({ type: types.SWAP_TREATMENT_ORDER_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/treatment_groups/{id}" : "/dap-admin/api/v1/clinical-data/treatment-groups/{id}",
        uriParams: {
            id: treatmentOne.id,
        },
        token: auth.accessToken,
        method: "PUT",
        data: { order: treatmentTwo.order },
    })
        .then(() =>
            dispatch({
                type: types.SWAP_TREATMENT_ORDER_SUCCESS,
                data: {
                    treatmentOne: {
                        ...treatmentOne,
                        order: treatmentTwo.order,
                    },
                    treatmentTwo: {
                        ...treatmentTwo,
                        order: treatmentOne.order,
                    },
                },
            })
        )
        .catch((error) => dispatch({ type: types.SWAP_TREATMENT_ORDER_FAILURE, data: error }));
};
