import React from "react";

const reducer = (prevState, action) => {
    switch (action.type) {
        case "LOAD":
            return {
                ...prevState,
                loading: false,
            };
        case "PREV_STEP":
            return {
                ...prevState,
                currentStep: prevState.currentStep - 1,
            };
        case "NEXT_STEP_FROM_0_TO_1":
            return {
                ...prevState,
                currentStep: 1,
                patient: action.patient,
            };
        case "NEXT_STEP_FROM_1_TO_2": {
            let formattedAnswers = [];
            if (action.patient.questionnaire.answers) {
                for (const item of action.patient.questionnaire.answers) {
                    let answer = {
                        ...item,
                        id: item["@id"],
                    };
                    delete answer["@id"];
                    delete answer["@type"];
                    formattedAnswers.push(answer);
                }
            }
            const isEligible = action.patient === true ? false : action.patient.questionnaire.score >= 50;
            const datas =
                action.patient === true
                    ? {
                          // TODO action.patient.questionnaire is undefined since action.patient === true. To be fixed(front + back)
                          answers: action.patient.questionnaire.answers,
                      }
                    : {
                          answers: action.patient.questionnaire.answers,
                          patient: {
                              id: action.patient.id,
                              filledBy: action.patient.filledBy,
                              answers: action.patient.questionnaire.answers,
                              allowDataUsageForStudy: action.patient.allowDataUsageForStudy,
                              birthDate: action.patient.birthDate,
                              email: action.patient.email,
                              firstName: action.patient.firstName,
                              lastName: action.patient.lastName,
                              phoneNumber: action.patient.phoneNumber,
                              practitioner: action.patient.practitioner,
                              score: action.patient.questionnaire.score,
                              questionnaire: {
                                  id: action.patient.questionnaire["@id"],
                                  type: action.patient.questionnaire.type,
                                  score: action.patient.questionnaire.score,
                                  answers: formattedAnswers,
                              },
                          },
                      };

            return {
                ...prevState,
                currentStep: 2,
                isEligible: isEligible,
                ...datas,
            };
        }
        default:
            return prevState;
    }
};

const useState = (mustLoad) => {
    const defaultState = React.useMemo(
        () => ({
            loading: mustLoad,
            currentStep: 0,
            isEligible: false,
            patient: {},
            answers: undefined,
        }),
        []
    );

    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const methods = React.useMemo(
        () => ({
            load: () => dispatch({ type: "LOAD" }),

            prevStep: () => dispatch({ type: "PREV_STEP" }),

            nextStepFrom0To1: (patient) => dispatch({ type: "NEXT_STEP_FROM_0_TO_1", patient }),

            nextStepFrom1To2: (patient, answers) => dispatch({ type: "NEXT_STEP_FROM_1_TO_2", patient, answers }),
        }),
        []
    );

    return [state, methods];
};

export default useState;
