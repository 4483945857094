import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import Questions from "../../../actions/questions";

import BreakScoreQuestionForm from "../../../components/Settings/Forms/BreakScoreQuestionForm";
import SettingsEditFormScreen from "../../../components/Settings/SettingsEditFormScreen";

const { tryEditBreakScoreNoInsulinQuestion, tryFetchBreakScoreNoInsulinQuestions } = Questions;

const EditBreakScoreNoInsulinQuestionScreen = ({
    questions: { breakScoreNoInsulinQuestions, breakScoreNoInsulinQuestionsLoading, editLoading, editError },
    tryEditBreakScoreNoInsulinQuestion,
    tryFetchBreakScoreNoInsulinQuestions
}) => (
    <SettingsEditFormScreen
        /* Document and page titles */
        documentTitle="Modifier question de rupture"
        pageTitle="Modifier une question de rupture"
        /* Redux request callback use to add on BDD */
        formRequest={tryEditBreakScoreNoInsulinQuestion}
        fetchRequests={tryFetchBreakScoreNoInsulinQuestions}
        testFetchRequestsNeeded={breakScoreNoInsulinQuestions.length === 0}
        /* Forms */
        itemForm={BreakScoreQuestionForm}
        itemFormRequestLoading={editLoading}
        itemFormRequestError={editError}
        itemFormIsLoading={breakScoreNoInsulinQuestionsLoading}
        itemFormEditedItemPropName="editedBreakScoreQuestion"
        /* Items lists to retrieve the current item */
        items={breakScoreNoInsulinQuestions}
        /* Callback event call when edited item is found allowing to modity it before sending it into forms */
        onItemEditedFound={question => {
            question.responses = [];
            question.responsesWeight = [];
            question.scaleWeights = [];
            for (let i = 1; question[`answer${i}`]; ++i) {
                question.responses.push(question[`answer${i}`]);
                question.responsesWeight.push(question[`answer${i}Weight`]);
            }
            for (let i = 0; question[`scale${i}Weight`]; ++i) {
                question.scaleWeights.push(question[`scale${i}Weight`]);
            }

            return question;
        }}
        /* Configuration tab name for redirection */
        tab="break-score-no-insulin-questions"
    />
);

EditBreakScoreNoInsulinQuestionScreen.propTypes = {
    questions: PropTypes.shape({
        breakScoreQuestions: PropTypes.arrayOf(PropTypes.object).isRequired,
        breakScoreQuestionsLoading: PropTypes.bool.isRequired,
        editLoading: PropTypes.bool.isRequired,
        editError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object
            }),
            PropTypes.bool
        ]).isRequired
    }).isRequired,
    tryEditBreakScoreNoInsulinQuestion: PropTypes.func.isRequired,
    tryFetchBreakScoreNoInsulinQuestions: PropTypes.func.isRequired
};

const mapStateToProps = ({ questions }) => ({ questions });

const mapDispatchToProps = { tryEditBreakScoreNoInsulinQuestion, tryFetchBreakScoreNoInsulinQuestions };

export default connect(mapStateToProps, mapDispatchToProps)(EditBreakScoreNoInsulinQuestionScreen);
