import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { ModerationListItem } from "@rdcs/dap-front-library";

import SettingsTabLayout from "../../../components/Settings/SettingsTabLayout";

const ModeratorTab = ({ loading, moderators, onFetch, delete: onDelete, auth }) => (
    <SettingsTabLayout
        title="Modérateurs"
        icon="stats"
        addButton={{
            link: "/configuration/ajouter-moderateur",
            label: "Ajouter un modérateur"
        }}
        isLoading={loading}
    >
        {moderators.map(moderator => (
            <ModerationListItem
                key={moderator["@id"]}
                label={`${moderator.firstName} ${moderator.lastName}`}
                info={moderator.email}
                modifyLink={`/configuration/modifier-moderateur/${moderator.id}`}
                onDelete={() => onDelete(auth, moderator.id).then(() => onFetch(auth))}
                deleteText="Supprimer l'utilisateur"
            />
        ))}
    </SettingsTabLayout>
);

ModeratorTab.propTypes = {
    loading: PropTypes.bool.isRequired,
    moderators: PropTypes.arrayOf(PropTypes.object).isRequired,
    onFetch: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps)(ModeratorTab);
