import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import Questions from "../../../actions/questions";

import InformationsForm from "../../../components/Settings/Forms/InformationsForm";
import SettingsEditFormScreen from "../../../components/Settings/SettingsEditFormScreen";

const { tryEditDiagnosticQuestion, tryFetchDiagnosticQuestions } = Questions;

const EditDiagnosticQuestionScreen = ({
    questions: { diagnosticQuestions, diagnosticQuestionsLoading, editLoading, editError },
    tryEditDiagnosticQuestion,
    tryFetchDiagnosticQuestions,
}) => (
    <SettingsEditFormScreen
        /* Document and page titles */
        documentTitle="Modifier une question"
        pageTitle="Modifier une question"
        /* Redux request callback use to add on BDD */
        formRequest={tryEditDiagnosticQuestion}
        fetchRequests={[tryFetchDiagnosticQuestions]}
        //testFetchRequestsNeeded={[questions.length === 0]}
        /* Forms */
        itemForm={InformationsForm}
        itemFormRequestLoading={editLoading}
        itemFormRequestError={editError}
        itemFormExtraProps={{ diagnosticQuestions }}
        itemFormIsLoading={diagnosticQuestionsLoading}
        itemFormEditedItemPropName="editedInfo"
        /* Items lists to retrieve the current item */
        items={diagnosticQuestions}
        /* Configuration tab name for redirection */
        tab="informations-questions"
    />
);

EditDiagnosticQuestionScreen.propTypes = {
    questions: PropTypes.shape({
        diagnosticQuestions: PropTypes.arrayOf(PropTypes.object).isRequired,
        diagnosticQuestionsLoading: PropTypes.bool.isRequired,
        editLoading: PropTypes.bool.isRequired,
        editError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object,
            }),
            PropTypes.bool,
        ]).isRequired,
    }).isRequired,
    tryEditDiagnosticQuestion: PropTypes.func.isRequired,
    tryFetchDiagnosticQuestions: PropTypes.func.isRequired,
};

const mapStateToProps = ({ questions }) => ({ questions });

const mapDispatchToProps = { tryEditDiagnosticQuestion, tryFetchDiagnosticQuestions };

export default connect(mapStateToProps, mapDispatchToProps)(EditDiagnosticQuestionScreen);
