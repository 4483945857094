import * as types from "./types";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";
import store from "../store";

export const tryFetchMeasures = (idPatient) => dispatch => {
    const {
        auth:{
            accessToken
        }
    } = store.getState();
    dispatch({ type: types.MEASURES_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/measures" : "/dap-admin/api/v1/clinical-data/measures",
        queryParams: {
            "order[date]": "asc",
            pagination: false,
            "patient.id": idPatient
        },
        token: accessToken
    })
    .then(response => {
        dispatch({ type: types.MEASURES_FETCH_SUCCESS, data: response["hydra:member"], idPatient });
        return response["hydra:member"];
    })
    .catch(() => dispatch({ type: types.MEASURES_FETCH_FAILURE }));
};

export const tryCreateMeasure = (auth, data) => dispatch => {
    dispatch({ type: types.MEASURES_CREATE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/measures" : "/dap-admin/api/v1/clinical-data/measures",
        method: "POST",
        token: auth.accessToken,
        data: data
    })
        .then(response => dispatch({ type: types.MEASURES_CREATE_SUCCESS, data: response }))
        .catch(error => dispatch({ type: types.MEASURES_CREATE_FAILURE, data: error }));
};

export const tryFetchMeasureTypes = auth => dispatch => {
    dispatch({ type: types.MEASURE_TYPES_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/measure_types" : "/dap-admin/api/v1/clinical-data/measure-types",
        token: auth.accessToken
    })
        .then(response => dispatch({ type: types.MEASURE_TYPES_FETCH_SUCCESS, data: response["hydra:member"] }))
        .catch(error => dispatch({ type: types.MEASURE_TYPES_FETCH_FAILURE, data: error }));
};
