import * as types from "../actions/types";
import { toast } from "react-toastify";

const initialState = {
    createLoading: false,
    createError: false,
    editLoading: false,
    editError: false,
    deleteLoading: false,
    deleteError: false,
    activateLoading: false,
    swapOrderLoading: false,
};

for (const questionnaireType of types.QUESTIONNAIRE_TYPES) {
    const camelType = questionnaireType.replace(/(-[a-z]{1})/g, (w) => w.substring(w.length - 1).toUpperCase());

    initialState[`${camelType}Questions`] = [];
    initialState[`${camelType}QuestionsLoading`] = false;
}

for (const questionnaireAnswersType of types.QUESTIONNAIRE_ANSWERS_TYPES) {
    const camelType = questionnaireAnswersType.replace(/(-[a-z]{1})/g, (w) => w.substring(w.length - 1).toUpperCase());

    initialState[`${camelType}Answers`] = [];
    initialState[`${camelType}AnswersLoaded`] = 0;
    initialState[`${camelType}AnswersPotentialPatientId`] = -1;
}

function questions(state = initialState, action) {
    for (let i = 0; i < types.QUESTIONNAIRE_TYPES.length; ++i) {
        const _type = types.QUESTIONNAIRE_TYPES[i].toUpperCase().replace(/-/g, "_");
        const camelType = types.QUESTIONNAIRE_TYPES[i].replace(/(-[a-z]{1})/g, (w) => w.substring(w.length - 1).toUpperCase());

        if (action.type.startsWith(_type)) {
            switch (action.type.substring(_type.length + 1)) {
                case "QUESTIONS_FETCH_REQUESTED":
                    return {
                        ...state,
                        [`${camelType}QuestionsLoading`]: true,
                        [`${camelType}QuestionsLoaded`]: 0,
                    };
                case "QUESTIONS_FETCH_SUCCESS":
                    return {
                        ...state,
                        [`${camelType}QuestionsLoading`]: false,
                        [`${camelType}Questions`]: action.data,
                        [`${camelType}QuestionsLoaded`]: Date.now(),
                    };
                case "QUESTIONS_FETCH_FAILURE":
                    return {
                        ...state,
                        [`${camelType}QuestionsLoading`]: false,
                        [`${camelType}Questions`]: [],
                    };

                case "QUESTIONS_ADD_REQUESTED":
                    return {
                        ...state,
                        createLoading: true,
                        createError: false,
                    };
                case "QUESTIONS_ADD_SUCCESS":
                    toast.success(`La question ${types.QUESTIONNAIRE_NAME[i]} a été créée`);
                    return {
                        ...state,
                        createLoading: false,
                    };
                case "QUESTIONS_ADD_FAILURE":
                    toast.error(`La question ${types.QUESTIONNAIRE_NAME[i]} n'a pas pu être créée`);
                    return {
                        ...state,
                        createLoading: false,
                        createError: action.data,
                    };

                case "QUESTIONS_EDIT_REQUESTED":
                    return {
                        ...state,
                        editLoading: true,
                        editError: false,
                    };
                case "QUESTIONS_EDIT_SUCCESS":
                    toast.success(`La question ${types.QUESTIONNAIRE_NAME[i]} a été modifiée`);
                    return {
                        ...state,
                        editLoading: false,
                    };
                case "QUESTIONS_EDIT_FAILURE":
                    toast.error(`La question ${types.QUESTIONNAIRE_NAME[i]} n'a pas pu être modifiée`);
                    return {
                        ...state,
                        editLoading: false,
                        editError: action.data,
                    };

                case "QUESTIONS_ACTIVATE_REQUESTED":
                    return {
                        ...state,
                        activateLoading: true,
                    };
                case "QUESTIONS_ACTIVATE_SUCCESS":
                    toast.success(
                        action.data.active
                            ? `La question ${types.QUESTIONNAIRE_NAME[i]} a bien été activée.`
                            : `La question ${types.QUESTIONNAIRE_NAME[i]} a bien été désactivée.`
                    );
                    return {
                        ...state,
                        activateLoading: false,
                    };
                case "QUESTIONS_ACTIVATE_FAILURE":
                    toast.error(`La question ${types.QUESTIONNAIRE_NAME[i]} n'a pas pu être modifiée.`);
                    return {
                        ...state,
                        activateLoading: false,
                    };

                case "QUESTIONS_SWAP_REQUESTED":
                    return {
                        ...state,
                        swapOrderLoading: true,
                    };
                case "QUESTIONS_SWAP_SUCCESS":
                    toast.success("L'ordre a été changé");
                    return {
                        ...state,
                        swapOrderLoading: false,
                        [`${camelType}Questions`]: state[`${camelType}Questions`].map((c) => {
                            if (c.id === action.data.questionOne.id) c.order = action.data.questionOne.order;
                            if (c.id === action.data.questionTwo.id) c.order = action.data.questionTwo.order;
                            return c;
                        }),
                    };
                case "QUESTIONS_SWAP_FAILURE":
                    toast.error(`L'ordre des questions ${types.QUESTIONNAIRE_NAME[i]}s n'a pas pu être modifié`);
                    return {
                        ...state,
                        swapOrderLoading: false,
                    };

                case "ANSWERS_FETCH_REQUESTED": {
                    return {
                        ...state,
                        [`${camelType}AnswersLoaded`]: 0,
                        [`${camelType}AnswersPotentialPatientId`]: -1,
                    };
                }
                case "ANSWERS_FETCH_SUCCESS": {
                    return {
                        ...state,
                        [`${camelType}Answers`]: action.data,
                        [`${camelType}AnswersLoaded`]: Date.now(),
                        [`${camelType}AnswersPotentialPatientId`]: action.potentialPatientId,
                    };
                }
                case "ANSWERS_FETCH_FAILURE": {
                    return {
                        ...state,
                        [`${camelType}Answers`]: [],
                    };
                }
            }
        }
    }

    return state;
}

export default questions;
