import * as types from "./types";
import API from "../utils/API";
import { parseJwt } from "../reducers/auth";
import { isLocalAPI } from "../utils/utils";

export const tryLogin = (email, password) => dispatch => {
    dispatch({ type: types.LOGIN_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/login" : "/dap-admin/api/v1/customers/admins/login",
        method: "POST",
        data: {
            email: email,
            password: password
        }
    })
        .then(response => dispatch({ type: types.LOGIN_SUCCESS, data: response }))
        .catch(error => {
            dispatch({ type: types.LOGIN_FAILURE, data: error.body["hydra:description"] });
            throw new Error('Bad credentials !');
        });
};

export const tryLoginCode = (email, password, code) => dispatch => {
    dispatch({ type: types.LOGIN_CODE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/login_code" : "/dap-admin/api/v1/customers/admins/login-code",
        method: "POST",
        data: {
            email: email,
            password: password,
            code: code
        }
    })
        .then(response => {
            const user = parseJwt(response.access_token);
            if (user) {
                localStorage.setItem("access_token", response.access_token);
                localStorage.setItem("refresh_token", response.refresh_token);
                window.fileRetrieveHeaders = {
                    ...window.fileRetrieveHeaders,
                    Authorization: "Bearer " + response.access_token
                };
                dispatch({
                    type: types.LOGIN_CODE_SUCCESS,
                    data: {
                        access_token: response.access_token,
                        refresh_token: response.refresh_token,
                        user: user
                    }
                });
            } else {
                dispatch({ type: types.LOGIN_CODE_FAILURE, data: "Echec de la connexion" });
            }
        })
        .catch(error => dispatch({ type: types.LOGIN_CODE_FAILURE, data: error.body["hydra:description"] }));
};

export const tryRefreshToken = auth => dispatch => {
    dispatch({ type: types.REFRESH_TOKEN_REQUESTED });
    return API.request({
        method: "POST",
        path: isLocalAPI ? "/refresh_token" : "/dap-admin/api/v1/customers/admins/refresh-token",
        data: {
            refresh_token: auth.refreshToken
        }
    })
        .then(response => {
            const user = parseJwt(response.access_token);
            if (user) {
                localStorage.setItem("access_token", response.access_token);
                localStorage.setItem("refresh_token", response.refresh_token);
                dispatch({
                    type: types.REFRESH_TOKEN_SUCCESS,
                    data: {
                        access_token: response.access_token,
                        refresh_token: response.refresh_token,
                        user: user
                    }
                });
            } else {
                dispatch({ type: types.REFRESH_TOKEN_FAILURE, data: response });
            }
        })
        .catch(error => dispatch({ type: types.REFRESH_TOKEN_FAILURE, data: error }));
};

export const tryLogout = () => dispatch => {
    dispatch({ type: types.LOGOUT_REQUESTED });
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    dispatch({ type: types.LOGOUT_SUCCESS });
};

export const tryRequestPassword = email => dispatch => {
    dispatch({ type: types.REQUEST_PASSWORD_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/request_password" : "/dap-admin/api/v1/customers/admins/request-password",
        method: "POST",
        data: {
            email: email
        }
    })
        .then(response => dispatch({ type: types.REQUEST_PASSWORD_SUCCESS, data: response }))
        .catch(error => dispatch({ type: types.REQUEST_PASSWORD_FAILURE, data: error["body"][0] }));
};

export const tryResetPassword = (token, password, confirmPassword) => dispatch => {
    dispatch({ type: types.RESET_PASSWORD_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/reset_password" : "/dap-admin/api/v1/customers/admins/reset-password",
        method: "POST",
        data: {
            token: token,
            password: password,
            confirm_password: confirmPassword
        }
    })
        .then(response => dispatch({ type: types.RESET_PASSWORD_SUCCESS, data: response }))
        .catch(error => dispatch({ type: types.RESET_PASSWORD_FAILURE, data: error["body"][0] }));
};

export const tryGigyaSearchBySsn = (auth, ssn) => dispatch => {
    dispatch({ type: types.GIGYA_SEARCH_BY_SSN_REQUESTED });
    return API.request({
        method: "GET",
        path: isLocalAPI ? "/gigya/search-by-ssn/{ssn}" : "/dap-admin/api/v1/ciam/identities/search-by-ssn/{ssn}",
        uriParams: {
            ssn
        },

        token: auth.accessToken
    })
        .then(response => dispatch({ type: types.GIGYA_SEARCH_BY_SSN_SUCCESS, data: response }))
        .catch(error => dispatch({ type: types.GIGYA_SEARCH_BY_SSN_FAILURE, data: error }));
};
