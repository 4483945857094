import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { tryAddAdmin } from "../../../actions/admins";

import AdminForm from "../../../components/Settings/Forms/AdminForm";

import SettingsFormScreen from "../../../components/Settings/SettingsFormScreen";

const AddAdminScreen = ({ admins: { createLoading, createError }, tryAddAdmin }) => (
    <SettingsFormScreen
        /* Document and page titles */
        documentTitle="Ajouter administrateur"
        pageTitle="Ajouter un administrateur"
        /* Redux request callback use to add on BDD */
        formRequest={tryAddAdmin}
        /* Forms */
        itemForm={AdminForm}
        itemFormRequestLoading={createLoading}
        itemFormRequestError={createError}
        /* Configuration tab name for redirection */
        tab="admin"
    />
);

AddAdminScreen.propTypes = {
    admins: PropTypes.shape({
        createLoading: PropTypes.bool.isRequired,
        createError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object
            }),
            PropTypes.bool
        ]).isRequired
    }).isRequired,
    tryAddAdmin: PropTypes.func.isRequired
};

const mapStateToProps = ({ admins }) => ({ admins });

const mapDispatchToProps = { tryAddAdmin };

export default connect(mapStateToProps, mapDispatchToProps)(AddAdminScreen);
