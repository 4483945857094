import React from "react";

import moment from "moment";

import { constant } from "@rdcs/dap-front-library";

const defaultState = {
    recommendations: [],
    recommendationsFiltered: [],
    recommendationsRendered: [],
    nbItemToShow: 12,
    loading: true,
    scroll: false
};

const reducer = (prevState, action) => {
    switch (action.type) {
        case "LOAD": {
            return {
                ...prevState,
                loading: false,
            };
        }
        case "INIT":
            return {
                ...prevState,
                scroll: false,
                recommendations: action.recommendations,
                recommendationsFiltered: action.recommendations,
                recommendationsRendered: action.recommendations.slice(0, prevState.nbItemToShow)
            };
        case "SHOW_MORE":
            return {
                ...prevState,
                scroll: false,
                nbItemToShow: prevState.nbItemToShow + 12,
                recommendationsRendered: prevState.recommendationsFiltered.slice(0, prevState.nbItemToShow + 12)
            };
        case "FILTER":
            return {
                ...prevState,
                scroll: true,
                recommendationsFiltered: action.filteredRecommendations,
                recommendationsRendered: action.filteredRecommendations.slice(0, prevState.nbItemToShow)
            };
        default:
            return prevState;
    }
};

const useRecommendations = () => {
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const pathologies = React.useMemo(() => constant("pathologies"), []);

    return React.useMemo(
        () => ({
            ...state,
            canShowMore:
                state.recommendationsFiltered.length > 0 &&
                state.recommendationsFiltered.length !== state.recommendationsRendered.length,

            // Methods
            load: () => dispatch({ type: "LOAD" }),

            init: recommendations => dispatch({ type: "INIT", recommendations }),

            showMore: () => dispatch({ type: "SHOW_MORE" }),

            filterRecommendations: filters =>
                dispatch({
                    type: "FILTER",
                    filteredRecommendations: state.recommendations.filter(item => {
                        const allPathologies = pathologies.filter(pathology => {
                            const key = Object.keys(item).find(k => k.toLowerCase().endsWith(pathology.toLowerCase()));
                            return item[key];
                        });
                        const pathologiesDidMatch = filters.pathology.map(pathology =>
                            allPathologies.includes(pathology)
                        );

                        switch (true) {
                            case filters.search !== "" && item.title.indexOf(filters.search) === -1:
                                return false;
                            case filters.sex !== "all" && item.sex !== filters.sex:
                                return false;
                            case filters.pathology.length !== 0:
                                return pathologiesDidMatch.includes(true);
                            case filters.ageRange !== "all" && item.ageRange !== filters.ageRange:
                                return false;
                            case filters.treatment !== "all" &&
                                !item.treatments.find(treatment => treatment["@id"] === filters.treatment):
                                return false;
                            case filters.recurrence !== "all" && item.recurrence !== filters.recurrence:
                                return false;
                            case filters.type !== "all" && item.type !== filters.type:
                                return false;
                            case filters.recipient !== "all" && item.recipient !== filters.recipient:
                                return false;
                            case filters.grade !== "all" && item.grade !== filters.grade:
                                return false;
                            case filters.updatedAt !== "all" &&
                                moment(item.updatedAt || item.createdAt).format("MM-YYYY") !== filters.updatedAt:
                                return false;
                            case filters.active === true && item.active === false:
                                return false;
                            default:
                                return true;
                        }
                    })
                }),

            downloadCSV: () => {
                const CSVRow = values => values.map(value => `"${value}"`).join(";") + "\n";

                let csv = CSVRow([
                    "Titre",
                    "Type de recommendation",
                    "Description",
                    "Description simplifiée",
                    "Objectif Hb1c",
                    "Grade",
                    "Destinataire",
                    "Récurrence",
                    "Source",
                    "Sexe",
                    "Pathologie",
                    "Age",
                    "Traitement 1",
                    "Traitement 2",
                    "Traitement 3",
                    "Complication 1",
                    "Complication 2",
                    "Complication 3",
                    "Durée diabète"
                ]);

                state.recommendationsFiltered.forEach(reco => {
                    const allPathologies = pathologies
                        .filter(pathology => {
                            const key = Object.keys(reco).find(k => k.toLowerCase().endsWith(pathology.toLowerCase()));
                            return reco[key];
                        })
                        .map(pathology => constant("pathologies", pathology));

                    csv += CSVRow([
                        reco.title,
                        reco.type ? constant("recommendation", "types", reco.type) : "-",
                        reco.description,
                        reco.simplifiedDescription || "-",
                        reco.goal || "-",
                        reco.grade ? constant("recommendation", "grades", reco.grade) : "-",
                        reco.recipient ? constant("recommendation", "recipients", reco.recipient) : "-",
                        constant("recommendation", "recurrences", reco.recurrence),
                        reco.source,
                        constant("recommendation", "sexes", reco.sex),
                        allPathologies,
                        constant("recommendation", "ageRanges", reco.ageRange),
                        reco.treatments.length > 0 ? reco.treatments[0].name : "",
                        reco.treatments.length > 1 ? reco.treatments[1].name : "",
                        reco.treatments.length > 2 ? reco.treatments[2].name : "",
                        reco.complications.length > 0 ? reco.complications[0].name : "",
                        reco.complications.length > 1 ? reco.complications[1].name : "",
                        reco.complications.length > 2 ? reco.complications[2].name : "",
                        reco.diabetesDuration
                            ? constant("recommendation", "diabetesDurations", reco.diabetesDuration)
                            : "-"
                    ]);
                });

                const csvElem = document.createElement("a");

                csvElem.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
                csvElem.target = "_blank";
                csvElem.download = "recommandations.csv";
                csvElem.click();
            }
        }),
        [state, dispatch, pathologies]
    );
};

export default useRecommendations;
