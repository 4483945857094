import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";

import { tryDisableRecommendation, tryEnableRecommendation, tryFetchRecommendations } from "../../../actions/recommendations";
import { tryFetchTreatments } from "../../../actions/treatments";

import moment from "moment";

import { constant, Button, Icon, DefaultLoader } from "@rdcs/dap-front-library";

import useRecommendations from "./useRecommendations";
import RecommendationsFilter from "../../../components/Recommendations/RecommendationsFilter";
import AppLayout from "../../../components/Layout/AppLayout";

const breadcrumbs = [{ label: "Recommandations" }];

const RecommendationsScreen = ({
    auth,
    recommendations: { list: recommendationsList = [], listLoading = false } = {},
    treatments,
    tryFetchRecommendations,
    tryFetchTreatments,
    tryDisableRecommendation,
    tryEnableRecommendation,
}) => {
    const { loading, recommendationsFiltered, recommendationsRendered, canShowMore, scroll, load, init, showMore, filterRecommendations, downloadCSV } =
        useRecommendations();
    const recoListRef = React.useRef(null);

    React.useEffect(() => {
        document.title = "Timkl - Recommandations";

        Promise.all([tryFetchRecommendations(auth), tryFetchTreatments(auth)]).then(() => load());
    }, []);

    React.useEffect(() => {
        if (!loading) {
            init(recommendationsList);
        }
    }, [loading, recommendationsList]);

    React.useEffect(() => {
        if (recoListRef.current !== null && scroll) {
            recoListRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [recommendationsFiltered, recoListRef, scroll]);

    const recommendationsRenderedComponent = React.useMemo(
        () =>
            recommendationsRendered.map((reco) => (
                <div className={!reco.active ? "moderationListItem moderationListItem--desactivate" : "moderationListItem"} key={reco.id}>
                    <div className="moderationListItem__infos">
                        <div className="moderationListItem__name">
                            {constant("recommendation", "types", reco.type)} : {reco.title} - À réaliser{" "}
                            {constant("recommendation", "recurrences", reco.recurrence).toLowerCase()}
                        </div>
                        <div className="moderationListItem__infos-list">
                            <p className="moderationListItem__infos-item">
                                Profils concernés : {constant("recommendation", "sexes", reco.sex)}
                                <span className="mx-1">/</span>
                                {constant("pathologies")
                                    .filter((pathology) => {
                                        const key = Object.keys(reco).find((k) => k.toLowerCase().endsWith(pathology.toLowerCase()));
                                        return reco[key];
                                    })
                                    .map((pathology, index) => (
                                        <React.Fragment key={index}>
                                            {constant("pathologies", pathology)}
                                            <span className="mx-1">/</span>
                                        </React.Fragment>
                                    ))}
                                {constant("recommendation", "ageRanges", reco.ageRange)}
                            </p>
                            <p className="moderationListItem__infos-item">
                                Complications :{" "}
                                {reco.complications.length > 0
                                    ? reco.complications.map((complication) => (
                                          <span key={complication.id} className="ml-2">
                                              {complication.name}
                                          </span>
                                      ))
                                    : "Aucune complication"}
                            </p>
                            <p className="moderationListItem__infos-item">Données biologiques : {reco.biologicalDatum ? reco.biologicalDatum.name : "NC"}</p>
                            <p className="moderationListItem__infos-item">
                                Source :{" "}
                                <a href={reco.source} target="_blank" rel="noopener noreferrer">
                                    {reco.source}
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="moderationListItem__status">
                        <div className="moderationListItem__status-infos">
                            <div className="moderationListItem__status-text">{reco.grade ? constant("recommendation", "grades", reco.grade) : ""}</div>
                            <div className="moderationListItem__status-date">
                                MAJ : {reco.updatedAt ? moment(reco.updatedAt).format("DD/MM/YYYY") : moment(reco.createdAt).format("DD/MM/YYYY")}
                            </div>
                        </div>
                    </div>
                    <div
                        className={
                            !reco.active
                                ? "moderationListItem__actions moderationListItem__actions--onRightDesactivate"
                                : "moderationListItem__actions moderationListItem__actions--onRight"
                        }
                    >
                        <div className="moderationListItem__actions-content">
                            <Link to={`/recommandations/${reco.id}/modifier`}>
                                <button className="button-reset mx-3 moderationListItem__action">
                                    <Icon name="check-circle" className="moderationListItem__action-icon" />
                                    Éditer
                                </button>
                            </Link>
                            {reco.active ? (
                                <button
                                    className="button-reset mx-3 moderationListItem__action"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        tryDisableRecommendation(auth, reco.id);
                                    }}
                                >
                                    <Icon name="cross-circle" className="moderationListItem__action-icon" />
                                    Désactiver
                                </button>
                            ) : (
                                <button
                                    className="button-reset mx-3 moderationListItem__action"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        tryEnableRecommendation(auth, reco.id);
                                    }}
                                >
                                    <Icon name="check-circle" className="moderationListItem__action-icon" />
                                    Réactiver
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )),
        [recommendationsRendered, tryDisableRecommendation, tryEnableRecommendation, auth]
    );

    return (
        <AppLayout navActive={1} breadcrumbs={breadcrumbs}>
            <h1 className="title-1">Liste des recommandations</h1>

            <div className="blockList__header">
                <RecommendationsFilter
                    filterRecommendations={filterRecommendations}
                    downloadCSV={downloadCSV}
                    treatments={treatments.treatments}
                    recosLoading={listLoading}
                />
            </div>

            <div ref={recoListRef} className="moderationList">
                {loading || listLoading ? (
                    <DefaultLoader color="#CCC" />
                ) : recommendationsRendered.length === 0 ? (
                    <p className="title-4 text-center">Aucune recommandation</p>
                ) : (
                    recommendationsRenderedComponent
                )}
            </div>

            {canShowMore && (
                <div className="form__group text-center">
                    <Button className="button__primaryButton" onClick={showMore}>
                        Afficher la suite
                    </Button>
                </div>
            )}
        </AppLayout>
    );
};

RecommendationsScreen.propTypes = {
    /* Auth */
    auth: PropTypes.object.isRequired,

    /* Treatments */
    treatments: PropTypes.object.isRequired,
    tryFetchTreatments: PropTypes.func.isRequired,

    /* recommendations */
    recommendations: PropTypes.object.isRequired,
    tryFetchRecommendations: PropTypes.func.isRequired,
    tryDisableRecommendation: PropTypes.func.isRequired,
    tryEnableRecommendation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    recommendations: state.recommendations,
    treatments: state.treatments,
});

const mapDispatchToProps = {
    tryFetchRecommendations,
    tryFetchTreatments,
    tryDisableRecommendation,
    tryEnableRecommendation,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecommendationsScreen));
