import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import Questions from "../../../actions/questions";

import InformationsForm from "../../../components/Settings/Forms/InformationsForm";

import SettingsFormScreen from "../../../components/Settings/SettingsFormScreen";

const { tryAddDiagnosticQuestion, tryFetchDiagnosticQuestions } = Questions;

const AddDiagnosticQuestionScreen = ({
    questions: { diagnosticQuestions, diagnosticQuestionsLoading, createLoading, createError },
   tryAddDiagnosticQuestion,
   tryFetchDiagnosticQuestions
}) => (
    <SettingsFormScreen
        /* Document and page titles */
        documentTitle="Ajouter une question"
        pageTitle="Ajouter une question"
        /* Redux request callback use to add on BDD */
        formRequest={tryAddDiagnosticQuestion}
        fetchRequests={tryFetchDiagnosticQuestions}
        /* Forms */
        itemForm={InformationsForm}
        itemFormRequestLoading={createLoading}
        itemFormRequestError={createError}
        itemFormExtraProps={{ diagnosticQuestions }}
        itemFormIsLoading={diagnosticQuestionsLoading}
        testFetchRequestsNeeded={diagnosticQuestions && diagnosticQuestions.length === 0}
        /* Configuration tab name for redirection */
        tab="informations-questions"
    />
);

AddDiagnosticQuestionScreen.propTypes = {
    questions: PropTypes.shape({
        diagnosticQuestions: PropTypes.arrayOf(PropTypes.object).isRequired,
        diagnosticQuestionsLoading: PropTypes.bool.isRequired,
        createLoading: PropTypes.bool.isRequired,
        createError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object
            }),
            PropTypes.bool
        ]).isRequired
    }).isRequired,
    tryAddDiagnosticQuestion: PropTypes.func.isRequired,
    tryFetchDiagnosticQuestions: PropTypes.func.isRequired
};

const mapStateToProps = ({ questions }) => ({ questions });

const mapDispatchToProps = { tryAddDiagnosticQuestion, tryFetchDiagnosticQuestions };

export default connect(mapStateToProps, mapDispatchToProps)(AddDiagnosticQuestionScreen);
