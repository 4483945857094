import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import { InputEmail, Button, Icon } from "@rdcs/dap-front-library";

const RequestPasswordForm = ({ submit, loading, error }) => {
    const [email, setEmail] = React.useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        submit(email);
    };

    return (
        <form onSubmit={handleSubmit} className="form">
            <Link to="/connexion" className="credentialsMain__back">
                <Icon name="arrow-left" />
            </Link>
            <h1 className="credentials__title">Mot de passe oublié</h1>
            <p>
                Si vous avez perdu ou oublié votre mot de passe, vous pouvez en générer un nouveau que vous recevrez
                par email.
            </p>
            <InputEmail
                label="Votre adresse email"
                placeholder="Saisissez votre email"
                error={error}
                onChange={setEmail}
                required
            />
            <div className="buttons__callToAction">
                <Button type="submit" className="button__primaryButton" isLoading={loading}>
                    Valider
                </Button>
            </div>
        </form>
    );
}

RequestPasswordForm.propTypes = {
    submit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default RequestPasswordForm;
