import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { ModerationListItemCard } from "@rdcs/dap-front-library";

import SettingsTabLayout from "../../../components/Settings/SettingsTabLayout";

const TaskLabelTab = ({ auth, taskLabels, loading, delete: onDelete, swapOrder, isSwapingOrder }) => (
    <SettingsTabLayout
        title="Actions"
        icon="datas"
        addButton={{
            link: "/configuration/ajouter-action",
            label: "Ajouter une action"
        }}
        isLoading={loading}
    >
        {taskLabels
            .sort((a, b) => (a.order < b.order ? -1 : 1))
            .map((taskLabel, itemIndex) => (
                <ModerationListItemCard
                    key={`${taskLabel.id}-${itemIndex}`}
                    label={taskLabel.label}
                    modifyLink={`/configuration/modifier-action/${taskLabel.id}`}
                    swapOrder={{
                        current: taskLabel,
                        onSwap: (item1, item2) => swapOrder(auth, item1, item2),
                        prev: taskLabels[itemIndex - 1],
                        next: taskLabels[itemIndex + 1],
                        isSwapingOrder: isSwapingOrder
                    }}
                    onDelete={() => onDelete(auth, taskLabel.id)}
                />
            ))}
    </SettingsTabLayout>
);

TaskLabelTab.propTypes = {
    auth: PropTypes.object.isRequired,
    taskLabels: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            icon: PropTypes.shape({
                filename: PropTypes.string.isRequired
            }),
            active: PropTypes.bool.isRequired,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
        })
    ).isRequired,
    loading: PropTypes.bool.isRequired,
    delete: PropTypes.func.isRequired,
    swapOrder: PropTypes.func.isRequired,
    isSwapingOrder: PropTypes.bool.isRequired
};

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps)(TaskLabelTab);
