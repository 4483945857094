import React from "react";
import PropTypes from "prop-types";

import { ModerationListItemSwitch } from "@rdcs/dap-front-library";

import SettingsTabLayout from "../../../components/Settings/SettingsTabLayout";

const InformationsTab = ({ diagnosticQuestions, loading, activate, swapOrder, isSwapingOrder }) => (
    <SettingsTabLayout
        title="Informations (étape 1 de la consultation)"
        icon="informations"
        addButton={{
            link: "/configuration/ajouter-question-information",
            label: "Ajouter une question",
        }}
        isLoading={loading}
    >
        {diagnosticQuestions &&
            diagnosticQuestions
                .sort((a, b) => (a.order < b.order ? -1 : 1))
                .map((info, index, orderedQuestion) => (
                    <ModerationListItemSwitch
                        key={index}
                        label={info.label}
                        onChange={(value) =>
                            activate(info, value)
                                .then(() => value)
                                .catch(() => !value)
                        }
                        defaultChecked={info.active}
                        modifyLink={`/configuration/modifier-question-information/${info.id}`}
                        swapOrder={{
                            current: info,
                            onSwap: (item1, item2) => swapOrder(item1, item2),
                            prev: orderedQuestion[index - 1],
                            next: orderedQuestion[index + 1],
                            isSwapingOrder: isSwapingOrder,
                        }}
                    />
                ))}
    </SettingsTabLayout>
);

InformationsTab.propTypes = {
    diagnosticQuestions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        })
    ).isRequired,
    loading: PropTypes.bool.isRequired,
    activate: PropTypes.func.isRequired,
    swapOrder: PropTypes.func.isRequired,
    isSwapingOrder: PropTypes.bool.isRequired,
};

export default InformationsTab;
