import React from "react";
import PropTypes from "prop-types";

import HeaderCoordinator from "../../components/Coordinator/HeaderCoordinator";

const Header = ({ id, coordinators, onChange, loading }) => (
    <HeaderCoordinator
        title='Nouveaux patients à contacter'
        loading={loading}
        currentCoordinatorId={id}
        coordinators={coordinators}
        onChange={onChange}
    />
);

Header.propTypes = {
    id: PropTypes.number.isRequired,
    coordinators: PropTypes.arrayOf(
        PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            departments: PropTypes.arrayOf(
                PropTypes.shape({
                    code: PropTypes.string.isRequired
                }).isRequired
            ).isRequired
        }).isRequired
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default React.memo(Header);
