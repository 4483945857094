import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { tryAddModerator } from "../../../actions/moderators";

import ModeratorForm from "../../../components/Settings/Forms/ModeratorForm";

import SettingsFormScreen from "../../../components/Settings/SettingsFormScreen";

const AddModeratorScreen = ({ moderators: { createLoading, createError }, tryAddModerator }) => (
    <SettingsFormScreen
        /* Document and page titles */
        documentTitle="Ajouter moderateur"
        pageTitle="Ajouter un moderateur"
        /* Redux request callback use to add on BDD */
        formRequest={tryAddModerator}
        /* Forms */
        itemForm={ModeratorForm}
        itemFormRequestLoading={createLoading}
        itemFormRequestError={createError}
        /* Configuration tab name for redirection */
        tab="moderator"
    />
);

AddModeratorScreen.propTypes = {
    moderators: PropTypes.shape({
        createLoading: PropTypes.bool.isRequired,
        createError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object
            }),
            PropTypes.bool
        ]).isRequired
    }).isRequired,
    tryAddModerator: PropTypes.func.isRequired
};

const mapStateToProps = ({ moderators }) => ({ moderators });

const mapDispatchToProps = { tryAddModerator };

export default connect(mapStateToProps, mapDispatchToProps)(AddModeratorScreen);
