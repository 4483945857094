import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import { InputEmail, InputPassword, InputNumber, Button, Icon } from "@rdcs/dap-front-library";

const LoginForm = ({ loginSubmit, loginCodeSubmit, loginLoading, loginError, loginCodeLoading, loginCodeError }) => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [code, setCode] = React.useState('');
    const [stepLoginCode, setStepLoginCode] = React.useState(false);
    const [showCodeSent, setShowCodeSent] = React.useState(true);

    const handleSubmit = (event) => {
        event.preventDefault();
        loginSubmit(email, password).then(() => {
            setStepLoginCode(true);
        }).catch(() => undefined);
    }

    const handleSubmitCode = (event) => {
        event.preventDefault();
        setShowCodeSent(false);
        loginCodeSubmit(email, password, code);
    }

    return !stepLoginCode ? (
        <form onSubmit={handleSubmit} className="form">
            {loginError && (
                <div className="bgError radius shadow mb-2 pt-2 pl-3 pb-2 pr-3">{loginError}</div>
            )}
            <InputEmail
                label="Votre email"
                placeholder="Saisissez votre email"
                onChange={setEmail}
                required
            />
            <InputPassword label="Votre mot de passe" onChange={setPassword} required />
            <div className="buttons__callToAction">
                <Button type="submit" className="button__primaryButton" isLoading={loginLoading}>
                    Se connecter
                </Button>
            </div>
            <div className="form__group txtCenter">
                <Link to="/mot-de-passe-oublie">Mot de passe oublié ?</Link>
            </div>
        </form>
    ) : (
        <form onSubmit={handleSubmitCode} className="form">
            {showCodeSent && (
                <div className="bgNotice radius shadow mb-2 pt-2 pl-3 pb-2 pr-3">
                    Un code vous a été envoyé par mail
                </div>
            )}
            {loginCodeError && (
                <div className="bgError radius shadow mb-2 pt-2 pl-3 pb-2 pr-3">{loginCodeError}</div>
            )}
            <InputNumber
                label="Code de connexion"
                placeholder="Saisissez le code à 6 chiffres reçu par email"
                onChange={setCode}
                required
            />
            <div className="buttons__callToAction">
                <Button type="submit" className="button__primaryButton" isLoading={loginCodeLoading}>
                    Valider
                </Button>
            </div>
            <div className="form__group txtCenter">
                <Link to="/">
                    <Icon name="arrow-left" className="mr-1" /> Retour à la connexion
                </Link>
            </div>
        </form>
    );
}

LoginForm.propTypes = {
    loginSubmit: PropTypes.func.isRequired,
    loginCodeSubmit: PropTypes.func.isRequired,
    loginLoading: PropTypes.bool.isRequired,
    loginError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    loginCodeLoading: PropTypes.bool.isRequired,
    loginCodeError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default LoginForm;
