import React from "react";
import PropTypes from "prop-types";

import { ModerationListItemSwitch } from "@rdcs/dap-front-library";

import SettingsTabLayout from "../../../components/Settings/SettingsTabLayout";
import {connect} from "react-redux";

const OptionalRecommendationsTab = ({ optionnalRecos, loading, auth, enable, disable }) => (
    <SettingsTabLayout title="Recommandations optionnelles" icon="pro" isLoading={loading}>
        {<ModerationListItemSwitch
            label="Recommandations optionnelles"
            defaultChecked={optionnalRecos}
            onChange={value => {
                if (value) {enable(auth)}
                else {disable(auth)}
                return value
            }}
            />}
    </SettingsTabLayout>
);

OptionalRecommendationsTab.propTypes = {
    loading: PropTypes.bool.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps)(OptionalRecommendationsTab);
