import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { tryFetchStatistics } from "../../actions/statistics";

import Moment from "moment";

import { DefaultLoader, DataTable, PageTitle, Button } from "@rdcs/dap-front-library";

import AppLayout from "../../components/Layout/AppLayout";
import { Link } from "react-router-dom";

const columns = [
    {
        name: 'Mois',
        dataIndex: 'month'   
    },
    {
        name: 'Nombre de nouveaux médecins par mois',
        dataIndex: 'nbNewPractitioners'
    },
    {
        name: 'Nombre de nouveaux patients par mois',
        dataIndex: 'nbNewPatients'
    }
];

const breadcrumbs = [
    { label: 'Tableau de bord' }
];

const DashboardScreen = ({
    auth,
    statistics: {
        dataList: { patientsPerDoctor, months },
        loading
    },
    tryFetchStatistics
}) => {
    const nbPatientsPerDoctor = React.useMemo(() => parseInt(patientsPerDoctor) || 0, [patientsPerDoctor]);
    const statisticsInfos = React.useMemo(() => months
        .sort((a, b) => new Date(b.month) - new Date(a.month))
        .map(month => ({
            month: Moment(new Date(month.month)).format("MMMM YYYY"),
            nbNewPractitioners: month.newPractitioners,
            nbNewPatients: month.newPatients,
        }))
    );

    React.useEffect(() => {
        tryFetchStatistics(auth);
    }, []);

    return (
        <PageTitle title="Timkl - Tableau de bord">
            <AppLayout navActive={0} breadcrumbs={breadcrumbs}>
                {
                    <>
                        <h1 className="title-1">Tableau de bord</h1>
                        {loading ? (
                            <DefaultLoader color="#CCC" />
                        ) : (
                            <p className="text-1 mt-3">Nombre moyen de patients par médecin : {nbPatientsPerDoctor}</p>
                        )}
                        <DataTable
                            columns={columns}
                            datas={statisticsInfos}
                            dataIsLoading={loading}
                        />
                        <Link to='/suivi-facturation' className='form__group'>
                            <Button className='button__primaryButton'>
                                Liste des actions à facturer
                            </Button>
                        </Link>
                    </>
                }
            </AppLayout>
        </PageTitle>
    );
};

DashboardScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    statistics: PropTypes.shape({
        dataList: PropTypes.shape({
            patientsPerDoctor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            months: PropTypes.arrayOf(
                PropTypes.shape({
                    month: PropTypes.string,
                    newPractitioners: PropTypes.number,
                    newPatients: PropTypes.number
                })
            ).isRequired
        }),
        loading: PropTypes.bool.isRequired
    }),
    tryFetchStatistics: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    statistics: state.statistics
});

const mapDispatchToProps = {
    tryFetchStatistics
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen);
