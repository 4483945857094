import * as types from "../actions/types";
import { toast } from "react-toastify";

const initialState = {
    list: [],
    listLoading: false,
    totalItems: 0,
    patientCreateLoading: false,
    patientCreateError: false,
    patientEdited: false,
    patientEditLoading: false,
    patientEditError: false,
    createAppointmentLoading: false,
    createAppointmentError: false,
    patientAppointments: [],
    patientAppointmentsLoading: false,
    singlePatientLoading: false,
    singlePatient: null,
    diagnosticRecommendations: [],
    diagnosticRecommendationsDiagnosticId: null,
    diagnosticRecommendationsLoading: false,
    patientLastDiagnosis: null,
    patientLastDiagnosisLoading: false,
    addPractitionerLoading: false,
    addPractitionerError: false,
    patientTasks: [],
    patientTasksLoading: false,
    createTaskLoading: false,
    editAppointmentLoading: false,
    patientsUpdateStatusLoading: false,
    consultationAnswers: [],
    potentialPatientList: [],
    potentialPatientsLoading: false,
    potentialPatientsError: false,
    potentialPatient: null,
    potentialPatientLoading: false,
    potentialPatientError: false,
};

function patients(state = initialState, action) {
    switch (action.type) {
        // FETCH
        case types.PATIENTS_FETCH_REQUESTED: {
            return Object.assign({}, state, { listLoading: true });
        }
        case types.PATIENTS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                listLoading: false,
                list: action.data,
                totalItems: action.totalItems,
            });
        }
        case types.PATIENTS_FETCH_FAILURE: {
            return Object.assign({}, state, { listLoading: false, list: [] });
        }
        // Create patient
        case types.CREATE_PATIENT_REQUESTED: {
            return Object.assign({}, state, {
                patientCreateLoading: true,
                patientCreateError: false,
            });
        }
        case types.CREATE_PATIENT_SUCCESS: {
            toast.success("Le patient va recevoir un email afin de finaliser son inscription", { autoClose: 5000 });
            return Object.assign({}, state, {
                patientCreateLoading: false,
                list: [...state.list, action.data],
            });
        }
        case types.CREATE_PATIENT_FAILURE: {
            toast.error("Le patient n'a pas pu être créé");
            return Object.assign({}, state, {
                patientCreateLoading: false,
                patientCreateError: action.data,
            });
        }
        // EDIT
        case types.PATIENTS_EDIT_REQUESTED: {
            return Object.assign({}, state, {
                patientEditLoading: true,
                patientEdited: false,
                editErrors: {},
            });
        }
        case types.PATIENTS_EDIT_SUCCESS: {
            toast.success("Le profil du patient a été modifié");
            return Object.assign({}, state, {
                patientEditLoading: false,
                patientEditError: false,
                patientEdited: true,
                singlePatient: action.data,
            });
        }
        case types.PATIENTS_EDIT_FAILURE: {
            toast.error("Le profil du patient n'a pas pu être modifié");
            return Object.assign({}, state, {
                patientEditLoading: false,
                patientEditError: action.data,
                patientEdited: false,
            });
        }
        // Fetch patient appointments
        case types.FETCH_PATIENT_APPOINTMENTS_REQUESTED: {
            return Object.assign({}, state, {
                patientAppointmentsLoading: true,
            });
        }
        case types.FETCH_PATIENT_APPOINTMENTS_SUCCESS: {
            return Object.assign({}, state, {
                patientAppointmentsLoading: false,
                patientAppointments: action.data,
            });
        }
        case types.FETCH_PATIENT_APPOINTMENTS_FAILURE: {
            return Object.assign({}, state, {
                patientAppointmentsLoading: false,
            });
        }
        // Create patient appointment
        case types.CREATE_PATIENT_APPOINTMENT_REQUESTED: {
            return Object.assign({}, state, {
                createAppointmentLoading: true,
                createAppointmentError: false,
            });
        }
        case types.CREATE_PATIENT_APPOINTMENT_SUCCESS: {
            toast.success("Le rendez-vous a été créé");
            return Object.assign({}, state, {
                createAppointmentLoading: false,
                patientAppointments: [...state.patientAppointments, action.data],
            });
        }
        case types.CREATE_PATIENT_APPOINTMENT_FAILURE: {
            toast.error("Le rendez-vous n'a pas pu être créé");
            return Object.assign({}, state, {
                createAppointmentLoading: false,
                createAppointmentError: action.data,
            });
        }
        // Edit patient appointment
        case types.EDIT_PATIENT_APPOINTMENT_REQUESTED: {
            return Object.assign({}, state, {
                editAppointmentLoading: true,
                editAppointmentError: false,
            });
        }
        case types.EDIT_PATIENT_APPOINTMENT_SUCCESS: {
            toast.success("Le rendez-vous a été modifié");
            return Object.assign({}, state, {
                editAppointmentLoading: false,
                patientAppointments: state.patientAppointments.map((item) => (item["@id"] === action.data["@id"] ? action.data : item)),
            });
        }
        case types.EDIT_PATIENT_APPOINTMENT_FAILURE: {
            toast.error("Le rendez-vous n'a pas pu être modifié");
            return Object.assign({}, state, {
                editAppointmentLoading: false,
                editAppointmentError: action.data,
            });
        }
        // Remove patient appointment
        case types.DELETE_PATIENT_APPOINTMENT_REQUESTED: {
            return Object.assign({}, state, { deleteAppointmentLoading: true });
        }
        case types.DELETE_PATIENT_APPOINTMENT_SUCCESS: {
            toast.success("Le rendez-vous a été supprimé");
            return Object.assign({}, state, {
                deleteAppointmentLoading: false,
            });
        }
        case types.DELETE_PATIENT_APPOINTMENT_FAILURE: {
            toast.error("Le rendez-vous n'a pas pu être supprimé");
            return Object.assign({}, state, {
                deleteAppointmentLoading: false,
            });
        }
        // Add health professional to patient
        case types.ADD_PRACTITIONER_REQUESTED: {
            return Object.assign({}, state, {
                addPractitionerLoading: true,
                addPractitionerError: false,
            });
        }
        case types.ADD_PRACTITIONER_SUCCESS: {
            toast.success("Le professionnel de santé a été ajouté au patient");
            return Object.assign({}, state, {
                addPractitionerLoading: false,
            });
        }
        case types.ADD_PRACTITIONER_FAILURE: {
            toast.error("Le professionnel de santé n'a pas pu être ajouté");
            return Object.assign({}, state, {
                addPractitionerLoading: false,
                addPractitionerError: action.data,
            });
        }
        // FETCH SINGLE PATIENT
        case types.SINGLE_PATIENT_REQUESTED: {
            return Object.assign({}, state, {
                singlePatientLoading: true,
                singlePatient: null,
            });
        }
        case types.SINGLE_PATIENT_SUCCESS: {
            return Object.assign({}, state, {
                singlePatientLoading: false,
                singlePatient: action.data,
            });
        }
        case types.SINGLE_PATIENT_FAILURE: {
            return Object.assign({}, state, {
                singlePatientLoading: false,
                singlePatient: null,
            });
        }
        // FETCH DIAGNOSTIC RECOMMENDATIONS
        case types.DIAGNOSTIC_RECOMMENDATIONS_REQUESTED: {
            return Object.assign({}, state, { diagnosticRecommendationsLoading: true });
        }
        case types.DIAGNOSTIC_RECOMMENDATIONS_SUCCESS: {
            return Object.assign({}, state, {
                diagnosticRecommendations: action.data,
                diagnosticRecommendationsDiagnosticId: action.diagnosticId,
                diagnosticRecommendationsLoading: false,
            });
        }
        case types.DIAGNOSTIC_RECOMMENDATIONS_FAILURE: {
            return Object.assign({}, state, {
                diagnosticRecommendations: [],
                diagnosticRecommendationsDiagnosticId: null,
                diagnosticRecommendationsLoading: false,
            });
        }
        // FETCH PATIENT LAST DIAGNOSIS
        case types.PATIENT_LAST_DIAGNOSIS_REQUESTED: {
            return Object.assign({}, state, {
                patientLastDiagnosisLoading: true,
                patientLastDiagnosis: null,
            });
        }
        case types.PATIENT_LAST_DIAGNOSIS_SUCCESS: {
            return Object.assign({}, state, {
                patientLastDiagnosisLoading: false,
                patientLastDiagnosis: action.data && action.data.length > 0 ? action.data[0] : null,
            });
        }
        case types.PATIENT_LAST_DIAGNOSIS_FAILURE: {
            return Object.assign({}, state, {
                patientLastDiagnosisLoading: false,
            });
        }
        // Fetch patient tasks
        case types.FETCH_PATIENT_TASKS_REQUESTED: {
            return Object.assign({}, state, {
                patientTasksLoading: true,
            });
        }
        case types.FETCH_PATIENT_TASKS_SUCCESS: {
            return Object.assign({}, state, {
                patientTasksLoading: false,
                patientTasks: action.data,
            });
        }
        case types.FETCH_PATIENT_TASKS_FAILURE: {
            return Object.assign({}, state, {
                patientTasksLoading: false,
            });
        }
        // Create patient task
        case types.CREATE_PATIENT_TASK_REQUESTED: {
            return Object.assign({}, state, {
                createTaskLoading: true,
                createTaskError: false,
            });
        }
        case types.CREATE_PATIENT_TASK_SUCCESS: {
            toast.success("La tâche a été créée");
            return Object.assign({}, state, {
                createTaskLoading: false,
                patientTasks: [...state.patientTasks, action.data],
            });
        }
        case types.CREATE_PATIENT_TASK_FAILURE: {
            toast.error("La tâche n'a pas pu être créée");
            return Object.assign({}, state, {
                createTaskLoading: false,
                createTaskError: action.data,
            });
        }
        // Edit patient task
        case types.EDIT_PATIENT_TASK_REQUESTED: {
            return Object.assign({}, state, {
                editTaskLoading: true,
                editTaskError: false,
            });
        }
        case types.EDIT_PATIENT_TASK_SUCCESS: {
            toast.success("La tâche a été modifiée");
            return Object.assign({}, state, {
                editTaskLoading: false,
                patientTasks: state.patientTasks.map((item) => (item["@id"] === action.data["@id"] ? action.data : item)),
            });
        }
        case types.EDIT_PATIENT_TASK_FAILURE: {
            toast.error("La tâche n'a pas pu être modifiée");
            return Object.assign({}, state, {
                editTaskLoading: false,
                editTaskError: action.data,
            });
        }
        // Update patients status
        case types.PATIENTS_UPDATE_STATUS_REQUESTED: {
            toast.info("La mise à jour est en cours d'exécution.");
            return Object.assign({}, state, {
                patientsUpdateStatusLoading: true,
            });
        }
        case types.PATIENTS_UPDATE_STATUS_SUCCESS: {
            toast.success("Les statuts des patients ont été mis à jour");
            return Object.assign({}, state, {
                patientsUpdateStatusLoading: false,
            });
        }
        case types.PATIENTS_UPDATE_STATUS_FAILURE: {
            toast.error(action.data.body["hydra:description"] ? action.data.body["hydra:description"] : "Echec de la mise à jour du statut des patients");
            return Object.assign({}, state, {
                patientsUpdateStatusLoading: false,
            });
        }
        // Update patients bgm average
        case types.PATIENTS_UPDATE_BGM_REQUESTED: {
            toast.info("La mise à jour est en cours d'exécution.");
            return Object.assign({}, state, {
                patientsUpdateBgmLoading: true,
            });
        }
        case types.PATIENTS_UPDATE_BGM_SUCCESS: {
            toast.success("La moyenne des prises ASG sur les 30 derniers jours de chaque patient a été mise à jour");
            return Object.assign({}, state, {
                patientsUpdateBgmLoading: false,
            });
        }
        case types.PATIENTS_UPDATE_BGM_FAILURE: {
            toast.error(action.data.body["hydra:description"] ? action.data.body["hydra:description"] : "Echec de la mise à jour des moyennes de prises ASG");
            return Object.assign({}, state, {
                patientsUpdateBgmLoading: false,
            });
        }
        // FETCH DIAGNOSIS CONSULTATION ANSWERS
        case types.DIAGNOSIS_CONSULTATION_ANSWERS_REQUESTED: {
            return Object.assign({}, state, { consultationAnswers: [] });
        }
        case types.DIAGNOSIS_CONSULTATION_ANSWERS_SUCCESS: {
            return Object.assign({}, state, { consultationAnswers: action.data });
        }
        case types.DIAGNOSIS_CONSULTATION_ANSWERS_FAILURE: {
            return Object.assign({}, state, {});
        }

        /* POTENTIAL PATIENTS */
        // FETCH
        case types.POTENTIAL_PATIENTS_FETCH_REQUESTED: {
            return Object.assign({}, state, { potentialPatientsLoading: true, potentialPatientsError: false });
        }
        case types.POTENTIAL_PATIENTS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                potentialPatientsLoading: false,
                potentialPatientList: action.data,
                totalItems: action.totalItems,
            });
        }
        case types.POTENTIAL_PATIENTS_FETCH_FAILURE: {
            return Object.assign({}, state, {
                potentialPatientsLoading: false,
                potentialPatientList: [],
                potentialPatientsError: action.data,
            });
        }
        // FETCH ONE
        case types.POTENTIAL_PATIENT_FETCH_REQUESTED: {
            return Object.assign({}, state, { potentialPatientLoading: true, potentialPatientError: false });
        }
        case types.POTENTIAL_PATIENT_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                potentialPatientLoading: false,
                potentialPatient: action.data,
            });
        }
        case types.POTENTIAL_PATIENT_FETCH_FAILURE: {
            return Object.assign({}, state, {
                potentialPatientLoading: false,
                potentialPatient: null,
                potentialPatientError: action.data,
            });
        }
        // EDIT
        case types.EDIT_POTENTIAL_PATIENT_REQUESTED: {
            return Object.assign({}, state, {
                potentialPatientsLoading: true,
                potentialPatientsError: false,
            });
        }
        case types.EDIT_POTENTIAL_PATIENT_SUCCESS: {
            const potentialPatientList = state.potentialPatientList.slice();
            const potentialPatientListIndex = potentialPatientList.findIndex((potentialPatient) => potentialPatient.id === action.potentialPatientId);

            if (potentialPatientListIndex !== -1) {
                if (action.data === true) {
                    potentialPatientList.splice(potentialPatientListIndex, 1);
                } else {
                    potentialPatientList[potentialPatientListIndex] = action.data;
                }
            }

            toast.success("Le profil du patient a été modifié");
            return Object.assign({}, state, {
                potentialPatientsLoading: false,
                potentialPatientsError: false,
                potentialPatientList: potentialPatientList,
            });
        }
        case types.EDIT_POTENTIAL_PATIENT_FAILURE: {
            toast.error("Le profil du patient n'a pas pu être modifié");
            return Object.assign({}, state, {
                potentialPatientsLoading: false,
                potentialPatientsError: action.data,
            });
        }
        default:
            return state;
    }
}

export default patients;
