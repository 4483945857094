import * as types from "../actions/types";
import { toast } from "react-toastify";

const initialState = {
    treatmentGroups: [],
    treatmentGroupsLoading: false,
    treatments: [],
    treatmentsLoading: false,
    createLoading: false,
    createError: false,
    editLoading: false,
    editError: false,
    deleteLoading: false,
    deleteError: false,
    swapOrderLoading: false,
};

function treatments(state = initialState, action) {
    switch (action.type) {
        // FETCH
        case types.TREATMENT_GROUPS_FETCH_REQUESTED: {
            return Object.assign({}, state, { treatmentGroupsLoading: true });
        }
        case types.TREATMENT_GROUPS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                treatmentGroupsLoading: false,
                treatmentGroups: action.data,
            });
        }
        case types.TREATMENT_GROUPS_FETCH_FAILURE: {
            return Object.assign({}, state, { treatmentGroupsLoading: false, treatmentGroups: [] });
        }
        // FETCH
        case types.TREATMENTS_FETCH_REQUESTED: {
            return Object.assign({}, state, { treatmentsLoading: true });
        }
        case types.TREATMENTS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                treatmentsLoading: false,
                treatments: action.data,
            });
        }
        case types.TREATMENTS_FETCH_FAILURE: {
            return Object.assign({}, state, { treatmentsLoading: false, treatments: [] });
        }
        // ADD
        case types.ADD_TREATMENT_GROUP_REQUESTED: {
            return Object.assign({}, state, {
                createLoading: true,
                createError: false,
            });
        }
        case types.ADD_TREATMENT_GROUP_SUCCESS: {
            toast.success("Le traitement a été créé");
            return Object.assign({}, state, { createLoading: false });
        }
        case types.ADD_TREATMENT_GROUP_FAILURE: {
            toast.error("Le traitement n'a pas pu être créé");
            return Object.assign({}, state, {
                createLoading: false,
                createError: action.data,
            });
        }
        // EDIT
        case types.EDIT_TREATMENT_GROUP_REQUESTED: {
            return Object.assign({}, state, {
                editLoading: true,
                editError: false,
            });
        }
        case types.EDIT_TREATMENT_GROUP_SUCCESS: {
            toast.success("Le traitement a été modifié");
            return Object.assign({}, state, { editLoading: false });
        }
        case types.EDIT_TREATMENT_GROUP_FAILURE: {
            toast.error("Le traitement n'a pas pu être modifié");
            return Object.assign({}, state, {
                editLoading: false,
                editError: action.data,
            });
        }
        // SWAP TREATMENT ORDER
        case types.SWAP_TREATMENT_ORDER_REQUESTED: {
            return Object.assign({}, state, { swapOrderLoading: true });
        }
        case types.SWAP_TREATMENT_ORDER_SUCCESS: {
            toast.success("L'ordre a été changé");

            return Object.assign({}, state, {
                swapOrderLoading: false,
                treatmentGroups: state.treatmentGroups.map((c) => {
                    if (c.id === action.data.treatmentOne.id) c.order = action.data.treatmentOne.order;
                    if (c.id === action.data.treatmentTwo.id) c.order = action.data.treatmentTwo.order;
                    return c;
                }),
            });
        }
        case types.SWAP_TREATMENT_ORDER_FAILURE: {
            toast.error("L'ordre des treatments n'a pas pu être modifié");
            return Object.assign({}, state, { swapOrderLoading: false });
        }
        // TREATMENT ACTIVATE
        case types.ACTIVATE_TREATMENT_SUCCESS: {
            toast.success(action.data.active ? "Le traitement a bien été activé." : "Le traitement a bien été désactivé.");
            return Object.assign({}, state, {});
        }
        case types.ACTIVATE_TREATMENT_FAILURE: {
            toast.error("Le traitement n'a pas pu être modifié.");
            return Object.assign({}, state, {});
        }
        // DEFAULT
        default:
            return state;
    }
}

export default treatments;
