import React from "react";
import PropTypes from "prop-types";
import { withRouter, useParams } from "react-router-dom";

import { connect } from "react-redux";
import { tryFetchLastDiagnosis, tryFetchDiagnosticRecommendations } from "../../actions/patients";
import { tryFetchNotes, tryFetchSinglePatientNotes } from "../../actions/notes";
import { tryFetchMeasures } from "../../actions/measures";
import { tryFetchPartners } from "../../actions/partners";
import { tryFetchReports } from "../../actions/reports";
import { tryFetchPatientAppointments } from "../../actions/patients";
import AppointmentRealizedCardContent from "./AppointmentRealizedCardContent";
import moment from "moment";
import { DefaultLoader, SliderCards, Modal } from "@rdcs/dap-front-library";
import RecommendedExams from "./sectionsDashboard/RecommendedExams";
import RecommendedVisits from "./sectionsDashboard/RecommendedVisits";
import SubTitle from "../../components/Coordinator/SubTitle";
import CoordinatorPatientLayout from "../../components/Coordinator/CoordinatorPatientLayout";
import Notes from "./sectionsDashboard/Notes/index";
import PatientReportForm from "../../components/Patients/PatientReportForm";

const CoordinatorPatientDashboardScreen = ({
    auth,
    patients,
    tryFetchLastDiagnosis,
    tryFetchDiagnosticRecommendations,
    tryFetchSinglePatientNotes,
    tryFetchMeasures,
    tryFetchPatientAppointments,
    tryFetchPartners,
    tryFetchReports,
}) => {
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), []);
    const patientIri = React.useMemo(() => `/patients/${patientId}`, [patientId]);

    const [loading, setLoading] = React.useState(true);
    const [diagnosticRecommendationsLoading, setDiagnosticRecommendationsLoading] = React.useState(true);

    React.useEffect(() => {
        document.title = "Timkl - Patients";
        Promise.all([
            tryFetchMeasures(patientId),
            tryFetchLastDiagnosis(patientId),
            tryFetchPatientAppointments(patientId),
            tryFetchSinglePatientNotes(auth, patientId),
            tryFetchPartners(auth),
            tryFetchReports(patientId),
            tryFetchNotes(auth, patientId),
        ]).then(() => {
            tryFetchDiagnosticRecommendations(auth, patients.singlePatient).then(() => setDiagnosticRecommendationsLoading(false));
            setLoading(false);
        });
    }, []);

    // Retrieve all consultation's appointments.
    const consultationAppointments = React.useMemo(
        () => patients.patientAppointments.filter((item) => item.recommendations.find((r) => r.type === "consultation")),
        [patients.patientAppointments]
    );
    // Retrieve all examination's appointments.
    const examinationAppointments = React.useMemo(
        () => patients.patientAppointments.filter((item) => item.recommendations.find((r) => r.type === "examination")),
        [patients.patientAppointments]
    );

    // Retrieve three lasts consultation's appointments done.
    const appointmentsDone = React.useMemo(
        () =>
            consultationAppointments
                .filter(({ doneAt }) => doneAt !== null)
                .sort((a, b) => new Date(b.doneAt) - new Date(a.doneAt))
                .slice(0, 3),
        [consultationAppointments]
    );

    // Retrieve all consultation's recommendations not planned.
    const recoConsults = React.useMemo(() => {
        const now = moment().unix();
        return (
            patients.diagnosticRecommendations
                // Keep consultations only
                .filter(({ recommendation: { type, id: _id } }) => {
                    if (type === "consultation") {
                        for (const appointment of consultationAppointments) {
                            if (appointment.recommendations.find((recommendation) => recommendation.id === _id)) {
                                return false;
                            }
                        }
                        return true;
                    }
                    return false;
                })
                // Bind Diagnostic recos with appointments
                .map((reco) => ({ ...reco, statusColor: now < moment(reco.dueAt).unix() ? "orange" : "red" }))
                // Remove all consultation done.
                .filter(({ appointment = null }) => !appointment?.doneAt)
                // Sort by color and after, sort by dueAt
                .sort((a, b) => {
                    const codeA = a.statusColor.charCodeAt(0);
                    const codeB = b.statusColor.charCodeAt(0);

                    if (codeA !== codeB) {
                        return codeB - codeA;
                    }
                    return new Date(a.appointment?.date || a.dueAt) - new Date(b.appointment?.date || b.dueAt);
                })
        );
    }, [patients.diagnosticRecommendations, consultationAppointments, loading]);

    const recoExams = React.useMemo(
        () =>
            patients.diagnosticRecommendations
                // Keep consultations only
                .filter(({ recommendation: { type, id: _id } }) => {
                    if (type === "examination") {
                        for (const appointment of examinationAppointments) {
                            if (appointment.recommendations.find((recommendation) => recommendation.id === _id)) {
                                return false;
                            }
                        }
                        return true;
                    }
                    return false;
                })
                // Remove all examinations already done
                .filter(({ appointment = null }) => !appointment?.doneAt)
                .sort((a, b) => new Date(a.appointment?.date || a.dueAt) - new Date(b.appointment?.date || b.dueAt))
                .slice(0, 8),
        [patients.diagnosticRecommendations, examinationAppointments, loading]
    );

    return (
        <CoordinatorPatientLayout activeNav="dashboard">
            {loading ? (
                <DefaultLoader color="#CCC" />
            ) : (
                <>
                    <SliderCards
                        icon="visite-realisees"
                        title="Visites réalisées"
                        subTitle={<SubTitle>Visites réalisées par le patient ces 12 derniers mois.</SubTitle>}
                        renderCard={(report) => {
                            const card = <AppointmentRealizedCardContent type="consultation" title={report.topic} date={report.doneAt} />;

                            return report["@type"].toLowerCase() === "report" ? (
                                <Modal
                                    reportModal
                                    buttonClass="complicationCategory__row"
                                    containerClass="report__modal__container"
                                    className="report__modal__content"
                                    closeButtonClass="closeModalLarge"
                                    buttonContent={card}
                                >
                                    <PatientReportForm
                                        title="Lancer un entretien de suivi"
                                        patientId={report.patient["@id"]}
                                        report={report}
                                        edition
                                        canModify={false}
                                    />
                                </Modal>
                            ) : (
                                card
                            );
                        }}
                        itemsCard={appointmentsDone}
                        renderEmpty={<p className="mt-2">Aucune visite réalisée</p>}
                        className="mb-4"
                        classNameCard="d-flex justify-space-between"
                        withFog
                    />
                    <RecommendedVisits isLoading={diagnosticRecommendationsLoading} datas={recoConsults} patientId={patientId} patientIri={patientIri} />
                    <RecommendedExams isLoading={diagnosticRecommendationsLoading} datas={recoExams} patientId={patientId} patientIri={patientIri} />
                    <Notes patientId={patientId} />
                </>
            )}
        </CoordinatorPatientLayout>
    );
};

CoordinatorPatientDashboardScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    patients: PropTypes.object.isRequired,
    partners: PropTypes.arrayOf(PropTypes.object).isRequired,
    tryFetchLastDiagnosis: PropTypes.func.isRequired,
    tryFetchDiagnosticRecommendations: PropTypes.func.isRequired,
    tryFetchSinglePatientNotes: PropTypes.func.isRequired,
    tryFetchMeasures: PropTypes.func.isRequired,
    tryFetchPatientAppointments: PropTypes.func.isRequired,
    tryFetchPartners: PropTypes.func.isRequired,
    tryFetchReports: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, patients, partners: { list: partners } }) => ({
    auth,
    patients,
    partners,
});

const mapDispatchToProps = {
    tryFetchLastDiagnosis,
    tryFetchDiagnosticRecommendations,
    tryFetchSinglePatientNotes,
    tryFetchMeasures,
    tryFetchPatientAppointments,
    tryFetchPartners,
    tryFetchReports,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CoordinatorPatientDashboardScreen));
