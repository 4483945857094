import { combineReducers } from "redux";

import authReducer from "./auth";
import recommendationsReducer from "./recommendations";
import treatmentsReducer from "./treatments";
import complicationsReducer from "./complications";
import questionsReducer from "./questions";
import adminsReducer from "./admins";
import moderatorsReducer from "./moderators";
import partnersReducer from "./partners";
import coordinatorsReducer from "./coordinators";
import measuresReducer from "./measures";
import notesReducer from "./notes";
import statisticsReducer from "./statistics";
import patientsReducer from "./patients";
import profileReducer from "./profile";
import medicalsReducer from "./medicals";
import taskLabelsReducer from "./taskLabels";
import pointsOfAttentionReducer from "./pointsOfAttention";
import reportsReducer from "./reports";
import thematicReducer from "./thematic";
import diagnosisReducer from "./diagnosis";


const reducers = combineReducers({
    auth: authReducer,
    recommendations: recommendationsReducer,
    treatments: treatmentsReducer,
    complications: complicationsReducer,
    questions: questionsReducer,
    admins: adminsReducer,
    moderators: moderatorsReducer,
    partners: partnersReducer,
    coordinators: coordinatorsReducer,
    measures: measuresReducer,
    notes: notesReducer,
    statistics: statisticsReducer,
    patients: patientsReducer,
    profile: profileReducer,
    medicals: medicalsReducer,
    taskLabels: taskLabelsReducer,
    pointsOfAttention: pointsOfAttentionReducer,
    reports: reportsReducer,
    thematic : thematicReducer,
    diagnosis : diagnosisReducer
});

export default reducers;
