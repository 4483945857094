import React from "react";
import PropTypes from 'prop-types';

import { Button, InputText, Select, InputDateTime } from "@rdcs/dap-front-library";
import moment from "moment";

const CoordinatorPatientTaskForm = ({
    patientId,
    taskLabels,
    isLoading,
    errors,
    onSubmit,
    patientPractitioners
}) => {
    const [showInputLabel, setShowInputLabel] = React.useState(false);
    const [label, setLabel] = React.useState(null);
    const [responsible, setResponsible] = React.useState(null);
    const [dueAt, setDueAt] = React.useState(false);

    const taskLabelOptions =  React.useMemo(() => ([
        { label: "-- Choisir --", value: "" },
        ...taskLabels
            .sort((a, b) => (a.order < b.order ? -1 : 1))
            .map(tL => ({ label: tL.label, value: tL.label })),
        { label: "Personnalisé", value: "custom" }
    ]), [taskLabels]);

    const responsibleOptions =  React.useMemo(() => ([
        { label: "-- Choisir --", value: "" },
        ...patientPractitioners.map(aR => ({
            label: aR.user.firstName + " " + aR.user.lastName,
            value: aR.user["@id"]
        }))
    ]), [patientPractitioners]);

    return (
        <form
            onSubmit={e => {
                e.preventDefault();

                const form = {
                    label,
                    dueAt: moment(dueAt).format('YYYY-MM-DD[T]HH:mm:ssZ'),
                    patient: `/patients/${patientId}`
                };

                if (responsible !== null) {
                    form.responsible = responsible;
                }

                onSubmit(form);
            }}
        >
            <Select
                label="Intitulé de l'action"
                options={taskLabelOptions}
                onChange={val => {
                    if (val === "custom") {
                        setLabel(null);
                        setShowInputLabel(true);
                    } else {
                        setLabel(val);
                        setShowInputLabel(false);
                    }
                }}
                error={errors.label}
                required
            />
            {showInputLabel && (
                <InputText
                    onChange={setLabel}
                    placeholder="Saisissez le titre de l'action"
                    required
                />
            )}
            <Select
                options={responsibleOptions}
                label="Assignée à"
                onChange={setResponsible}
            />
            <InputDateTime
                label="Avant le"
                placeholder="Choisissez une date"
                onChange={setDueAt}
                minDate={new Date()}
                error={errors.dueAt}
            />
            <Button className="button__primaryButton mt-3" isLoading={isLoading}>
                Ajouter l'action
            </Button>
        </form>
    );
}

CoordinatorPatientTaskForm.propTypes = {
    patientId: PropTypes.number.isRequired,
    taskLabels: PropTypes.arrayOf(PropTypes.shape({
        order: PropTypes.number,
        label: PropTypes.string.isRequired,
    })).isRequired,
    isLoading: PropTypes.bool.isRequired,
    errors: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    patientPractitioners: PropTypes.arrayOf(PropTypes.shape({
        user: PropTypes.shape({
            "@id": PropTypes.string.isRequired,
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
        }).isRequired,
    })).isRequired,
};

export default CoordinatorPatientTaskForm;
