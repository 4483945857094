import React from "react";
import PropTypes from 'prop-types';

import { connect } from "react-redux";
import { userCan } from "../../reducers/auth";

import { AdminLayout } from "@rdcs/dap-front-library";

const userNav = [
    {
        title: "Profil",
        path: "/mon-profil",
        icon: "datas"
    },
    {
        title: "Se déconnecter",
        path: "/deconnexion",
        icon: "close"
    }
];

const adminNav = [
    {
        path: "/dashboard",
        icon: "datas",
        title: "Tableau de bord"
    },
    {
        path: "/recommandations",
        icon: "stats",
        title: "Recommandations"
    },
    {
        path: "/configuration",
        icon: "reglages",
        title: "Configuration"
    }
];

const moderatorNav = [
    {
        path: "/moderation",
        icon: "pro",
        title: "Espace de modération"
    }
];

const coordinatorNav = [
    {
        path: "/facilitateurs",
        icon: "datas",
        title: "Espace facilitateur"
    },
    {
        path: "/patients-potentiels",
        icon: "datas",
        title: "Nouveaux patients"
    },
    {
        path: "/questionnaire-rupture",
        icon: "question",
        title: "Questionnaire d'identification de nouveaux patients"
    }
];

const AppLayout = ({ navActive, pageClass, auth, children, breadcrumbs }) => {
    const nav = React.useMemo(() => {
        switch (true) {
            case userCan("ROLE_MODERATOR"):
                return moderatorNav;
            case userCan("ROLE_COORDINATOR"):
                return coordinatorNav;
            case userCan("ROLE_ADMIN"):
            default:
                return adminNav;
        }
    }, [auth.user]);

    return (
        <AdminLayout
            nav={nav}
            userNav={userNav}
            user={auth.user}
            pageClass={pageClass}
            navActive={navActive}
            breadcrumbs={breadcrumbs}
            roles={auth.user.roles}
        >
            {children}
        </AdminLayout>
    );
};

AppLayout.propTypes = {
    navActive: PropTypes.number,
    pageClass: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ]),
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        link: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                pathname: PropTypes.string
            })
        ]),
    })),
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(AppLayout);
