import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import Questions from "../../../actions/questions";

import StudyQuestionForm from "../../../components/Settings/Forms/StudyQuestionForm";

import SettingsFormScreen from "../../../components/Settings/SettingsFormScreen";

const { tryAddStudyQuestion } = Questions;

const AddStudyQuestionScreen = ({ questions: { createLoading, createError }, tryAddStudyQuestion }) => (
    <SettingsFormScreen
        /* Document and page titles */
        documentTitle="Ajouter question d'étude"
        pageTitle="Ajouter une question d'étude"
        /* Redux request callback use to add on BDD */
        formRequest={tryAddStudyQuestion}
        /* Forms */
        itemForm={StudyQuestionForm}
        itemFormRequestLoading={createLoading}
        itemFormRequestError={createError}
        /* Configuration tab name for redirection */
        tab="study-questions"
    />
);

AddStudyQuestionScreen.propTypes = {
    questions: PropTypes.shape({
        createLoading: PropTypes.bool.isRequired,
        createError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object
            }),
            PropTypes.bool
        ]).isRequired
    }).isRequired,
    tryAddStudyQuestion: PropTypes.func.isRequired
};

const mapStateToProps = ({ questions }) => ({ questions });

const mapDispatchToProps = { tryAddStudyQuestion };

export default connect(mapStateToProps, mapDispatchToProps)(AddStudyQuestionScreen);
