import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import Questions from "../../../actions/questions";

import StudyQuestionForm from "../../../components/Settings/Forms/StudyQuestionForm";
import SettingsEditFormScreen from "../../../components/Settings/SettingsEditFormScreen";

const { tryEditStudyQuestion, tryFetchStudyQuestions } = Questions;

const EditStudyQuestionScreen = ({
    questions: { studyQuestions, studyQuestionsLoading, editLoading, editError },
    tryEditStudyQuestion,
    tryFetchStudyQuestions
}) => (
    <SettingsEditFormScreen
        /* Document and page titles */
        documentTitle="Modifier question d'étude"
        pageTitle="Modifier une question d'étude"
        /* Redux request callback use to add on BDD */
        formRequest={tryEditStudyQuestion}
        fetchRequests={tryFetchStudyQuestions}
        testFetchRequestsNeeded={studyQuestions.length === 0}
        /* Forms */
        itemForm={StudyQuestionForm}
        itemFormRequestLoading={editLoading}
        itemFormRequestError={editError}
        itemFormIsLoading={studyQuestionsLoading}
        itemFormEditedItemPropName="editedStudyQuestion"
        /* Items lists to retrieve the current item */
        items={studyQuestions}
        /* Callback event call when edited item is found allowing to modity it before sending it into forms */
        onItemEditedFound={question => {
            question.responses = [];
            question.responsesWeight = [];
            for (let i = 1; question[`answer${i}`]; ++i) {
                question.responses.push(question[`answer${i}`]);
                question.responsesWeight.push(question[`answer${i}Weight`]);
            }
            return question;
        }}
        /* Configuration tab name for redirection */
        tab="study-questions"
    />
);

EditStudyQuestionScreen.propTypes = {
    questions: PropTypes.shape({
        studyQuestions: PropTypes.arrayOf(PropTypes.object).isRequired,
        studyQuestionsLoading: PropTypes.bool.isRequired,
        editLoading: PropTypes.bool.isRequired,
        editError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object
            }),
            PropTypes.bool
        ]).isRequired
    }).isRequired,
    tryEditStudyQuestion: PropTypes.func.isRequired,
    tryFetchStudyQuestions: PropTypes.func.isRequired
};

const mapStateToProps = ({ questions }) => ({ questions });

const mapDispatchToProps = { tryEditStudyQuestion, tryFetchStudyQuestions };

export default connect(mapStateToProps, mapDispatchToProps)(EditStudyQuestionScreen);
