import React from "react";
import PropTypes from "prop-types";
import { Redirect, withRouter } from "react-router-dom";

import { connect } from "react-redux";

import { tryLogin, tryLoginCode } from "../../actions/auth";

import LoginForm from "../../components/Login/LoginForm";
import { AdminLayoutAnonymous, AdminLayoutAsideAnonymous } from "@rdcs/dap-front-library";
import { userCan } from "../../reducers/auth";

const LoginScreen = ({
    location,
    location: { afterAuthPath },
    auth: { user, loginError, loginCodeError, loginLoading, loginCodeLoading },
    tryLogin,
    tryLoginCode
}) => {
    document.title = "Timkl - Connexion";

    const afterAuthPathMemo = React.useMemo(
        () =>
            afterAuthPath
                ? afterAuthPath
                : userCan("ROLE_ADMIN")
                ? "/dashboard"
                : userCan("ROLE_MODERATOR")
                ? "/moderation"
                : userCan("ROLE_COORDINATOR")
                ? "/facilitateurs"
                : "/dashboard",
        [afterAuthPath, user]
    );

    if (user === null) {
        return (
            <AdminLayoutAnonymous>
                <div className="grid credentials__container">
                    <AdminLayoutAsideAnonymous />
                    <main className="col-sm-50 credentialsMain__container">
                        <div className="credentialsMain">
                            <div className="credentials__title">
                                Connectez-vous à <br />
                                Diabète Adhérence Plateforme
                            </div>
                            <LoginForm
                                loginSubmit={tryLogin}
                                loginCodeSubmit={tryLoginCode}
                                loginError={loginError}
                                loginCodeError={loginCodeError}
                                loginLoading={loginLoading}
                                loginCodeLoading={loginCodeLoading}
                            />
                        </div>
                    </main>
                </div>
            </AdminLayoutAnonymous>
        );
    } else {
        return (
            <Redirect
                to={{
                    pathname: afterAuthPathMemo,
                    state: { from: location }
                }}
            />
        );
    }
};

LoginScreen.propTypes = {
    auth: PropTypes.shape({
        user: PropTypes.object,
        loginError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
        loginCodeError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
        loginLoading: PropTypes.bool.isRequired,
        loginCodeLoading: PropTypes.bool.isRequired
    })
};

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = {
    tryLogin,
    tryLoginCode
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginScreen));
