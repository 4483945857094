import React, { useEffect, useRef, useState } from "react";
import { PageTitle, DefaultLoader, Card, ImagePreview, DashboardHeaderTitle, Modal, AntdPagination } from "@rdcs/dap-front-library";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import defaultUserImage from "@rdcs/dap-front-library/static/images/default-user.png";
import CoordinatorPatientLayout from "../../components/Coordinator/CoordinatorPatientLayout";
import PatientReportForm from "../../components/Patients/PatientReportForm";
import { tryFetchReports } from "../../actions/reports";

const CoordinatorPatientReportsScreen = ({ tryFetchReports, reports, user }) => {

  const [currentModalIndex, setCurrentModalIndex] = useState(null);
  const modalRefs = useRef([]);

  const { id } = useParams();
  const patientId = parseInt(id);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  function reloadReport() {
    tryFetchReports(patientId, currentPage)
      .then(() => {
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false);
      });

  }

  React.useEffect(() => {
    tryFetchReports(patientId, currentPage)
      .then(() => {
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [])

  const openModal = (index) => {
    setCurrentModalIndex(index);
  };

  const closeModal = () => {
    setCurrentModalIndex(null);
  };

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };


  const startIndex = (currentPage - 1) * 8;
  const endIndex = startIndex + 8;


  const sortedReports = reports?.list?.sort((a, b) => new Date(b.date) - new Date(a.date));
  const visibleReports = sortedReports?.slice(startIndex, endIndex);


  return (
    <PageTitle title="Timkl - Entretiens de suivi">
      <CoordinatorPatientLayout patientId={patientId}>
        {loading ? (
          <DefaultLoader color="#CCC" />
        ) : (
          <section>
            <DashboardHeaderTitle
              title="Entretiens de suivi"
              icon="questionnaire"
              subTitle="→ Historique de tous les entretiens de suivi réalisés par l’équipe de soin du patient"
              className="mb-2"
            />
            {visibleReports.length > 0 ? (
              visibleReports.map((report, index) => (
                <div
                  className="cursorPointer"
                  key={report.id}
                  onClick={() => openModal(index)}
                >
                  <Card key={report.id} className="justify-space-between mb-1">
                    <p className="titleReportCard">
                      Entretien du {moment(report.date).format("DD/MM/YYYY")}
                    </p>
                    <div className="justify-end d-flex">
                      <ImagePreview
                        className="coordinatorHCPAgreement__modal__body--image"
                        filename={null}
                        fallbackImageUrl={defaultUserImage}
                        user={report.createdBy}
                      />
                      <div className="nameCardReport ml-2">
                        <h3>
                          {report.createdBy.lastName} {report.createdBy.firstName},{" "}
                          <small>{report.createdBy["@type"]}</small>
                        </h3>
                      </div>
                    </div>
                  </Card>
                  {currentModalIndex === index && (
                    <Modal
                      key={report.id}
                      reportModal
                      ref={(ref) => (modalRefs.current[index] = ref)}
                      show
                      containerClass="report__modal__container"
                      className="report__modal__content"
                      onClose={closeModal}
                      closeButtonClass="closeModalLarge"
                    >
                      <PatientReportForm
                        title="Lancer un entretien de suivi"
                        patientId={report.patient["@id"]}
                        report={report}
                        canModify={false}
                        edition
                        prevObjective={visibleReports[index + 1]?.objective}
                        setLoading={setLoading}
                        onSubmit={() => {
                          modalRefs.current[index].toggleModal();
                        }}
                      />
                    </Modal>
                  )}
                </div>
              ))
            ) : (
              <p>Aucun rapport trouvé.</p>
            )}
          </section>
        )}
        <AntdPagination
          className="mt-3"
          defaultPageSize={8}
          current={currentPage}
          total={reports.list.length}
          onChange={handlePaginationChange}
          hideOnSinglePage
        />
      </CoordinatorPatientLayout  >
    </PageTitle>
  );
};

const mapStateToProps = ({ reports, auth: { user } }) => ({
  reports,
  user,
});

const mapDispatchToProps = {
  tryFetchReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoordinatorPatientReportsScreen);
