import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { tryRequestPassword } from "../../actions/auth";

import { AdminLayoutAnonymous, AdminLayoutAsideAnonymous } from "@rdcs/dap-front-library";

import RequestPasswordForm from "../../components/Login/RequestPasswordForm";

const RequestPasswordScreen = ({ auth: { requestPasswordLoading, requestPasswordError }, tryRequestPassword }) => {
    React.useEffect(() => {
        document.title = "Timkl - Mot de passe oublié";
    }, []);

    return (
        <AdminLayoutAnonymous>
            <div className="grid credentials__container">
                <AdminLayoutAsideAnonymous />
                <main className="col-sm-50 credentialsMain__container">
                    <div className="credentialsMain">
                        <RequestPasswordForm
                            submit={tryRequestPassword}
                            loading={requestPasswordLoading}
                            error={requestPasswordError}
                        />
                    </div>
                </main>
            </div>
        </AdminLayoutAnonymous>
    );
};

RequestPasswordScreen.propTypes = {
    auth: PropTypes.shape({
        requestPasswordLoading: PropTypes.bool.isRequired,
        requestPasswordError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired
    }).isRequired,
    tryRequestPassword: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = {
    tryRequestPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestPasswordScreen);
