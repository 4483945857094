import React from "react";
import PropTypes from "prop-types";

import { InputText, InputRadio, InputSymbol, TextArea, Select, SelectMultiple, Button, CheckboxGroup, InputNumber } from "@rdcs/dap-front-library";

import { constant } from "../../utils/utils";

const RecommendationForm = ({
    editedRecommendation = {},
    recommendations,
    isLoading,
    errors,
    onSubmit,
    treatments: treatmentsProps,
    complications: complicationsProps,
    partners: partnersProps,
}) => {
    const pathologies = React.useMemo(() => constant("pathologies"), []);
    const editedPathologies = React.useMemo(
        () =>
            pathologies
                .filter((pathology) => {
                    const key = Object.keys(editedRecommendation).find((k) => k.toLowerCase().endsWith(pathology.toLowerCase()));
                    return editedRecommendation[key];
                })
                .map((pathology) => pathology),
        [pathologies, editedRecommendation]
    );

    const consultationNameOptions = React.useMemo(() => {
        let options = [];
        recommendations.list.forEach((r) => {
            if (r.type === "consultation" && options.indexOf(r.title) === -1) {
                options.push(r.title);
            }
        });
        return [{ label: "-- Aucune --", value: "" }, ...options.map((name) => ({ label: name, value: name }))];
    }, [recommendations.list]);

    const recommendationsSexes = React.useMemo(() => constant("recommendation", "sexes"), []);
    const recommendationsAgeRanges = React.useMemo(() => constant("recommendation", "ageRanges"), []);
    const recommendationsDiabetesDurations = React.useMemo(() => constant("recommendation", "diabetesDurations"), []);
    const recommendationsRecurrences = React.useMemo(() => constant("recommendation", "recurrences"), []);
    const recommendationsTypes = React.useMemo(() => constant("recommendation", "types"), []);
    const recommendationsRecipients = React.useMemo(() => constant("recommendation", "recipients"), []);
    const recommendationsGrades = React.useMemo(() => constant("recommendation", "grades"), []);
    const recommendationsSources = React.useMemo(() => constant("recommendation", "sources"), []);

    const recommendationsSexesOptions = React.useMemo(
        () => recommendationsSexes.map((sex) => ({ label: constant("recommendation", "sexes", sex), value: sex })),
        [recommendationsSexes]
    );
    const recommendationsAgeRangesOptions = React.useMemo(
        () => recommendationsAgeRanges.map((ageRange) => ({ label: constant("recommendation", "ageRanges", ageRange), value: ageRange })),
        [recommendationsAgeRanges]
    );
    const recommendationsDiabetesDurationsOptions = React.useMemo(
        () =>
            recommendationsDiabetesDurations.map((diabetesDuration) => ({
                label: constant("recommendation", "diabetesDurations", diabetesDuration),
                value: diabetesDuration,
            })),
        [recommendationsDiabetesDurations]
    );
    const recommendationsRecurrencesOptions = React.useMemo(
        () => recommendationsRecurrences.map((recurrence) => ({ label: constant("recommendation", "recurrences", recurrence), value: recurrence })),
        [recommendationsRecurrences]
    );
    const recommendationsTypesOptions = React.useMemo(
        () => recommendationsTypes.map((type) => ({ label: constant("recommendation", "types", type), value: type })),
        [recommendationsTypes]
    );
    const recommendationsRecipientsOptions = React.useMemo(
        () => recommendationsRecipients.map((recipient) => ({ label: constant("recommendation", "recipients", recipient), value: recipient })),
        [recommendationsRecipients]
    );
    const recommendationsGradesOptions = React.useMemo(
        () => recommendationsGrades.map((grade) => ({ label: constant("recommendation", "grades", grade), value: grade })),
        [recommendationsGrades]
    );
    const recommendationsSourcesOptions = React.useMemo(
        () => recommendationsSources.map((source) => ({ label: source, value: source })),
        [recommendationsSources]
    );
    const pathologiesOptions = React.useMemo(
        () => constant("pathologies").map((pathology) => ({ label: constant("pathologies", pathology), value: pathology })),
        []
    );

    const defaultTreatments = React.useMemo(
        () => (editedRecommendation.treatments ? editedRecommendation.treatments.map((t) => t["@id"]) : []),
        [editedRecommendation.treatments]
    );
    const defaultComplications = React.useMemo(
        () => (editedRecommendation.complications ? editedRecommendation.complications.map((c) => c["@id"]) : []),
        [editedRecommendation.complications]
    );

    const [sex, setSex] = React.useState(editedRecommendation.sex || recommendationsSexes[0]);
    const [pathology, setPathology] = React.useState(editedPathologies);
    const [ageRange, setAgeRange] = React.useState(editedRecommendation.ageRange || recommendationsAgeRanges[0]);
    const [treatments, setTreatments] = React.useState(defaultTreatments);
    const [complications, setComplications] = React.useState(defaultComplications);
    const [diabetesDuration, setDiabetesDuration] = React.useState(editedRecommendation.diabetesDuration || recommendationsDiabetesDurations[0]);
    const [initialCheckUp, setInitialCheckUp] = React.useState(editedRecommendation.initialCheckUp);
    const [mandatory, setMandatory] = React.useState(editedRecommendation.mandatory);
    const [title, setTitle] = React.useState(editedRecommendation.title || "");
    const [description, setDescription] = React.useState(editedRecommendation.description || "");
    const [simplifiedDescription, setSimplifiedDescription] = React.useState(editedRecommendation.simplifiedDescription || "");
    const [goal, setGoal] = React.useState(editedRecommendation.goal || null);
    const [recurrence, setRecurrence] = React.useState(editedRecommendation.recurrence || recommendationsRecurrences[0]);
    const [type, setType] = React.useState(editedRecommendation.type || recommendationsTypes[0]);
    const [recipient, setRecipient] = React.useState(editedRecommendation.recipient || recommendationsRecipients[0]);
    const [grade, setGrade] = React.useState(editedRecommendation.grade || recommendationsGrades[0]);
    const [source, setSource] = React.useState(editedRecommendation.source || recommendationsSources[0]);
    const [consultationName, setConsultationName] = React.useState(editedRecommendation.consultationName || null);
    const [position, setPosition] = React.useState(editedRecommendation.position || null);
    const [hasPartner, setHasPartner] = React.useState(editedRecommendation.partner ? "true" : "false");
    const [partner, setPartner] = React.useState(editedRecommendation.partner && editedRecommendation.partner["@id"]);
    const onSubmitCallback = (event) => {
        event.preventDefault();
        onSubmit({
            sex,
            pathology,
            ageRange,
            treatments,
            complications,
            diabetesDuration,
            initialCheckUp,
            mandatory,
            title,
            description: description.trim(),
            simplifiedDescription: simplifiedDescription.trim(),
            goal,
            recurrence,
            type,
            recipient,
            grade,
            source,
            consultationName,
            position,
            partner: hasPartner === "true" ? partner : null,
        });
    };

    return (
        <div className="card mt-3">
            <form onSubmit={onSubmitCallback} className="grid grid__padding-25">
                <h2 className="title-2 col-100">Contenu de la recommandation</h2>
                <div className="col-100">
                    <InputText label="Titre*" error={errors.title} onChange={setTitle} defaultValue={title} required />
                </div>
                <div className="col-100">
                    <TextArea label="Description complète*" error={errors.description} onChange={setDescription} value={description} required />
                </div>
                <div className="col-100">
                    <TextArea
                        label="Description simplifiée"
                        error={errors.simplifiedDescription}
                        onChange={setSimplifiedDescription}
                        value={simplifiedDescription}
                    />
                </div>
                <div className="col-md-50">
                    <InputSymbol label="Objectif Hb1c" symbol="%" error={errors.goal} onChange={(val) => setGoal(parseFloat(val) || 0)} defaultValue={goal} />
                </div>
                <div className="col-md-50">
                    <Select
                        label="Récurrence*"
                        error={errors.recurrence}
                        onChange={setRecurrence}
                        defaultValue={recurrence}
                        options={recommendationsRecurrencesOptions}
                        required
                    />
                </div>
                <div className="col-md-50">
                    <Select
                        label="Type de recommendation*"
                        error={errors.type}
                        onChange={(val) => {
                            setType(val);
                            setConsultationName(val !== "examination" ? null : consultationName);
                        }}
                        defaultValue={type}
                        options={recommendationsTypesOptions}
                        required
                    />
                </div>
                <div className="col-md-50">
                    {type === "examination" && (
                        <Select
                            label="Peut être réalisé lors de la consultation suivante"
                            error={errors.consultationName}
                            onChange={setConsultationName}
                            defaultValue={consultationName}
                            options={consultationNameOptions}
                        />
                    )}
                </div>
                <div className="clearfix" />
                <div className="col-md-50">
                    <Select
                        label="Destinataire*"
                        error={errors.recipient}
                        onChange={setRecipient}
                        defaultValue={recipient}
                        options={recommendationsRecipientsOptions}
                        required
                    />
                </div>
                <div className="col-md-50">
                    <Select label="Grade*" error={errors.grade} onChange={setGrade} defaultValue={grade} options={recommendationsGradesOptions} required />
                </div>
                <div className="col-md-100">
                    <Select label="Source*" error={errors.source} onChange={setSource} defaultValue={source} options={recommendationsSourcesOptions} required />
                </div>

                <h2 className="title-2 col-100 mt-3 form__group">Règles d'application</h2>
                <div className="col-md-50">
                    <InputRadio label="Sexe*" onChange={setSex} defaultValue={sex} options={recommendationsSexesOptions} required />
                </div>
                <div className="col-md-50">
                    <CheckboxGroup
                        label="Pathologie(s)"
                        onChange={setPathology}
                        defaultValue={pathology}
                        options={pathologiesOptions}
                        defaultChecked={pathology}
                    />
                </div>
                <div className="clearfix" />
                <div className="col-md-50">
                    <Select
                        label="Âge*"
                        error={errors.ageRange}
                        onChange={setAgeRange}
                        defaultValue={ageRange}
                        options={recommendationsAgeRangesOptions}
                        required
                    />
                </div>
                <div className="col-md-50">
                    <Select
                        label="Durée de découverte du diabète*"
                        error={errors.diabetesDuration}
                        onChange={setDiabetesDuration}
                        defaultValue={diabetesDuration}
                        options={recommendationsDiabetesDurationsOptions}
                        required
                    />
                </div>
                <div className="col-100">
                    <SelectMultiple
                        label="Traitements"
                        legend="Sélectionnez le ou les traitements pour lesquels la recommandation doit s'appliquer"
                        placeholder="Sélectionner un ou plusieurs traitements"
                        onChange={setTreatments}
                        defaultSelected={treatments}
                        multiple
                        options={treatmentsProps.map((option) => ({
                            key: option.id,
                            id: option.id,
                            title: option.name,
                            value: option["@id"],
                        }))}
                    />
                </div>
                <div className="col-100">
                    <SelectMultiple
                        label="Complications"
                        placeholder="Sélectionner une ou plusieurs complications"
                        onChange={setComplications}
                        defaultSelected={complications}
                        multiple
                        options={complicationsProps
                            .filter((c) => c.active === true)
                            .map((option) => ({
                                key: option.id,
                                id: option.id,
                                title: option.name,
                                value: option["@id"],
                                pId: option.parent ? option.parent.id : 0,
                            }))}
                    />
                </div>

                <div className="clearfix" />
                <div className="col-md-50">
                    <InputRadio
                        label="Application systématique*"
                        onChange={(val) => setMandatory(val === "true")}
                        defaultValue={mandatory}
                        options={[
                            { label: "Oui", value: true },
                            { label: "Non", value: false },
                        ]}
                        required
                    />
                </div>
                <div className="col-50">
                    <InputRadio
                        label="La recommandation s’applique uniquement lors de la première consultation*"
                        error={errors.initialCheckUp}
                        onChange={(val) => {
                            if (val === "true") {
                                setInitialCheckUp(true);
                            } else if (val === "false") {
                                setInitialCheckUp(false);
                            } else {
                                setInitialCheckUp("");
                            }
                        }}
                        defaultValue={initialCheckUp}
                        options={[
                            { label: "Oui", value: true },
                            { label: "Non", value: false },
                        ]}
                        required
                    />
                </div>
                <div className="col-100">
                    <InputNumber
                        label="Position"
                        placeholder="La position à laquelle vous voulez que s'affiche la recommandation"
                        error={errors.position}
                        onChange={(val) => setPosition(val === "" ? null : Number(val))}
                        defaultValue={position}
                    />
                </div>
                <div className="col-50">
                    <InputRadio
                        label="Un suivi partenaire est-il disponible pour cette recommandation ? *"
                        error={errors.initialCheckUp}
                        onChange={setHasPartner}
                        defaultValue={hasPartner}
                        options={[
                            { label: "Oui", value: "true" },
                            { label: "Non", value: "false" },
                        ]}
                        required
                    />
                </div>
                <div className="col-50">
                    {hasPartner === "true" && (
                        <Select
                            label="Choisir le partenaire*"
                            onChange={setPartner}
                            defaultValue={partner}
                            options={[
                                {
                                    label: "",
                                    value: "",
                                },
                                ...partnersProps.map((option) => ({
                                    key: option.id,
                                    id: option.id,
                                    label: option.name,
                                    value: option["@id"],
                                })),
                            ]}
                            required
                        />
                    )}
                </div>
                <div className="clearfix" />

                <div className="form__group col-100 text-center">
                    <Button type="submit" className="button__primaryButton" isLoading={isLoading}>
                        Enregistrer
                    </Button>
                </div>
            </form>
        </div>
    );
};

RecommendationForm.propTypes = {
    editedRecommendation: PropTypes.shape({
        sex: PropTypes.string,
        ageRange: PropTypes.string,
        treatments: PropTypes.arrayOf(
            PropTypes.shape({
                "@id": PropTypes.string.isRequired,
            })
        ),
        complications: PropTypes.arrayOf(
            PropTypes.shape({
                "@id": PropTypes.string.isRequired,
            })
        ),
        diabetesDuration: PropTypes.string,
        initialCheckUp: PropTypes.bool,
        mandatory: PropTypes.bool,
        title: PropTypes.string,
        description: PropTypes.string,
        simplifiedDescription: PropTypes.string,
        goal: PropTypes.number,
        recurrence: PropTypes.string,
        type: PropTypes.string,
        recipient: PropTypes.string,
        grade: PropTypes.string,
        source: PropTypes.string,
        consultationName: PropTypes.string,
        position: PropTypes.number,
    }),
    recommendations: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    errors: PropTypes.shape({
        ageRange: PropTypes.string,
        diabetesDuration: PropTypes.string,
        initialCheckUp: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        simplifiedDescription: PropTypes.string,
        goal: PropTypes.string,
        recurrence: PropTypes.string,
        type: PropTypes.string,
        recipient: PropTypes.string,
        grade: PropTypes.string,
        source: PropTypes.string,
        consultationName: PropTypes.string,
        position: PropTypes.string,
    }),
    onSubmit: PropTypes.func.isRequired,
    treatments: PropTypes.arrayOf(
        PropTypes.shape({
            "@id": PropTypes.string.isRequired,
        })
    ),
    complications: PropTypes.arrayOf(
        PropTypes.shape({
            "@id": PropTypes.string.isRequired,
        })
    ),
};

export default RecommendationForm;
