import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import { InputText, InputRadio, InputCheckbox, Button, Select, Icon, CheckboxGroup } from "@rdcs/dap-front-library";

import { constant, getLastSixMonths, ucFirst } from "../../utils/utils";

const emptyFilterValue = {
    search: "",
    sex: "all",
    pathology: [],
    ageRange: "all",
    treatment: "all",
    recurrence: "all",
    type: "all",
    recipient: "all",
    grade: "all",
    updatedAt: "all",
    active: false
};

const defaultState = {
    filter: { ...emptyFilterValue },
    isOpenFilter: false,
}

const reducer = (prevState, action) => {
    switch (action.type) {
        case 'RESET_FILTER':
            return defaultState;
        case 'TOGGLE_FILTER':
            return {
                ...prevState,
                isOpenFilter: !prevState.isOpenFilter
            }
        case 'SET_SEARCH':
            return {
                ...prevState,
                filter: {
                    ...prevState.filter,
                    search: action.search,
                },
            }
        case 'SET_SEX':
        return {
            ...prevState,
            filter: {
                ...prevState.filter,
                sex: action.sex,
            },
        }
        case 'SET_PATHOLOGY':
        return {
            ...prevState,
            filter: {
                ...prevState.filter,
                pathology: action.pathology,
            },
        }
        case 'SET_AGE_RANGE':
        return {
            ...prevState,
            filter: {
                ...prevState.filter,
                ageRange: action.ageRange,
            },
        }
        case 'SET_TREATMENT':
        return {
            ...prevState,
            filter: {
                ...prevState.filter,
                treatment: action.treatment,
            },
        }
        case 'SET_RECURRENCE':
        return {
            ...prevState,
            filter: {
                ...prevState.filter,
                recurrence: action.recurrence,
            },
        }
        case 'SET_TYPE':
        return {
            ...prevState,
            filter: {
                ...prevState.filter,
                filterType: action.filterType,
            },
        }
        case 'SET_RECIPIENT':
        return {
            ...prevState,
            filter: {
                ...prevState.filter,
                recipient: action.recipient,
            },
        }
        case 'SET_GRADE':
        return {
            ...prevState,
            filter: {
                ...prevState.filter,
                grade: action.grade,
            },
        }
        case 'SET_UPDATED_AT':
        return {
            ...prevState,
            filter: {
                ...prevState.filter,
                updatedAt: action.updatedAt,
            },
        }
        case 'SET_ACTIVE':
        return {
            ...prevState,
            filter: {
                ...prevState.filter,
                active: action.active,
            },
        }
        default:
            return prevState;
    }
}

const RecommendationsFilter = ({ filterRecommendations, recosLoading, downloadCSV, treatments }) => {
    const sexOptions = React.useMemo(() => constant("recommendation", "sexes").map(sex => ({
        label: constant("recommendation", "sexes", sex),
        value: sex
    })), []);

    const pathologyOptions = React.useMemo(() => constant("pathologies").map(pathology => ({
        label: constant("pathologies", pathology),
        value: pathology
    })), []);

    const ageRangeOptions = React.useMemo(() => constant("recommendation", "ageRanges").map(ageRange => ({
        label: constant("recommendation", "ageRanges", ageRange),
        value: ageRange
    })), []);

    const treatmentOptions = React.useMemo(() => ([
        { label: "Tout traitement", value: "all" },
        ...treatments.map(treatment => ({
            label: treatment.name,
            value: treatment["@id"]
        }))
    ]), [treatments]);

    const recurrenceOptions = React.useMemo(() => ([
        { label: "Toute récurrence", value: "all" },
        ...constant("recommendation", "recurrences").map(recurrence => ({
            label: constant("recommendation", "recurrences", recurrence),
            value: recurrence
        }))
    ]), []);

    const typeOptions = React.useMemo(() => ([
        { label: "Toute recommandation", value: "all" },
        ...constant("recommendation", "types").map(type => ({
            label: constant("recommendation", "types", type),
            value: type
        }))
    ]), []);

    const recipientOptions = React.useMemo(() => ([
        {
            label: "Tout destinataire",
            value: "all"
        },
        ...constant("recommendation", "recipients").map(recipient => ({
            label: constant("recommendation", "recipients", recipient),
            value: recipient
        }))
    ]), []);

    const gradeOptions = React.useMemo(() => ([
        { label: "Tout grade", value: "all" },
        ...constant("recommendation", "grades").map(grade => ({
            label: constant("recommendation", "grades", grade),
            value: grade
        }))
    ]), []);

    const updatedAtOptions = React.useMemo(() => ([
        { label: "Toute date", value: "all" },
        ...getLastSixMonths().map(month => ({
            label: ucFirst(month.format("MMMM YYYY")),
            value: month.format("MM-YYYY")
        }))
    ]), []);

    const [{ filter, isOpenFilter }, dispatch] = React.useReducer(reducer, defaultState);

    const applyFilter = (e) => {
        if (e) {
            e.preventDefault();
        }
        filterRecommendations(filter);
    }

    const resetFilter = () => {
        dispatch({ type: 'RESET_FILTER' });
        filterRecommendations(emptyFilterValue);
    };

    const toggleFilter = () => dispatch({ type: 'TOGGLE_FILTER' });

    const setSearch = (search) => dispatch({ type: 'SET_SEARCH', search });
    const setSex = (sex) => dispatch({ type: 'SET_SEX', sex });
    const setPathology = (pathology) => dispatch({ type: 'SET_PATHOLOGY', pathology });
    const setAgeRange = (ageRange) => dispatch({ type: 'SET_AGE_RANGE', ageRange });
    const setTreatment = (treatment) => dispatch({ type: 'SET_TREATMENT', treatment });
    const setRecurrence = (recurrence) => dispatch({ type: 'SET_RECURRENCE', recurrence });
    const setType = (filterType) => dispatch({ type: 'SET_TYPE', filterType });
    const setRecipient = (recipient) => dispatch({ type: 'SET_RECIPIENT', recipient });
    const setGrade = (grade) => dispatch({ type: 'SET_GRADE', grade });
    const setUpdatedAt = (updatedAt) => dispatch({ type: 'SET_UPDATED_AT', updatedAt });
    const setActive = (active) => dispatch({ type: 'SET_ACTIVE', active });

    return (
        <div className="grid mb-3">
            <form onSubmit={applyFilter} className="col-100">
                <div className="col-md-50">
                    <InputText
                        placeholder="Rechercher par nom..."
                        name="recommandations-search"
                        onChange={setSearch}
                        required
                    />
                </div>
                <Button type="submit" className="button__secondaryButton mt-2 ml-2">
                    <Icon name="search" /> Rechercher
                </Button>
            </form>
            <div className="col-100 form__group text-center mb-3">
                <Button type="button" onClick={toggleFilter} className="button__secondaryButton">
                    Filtre d'affichage
                    {isOpenFilter && (
                        <span className="ml-2">
                            <Icon name="cross-circle" />
                        </span>
                    )}
                </Button>

                {!recosLoading && (
                    <Button className="button__secondaryButton" onClick={downloadCSV}>
                        Exporter en CSV
                    </Button>
                )}

                <Link to="/recommandations/ajouter">
                    <Button className="button__primaryButton">Ajouter une recommandation</Button>
                </Link>
            </div>
            {isOpenFilter && (
                <form onSubmit={applyFilter} className="grid grid__padding-25">
                    <div className="col-md-50">
                        <InputRadio
                            label="Sexe"
                            options={sexOptions}
                            onChange={setSex}
                            defaultValue={filter.sex}
                        />
                    </div>
                    <div className="col-md-50">
                        <CheckboxGroup
                            label="Pathologie"
                            options={pathologyOptions}
                            onChange={setPathology}
                            defaultChecked={filter.pathology}
                        />
                    </div>
                    <div className="col-md-50">
                        <Select
                            label="Âge"
                            options={ageRangeOptions}
                            onChange={setAgeRange}
                            defaultValue={""}
                        />
                    </div>
                    <div className="col-md-50">
                        <Select
                            label="Traitement"
                            options={treatmentOptions}
                            onChange={setTreatment}
                            defaultValue={filter.treatment}
                        />
                    </div>
                    <div className="col-md-50">
                        <Select
                            label="Récurrence"
                            options={recurrenceOptions}
                            onChange={setRecurrence}
                            defaultValue={filter.recurrence}
                        />
                    </div>
                    <div className="col-md-50">
                        <Select
                            label="Type de recommandation"
                            options={typeOptions}
                            onChange={setType}
                            defaultValue={filter.type}
                        />
                    </div>
                    <div className="col-md-50">
                        <Select
                            label="Destinataire"
                            options={recipientOptions}
                            onChange={setRecipient}
                            defaultValue={filter.recipient}
                        />
                    </div>
                    <div className="col-md-50">
                        <Select
                            label="Grade"
                            options={gradeOptions}
                            onChange={setGrade}
                            defaultValue={filter.grade}
                        />
                    </div>
                    <div className="col-md-50">
                        <Select
                            label="Date de mise à jour"
                            options={updatedAtOptions}
                            onChange={setUpdatedAt}
                            defaultValue={filter.updatedAt}
                        />
                    </div>
                    <div className="col-100 mt-2">
                        <InputCheckbox
                            label="Active uniquement"
                            onChange={setActive}
                            defaultChecked={filter.active}
                        />
                    </div>
                    <div className="text-center col-100">
                        <Button type="button" onClick={resetFilter} className="button__secondaryButton">
                            Réinitialiser
                        </Button>
                        <Button type="submit" className="button__primaryButton">
                            Filtrer les résultats
                        </Button>
                    </div>
                </form>
            )}
        </div>
    );
}

RecommendationsFilter.propTypes = {
    filterRecommendations: PropTypes.func.isRequired,
    recosLoading: PropTypes.bool.isRequired,
    downloadCSV: PropTypes.func.isRequired,
    treatments: PropTypes.arrayOf(PropTypes.shape({
        "@id": PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
};

export default RecommendationsFilter;
