import ContentDropdown from './contentDropdown'
import { connect } from "react-redux";
import { InputDateTime } from '@rdcs/dap-front-library';
import React from 'react';
import moment from 'moment';

const PatientReportForm = ({
    singlePatient,
    report,
    canModify = true,
    edition,
    prevObjective,
}) => (
    <div className='d-flex flex-column justify-space-between' style={{ minHeight: "100vh" }}>
        <div>
            <h2 className='mx-4 my-2 timkl__color--violet-dark txtBold txtSizeMedium'>{report ? `Compte rendu d’entretien ${moment(report.date).format('DD/MM/YYYY')}` : "Ajouter un compte rendu d’entretien"}</h2>
            <div className='timkl__backgroundColor--violet-light pxy-4'>
                <p> Patient : {singlePatient?.firstName} {singlePatient?.lastName}</p>
                <p className='d-flex align-items-center'> Date :
                    <InputDateTime
                        className="inputDateReport"
                        defaultValue={new Date(report ? report.date : new Date())}
                        name='date'
                        placeholder={report ? report.date : "jj/mm/aaaa"}
                        required
                        disabled
                    />
                </p>
            </div>
            <ContentDropdown prevObjective={prevObjective} actualReport={report} canModify={canModify} edition={edition} />
        </div>
    </div>
);

const mapStateToProps = ({ patients: { singlePatient } }) => ({ singlePatient });

export default connect(mapStateToProps)(PatientReportForm);
