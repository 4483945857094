import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { ModerationListItemCard } from "@rdcs/dap-front-library";

import SettingsTabLayout from "../../../components/Settings/SettingsTabLayout";

const PartnersListTab = ({ loading, partners, onFetch, delete: onDelete, auth }) => (
    <SettingsTabLayout
        title="Liste des partenaires"
        icon="pro"
        addButton={{
            link: "/configuration/ajouter-partenaire",
            label: "Ajouter un partenaire"
        }}
        isLoading={loading}
    >
        {partners && partners
            .sort((a, b) => (a.order < b.order ? -1 : 1))
            .map((partner) => (
                <ModerationListItemCard
                    key={partner["@id"]}
                    label={partner.name}
                    modifyLink={`/configuration/modifier-partenaire/${partner.id}`}
                    onDelete={() => onDelete(auth, partner.id).then(() => onFetch(auth))}
                    deleteText="Supprimer le partenaire"
                />
            ))}
    </SettingsTabLayout>
);

PartnersListTab.propTypes = {
    auth: PropTypes.object.isRequired,
    partners: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
        })
    ).isRequired,
    loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps)(PartnersListTab);
