import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { tryEditCoordinator, tryFetchCoordinators, tryFetchDepartments } from "../../../actions/coordinators";

import CoordinatorForm from "../../../components/Settings/Forms/CoordinatorForm";
import SettingsEditFormScreen from "../../../components/Settings/SettingsEditFormScreen";

const EditCoordinatorScreen = ({
    coordinators: { list: coordinators, listLoading, departments, departmentsLoading, editLoading, editError },
    tryEditCoordinator,
    tryFetchCoordinators,
    tryFetchDepartments
}) => (
    <SettingsEditFormScreen
        /* Document and page titles */
        documentTitle="Modifier facilitateur"
        pageTitle="Modifier un facilitateur"
        /* Redux request callback use to add on BDD */
        formRequest={tryEditCoordinator}
        fetchRequests={[tryFetchCoordinators, tryFetchDepartments]}
        testFetchRequestsNeeded={[coordinators.length === 0, departments.length === 0]}
        /* Forms */
        itemForm={CoordinatorForm}
        itemFormRequestLoading={editLoading}
        itemFormRequestError={editError}
        itemFormExtraProps={{ coordinators, departments }}
        itemFormIsLoading={listLoading || departmentsLoading}
        itemFormEditedItemPropName="editedCoordinator"
        /* Items lists to retrieve the current item */
        items={coordinators}
        /* Configuration tab name for redirection */
        tab="coordinator"
    />
);

EditCoordinatorScreen.propTypes = {
    coordinators: PropTypes.shape({
        list: PropTypes.arrayOf(PropTypes.object).isRequired,
        listLoading: PropTypes.bool.isRequired,
        editLoading: PropTypes.bool.isRequired,
        editError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object
            }),
            PropTypes.bool
        ]).isRequired
    }).isRequired,
    tryEditCoordinator: PropTypes.func.isRequired,
    tryFetchCoordinators: PropTypes.func.isRequired,
    tryFetchDepartments: PropTypes.func.isRequired
};

const mapStateToProps = ({ coordinators }) => ({ coordinators });

const mapDispatchToProps = { tryEditCoordinator, tryFetchCoordinators, tryFetchDepartments };

export default connect(mapStateToProps, mapDispatchToProps)(EditCoordinatorScreen);
