import * as types from "./types";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";
import { daySec } from '../utils/constant';
import { hasIntersection, parseMissingSegments, mergeSegments } from '../utils/segment';

import store from '../store';

import moment from 'moment';

export const tryFetchStatistics = auth => dispatch => {
    dispatch({ type: types.STATISTICS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/statistics" : "/dap-admin/api/v1/statistics",
        token: auth.accessToken
    })
    .then(response => dispatch({ type: types.STATISTICS_FETCH_SUCCESS, data: response }))
    .catch(error => dispatch({ type: types.STATISTICS_FETCH_FAILURE, data: error }));
};

const dateFormat = 'YYYY-MM-DD';
const trackingBillingRequest = (accessToken, minDateSec, maxDateSec) => (
    API.request({
        path: isLocalAPI ? "/billable_actions" : "/dap-admin/api/v1/billable-actions",
        token: accessToken,
        queryParams: {
            "createdAt[after]": moment.unix(minDateSec).format(dateFormat),
            "createdAt[strictly_before]": moment.unix(maxDateSec).add(1, 'days').endOf('day').format(dateFormat)
        },
    })
);

export const tryFetchTrackingBilling = (minDate, maxDate) => dispatch => {
    const {
        auth: {
            accessToken
        },
        statistics: {
            trackingBillingListChunked,
        }
    } = store.getState();
    const minDateMoment = moment(minDate).startOf('day');
    const maxDateMoment = moment(maxDate).startOf('day');
    const minDateSec = minDateMoment.unix();
    const maxDateSec = maxDateMoment.unix();
    const promises = [];

    if (isNaN(minDateSec) || isNaN(maxDateSec)) {
        return Promise.reject("Min date and max date must be valid date !");
    }
    if (maxDateSec < minDateSec) {
        return Promise.reject("Max date must be greater or equal to min date !");
    }

    // The cache works on the basis of a date range defined as a segment.
    // We define the searched segment as being the current segment.
    const currentSegment = [minDateSec, maxDateSec];

    // Look at all segment segments stored on the cache to define any missing segments.
    const missingSegments = parseMissingSegments(
        currentSegment,
        trackingBillingListChunked
        .map(({ begin, end }) => [begin, end])
        .filter(segment => hasIntersection(currentSegment, segment, daySec)),
        daySec
    );

    // We send small requests to retrieve the missing data according to the missing segment
    // to generate the current segments with the least distant data requested.
    for (const [begin, end] of missingSegments) {
        promises.push(trackingBillingRequest(accessToken, begin, end));
    }

    dispatch({ type: types.TRACKING_BILLING_FETCH_REQUESTED });
    return Promise.all(promises)
    .then((responses) => {
        let actions = [];

        for (const response of responses) {
            actions = [...actions, ...response['hydra:member']]
        }

        const [list, currentChunk] = mergeSegments(trackingBillingListChunked, actions, minDateSec, maxDateSec, daySec);
        const currentSegmentItems =  currentChunk.filter(({ createdAt }) => {
            const createdAtSec = moment(createdAt).startOf('day').unix();

            return minDateSec <= createdAtSec  && createdAtSec <= maxDateSec;
        });

        dispatch({
            type: types.TRACKING_BILLING_FETCH_SUCCESS,
            chunk: list,
            data: currentSegmentItems,
        });
        return currentSegmentItems;
    })
    .catch(error => {
        dispatch({ type: types.TRACKING_BILLING_FETCH_FAILURE, data: error });
        throw error;
    });
};
