import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { ModerationListItemCard } from "@rdcs/dap-front-library";

import SettingsTabLayout from "../../../components/Settings/SettingsTabLayout";

const PointsOfAttentionTab  = ({ loading, pointsOfAttention, delete: onDelete, auth }) => (
    <SettingsTabLayout
        title="Liste des points de vigilance"
        icon="ic-health"
        addButton={{
            link: "/configuration/ajouter-point-de-vigilance",
            label: "Ajouter un point de vigilance"
        }}
        isLoading={loading}
    >
        {pointsOfAttention && pointsOfAttention
            // .sort((a, b) => (a.order < b.order ? -1 : 1))
            .map((pointOfAttention) => (
                <ModerationListItemCard
                    key={pointOfAttention["@id"]}
                    label={pointOfAttention.name}
                    modifyLink={`/configuration/modifier-point-de-vigilance/${pointOfAttention.id}`}
                    onDelete={() => onDelete(pointOfAttention.id)}
                    deleteText="Supprimer le point de vigilance"
                />
            ))}
    </SettingsTabLayout>
);

PointsOfAttentionTab.propTypes = {
    auth: PropTypes.object.isRequired,
    pointsOfAttention: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        }).isRequired
    ).isRequired,
    loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps)(PointsOfAttentionTab);
