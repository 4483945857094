import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { ModerationListItemSwitch } from "@rdcs/dap-front-library";

import SettingsTabLayout from "../../../components/Settings/SettingsTabLayout";

const guessTreatmentName = ({ levelOne, levelTwo, levelThree }) => {
    let name = levelOne.name;

    if (levelTwo) name += " / " + levelTwo.name;
    if (levelThree) name += " / " + levelThree.name;
    return name;
};

const TreatmentTab = ({ auth, treatments, loading, activate, swapOrder, isSwapingOrder }) => (
    <SettingsTabLayout
        title="Groupes de traitement"
        icon="datas"
        addButton={{
            link: "/configuration/ajouter-traitement",
            label: "Ajouter un groupe de traitement"
        }}
        isLoading={loading}
    >
        {treatments
            .sort((a, b) => (a.order < b.order ? -1 : 1))
            .map((treatment, index, orderedTreatments) => (
                <ModerationListItemSwitch
                    key={index}
                    label={guessTreatmentName(treatment)}
                    onChange={value =>
                        activate(auth, treatment, value)
                            .then(() => value)
                            .catch(() => !value)
                    }
                    defaultChecked={treatment.active}
                    modifyLink={`/configuration/modifier-traitement/${treatment.id}`}
                    swapOrder={{
                        current: treatment,
                        onSwap: (item1, item2) => swapOrder(auth, item1, item2),
                        prev: orderedTreatments[index - 1],
                        next: orderedTreatments[index + 1],
                        isSwapingOrder: isSwapingOrder
                    }}
                    confirmModal={{
                        title: "Merci de confirmer",
                        body:
                            "Ce traitement peut être utilisé par des recommandations ou consultations, voulez-vous la désactiver ?"
                    }}
                />
            ))}
    </SettingsTabLayout>
);

TreatmentTab.propTypes = {
    auth: PropTypes.object.isRequired,
    treatments: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            icon: PropTypes.shape({
                filename: PropTypes.string.isRequired
            }),
            active: PropTypes.bool.isRequired,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
        })
    ).isRequired,
    loading: PropTypes.bool.isRequired,
    activate: PropTypes.func.isRequired,
    swapOrder: PropTypes.func.isRequired,
    isSwapingOrder: PropTypes.bool.isRequired
};

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps)(TreatmentTab);
