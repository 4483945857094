import * as types from "./types";
import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";
import store from "../store";

export const tryFetchSinglePatientNotes = (auth, patientId) => dispatch => {
    const {
        auth: {
            accessToken,
        },
    } = store.getState();
    dispatch({ type: types.NOTES_SINGLE_PATIENT_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/notes" : "/dap-admin/api/v1/customers/patients/notes",
        queryParams: {
            patient: patientId,
            pagination: false
        },
        token: accessToken
    })
        .then(response => dispatch({ type: types.NOTES_SINGLE_PATIENT_FETCH_SUCCESS, data: response["hydra:member"] }))
        .catch(error => dispatch({ type: types.NOTES_SINGLE_PATIENT_FETCH_FAILURE, data: error }));
};

export const tryFetchNotes = auth => dispatch => {
    const {
        auth: {
            accessToken,
        },
    } = store.getState();
    dispatch({ type: types.NOTES_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/notes" : "/dap-admin/api/v1/customers/patients/notes",
        queryParams: {
            pagination: false
        },
        token: accessToken
    })
        .then(response => dispatch({ type: types.NOTES_FETCH_SUCCESS, data: response["hydra:member"] }))
        .catch(error => dispatch({ type: types.NOTES_FETCH_FAILURE, data: error }));
};

export const tryCreateNote = (auth, data) => dispatch => {
    const {
        auth: {
            accessToken,
        },
    } = store.getState();
    dispatch({ type: types.NOTES_CREATE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/notes" : "/dap-admin/api/v1/customers/patients/notes",
        method: "POST",
        token: accessToken,
        data: data
    })
        .then(response => dispatch({ type: types.NOTES_CREATE_SUCCESS, data: response }))
        .catch(error => dispatch({ type: types.NOTES_CREATE_FAILURE, data: error }));
};

export const tryEditNote = (noteId, form) => dispatch => {
    const {
        auth: {
            accessToken,
        },
    } = store.getState();
    dispatch({ type: types.EDIT_NOTE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/notes/{id}" : "/dap-admin/api/v1/customers/patients/notes/{id}",
        uriParams: {
            id: noteId
        },
        token: accessToken,
        method: "PUT",
        data: form
    })
        .then(response => dispatch({ type: types.EDIT_NOTE_SUCCESS, data: response }))
        .catch(error => dispatch({ type: types.EDIT_NOTE_FAILURE, data: error }))
};

export const tryRemoveNote = (noteId) => dispatch => {
    const {
        auth: {
            accessToken,
        },
    } = store.getState();
    dispatch({ type: types.NOTE_REMOVE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/notes/{id}" : "/dap-admin/api/v1/customers/patients/notes/{id}",
        uriParams: {
            id: noteId
        },
        token: accessToken,
        method: "PUT",
        data: { active: false }
    })
        .then(response => dispatch({ type: types.NOTE_REMOVE_SUCCESS, data: response['@id'] }))
        .catch(error => dispatch({ type: types.NOTE_REMOVE_FAILURE, data: error }));
};
