import React from "react";
import { Route, Router, Switch, Redirect } from "react-router-dom";

import history from "./config/history";
import PrivateRoute from "./components/Utils/PrivateRoute";
import { ToastContainer } from "react-toastify";

import LoginScreen from "./screens/Login/LoginScreen";
import RequestPasswordScreen from "./screens/Login/RequestPasswordScreen";
import LogoutScreen from "./screens/Login/LogoutScreen";
import ScrollToTop from "./components/Utils/ScrollToTop";
import ResetPasswordScreen from "./screens/Email/ResetPasswordScreen";
import NotFoundExceptionScreen from "./screens/Exceptions/NotFoundException";
import DashboardScreen from "./screens/Dashboard/DashboardScreen";
import RecommendationsScreen from "./screens/Recommendations/RecommendationsScreen";
import RecommendationsAddScreen from "./screens/Recommendations/RecommendationsAddScreen";
import RecommendationsEditScreen from "./screens/Recommendations/RecommendationsEditScreen";
import TrackingBillingScreen from "./screens/TrackingBilling/TrackingBillingScreen";

import SettingsScreen from "./screens/Settings/SettingsScreen";
import {
    AddAdminScreen,
    AddBreakScoreQuestionScreen,
    AddBreakScoreNoInsulinQuestionScreen,
    AddComplicationScreen,
    AddCoordinatorScreen,
    AddModeratorScreen,
    AddPartnerScreen,
    AddPointOfAttentionScreen,
    AddStudyQuestionScreen,
    AddTaskLabelScreen,
    AddTreatmentGroupScreen,
    AddDiagnosticQuestionScreen,
    AddThematicsScreen,
} from "./screens/Settings/AddScreens";
import {
    EditAdminScreen,
    EditBreakScoreQuestionScreen,
    EditBreakScoreNoInsulinQuestionScreen,
    EditComplicationScreen,
    EditCoordinatorScreen,
    EditModeratorScreen,
    EditPartnerScreen,
    EditPointOfAttentionScreen,
    EditStudyQuestionScreen,
    EditTaskLabelScreen,
    EditTreatmentGroupScreen,
    EditDiagnosticQuestionScreen,
} from "./screens/Settings/EditScreens";

import ProfileScreen from "./screens/Profile/ProfileScreen";
import MedicalsScreen from "./screens/Moderation/MedicalsScreen";
import CoordinatorScreen from "./screens/Coordinator/CoordinatorScreen";
import CoordinatorPatientBreakScoreScreen from "./screens/Coordinator/CoordinatorPatientBreakScoreScreen";
import CoordinatorPatientInformationsScreen from "./screens/Coordinator/CoordinatorPatientInformationsScreen";
import CoordinatorPatientDashboardScreen from "./screens/Coordinator/CoordinatorPatientDashboardScreen";
import CoordinatorPatientLastDiagnosticScreen from "./screens/Coordinator/CoordinatorPatientLastDiagnosticScreen";
import CoordinatorPatientCoordonneesScreen from "./screens/Coordinator/CoordinatorPatientCoordonneesScreen";
import CoordinatorPatientNotesScreen from "./screens/Coordinator/CoordinatorPatientNotesScreen";
import CoordinatorPatientReportsScreen from "./screens/Coordinator/CoordinatorPatientReportsScreen";
import CoordinatorPatientConsultsScreen from "./screens/Coordinator/CoordinatorPatientConsultsScreen";
import CoordinatorPatientExamsScreen from "./screens/Coordinator/CoordinatorPatientExamsScreen";
import CoordinatorPatientProSanteScreen from "./screens/Coordinator/CoordinatorPatientProSanteScreen";
import CoordinatorPatientDataScreen from "./screens/Coordinator/CoordinatorPatientDataScreen";
import PotentialPatientsScreen from "./screens/PotentialPatients/PotentialPatients";
import CoordinatorPatientTasksScreen from "./screens/Coordinator/CoordinatorPatientTasksScreen";
import PatientsAddScreen from "./screens/Patients/PatientsAddScreen";
import BreakScoreQuestionnaireScreen from "./screens/BreakScoreQuestionnaireScreen";
import EditThematicScreen from "./screens/Settings/EditScreens/EditThematic";
import CoordinatorPatientsDiagnosticScreen from "./screens/Coordinator/PatientsDiagnosticScreen/index";
import CoordinatorPatientBeckScreen from "./screens/Coordinator/CoordinatorPatientBeckScreen";
import CoordinatorPatientEpicesScreen from "./screens/Coordinator/CoordinatorPatientEpicesScreen";

const App = () => (
    <Router history={history}>
        <ScrollToTop>
            <ToastContainer autoClose={3000} />
            <Switch>
                <Redirect exact from="/" to="/connexion" />
                <Route exact path="/connexion" component={LoginScreen} />
                <Route exact path="/mot-de-passe-oublie" component={RequestPasswordScreen} />
                <Route exact path="/email/new-password" component={ResetPasswordScreen} />
                <Route exact path="/deconnexion" component={LogoutScreen} />

                <PrivateRoute exact path="/dashboard" component={DashboardScreen} allowedRoles={["ROLE_ADMIN"]} />

                <PrivateRoute exact path="/suivi-facturation" component={TrackingBillingScreen} allowedRoles={["ROLE_ADMIN"]} />

                <PrivateRoute exact path="/recommandations" component={RecommendationsScreen} allowedRoles={["ROLE_ADMIN"]} />
                <PrivateRoute exact path="/recommandations/ajouter" component={RecommendationsAddScreen} allowedRoles={["ROLE_ADMIN"]} />
                <PrivateRoute exact path="/recommandations/:id/modifier" component={RecommendationsEditScreen} allowedRoles={["ROLE_ADMIN"]} />
                <PrivateRoute exact path="/configuration" component={SettingsScreen} allowedRoles={["ROLE_ADMIN"]} />
                <PrivateRoute exact path="/configuration/ajouter-traitement" component={AddTreatmentGroupScreen} allowedRoles={["ROLE_ADMIN"]} />
                <PrivateRoute exact path="/configuration/modifier-traitement/:id" component={EditTreatmentGroupScreen} allowedRoles={["ROLE_ADMIN"]} />

                <PrivateRoute exact path="/configuration/ajouter-action" component={AddTaskLabelScreen} allowedRoles={["ROLE_ADMIN"]} />
                <PrivateRoute exact path="/configuration/modifier-action/:id" component={EditTaskLabelScreen} allowedRoles={["ROLE_ADMIN"]} />

                <PrivateRoute exact path="/configuration/ajouter-administrateur" component={AddAdminScreen} allowedRoles={["ROLE_ADMIN"]} />
                <PrivateRoute exact path="/configuration/modifier-administrateur/:id" component={EditAdminScreen} allowedRoles={["ROLE_ADMIN"]} />

                <PrivateRoute exact path="/configuration/ajouter-moderateur" component={AddModeratorScreen} allowedRoles={["ROLE_ADMIN"]} />
                <PrivateRoute exact path="/configuration/modifier-moderateur/:id" component={EditModeratorScreen} allowedRoles={["ROLE_ADMIN"]} />
                <PrivateRoute exact path="/configuration/ajouter-partenaire" component={AddPartnerScreen} allowedRoles={["ROLE_ADMIN"]} />
                <PrivateRoute exact path="/configuration/modifier-partenaire/:id" component={EditPartnerScreen} allowedRoles={["ROLE_ADMIN"]} />
                <PrivateRoute exact path="/configuration/ajouter-point-de-vigilance" component={AddPointOfAttentionScreen} allowedRoles={["ROLE_ADMIN"]} />
                <PrivateRoute exact path="/configuration/ajouter-thematique" component={AddThematicsScreen} allowedRoles={["ROLE_ADMIN"]} />
                <PrivateRoute
                    exact
                    path="/configuration/modifier-point-de-vigilance/:id"
                    component={EditPointOfAttentionScreen}
                    allowedRoles={["ROLE_ADMIN"]}
                />
                <PrivateRoute exact path="/configuration/modifier-thematique/:id" component={EditThematicScreen} allowedRoles={["ROLE_ADMIN"]} />
                <PrivateRoute exact path="/configuration/ajouter-facilitateur" component={AddCoordinatorScreen} allowedRoles={["ROLE_ADMIN"]} />
                <PrivateRoute exact path="/configuration/modifier-facilitateur/:id" component={EditCoordinatorScreen} allowedRoles={["ROLE_ADMIN"]} />

                <PrivateRoute exact path="/configuration/ajouter-complication" component={AddComplicationScreen} allowedRoles={["ROLE_ADMIN"]} />
                <PrivateRoute exact path="/configuration/modifier-complication/:id" component={EditComplicationScreen} allowedRoles={["ROLE_ADMIN"]} />

                <PrivateRoute exact path="/configuration/ajouter-question-etude" component={AddStudyQuestionScreen} allowedRoles={["ROLE_ADMIN"]} />
                <PrivateRoute exact path="/configuration/modifier-question-etude/:id" component={EditStudyQuestionScreen} allowedRoles={["ROLE_ADMIN"]} />

                <PrivateRoute exact path="/configuration/ajouter-question-rupture" component={AddBreakScoreQuestionScreen} allowedRoles={["ROLE_ADMIN"]} />
                <PrivateRoute
                    exact
                    path="/configuration/modifier-question-rupture/:id"
                    component={EditBreakScoreQuestionScreen}
                    allowedRoles={["ROLE_ADMIN"]}
                />

                <PrivateRoute
                    exact
                    path="/configuration/ajouter-question-rupture-non-insulinotraite"
                    component={AddBreakScoreNoInsulinQuestionScreen}
                    allowedRoles={["ROLE_ADMIN"]}
                />
                <PrivateRoute
                    exact
                    path="/configuration/modifier-question-rupture-non-insulinotraite/:id"
                    component={EditBreakScoreNoInsulinQuestionScreen}
                    allowedRoles={["ROLE_ADMIN"]}
                />

                <PrivateRoute exact path="/configuration/ajouter-question-information" component={AddDiagnosticQuestionScreen} allowedRoles={["ROLE_ADMIN"]} />
                <PrivateRoute
                    exact
                    path="/configuration/modifier-question-information/:id"
                    component={EditDiagnosticQuestionScreen}
                    allowedRoles={["ROLE_ADMIN"]}
                />

                <PrivateRoute exact path="/moderation" component={MedicalsScreen} allowedRoles={["ROLE_MODERATOR"]} />

                <PrivateRoute exact path="/facilitateurs" component={CoordinatorScreen} allowedRoles={["ROLE_COORDINATOR"]} />
                <PrivateRoute
                    exact
                    path="/facilitateurs/patient/:id"
                    component={(props) => <Redirect to={props.location.pathname + "/dashboard"} />}
                    allowedRoles={["ROLE_COORDINATOR"]}
                />
                <PrivateRoute
                    exact
                    path="/facilitateurs/patient/:id/dashboard"
                    component={CoordinatorPatientDashboardScreen}
                    allowedRoles={["ROLE_COORDINATOR"]}
                />
                <PrivateRoute exact path="/facilitateurs/patient/:id/donnees" component={CoordinatorPatientDataScreen} allowedRoles={["ROLE_COORDINATOR"]} />
                <PrivateRoute exact path="/facilitateurs/patient/:id/examens" component={CoordinatorPatientExamsScreen} allowedRoles={["ROLE_COORDINATOR"]} />
                <PrivateRoute
                    exact
                    path="/facilitateurs/patient/:id/visites"
                    component={CoordinatorPatientConsultsScreen}
                    allowedRoles={["ROLE_COORDINATOR"]}
                />
                <PrivateRoute
                    exact
                    path="/facilitateurs/patient/:id/informations"
                    component={CoordinatorPatientInformationsScreen}
                    allowedRoles={["ROLE_COORDINATOR"]}
                />
                <PrivateRoute
                    exact
                    path="/facilitateurs/patient/:id/derniere-consultation"
                    component={CoordinatorPatientLastDiagnosticScreen}
                    allowedRoles={["ROLE_COORDINATOR"]}
                />
                <PrivateRoute
                    exact
                    path="/facilitateurs/patient/:id/coordonnees"
                    component={CoordinatorPatientCoordonneesScreen}
                    allowedRoles={["ROLE_COORDINATOR"]}
                />
                <PrivateRoute exact path="/facilitateurs/patient/:id/notes" component={CoordinatorPatientNotesScreen} allowedRoles={["ROLE_COORDINATOR"]} />
                <PrivateRoute
                    exact
                    path="/facilitateurs/patient/:id/comptes-rendus"
                    component={CoordinatorPatientReportsScreen}
                    allowedRoles={["ROLE_COORDINATOR"]}
                />
                <PrivateRoute
                    exact
                    path="/facilitateurs/patient/:id/medecins"
                    component={CoordinatorPatientProSanteScreen}
                    allowedRoles={["ROLE_COORDINATOR"]}
                />
                <PrivateRoute exact path="/facilitateurs/patient/:id/actions" component={CoordinatorPatientTasksScreen} allowedRoles={["ROLE_COORDINATOR"]} />
                <PrivateRoute
                    exact
                    path="/facilitateurs/patient/:id/break-score"
                    component={CoordinatorPatientBreakScoreScreen}
                    allowedRoles={["ROLE_COORDINATOR"]}
                />

                <PrivateRoute
                    exact
                    path="/facilitateurs/patient/:id/nouvelle-consultation"
                    component={CoordinatorPatientsDiagnosticScreen}
                    allowedRoles={["ROLE_COORDINATOR"]}
                />
                <PrivateRoute exact path="/patients-potentiels" component={PotentialPatientsScreen} allowedRoles={["ROLE_COORDINATOR"]} />
                <PrivateRoute exact path="/patients-potentiels/:id/nouveau-patient" component={PatientsAddScreen} allowedRoles={["ROLE_COORDINATOR"]} />

                <PrivateRoute exact path="/mon-profil" component={ProfileScreen} allowedRoles={["ROLE_ADMIN", "ROLE_MODERATOR", "ROLE_COORDINATOR"]} />

                <PrivateRoute
                    exact
                    path="/questionnaire-rupture"
                    component={BreakScoreQuestionnaireScreen}
                    allowedRoles={["ROLE_ADMIN", "ROLE_MODERATOR", "ROLE_COORDINATOR"]}
                />
                <PrivateRoute
                    exact
                    path="/facilitateurs/patient/:id/questionnaire-beck"
                    component={CoordinatorPatientBeckScreen}
                    allowedRoles={["ROLE_COORDINATOR"]}
                />
                <PrivateRoute
                    exact
                    path="/facilitateurs/patient/:id/questionnaire-epices"
                    component={CoordinatorPatientEpicesScreen}
                    allowedRoles={["ROLE_COORDINATOR"]}
                />

                <Route component={NotFoundExceptionScreen} />
            </Switch>
        </ScrollToTop>
    </Router>
);

export default App;
