import React from "react";
import PropTypes from "prop-types";

import { StepButtons, Form, DynamicQuestions } from "@rdcs/dap-front-library";

const Step2 = ({ questions, onSubmit, prevStep, isLoading, answers = undefined }) => {
    return (
        <div className="breakScoreQuestionnaire__container">
            <Form onSubmit={onSubmit}>
                <div className="breakScoreQuestionnaire__fieldset col-xs-100 px-8 py-4 mb-4">
                    <h2 className="title-2 mb-3">Questions</h2>
                    <DynamicQuestions questions={questions} answers={answers} />
                </div>
                <StepButtons
                    nextButtonClassName="timkl__backgroundColor--orange-dark-important"
                    prevButtonClassName="text-uppercase"
                    prevStep={prevStep}
                    isLoading={isLoading}
                    isLast
                />
            </Form>
        </div>
    );
};

Step2.propTypes = {
    questions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            active: PropTypes.bool.isRequired,
            answer1: PropTypes.string.isRequired,
            answer2: PropTypes.string.isRequired,
            answer3: PropTypes.string,
            answer4: PropTypes.string,
            answer5: PropTypes.string,
        })
    ).isRequired,
    onSubmit: PropTypes.func.isRequired,
    prevStep: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    answers: PropTypes.arrayOf(
        PropTypes.shape({
            question: PropTypes.string,
            values: PropTypes.arrayOf(PropTypes.any),
        })
    ),
};

export default Step2;
