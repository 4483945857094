import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { tryAddTreatmentGroup, tryFetchTreatments } from "../../../actions/treatments";

import TreatmentGroupForm from "../../../components/Settings/Forms/TreatmentGroupForm";

import SettingsFormScreen from "../../../components/Settings/SettingsFormScreen";

const AddTreatmentGroupScreen = ({
    treatments: { treatments, treatmentsLoading, createLoading, createError },
    tryAddTreatmentGroup,
    tryFetchTreatments
}) => (
    <SettingsFormScreen
        /* Document and page titles */
        documentTitle="Ajouter traitement"
        pageTitle="Ajouter un groupe de traitement"
        /* Redux request callback use to add on BDD */
        formRequest={tryAddTreatmentGroup}
        fetchRequests={tryFetchTreatments}
        /* Forms */
        itemForm={TreatmentGroupForm}
        itemFormRequestLoading={createLoading}
        itemFormRequestError={createError}
        itemFormExtraProps={{ treatments }}
        itemFormIsLoading={treatmentsLoading}
        testFetchRequestsNeeded={treatments.length === 0}
        /* Configuration tab name for redirection */
        tab="treatment-groups"
    />
);

AddTreatmentGroupScreen.propTypes = {
    treatments: PropTypes.shape({
        treatments: PropTypes.arrayOf(PropTypes.object).isRequired,
        treatmentsLoading: PropTypes.bool.isRequired,
        createLoading: PropTypes.bool.isRequired,
        createError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object
            }),
            PropTypes.bool
        ]).isRequired
    }).isRequired,
    tryAddTreatmentGroup: PropTypes.func.isRequired,
    tryFetchTreatments: PropTypes.func.isRequired
};

const mapStateToProps = ({ treatments }) => ({ treatments });

const mapDispatchToProps = { tryAddTreatmentGroup, tryFetchTreatments };

export default connect(mapStateToProps, mapDispatchToProps)(AddTreatmentGroupScreen);
