import React from "react";
import PropTypes from "prop-types";

import { Button, InputText, Icon } from "@rdcs/dap-front-library";
import { Link } from "react-router-dom";

const TaskLabelForm = ({
    editedTaskLabel: { label: editedTaskLabelLabel = "" } = {},
    errors,
    onSubmit,
    location,
    isLoading
}) => {
    const [label, setLabel] = React.useState(editedTaskLabelLabel);

    const onSubmitCallback = event => {
        event.preventDefault();
        onSubmit({
            label
        });
    };

    return (
        <form onSubmit={onSubmitCallback}>
            <div className="col-100">
                <InputText
                    label="Titre de l'action"
                    error={errors.name}
                    placeholder="Saisissez un titre d'action"
                    defaultValue={label}
                    required
                    onChange={setLabel}
                />
            </div>
            <div className="form__group col-100 text-center">
                <Link
                    to={{
                        pathname: "/configuration",
                        state: { from: location },
                        tab: "actions"
                    }}
                >
                    <Button type="button" className="button__secondaryButton">
                        <Icon name="arrow-left" className="mr-1" /> Retour
                    </Button>
                </Link>
                <Button type="submit" className="button__primaryButton" isLoading={isLoading}>
                    Enregistrer
                </Button>
            </div>
        </form>
    );
};

TaskLabelForm.propTypes = {
    editedTaskLabel: PropTypes.shape({
        label: PropTypes.string.isRequired
    }),
    errors: PropTypes.shape({
        name: PropTypes.string
    }),
    onSubmit: PropTypes.func.isRequired,
    location: PropTypes.any,
    isLoading: PropTypes.bool.isRequired
};

export default TaskLabelForm;
