import * as types from "./types";
import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";
import store from "../store";

export const tryFetchComplications = (auth) => (dispatch) => {
    dispatch({ type: types.COMPLICATIONS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/complications" : "/dap-admin/api/v1/customers/patients/complications",
        queryParams: {
            pagination: false,
            active: true,
        },
        token: auth.accessToken,
    })
        .then((response) => dispatch({ type: types.COMPLICATIONS_FETCH_SUCCESS, data: response["hydra:member"] }))
        .catch((error) => dispatch({ type: types.COMPLICATIONS_FETCH_FAILURE, data: error }));
};

export const tryAddComplication = (complication) => (dispatch) => {
    const { auth } = store.getState();

    dispatch({ type: types.ADD_COMPLICATION_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/complications" : "/dap-admin/api/v1/customers/patients/complications",
        token: auth.accessToken,
        method: "POST",
        data: complication,
    })
        .then((response) => {
            dispatch({ type: types.ADD_COMPLICATION_SUCCESS, data: response });
            return tryFetchComplications(auth);
        })
        .catch((error) => {
            dispatch({ type: types.ADD_COMPLICATION_FAILURE, data: error });
            throw error;
        });
};

export const tryEditComplication = (complicationId, form) => (dispatch) => {
    const { auth } = store.getState();

    dispatch({ type: types.EDIT_COMPLICATION_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/complications/{id}" : "/dap-admin/api/v1/customers/patients/complications/{id}",
        uriParams: {
            id: complicationId,
        },
        token: auth.accessToken,
        method: "PUT",
        data: form,
    })
        .then((response) => {
            dispatch({ type: types.EDIT_COMPLICATION_SUCCESS, data: response });
            return tryFetchComplications(auth);
        })
        .catch((error) => {
            dispatch({ type: types.EDIT_COMPLICATION_FAILURE, data: error });
            throw error;
        });
};

export const tryActivateComplication = (auth, complication, active) => (dispatch) => {
    dispatch({ type: types.ACTIVATE_COMPLICATION_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/complications/{id}" : "/dap-admin/api/v1/customers/patients/complications/{id}",
        uriParams: {
            id: complication.id,
        },
        token: auth.accessToken,
        method: "PUT",
        data: { active: active },
    })
        .then((response) => {
            dispatch({ type: types.ACTIVATE_COMPLICATION_SUCCESS, data: response });
            return tryFetchComplications(auth);
        })
        .catch((error) => {
            dispatch({ type: types.ACTIVATE_COMPLICATION_FAILURE, data: error });
            throw error;
        });
};

export const trySwapComplicationOrder = (auth, complicationOne, complicationTwo) => (dispatch) => {
    dispatch({ type: types.SWAP_COMPLICATION_ORDER_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/complications/{id}" : "/dap-admin/api/v1/customers/patients/complications/{id}",
        uriParams: {
            id: complicationOne.id,
        },
        token: auth.accessToken,
        method: "PUT",
        data: { order: complicationTwo.order },
    })
        .then(() =>
            dispatch({
                type: types.SWAP_COMPLICATION_ORDER_SUCCESS,
                data: {
                    complicationOne: {
                        ...complicationOne,
                        order: complicationTwo.order,
                    },
                    complicationTwo: {
                        ...complicationTwo,
                        order: complicationOne.order,
                    },
                },
            })
        )
        .catch((error) => dispatch({ type: types.SWAP_COMPLICATION_ORDER_FAILURE, data: error }));
};
