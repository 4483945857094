import React from "react";

const HeaderList = () => (
    <li className='d-flex justify-space-between mb-1 hide-md-max'>
        <div className='title-3 spo__color--gray-4' style={{ marginLeft: '42px' }}>Coordonnées du patient</div>
        <div className='title-3 spo__color--gray-4'>Coordonnées du référent</div>
        <div className='title-3 spo__color--gray-4'>Coordonnées du médecin</div>
        <div className='title-3 spo__color--gray-4' style={{ width: '170px' }}></div>
    </li>
);

HeaderList.propTypes = {};

export default React.memo(HeaderList);
