import React from "react";
import PropTypes from "prop-types";

import { Button, Icon, QuestionnaireListContainer, QuestionnaireListItem } from "@rdcs/dap-front-library";

const QuestionnaireListDisplay = ({ questionnaireList, setIndexToDisplay, indexToDisplay, setFillNewQuestionnaire }) => {
    const handleClick = (evt, index) => {
        evt.preventDefault();
        setIndexToDisplay(index);
    };

    return (
        <QuestionnaireListContainer>
            <Button
                className="button__primaryButton timkl__backgroundColor--orange-dark-important d-flex justify-center align-items-center mt-4"
                onClick={() => setFillNewQuestionnaire(true)}
            >
                <Icon name="document" size={21} className="mr-1" />
                <span className="button-text-3-nunito">Ajouter un nouveau questionnaire</span>
            </Button>
            <h4 className="title-4-nunito timkl__color--blue-dark mt-4 mb-2">Historique des questionnaires</h4>
            <ul>
                {questionnaireList &&
                    questionnaireList.map((questionnaire, index) => {
                        const { date, score } = questionnaire;
                        let colorBadge = "#CF021B";
                        if (questionnaire.score < 66) colorBadge = "#F5A623";
                        if (questionnaire.score < 33) colorBadge = "#7ED321";
                        let fontWeight = 400;
                        if (indexToDisplay === index) fontWeight = 700;
                        return (
                            <QuestionnaireListItem
                                key={date}
                                date={date}
                                score={Math.round(Math.min(100, score))}
                                colorBadge={colorBadge}
                                index={index}
                                fontWeight={fontWeight}
                                handleClick={handleClick}
                            />
                        );
                    })}
            </ul>
        </QuestionnaireListContainer>
    );
};
QuestionnaireListDisplay.propTypes = {
    questionnaireList: PropTypes.arrayOf(PropTypes.object).isRequired,
    setIndexToDisplay: PropTypes.func.isRequired,
    indexToDisplay: PropTypes.number.isRequired,
    setFillNewQuestionnaire: PropTypes.func.isRequired,
};

export default QuestionnaireListDisplay;
