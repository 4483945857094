import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { tryAddTaskLabel } from "../../../actions/taskLabels";

import TaskLabelForm from "../../../components/Settings/Forms/TaskLabelForm";

import SettingsFormScreen from "../../../components/Settings/SettingsFormScreen";

const AddTaskLabelScreen = ({ taskLabels: { createLoading, createError }, tryAddTaskLabel }) => (
    <SettingsFormScreen
        /* Document and page titles */
        documentTitle="Ajouter action"
        pageTitle="Ajouter une action"
        /* Redux request callback use to add on BDD */
        formRequest={tryAddTaskLabel}
        /* Forms */
        itemForm={TaskLabelForm}
        itemFormRequestLoading={createLoading}
        itemFormRequestError={createError}
        /* Configuration tab name for redirection */
        tab="actions"
    />
);

AddTaskLabelScreen.propTypes = {
    taskLabels: PropTypes.shape({
        createLoading: PropTypes.bool.isRequired,
        createError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object
            }),
            PropTypes.bool
        ]).isRequired
    }).isRequired,
    tryAddTaskLabel: PropTypes.func.isRequired
};

const mapStateToProps = ({ taskLabels }) => ({ taskLabels });

const mapDispatchToProps = { tryAddTaskLabel };

export default connect(mapStateToProps, mapDispatchToProps)(AddTaskLabelScreen);
