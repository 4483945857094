import * as types from "../actions/types";
import { toast } from "react-toastify";

const initialState = {
    list: [],
    listLoading: false,
    createLoading: false,
    createError: false,
    editLoading: false,
    editError: false,
    deleteLoading: false,
    deleteError: false,
    swapOrderLoading: false,
};

function complications(state = initialState, action) {
    switch (action.type) {
        // FETCH
        case types.COMPLICATIONS_FETCH_REQUESTED: {
            return Object.assign({}, state, { listLoading: true });
        }
        case types.COMPLICATIONS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                listLoading: false,
                list: action.data,
            });
        }
        case types.COMPLICATIONS_FETCH_FAILURE: {
            return Object.assign({}, state, { listLoading: false, list: [] });
        }
        // ADD
        case types.ADD_COMPLICATION_REQUESTED: {
            return Object.assign({}, state, {
                createLoading: true,
                createError: false,
            });
        }
        case types.ADD_COMPLICATION_SUCCESS: {
            toast.success("La complication a été créée");
            return Object.assign({}, state, { createLoading: false });
        }
        case types.ADD_COMPLICATION_FAILURE: {
            toast.error("La complication n'a pas pu être créée");
            return Object.assign({}, state, {
                createLoading: false,
                createError: action.data,
            });
        }
        // EDIT
        case types.EDIT_COMPLICATION_REQUESTED: {
            return Object.assign({}, state, {
                editLoading: true,
                editError: false,
            });
        }
        case types.EDIT_COMPLICATION_SUCCESS: {
            toast.success("La complication a été modifiée");
            return Object.assign({}, state, { editLoading: false });
        }
        case types.EDIT_COMPLICATION_FAILURE: {
            toast.error("La complication n'a pas pu être modifiée");
            return Object.assign({}, state, {
                editLoading: false,
                editError: action.data,
            });
        }
        // SWAP COMPLICATION ORDER
        case types.SWAP_COMPLICATION_ORDER_REQUESTED: {
            return Object.assign({}, state, { swapOrderLoading: true });
        }
        case types.SWAP_COMPLICATION_ORDER_SUCCESS: {
            toast.success("L'ordre a été changé");

            return Object.assign({}, state, {
                swapOrderLoading: false,
                list: state.list.map((c) => {
                    if (c.id === action.data.complicationOne.id) c.order = action.data.complicationOne.order;
                    if (c.id === action.data.complicationTwo.id) c.order = action.data.complicationTwo.order;
                    return c;
                }),
            });
        }
        case types.SWAP_COMPLICATION_ORDER_FAILURE: {
            toast.error("L'ordre des complications n'a pas pu être modifié");
            return Object.assign({}, state, { swapOrderLoading: false });
        }
        // COMPLICATION ACTIVATE
        case types.ACTIVATE_COMPLICATION_SUCCESS: {
            toast.success(action.data.active ? "La complication a bien été activée." : "La complication a bien été désactivée.");
            return Object.assign({}, state, {});
        }
        case types.ACTIVATE_COMPLICATION_FAILURE: {
            toast.error("La complication n'a pas pu être modifiée.");
            return Object.assign({}, state, {});
        }
        // DEFAULT
        default:
            return state;
    }
}

export default complications;
