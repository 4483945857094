import { useParams } from "react-router-dom";

import { connect } from "react-redux";
import React from "react";
import { tryFetchPractitioners } from "../../../actions/medicals";
import { tryEditPatientAppointment, tryFetchPatientAppointments } from "../../../actions/patients";
import { tryCreatePatientAppointment } from "../../../actions/patients";
import { sendEmailToPartnerForRecommendation } from "../../../actions/partners";
import { tryFetchCurrentPartnerCheckUps } from "../../../actions/partners";
import { AppointmentCheckUpRecommended } from "@rdcs/dap-front-library";

const RecoCard = ({
    createAppointmentLoading,
    checkUps,
    tryFetchPractitioners,
    tryEditPatientAppointment,
    tryFetchPatientAppointments,
    tryCreatePatientAppointment,
    sendEmailToPartnerForRecommendation,
    tryFetchCurrentPartnerCheckUps,
    reco
}) => {

    React.useEffect(() => {
        tryFetchCurrentPartnerCheckUps(patientId);
    }, []);

    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);
    const patientIri = React.useMemo(() => `/patients/${patientId}`, [patientId]);

    return (
        <div className="dropDownRecoContainer">
            {reco.map((recoInstance => (
                <AppointmentCheckUpRecommended
                    key={recoInstance.id}
                    dueAt={recoInstance.dueAt}
                    statusColor={recoInstance.statusColor}
                    recommendation={recoInstance.recommendation}
                    appointment={recoInstance.appointment}
                    lastAppointment={recoInstance.lastAppointment || null}
                    showDescription={false}
                    exams={reco.filter(({ recommendation: { consultationName } }) =>
                        consultationName === recoInstance.recommendation.title
                    )}
                    patientIri={patientIri}
                    searchPractitionerAction={tryFetchPractitioners}
                    createAppointmentLoading={createAppointmentLoading}
                    createAppointmentAction={(appointment) =>
                        tryCreatePatientAppointment(appointment).then(() => tryFetchPatientAppointments(patientId))}
                    confirmAppointmentAction={tryEditPatientAppointment}
                    partnerAction={() => sendEmailToPartnerForRecommendation(recoInstance.recommendation.id, patientId)}
                    partnerCheckUps={checkUps}
                    reportCard
                />
            )))}
        </div>
    )

}


const mapStateToProps = ({
    patients: {
        createAppointmentLoading
    },
    partners: {
        checkUps,
        checkUpsLoading
    }
}) => ({
    createAppointmentLoading,
    checkUps,
    checkUpsLoading
});

const mapDispatchToProps = {
    tryFetchPractitioners,
    tryEditPatientAppointment,
    tryCreatePatientAppointment,
    tryFetchPatientAppointments,
    sendEmailToPartnerForRecommendation,
    tryFetchCurrentPartnerCheckUps,
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(RecoCard));