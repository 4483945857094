import React from "react";
import PropTypes from 'prop-types';
import { useLocation, withRouter } from "react-router-dom";

const ScrollToTop = ({ children }) => {
    const location = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return children;
}

ScrollToTop.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ]).isRequired,
};

export default withRouter(ScrollToTop);