import * as types from "./types";
import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

export const tryFetchRecommendations = auth => dispatch => {
    dispatch({ type: types.RECOMMENDATIONS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/recommendations" : "/dap-admin/api/v1/clinical-data/recommendations",
        queryParams: {
            pagination: false,
            "order[position]": "asc"
        },
        token: auth.accessToken
    })
        .then(response => dispatch({ type: types.RECOMMENDATIONS_FETCH_SUCCESS, data: response["hydra:member"] }))
        .catch(error => dispatch({ type: types.RECOMMENDATIONS_FETCH_FAILURE, data: error }));
};

export const tryCreateRecommendation = (auth, recommendation) => dispatch => {
    dispatch({ type: types.RECOMMENDATIONS_CREATE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/recommendations" : "/dap-admin/api/v1/clinical-data/recommendations",
        method: "POST",
        token: auth.accessToken,
        data: recommendation
    })
        .then(response => dispatch({ type: types.RECOMMENDATIONS_CREATE_SUCCESS, data: response }))
        .catch(error => dispatch({ type: types.RECOMMENDATIONS_CREATE_FAILURE, data: error }));
};

export const clearCreateRecommendation = () => dispatch => dispatch({ type: types.RECOMMENDATIONS_CREATE_CLEAR });

export const tryEditRecommendation = (auth, recommendationId, form) => dispatch => {
    dispatch({ type: types.RECOMMENDATIONS_EDIT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/recommendations/{id}" : "/dap-admin/api/v1/clinical-data/recommendations/{id}",
        uriParams: {
            id: recommendationId
        },
        method: "PUT",
        token: auth.accessToken,
        data: form
    })
        .then(response => dispatch({ type: types.RECOMMENDATIONS_EDIT_SUCCESS, data: response }))
        .catch(error => dispatch({ type: types.RECOMMENDATIONS_EDIT_FAILURE, data: error }));
};

export const clearEditRecommendation = () => dispatch => dispatch({ type: types.RECOMMENDATIONS_EDIT_CLEAR });

export const tryDeleteRecommendation = (auth, recommendationId) => dispatch => {
    dispatch({ type: types.RECOMMENDATIONS_DELETE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/recommendations/{id}" : "/dap-admin/api/v1/clinical-data/recommendations/{id}",
        uriParams: {
            id: recommendationId
        },
        method: "DELETE",
        token: auth.accessToken
    })
        .then(() => dispatch({ type: types.RECOMMENDATIONS_DELETE_SUCCESS, data: recommendationId }))
        .catch(error => dispatch({ type: types.RECOMMENDATIONS_DELETE_FAILURE, data: error }));
};

export const tryDisableRecommendation = (auth, recommendationId) => dispatch =>
    API.request({
        path: isLocalAPI ? "/recommendations/{id}" : "/dap-admin/api/v1/clinical-data/recommendations/{id}",
        uriParams: {
            id: recommendationId
        },
        method: "PUT",
        token: auth.accessToken,
        data: { active: false }
    })
        .then(response => dispatch({ type: types.RECOMMENDATIONS_DISABLE_SUCCESS, data: response }))
        .catch(error => dispatch({ type: types.RECOMMENDATIONS_DISABLE_FAILURE, data: error }));

export const tryEnableRecommendation = (auth, recommendationId) => dispatch =>
    API.request({
        path: isLocalAPI ? "/recommendations/{id}" : "/dap-admin/api/v1/clinical-data/recommendations/{id}",
        uriParams: {
            id: recommendationId
        },
        method: "PUT",
        token: auth.accessToken,
        data: { active: true }
    })
        .then(response => dispatch({ type: types.RECOMMENDATIONS_ENABLE_SUCCESS, data: response }))
        .catch(error => dispatch({ type: types.RECOMMENDATIONS_ENABLE_FAILURE, data: error }));

export const tryFetchAllDefaultRecommendation = (auth) => dispatch =>
    API.request({
        path: isLocalAPI ? "/get-check-all-recommendations-by-default" : "/dap-admin/api/v1/get-check-all-recommendations-by-default",
        method: "GET",
        token: auth.accessToken
    })
        .then(response => dispatch({ type: types.RECOMMENDATIONS_ALL_DEFAULT_FETCH_SUCCESS, data: response }))
        .catch(error => dispatch({ type: types.RECOMMENDATIONS_ALL_DEFAULT_FETCH_FAILURE, data: error }));

export const tryDisableAllDefaultRecommendation = (auth) => dispatch =>
    API.request({
        path: isLocalAPI ? "/uncheck-all-recommendations-by-default" : "/dap-admin/api/v1/uncheck-all-recommendations-by-default",
        method: "POST",
        token: auth.accessToken
    })
        .then(response => dispatch({ type: types.RECOMMENDATIONS_ALL_DEFAULT_DISABLE_SUCCESS, data: response }))
        .catch(error => dispatch({ type: types.RECOMMENDATIONS_ALL_DEFAULT_DISABLE_FAILURE, data: error }));

export const tryEnableAllDefaultRecommendation = (auth) => dispatch =>
    API.request({
        path: isLocalAPI ? "/check-all-recommendations-by-default" : "/dap-admin/api/v1/check-all-recommendations-by-default",
        method: "POST",
        token: auth.accessToken
    })
        .then(response => dispatch({ type: types.RECOMMENDATIONS_ALL_DEFAULT_ENABLE_SUCCESS, data: response }))
        .catch(error => dispatch({ type: types.RECOMMENDATIONS_ALL_DEFAULT_ENABLE_FAILURE, data: error }));
