import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { tryEditPartner, tryFetchPartners } from "../../../actions/partners";

import PartnerForm from "../../../components/Settings/Forms/PartnerForm";
import SettingsEditFormScreen from "../../../components/Settings/SettingsEditFormScreen";

const EditPartnerScreen = ({
    partners: { list: partner, listLoading, editLoading, editError },
    tryEditPartner,
    tryFetchPartners
}) => (
    <SettingsEditFormScreen
        /* Document and page titles */
        documentTitle="Modifier partenaire"
        pageTitle="Modifier un partenaire"
        /* Redux request callback use to add on BDD */
        formRequest={tryEditPartner}
        fetchRequests={tryFetchPartners}
        testFetchRequestsNeeded={partner.length === 0}
        /* Forms */
        itemForm={PartnerForm}
        itemFormRequestLoading={editLoading}
        itemFormRequestError={editError}
        itemFormExtraProps={{ partner }}
        itemFormIsLoading={listLoading}
        itemFormEditedItemPropName="editedPartner"
        /* Items lists to retrieve the current item */
        items={partner}
        /* Configuration tab name for redirection */
        tab="partners-list"
    />
);

EditPartnerScreen.propTypes = {
    partners: PropTypes.shape({
        list: PropTypes.arrayOf(PropTypes.object).isRequired,
        listLoading: PropTypes.bool.isRequired,
        editLoading: PropTypes.bool.isRequired,
        editError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object
            }),
            PropTypes.bool
        ]).isRequired
    }).isRequired,
    tryEditPartner: PropTypes.func.isRequired,
    tryFetchPartners: PropTypes.func.isRequired
};

const mapStateToProps = ({ partners }) => ({ partners });

const mapDispatchToProps = { tryEditPartner, tryFetchPartners };

export default connect(mapStateToProps, mapDispatchToProps)(EditPartnerScreen);
