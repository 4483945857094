import React from "react";
import { AdminLayoutAnonymous } from "@rdcs/dap-front-library";

const AccessDeniedExceptionScreen = () => {
    React.useEffect(() => {
        document.title = "Timkl - Accès non autorisé";
    }, []);

    return (
        <AdminLayoutAnonymous>
            <div className="appLoading">
                <div className="appLoading__container">
                    <h1 className="title-1">403</h1>
                    <p className="text-3">Vous n'avez pas les droits requis pour accéder à la page demandée</p>
                </div>
            </div>
        </AdminLayoutAnonymous>
    );
};

export default AccessDeniedExceptionScreen;
