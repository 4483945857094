import * as types from "./types";
import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import store from "../store";

export const tryFetchAdmins = (auth) => (dispatch) => {
    dispatch({ type: types.ADMINS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/admins" : "/dap-admin/api/v1/customers/admins",
        queryParams: {
            pagination: false,
        },
        token: auth.accessToken,
    })
        .then((response) => dispatch({ type: types.ADMINS_FETCH_SUCCESS, data: response["hydra:member"] }))
        .catch((error) => dispatch({ type: types.ADMINS_FETCH_FAILURE, data: error }));
};

export const tryAddAdmin = (admin) => (dispatch) => {
    const { auth } = store.getState();

    dispatch({ type: types.ADD_ADMIN_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/admins" : "/dap-admin/api/v1/customers/admins",
        token: auth.accessToken,
        method: "POST",
        data: {
            ...admin,
            enable: true,
        },
    })
        .then((response) => dispatch({ type: types.ADD_ADMIN_SUCCESS, data: response }))
        .catch((error) => {
            dispatch({ type: types.ADD_ADMIN_FAILURE, data: error });
            throw error;
        });
};

export const tryRemoveAdmin = (auth, adminId) => (dispatch) => {
    dispatch({ type: types.REMOVE_ADMIN_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/admins/{id}" : "/dap-admin/api/v1/customers/admins/{id}",
        uriParams: {
            id: adminId,
        },
        token: auth.accessToken,
        method: "DELETE",
    })
        .then(() => dispatch({ type: types.REMOVE_ADMIN_SUCCESS, data: "/admins/" + adminId }))
        .catch((error) => dispatch({ type: types.REMOVE_ADMIN_FAILURE, data: error }));
};

export const tryEditAdmin = (adminId, form) => (dispatch) => {
    const { auth } = store.getState();

    dispatch({ type: types.EDIT_ADMIN_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/admins/{id}" : "/dap-admin/api/v1/customers/admins/{id}",
        uriParams: {
            id: adminId,
        },
        token: auth.accessToken,
        method: "PUT",
        data: form,
    })
        .then((response) => dispatch({ type: types.EDIT_ADMIN_SUCCESS, data: response }))
        .catch((error) => {
            dispatch({ type: types.EDIT_ADMIN_FAILURE, data: error });
            throw error;
        });
};
