import React from "react";
import { AdminLayoutAnonymous } from "@rdcs/dap-front-library";

const NotFoundExceptionScreen = () => {
    React.useEffect(() => {
        document.title = "Timkl - Page non trouvée";
    }, []);

    return (
        <AdminLayoutAnonymous>
            <div className="appLoading">
                <div className="appLoading__container">
                    <h1 className="title-1">404</h1>
                    <p className="text-3">La page demandée n'existe pas ou le contenu est indisponible</p>
                </div>
            </div>
        </AdminLayoutAnonymous>
    );
};

export default NotFoundExceptionScreen;
