import React from "react";
import PropTypes from "prop-types";

import { Button, InputText, InputEmail, Icon } from "@rdcs/dap-front-library";
import { Link } from "react-router-dom";

const ModeratorForm = ({
    editedModerator: {
        firstName: editedModeratorFirstName = "",
        lastName: editedModeratorLastName = "",
        email: editedModeratorEmail = ""
    } = {},
    errors,
    onSubmit,
    location,
    isLoading
}) => {
    const [firstName, setFirstName] = React.useState(editedModeratorFirstName);
    const [lastName, setLastName] = React.useState(editedModeratorLastName);
    const [email, setEmail] = React.useState(editedModeratorEmail);
    const isEditMode = React.useMemo(() => editedModeratorEmail !== '', []);

    const onSubmitCallback = event => {
        const form = {
            firstName,
            lastName
        };

        event.preventDefault();
        if (!isEditMode) {
            form.email = email;
        }
        onSubmit(form);
    };

    return (
        <form className="form grid grid__padding-10" onSubmit={onSubmitCallback}>
            <div className="col-md-50">
                <InputText
                    label="Prénom"
                    placeholder="Saisissez votre prénom"
                    name="firstName"
                    error={errors.firstName}
                    defaultValue={firstName}
                    required
                    onChange={setFirstName}
                />
            </div>
            <div className="col-md-50">
                <InputText
                    label="Nom"
                    placeholder="Saisissez votre nom"
                    name="lastName"
                    error={errors.lastName}
                    defaultValue={lastName}
                    required
                    onChange={setLastName}
                />
            </div>
            <div className="col-100">
                <InputEmail
                    label="Email"
                    placeholder="Saisissez votre email"
                    error={errors.email}
                    defaultValue={email}
                    required
                    onChange={setEmail}
                    disabled={isEditMode}
                />
            </div>
            <div className="form__group col-100 text-center">
                <Link
                    to={{
                        pathname: "/configuration",
                        state: { from: location },
                        tab: "moderator"
                    }}
                >
                    <Button className="button__secondaryButton">
                        <Icon name="arrow-left" className="mr-1" /> Retour
                    </Button>
                </Link>
                <Button type="submit" className="button__primaryButton" isLoading={isLoading}>
                    Enregistrer
                </Button>
            </div>
        </form>
    );
};

ModeratorForm.propTypes = {
    editedModerator: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired
    }),
    errors: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string
    }),
    onSubmit: PropTypes.func.isRequired,
    location: PropTypes.any,
    isLoading: PropTypes.bool.isRequired
};

export default ModeratorForm;
