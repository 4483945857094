import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { tryEditAdmin, tryFetchAdmins } from "../../../actions/admins";

import AdminForm from "../../../components/Settings/Forms/AdminForm";
import SettingsEditFormScreen from "../../../components/Settings/SettingsEditFormScreen";

const EditAdminScreen = ({
    admins: { list: admins, listLoading, editLoading, editError },
    tryEditAdmin,
    tryFetchAdmins
}) => (
    <SettingsEditFormScreen
        /* Document and page titles */
        documentTitle="Modifier administrateur"
        pageTitle="Modifier un administrateur"
        /* Redux request callback use to add on BDD */
        formRequest={tryEditAdmin}
        fetchRequests={tryFetchAdmins}
        testFetchRequestsNeeded={admins.length === 0}
        /* Forms */
        itemForm={AdminForm}
        itemFormRequestLoading={editLoading}
        itemFormRequestError={editError}
        itemFormIsLoading={listLoading}
        itemFormEditedItemPropName="editedAdmin"
        /* Items lists to retrieve the current item */
        items={admins}
        /* Configuration tab name for redirection */
        tab="admin"
    />
);

EditAdminScreen.propTypes = {
    admins: PropTypes.shape({
        list: PropTypes.arrayOf(PropTypes.object).isRequired,
        listLoading: PropTypes.bool.isRequired,
        editLoading: PropTypes.bool.isRequired,
        editError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object
            }),
            PropTypes.bool
        ]).isRequired
    }).isRequired,
    tryEditAdmin: PropTypes.func.isRequired,
    tryFetchAdmins: PropTypes.func.isRequired
};

const mapStateToProps = ({ admins }) => ({ admins });

const mapDispatchToProps = { tryEditAdmin, tryFetchAdmins };

export default connect(mapStateToProps, mapDispatchToProps)(EditAdminScreen);
