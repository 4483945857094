import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { DefaultLoader, Button, Icon } from "@rdcs/dap-front-library";

const SettingsTabLayout = ({
    title,
    icon = undefined,
    addButton: { link = undefined, label = undefined } = {},
    isLoading = false,
    children
}) => (
    <div>
        <div className="contentHolder__title">
            {icon && (typeof icon === "string" || icon instanceof String) ? (
                <Icon name={icon} className="contentHolder__title-icon" />
            ) : (
                icon
            )}
            <h1 className="title-1">{title}</h1>
        </div>
        {link && label && (
            <div className="text-right">
                <Link to={link}>
                    <Button className="button__primaryButton">{label}</Button>
                </Link>
            </div>
        )}
        <div className="moderationList form__group">{isLoading ? <DefaultLoader color="#CCC" /> : children}</div>
    </div>
);

SettingsTabLayout.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    addButton: PropTypes.shape({
        link: PropTypes.string,
        label: PropTypes.string
    }),
    isLoading: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default SettingsTabLayout;
