import * as types from "./types";

import store from "../store";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";
import { toast } from "react-toastify";

export const tryFetchPatients = (auth, queryParams) => (dispatch) => {
    dispatch({ type: types.PATIENTS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/patients" : "/dap-admin/api/v1/customers/patients",
        queryParams,
        token: auth.accessToken,
    })
        .then((response) => {
            dispatch({
                type: types.PATIENTS_FETCH_SUCCESS,
                data: response["hydra:member"],
                totalItems: response["hydra:totalItems"],
            });
            return response["hydra:member"];
        })
        .catch((error) => {
            dispatch({ type: types.PATIENTS_FETCH_FAILURE, data: error });
            throw error;
        });
};

export const tryCreatePatient = (auth, patient) => (dispatch) => {
    dispatch({ type: types.CREATE_PATIENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/patients" : "/dap-admin/api/v1/customers/patients",
        method: "POST",
        token: auth.accessToken,
        data: patient,
        patientCreationByAdmin: true,
    })
        .then((response) => {
            dispatch({ type: types.CREATE_PATIENT_SUCCESS, data: response });
            return response;
        })
        .catch((error) => {
            dispatch({
                type: types.CREATE_PATIENT_FAILURE,
                data: error,
            });
            throw error;
        });
};

export const tryEditPatient = (auth, patientId, data) => (dispatch) => {
    dispatch({ type: types.PATIENTS_EDIT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/patients/{id}" : "/dap-admin/api/v1/customers/patients/{id}",
        uriParams: {
            id: patientId,
        },
        method: "PUT",
        token: auth.accessToken,
        data: data,
    })
        .then((response) => dispatch({ type: types.PATIENTS_EDIT_SUCCESS, data: response }))
        .catch((error) => dispatch({ type: types.PATIENTS_EDIT_FAILURE, data: error }));
};

export const tryFetchPatientAppointments = (patientUri) => (dispatch) => {
    const { auth } = store.getState();
    dispatch({ type: types.FETCH_PATIENT_APPOINTMENTS_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/appointments" : "/dap-admin/api/v1/customers/patients/appointments",
        queryParams: {
            patient: patientUri,
        },
        token: auth.accessToken,
    })
        .then((response) =>
            dispatch({
                type: types.FETCH_PATIENT_APPOINTMENTS_SUCCESS,
                data: response["hydra:member"],
            })
        )
        .catch((error) =>
            dispatch({
                type: types.FETCH_PATIENT_APPOINTMENTS_FAILURE,
                data: error,
            })
        );
};

export const tryCreatePatientAppointment = (appointment) => (dispatch) => {
    const { auth } = store.getState();

    dispatch({ type: types.CREATE_PATIENT_APPOINTMENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/appointments" : "/dap-admin/api/v1/customers/patients/appointments",
        method: "POST",
        token: auth.accessToken,
        data: {
            recommendations: appointment.recommendations,
            patient: appointment.patientIri,
            practitioner: appointment.practitioner["@id"],
            date: appointment.date,
            topic: appointment.topic,
        },
    })
        .then((response) =>
            dispatch({
                type: types.CREATE_PATIENT_APPOINTMENT_SUCCESS,
                data: response,
            })
        )
        .catch((error) =>
            dispatch({
                type: types.CREATE_PATIENT_APPOINTMENT_FAILURE,
                data: error,
            })
        );
};

export const tryEditPatientAppointment = (auth, appointment) => (dispatch) => {
    dispatch({ type: types.EDIT_PATIENT_APPOINTMENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/appointments/{id}" : "/dap-admin/api/v1/customers/patients/appointments/{id}",
        uriParams: {
            id: appointment.id,
        },
        method: "PUT",
        token: auth.accessToken,
        data: appointment,
    })
        .then((response) =>
            dispatch({
                type: types.EDIT_PATIENT_APPOINTMENT_SUCCESS,
                data: response,
            })
        )
        .catch((error) => dispatch({ type: types.EDIT_PATIENT_APPOINTMENT_SUCCESS, data: error }));
};

export const tryDeletePatientAppointment = (auth, appointmentId) => (dispatch) => {
    dispatch({ type: types.DELETE_PATIENT_APPOINTMENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/appointments/{id}" : "/dap-admin/api/v1/customers/patients/appointments/{id}",
        uriParams: {
            id: appointmentId,
        },
        method: "DELETE",
        token: auth.accessToken,
    })
        .then((response) =>
            dispatch({
                type: types.DELETE_PATIENT_APPOINTMENT_SUCCESS,
                data: response,
            })
        )
        .catch((error) =>
            dispatch({
                type: types.DELETE_PATIENT_APPOINTMENT_FAILURE,
                data: error,
            })
        );
};

export const trySinglePatient = (id) => (dispatch) => {
    const {
        auth: { accessToken },
        patients: { singlePatient, singlePatientId, singlePatientLoaded },
    } = store.getState();

    if (singlePatientLoaded && singlePatientId === id) {
        return Promise.resolve(singlePatient);
    }

    dispatch({ type: types.SINGLE_PATIENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/patients/{id}" : "/dap-admin/api/v1/customers/patients/{id}",
        uriParams: {
            id,
        },
        token: accessToken,
    })
        .then((response) => {
            dispatch({ type: types.SINGLE_PATIENT_SUCCESS, data: response });
            return response;
        })
        .catch((error) => dispatch({ type: types.SINGLE_PATIENT_FAILURE, data: error }));
};

export const tryFetchDiagnosticRecommendations = (auth) => (dispatch) => {
    const {
        patients: { diagnosticRecommendations, diagnosticRecommendationsDiagnosticId, singlePatient },
    } = store.getState();
    if (!singlePatient?.lastDiagnostic) {
        dispatch({ type: types.DIAGNOSTIC_RECOMMENDATIONS_FAILURE });
        return Promise.resolve([]);
    } else if (singlePatient.lastDiagnostic["@id"] === diagnosticRecommendationsDiagnosticId) {
        return Promise.resolve(diagnosticRecommendations);
    }

    dispatch({ type: types.DIAGNOSTIC_RECOMMENDATIONS_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/diagnostic_recommendations" : "/dap-admin/api/v1/clinical-data/diagnostics-recommendations",
        queryParams: {
            active: 1,
            diagnostic: singlePatient.lastDiagnostic["@id"],
        },
        token: auth.accessToken,
    })
        .then((response) => {
            dispatch({
                type: types.DIAGNOSTIC_RECOMMENDATIONS_SUCCESS,
                data: response["hydra:member"],
                diagnosticId: singlePatient.lastDiagnostic["@id"],
            });
        })
        .catch(() => dispatch({ type: types.DIAGNOSTIC_RECOMMENDATIONS_FAILURE }));
};

export const tryAddPractitionerToPatient = (auth, medicalIri, patientIri) => (dispatch) => {
    dispatch({ type: types.ADD_PRACTITIONER_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/access_rights" : "/dap-admin/api/v1/ciam/access/rights",
        method: "POST",
        token: auth.accessToken,
        data: {
            patient: patientIri,
            user: medicalIri,
            rights: "rw",
        },
    })
        .then((response) => dispatch({ type: types.ADD_PRACTITIONER_SUCCESS, data: response.data }))
        .catch((error) => dispatch({ type: types.ADD_PRACTITIONER_FAILURE, data: error }));
};

export const tryFetchLastDiagnosis = (patientId) => (dispatch) => {
    const {
        auth: { accessToken },
    } = store.getState();
    dispatch({ type: types.PATIENT_LAST_DIAGNOSIS_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/diagnostics" : "/dap-admin/api/v1/clinical-data/diagnostics",
        queryParams: {
            itemsPerPage: 1,
            "order[createdAt]": "desc",
            patient: patientId,
        },
        method: "GET",
        token: accessToken,
    })
        .then((response) => {
            const data = response["hydra:member"];
            const patientLastDiagnostic = data?.[0] || null;

            dispatch({
                type: types.PATIENT_LAST_DIAGNOSIS_SUCCESS,
                data: patientLastDiagnostic,
                patientId,
            });
            return patientLastDiagnostic;
        })
        .catch((error) => dispatch({ type: types.PATIENT_LAST_DIAGNOSIS_FAILURE, data: error }));
};

export const tryFetchPatientTasks = (auth, patientUri) => (dispatch) => {
    dispatch({ type: types.FETCH_PATIENT_TASKS_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/tasks" : "/dap-admin/api/v1/customers/patients/tasks",
        queryParams: {
            patient: patientUri,
        },
        token: auth.accessToken,
    })
        .then((response) =>
            dispatch({
                type: types.FETCH_PATIENT_TASKS_SUCCESS,
                data: response["hydra:member"],
            })
        )
        .catch((error) => dispatch({ type: types.FETCH_PATIENT_TASKS_FAILURE, data: error }));
};

export const tryCreatePatientTask = (auth, task) => (dispatch) => {
    dispatch({ type: types.CREATE_PATIENT_TASK_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/tasks" : "/dap-admin/api/v1/customers/patients/tasks",
        method: "POST",
        token: auth.accessToken,
        data: task,
    })
        .then((response) => dispatch({ type: types.CREATE_PATIENT_TASK_SUCCESS, data: response }))
        .catch((error) => dispatch({ type: types.CREATE_PATIENT_TASK_FAILURE, data: error }));
};

export const tryEditPatientTask = (auth, task) => (dispatch) => {
    dispatch({ type: types.EDIT_PATIENT_TASK_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/tasks/{id}" : "/dap-admin/api/v1/customers/patients/tasks/{id}",
        uriParams: {
            id: task.id,
        },
        method: "PUT",
        token: auth.accessToken,
        data: task,
    })
        .then((response) => dispatch({ type: types.EDIT_PATIENT_TASK_SUCCESS, data: response }))
        .catch((error) => dispatch({ type: types.EDIT_PATIENT_TASK_FAILURE, data: error }));
};

export const tryPatientsUpdateStatus = (auth) => (dispatch) => {
    dispatch({ type: types.PATIENTS_UPDATE_STATUS_REQUESTED });
    return API.request({
        method: "POST",
        path: isLocalAPI ? "/batch/patients/update-status" : "/dap-admin/api/v1/customers/patients/batchs/update-status",
        token: auth.accessToken,
        data: {},
    })
        .then(() => dispatch({ type: types.PATIENTS_UPDATE_STATUS_SUCCESS }))
        .catch((res) => dispatch({ type: types.PATIENTS_UPDATE_STATUS_FAILURE, data: res }));
};

export const tryPatientsUpdateBgm = (auth) => (dispatch) => {
    dispatch({ type: types.PATIENTS_UPDATE_BGM_REQUESTED });
    return API.request({
        method: "POST",
        path: isLocalAPI ? "/batch/patients/update-count-bgm" : "/dap-admin/api/v1/customers/patients/batchs/update-count-bgm",
        token: auth.accessToken,
        data: {},
    })
        .then(() => dispatch({ type: types.PATIENTS_UPDATE_BGM_SUCCESS }))
        .catch((res) => dispatch({ type: types.PATIENTS_UPDATE_BGM_FAILURE, data: res }));
};

export const tryFetchConsultationAnswers = (auth, idDiagnostic) => (dispatch) => {
    dispatch({ type: types.DIAGNOSIS_CONSULTATION_ANSWERS_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/consultation_answers" : "/dap-admin/api/v1/customers/patients/consultation-answers",
        queryParams: {
            pagination: false,
            "diagnostic.id": idDiagnostic,
        },
        method: "GET",
        token: auth.accessToken,
    })
        .then((response) =>
            dispatch({
                type: types.DIAGNOSIS_CONSULTATION_ANSWERS_SUCCESS,
                data: response["hydra:member"],
            })
        )
        .catch((error) =>
            dispatch({
                type: types.DIAGNOSIS_CONSULTATION_ANSWERS_FAILURE,
                data: error,
            })
        );
};

export const tryFetchPotentialPatients = (auth, queryParams) => (dispatch) => {
    dispatch({ type: types.POTENTIAL_PATIENTS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/potential_patients" : "/dap-admin/api/v1/break-score-questionnaires/potential-patients",
        queryParams,
        token: auth.accessToken,
    })
        .then((response) => {
            dispatch({
                type: types.POTENTIAL_PATIENTS_FETCH_SUCCESS,
                data: response["hydra:member"],
                totalItems: response["hydra:totalItems"],
            });
            return response["hydra:member"];
        })
        .catch((error) => dispatch({ type: types.POTENTIAL_PATIENTS_FETCH_FAILURE, data: error }));
};

export const tryFetchPotentialPatient = (auth, potentialPatientId, queryParams) => (dispatch) => {
    dispatch({ type: types.POTENTIAL_PATIENT_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/potential_patients/{id}" : "/dap-admin/api/v1/break-score-questionnaires/potential-patients/{id}",
        uriParams: {
            id: potentialPatientId,
        },
        queryParams,
        method: "GET",
        token: auth.accessToken,
    })
        .then((response) => {
            dispatch({ type: types.POTENTIAL_PATIENT_FETCH_SUCCESS, data: response, potentialPatientId });
            return response;
        })
        .catch((error) => dispatch({ type: types.POTENTIAL_PATIENT_FETCH_FAILURE, data: error }));
};

export const tryEditPotentialPatient = (auth, potentialPatientId, data) => (dispatch) => {
    dispatch({ type: types.EDIT_POTENTIAL_PATIENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/potential_patients/{id}" : "/dap-admin/api/v1/break-score-questionnaires/potential-patients/{id}",
        uriParams: {
            id: potentialPatientId,
        },
        method: "PUT",
        token: auth.accessToken,
        data: data,
    })
        .then((response) => {
            dispatch({ type: types.EDIT_POTENTIAL_PATIENT_SUCCESS, data: response, potentialPatientId });
            return response;
        })
        .catch((error) => dispatch({ type: types.EDIT_POTENTIAL_PATIENT_FAILURE, data: error }));
};

export const tryArchivePatient = (auth, patient) => (dispatch) => {
    dispatch({ type: types.PATIENT_ARCHIVE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/patients/{id}" : "/dap-admin/api/v1/customers/patients/{id}",
        uriParams: {
            id: patient.id,
        },
        method: "PUT",
        token: auth.accessToken,
        data: {
            archived: true,
            ssn: patient.ssn,
        },
    })
        .then((response) => {
            dispatch({ type: types.PATIENT_ARCHIVE_SUCCESS, data: response });
            toast.success("Le patient a bien été archivé");
            return response;
        })
        .catch((error) => {
            dispatch({ type: types.PATIENT_ARCHIVE_FAILURE, data: error });
            toast.error("Le patient n'a pas pu être archivé");
        });
};

export const tryMedicalPatient = (id) => (dispatch) => {
    const {
        auth: { accessToken },
        patients: { medicalPatient, medicalPatientLoaded, medicalPatientPatientId, medicalPatientsLoaded, medicalPatients },
    } = store.getState();
    id = parseInt(id);

    return new Promise((resolve, reject) => {
        if (medicalPatientLoaded && medicalPatientPatientId === id) {
            return resolve(medicalPatient);
        }

        if (!medicalPatientsExpired(medicalPatientsLoaded)) {
            for (const page in medicalPatients) {
                const localMedicalPatient = medicalPatients[page].find(({ patient }) => patient.id === id);

                if (localMedicalPatient !== undefined) {
                    dispatch({
                        type: types.MEDICAL_PATIENT_SUCCESS,
                        data: localMedicalPatient,
                        patientId: id,
                    });
                    resolve(localMedicalPatient);
                }
            }
        }
        reject();
    }).catch(() => {
        dispatch({ type: types.MEDICAL_PATIENT_REQUESTED });
        return API.request({
            path: isLocalAPI ? `/access_rights` : `/dap-admin/api/v1/ciam/access/rights`,
            token: accessToken,
            queryParams: {
                patient: id,
            },
        })
            .then((response) => {
                if (response["hydra:totalItems"] !== 1) {
                    throw new Error("Patient not found !");
                }
                dispatch({
                    type: types.MEDICAL_PATIENT_SUCCESS,
                    data: response["hydra:member"][0],
                    patientId: id,
                });
                return response["hydra:member"][0];
            })
            .catch((error) => {
                dispatch({ type: types.MEDICAL_PATIENT_FAILURE });
                throw error;
            });
    });
};
