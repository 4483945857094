import * as types from "./types";
import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";
import store from "../store";
import moment from "moment";
import { toast } from "react-toastify";

export const tryFetchPartners = (auth) => (dispatch) => {
    dispatch({ type: types.PARTNERS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/partners" : "/dap-admin/api/v1/partners",
        queryParams: {
            pagination: false,
        },
        token: auth.accessToken,
    })
        .then((response) => dispatch({ type: types.PARTNERS_FETCH_SUCCESS, data: response["hydra:member"] }))
        .catch((error) => dispatch({ type: types.PARTNERS_FETCH_FAILURE, data: error }));
};

export const tryAddPartner = (partner) => (dispatch) => {
    const { auth } = store.getState();

    dispatch({ type: types.ADD_PARTNER_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/partners" : "/dap-admin/api/v1/partners",
        token: auth.accessToken,
        method: "POST",
        data: partner,
    })
        .then((response) => dispatch({ type: types.ADD_PARTNER_SUCCESS, data: response }))
        .catch((error) => {
            dispatch({ type: types.ADD_PARTNER_FAILURE, data: error });
            throw error;
        });
};

export const tryRemovePartner = (auth, partnerId) => (dispatch) => {
    dispatch({ type: types.REMOVE_PARTNER_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/partners/{id}" : "/dap-admin/api/v1/partners/{id}",
        uriParams: {
            id: partnerId,
        },
        token: auth.accessToken,
        method: "DELETE",
    })
        .then(() => dispatch({ type: types.REMOVE_PARTNER_SUCCESS, data: "/partners/" + partnerId }))
        .catch((error) => dispatch({ type: types.REMOVE_PARTNER_FAILURE, data: error }));
};

export const tryEditPartner = (partnerId, form) => (dispatch) => {
    const { auth } = store.getState();

    dispatch({ type: types.EDIT_PARTNER_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/partners/{id}" : "/dap-admin/api/v1/partners/{id}",
        uriParams: {
            id: partnerId,
        },
        token: auth.accessToken,
        method: "PUT",
        data: form,
    })
        .then((response) => dispatch({ type: types.EDIT_PARTNER_SUCCESS, data: response }))
        .catch((error) => {
            dispatch({ type: types.EDIT_PARTNER_FAILURE, data: error });
            throw error;
        });
};

export const sendEmailToPartnerForRecommendation = (recommendationId, patientId) => (dispatch) => {
    const {
        auth: { accessToken },
        partners: { emailIsSending },
    } = store.getState();

    if (emailIsSending) {
        return Promise.reject(false);
    }
    dispatch({ type: types.PARTNERS_EMAIL_SEND_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/send-email-to-partner-for-recommendation" : "/dap-admin/api/v1/send-email-to-partner-for-recommendation",
        token: accessToken,
        method: "POST",
        data: {
            recommendationId: recommendationId.toString(),
            patientId: patientId.toString(),
        },
    })
        .then((response) => {
            toast.success("La demande du suivi partenaire a été envoyée avec succès.");
            dispatch({ type: types.PARTNERS_EMAIL_SEND_SUCCESS, data: response["hydra:member"] });
            return response;
        })
        .catch((error) => {
            toast.error("Une erreur s'est produite lors de l'envoi de la demande du suivi partenaire.");
            dispatch({ type: types.PARTNERS_EMAIL_SEND_FAILURE });
            return error;
        });
};

export const tryFetchCurrentPartnerCheckUps = (patientId) => (dispatch) => {
    const {
        auth: { accessToken },
        partners: { checkUps, checkUpsLoaded, checkUpsPatientId },
    } = store.getState();

    if (checkUpsLoaded && checkUpsPatientId === patientId) {
        return Promise.resolve(checkUps);
    }

    dispatch({ type: types.PARTNER_CHECK_UPS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/partner_check_ups" : "/dap-admin/api/v1/partner-check-ups",
        token: accessToken,
        queryParams: {
            "createdAt[after]": moment().subtract(11, "months").format("YYYY-MM-DD"),
            patient: patientId,
        },
    })
        .then((response) => dispatch({ type: types.PARTNER_CHECK_UPS_FETCH_SUCCESS, data: response["hydra:member"], patientId }))
        .catch((error) => dispatch({ type: types.PARTNER_CHECK_UPS_FETCH_FAILURE, data: error }));
};
