import React, {Fragment} from "react";
import PropTypes from "prop-types";

import {Select, Button, Icon, InputText, InputCheckbox} from "@rdcs/dap-front-library";
import { Link } from "react-router-dom";
import { constant } from "../../../utils/utils";

const InformationsForm = ({
    editedInfo : {
        fieldType: editedInfoType = "",
        label: editedInfoLabel = "",
        fieldLength: editedInfoLength = "short",
        hasOtherChoice: editedInfoHasOtherChoice = true,
        mandatory: editedInfoMandatory = false,
        answer1: editedInfoAnswer1 = "",
        answer2: editedInfoAnswer2 = "",
        answer3: editedInfoAnswer3 = "",
        answer4: editedInfoAnswer4 = "",
        answer5: editedInfoAnswer5 = "",
        answer6: editedInfoAnswer6 = "",
        answer7: editedInfoAnswer7 = "",
        answer8: editedInfoAnswer8 = "",
        answer9: editedInfoAnswer9 = "",
        answer10: editedInfoAnswer10 = "",
    } = {},
    questions = [],
    errors = {},
    onSubmit,
    location,
    isLoading
}) => {
    const [fieldType, setFieldType] = React.useState(editedInfoType);
    const [label, setLabel] = React.useState(editedInfoLabel);
    const [fieldLength, setFieldLength] = React.useState(editedInfoLength);
    const [hasOtherChoice, setHasOtherChoice] = React.useState(editedInfoHasOtherChoice);
    const [mandatory, setMandatory] = React.useState(editedInfoMandatory);
    const [answersList, setAnswersList] = React.useState({
        answer1: editedInfoAnswer1,
        answer2: editedInfoAnswer2,
        answer3: editedInfoAnswer3,
        answer4: editedInfoAnswer4,
        answer5: editedInfoAnswer5,
        answer6: editedInfoAnswer6,
        answer7: editedInfoAnswer7,
        answer8: editedInfoAnswer8,
        answer9: editedInfoAnswer9,
        answer10: editedInfoAnswer10,
    });

    let initialNbAnswers = 2
    let answerCount = 0
    if (answersList && Object.keys(answersList).length) {
        for (const [key, value] of Object.entries(answersList)) {
            if (value) {
                answerCount ++
            }
        }
        initialNbAnswers = answerCount || 2;
    }

    const [nbAnswers, setNbAnswers] = React.useState(initialNbAnswers);

    let answersObject = []
    for (let i = 1; i <= nbAnswers; i++) {
        answersObject.push(i)
    }

    const onSubmitCallback = event => {
        event.preventDefault();
        onSubmit({
            label,
            fieldType,
            fieldLength,
            hasOtherChoice,
            otherChoiceAnswer: "",
            active: true,
            mandatory,
            ...answersList
        });
    };

    return (
        <form onSubmit={onSubmitCallback}>
            <div className="grid grid__padding-10">
                <div className="col-100">
                    <Select
                        label="Type de question"
                        defaultValue={fieldType}
                        options={[
                            {value: "", label: "Choisissez un type de question"},
                            {value: "text", label: "Question à réponse libre"},
                            {value: "choices", label: "Question à choix multiples"}
                        ]}
                        onChange={val => setFieldType(val)}
                        required
                    />
                </div>
                {fieldType && (
                    <div className="col-100">
                        <InputText
                            label="Intitulé de la question"
                            placeholder="Saisissez l'intitulé de la question"
                            name="firstName"
                            error={null}
                            defaultValue={label}
                            required
                            onChange={val => setLabel(val)}
                        />
                    </div>
                )}
                {fieldType === "text" && (
                    <div className="col-100">
                        <Select
                            label="Type de réponse"
                            defaultValue={fieldLength}
                            options={[
                                {value: "short", label: "Réponse courte (un mot)"},
                                {value: "long", label: "Réponse longue (une ou plusieurs phrases)"}
                            ]}
                            onChange={val => setFieldLength(val)}
                            required
                        />
                    </div>
                )}
                {fieldType === "choices" && (
                    <Fragment>
                        <div className="col-100">
                            <Select
                                className=""
                                label="Nombre de réponses"
                                defaultValue={nbAnswers}
                                options={[
                                    {value: 2, label: "2"},
                                    {value: 3, label: "3"},
                                    {value: 4, label: "4"},
                                    {value: 5, label: "5"},
                                    {value: 6, label: "6"},
                                    {value: 7, label: "7"},
                                    {value: 8, label: "8"},
                                    {value: 9, label: "9"},
                                    {value: 10, label: "10"},
                                ]}
                                onChange={val => setNbAnswers(val)}
                                required
                            />
                        </div>
                        {answersObject.map((i) => (
                            <div className="col-100" key={i}>
                                <InputText
                                    key={i}
                                    label={'Réponse ' + i}
                                    placeholder="Saisissez l'intitulé de la réponse"
                                    name={'answer' + i}
                                    error={null}
                                    defaultValue={answersList['answer' + i]}
                                    required
                                    onChange={val => {
                                        setAnswersList(prev => ({
                                            ...prev,
                                            [`answer${i}`] : val
                                        }))
                                    }}
                                />
                            </div>
                            )
                        )}
                        <div className="col-100">
                            <InputCheckbox
                                label='Ajouter une possibilité "Autre"'
                                defaultChecked={hasOtherChoice}
                                onChange={val => setHasOtherChoice(val)}
                            />
                        </div>
                    </Fragment>
                )}
                {fieldType && (
                    <div className="col-100">
                        <InputCheckbox
                            label="Question facultative pour le médecin (si non coché, une réponse du médecin sera requise pour passer à l'étape suivante)"
                            defaultChecked={!mandatory}
                            onChange={val => setMandatory(!val)}
                        />
                    </div>
                )}
            </div>
            <div className="form__group col-100">
                <Link
                    to={{
                        pathname: "/configuration",
                        state: { from: location },
                        tab: "informations-questions"
                    }}
                >
                    <Button className="button__secondaryButton">
                        <Icon name="arrow-left" className="mr-1" /> Retour
                    </Button>
                </Link>
                <Button type="submit" className="button__primaryButton" isLoading={isLoading}>
                    Enregistrer
                </Button>
            </div>
        </form>
    );
};

InformationsForm.propTypes = {
    // editedTreatmentGroup: PropTypes.shape({
    //     levelOne: PropTypes.shape({
    //         "@id": PropTypes.string.isRequired
    //     }),
    //     levelTwo: PropTypes.shape({
    //         "@id": PropTypes.string.isRequired
    //     }),
    //     levelThree: PropTypes.shape({
    //         "@id": PropTypes.string.isRequired
    //     }),
    //     asgGoal: PropTypes.number,
    //     asgGoalUnit: PropTypes.string
    // }),
    // treatments: PropTypes.arrayOf(
    //     PropTypes.shape({
    //         "@id": PropTypes.string.isRequired,
    //         name: PropTypes.string.isRequired
    //     })
    // ).isRequired,
    // errors: PropTypes.shape({
    //     levelOne: PropTypes.string,
    //     levelTwo: PropTypes.string,
    //     levelThree: PropTypes.string,
    //     asgGoal: PropTypes.string,
    //     asgGoalUnit: PropTypes.string
    // }),
    // onSubmit: PropTypes.func.isRequired,
    // location: PropTypes.any,
    // isLoading: PropTypes.bool.isRequired
};

export default InformationsForm;
