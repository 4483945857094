import React from "react";
import PropTypes from 'prop-types';

import {
    InputDateTime,
    InputNumber,
    InputRadio,
    InputText,
    InputTel,
    InputEmail,
    InputCheckbox,
    Button,
    Form
} from "@rdcs/dap-front-library";

const PatientsAddForm = ({
    defaultSsn,
    firstName: firstNameProps = '',
    lastName: lastNameProps = '',
    email: emailProps = '',
    dateOfBirth: dateOfBirthProps,
    sex: sexProps = '',
    phoneNumber: phoneNumberProps = '',
    alternativePhoneNumber: alternativePhoneNumberProps = '',
    submit,
    violations,
    gigyaProfile,
    loading
}) => {
    const disabledSsn = React.useMemo(() => Boolean(defaultSsn), [defaultSsn]);
    const sexOptions = React.useMemo(() => ([
        { label: "Homme", value: "male" },
        { label: "Femme", value: "female" }
    ]), []);
    const diabetesTypeOptions = React.useMemo(() => ([
        { label: "DT1", value: "DIABETES_TYPE1" },
        { label: "DT2", value: "DIABETES_TYPE2" },
        { label: "Gestationnel", value: "DIABETES_GESTATIONAL" }
    ]), []);

    const handleSubmit = form => {
        submit({
            ...form,
            alternativePhoneNumber: form.alternativePhoneNumber || null,
            emailNotifications: form.emailNotifications || false,
            smsNotifications: form.smsNotifications || false,
        });
    }

    return (
        <Form onSubmit={handleSubmit} className="card">
            <div className="grid grid__padding-10">
                <div className="col-xs-50">
                    <InputRadio
                        label="Sexe du patient"
                        options={sexOptions}
                        defaultValue={sexProps}
                        error={violations.sex}
                        name='sex'
                        required
                    />
                </div>
                <div className="col-xs-50">
                    <InputRadio
                        label="Type de diabète"
                        options={diabetesTypeOptions}
                        error={violations.diabetesType}
                        name='diabetesType'
                        required
                    />
                </div>
                <div className="col-xs-50">
                    <InputText
                        label="Nom du patient"
                        placeholder="Saisissez le nom du patient"
                        name="lastName"
                        defaultValue={lastNameProps}
                        error={violations.lastName}
                        required
                    />
                </div>
                <div className="col-xs-50">
                    <InputText
                        label="Prénom du patient"
                        placeholder="Saisissez le prénom du patient"
                        name="firstName"
                        defaultValue={firstNameProps}
                        error={violations.firstName}
                        required
                    />
                </div>
            </div>
            <div className="grid grid__padding-10">
                <div className="col-xs-50">
                    <InputNumber
                        label="Numéro de sécurité sociale du patient"
                        placeholder="Saisissez le numéro de 13 chiffres"
                        length={13}
                        name="ssn"
                        defaultValue={disabledSsn ? parseInt(defaultSsn) : undefined}
                        disabled={disabledSsn}
                        error={violations.ssn}
                        required
                    />
                </div>
                <div className="col-xs-50">
                    <InputDateTime
                        label="Date de naissance du patient"
                        name='dateOfBirth'
                        defaultValue={dateOfBirthProps}
                        error={violations.dateOfBirth}
                        maxDate={new Date()}
                        placeholder="jj/mm/aaaa"
                        required
                    />
                </div>
            </div>
            <InputEmail
                label="Email du patient"
                legend="Veuillez vous assurer de l'exactitude de l'adresse mail du patient"
                placeholder="Saisissez l'email du patient"
                defaultValue={emailProps}
                name='email'
                disabled={Boolean(gigyaProfile?.email)}
                error={violations.email}
                required
            />
            <InputTel
                label="Numéro de téléphone du patient"
                placeholder="Saisissez le numéro de téléphone du patient"
                name='phoneNumber'
                error={violations.phoneNumber}
                defaultValue={phoneNumberProps}
                required
            />
            <InputTel
                label="Numéro de téléphone alternatif (famille/proche)"
                placeholder="Saisissez le numéro de téléphone alternatif"
                name='alternativePhoneNumber'
                error={violations.alternativePhoneNumber}
                defaultValue={alternativePhoneNumberProps}
            />
            <InputCheckbox
                label="Recevoir les notifications SMS (modifiable plus tard)"
                error={violations.smsNotifications}
                name='emailNotifications'
            />
            <InputCheckbox
                label="Recevoir les notifications par email (modifiable plus tard)"
                error={violations.emailNotifications}
                name='smsNotifications'
            />
            <Button className="button__primaryButton" isLoading={loading}>
                Enregistrer
            </Button>
        </Form>
    );
}

PatientsAddForm.propTypes = {
    defaultSsn: PropTypes.number.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    dateOfBirth: PropTypes.instanceOf(Date),
    sex: PropTypes.string,
    phoneNumber: PropTypes.string,
    submit: PropTypes.func.isRequired,
    violations: PropTypes.object,
    gigyaProfile: PropTypes.shape({
        email: PropTypes.string.isRequired,
    }),
    loading: PropTypes.bool.isRequired,
}

export default PatientsAddForm;
