import React from "react";
import PropTypes from "prop-types";

import { ExamensCard } from "@rdcs/dap-front-library";

const ExamensCards = ({
    examens,
    patientIri,
    searchPractitionerAction,
    createAppointmentLoading,
    createAppointmentAction,
    //disableCreateAppointment,
    confirmAppointmentAction,
}) => {
    const examensContainer = [];
    let examensLine;

    for (const index in examens) {
        const { id, recommendation, dueAt, appointment, validationNeeded } = examens[index];

        if (index % 2 === 0) {
            examensLine = [];
            examensContainer.push(
                <div className="grid grid__padding-25" key={examensContainer.length}>
                    {examensLine}
                </div>
            );
        }
        examensLine.push(
            <div key={id} className="col-md-50 col-sm-100 pt-2">
                <ExamensCard
                    recommendation={recommendation}
                    dueAt={dueAt}
                    appointment={appointment}
                    validationNeeded={validationNeeded}
                    patientIri={patientIri}
                    exams={examens.filter(({ recommendation: { consultationName } }) => consultationName === recommendation.title)}
                    searchPractitionerAction={searchPractitionerAction}
                    createAppointmentLoading={createAppointmentLoading}
                    createAppointmentAction={createAppointmentAction}
                    //disableCreateAppointment={disableCreateAppointment}
                    confirmAppointmentAction={confirmAppointmentAction}
                />
            </div>
        );
    }
    return examensContainer;
};

ExamensCards.propTypes = {
    examens: PropTypes.arrayOf(PropTypes.object).isRequired,
    patientIri: PropTypes.string,
    searchPractitionerAction: PropTypes.func.isRequired,
    createAppointmentLoading: PropTypes.bool.isRequired,
    createAppointmentAction: PropTypes.func.isRequired,
    //disableCreateAppointment: PropTypes.bool.isRequired,
    confirmAppointmentAction: PropTypes.func.isRequired,
};

export default React.memo(ExamensCards);
