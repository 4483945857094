import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import moment from "moment";

import { Button, TextArea, Select, ModalSelectPractitioner, Icon, PractitionerInfo, ButtonUpload } from "@rdcs/dap-front-library";
import API from "../../../../utils/API";
import { isLocalAPI } from "../../../../utils/utils";
import useState from "./useState";
import { toast } from "react-toastify";

const PatientNoteForm = ({ patientId, note = {}, onSubmit, isLoading, errors, partners = [], access_token, user }) => {
    const [{ content, attachment, partner, performedBy }, { setContent, setAttachment, setPartner, setPerformedByTmp, setPerformedBy }] = useState(note);

    const gRecaptchaRef = React.createRef();

    const partnersOptions = React.useMemo(
        () => [
            { label: "", value: "" },
            ...partners.map((option) => ({
                key: option.id,
                id: option.id,
                label: option.name,
                value: option["@id"],
            })),
        ],
        [partners]
    );

    const uploadNotesDocumentAction = React.useCallback(
        (file) =>
            API.uploadFile(file)
                .then((item) => setAttachment(item["@id"]))
                .catch(() => {
                    setAttachment(null);
                    throw new Error("L'upload a échoué pour une raison inconnue");
                }),
        []
    );

    return (
        <div className="addNoteForm">
            <form
                onSubmit={(e) => {
                    e.preventDefault();

                    const formValues = {
                        content: content.trim(),
                        attachment: isLocalAPI ? null : attachment,
                        patient: `/patients/${patientId}`,
                    };

                    if (partners.length > 0) {
                        if (performedBy === null) {
                            toast.error("Vous devez sélectionner un practitioner.");
                            return;
                        }
                        formValues.partner = partner;
                        formValues.performedBy = performedBy["@id"];
                    }

                    onSubmit(formValues).then(() => setContent(""));
                }}
            >
                <h1 className="timkl__color--blue-dark2 title-1-5 text-center">Ajouter une note</h1>
                <div className="d-flex justify-space-between align-items-center">
                    <PractitionerInfo
                        color="#F08150"
                        date={moment("11/03/2022", "DD/MM/YYYY")}
                        firstname={user.firstName}
                        lastname={user.lastName}
                        role={user.profession}
                    />
                    <ButtonUpload
                        className="timkl__color--blue-important"
                        uploadAction={uploadNotesDocumentAction}
                        gRecaptchaRef={gRecaptchaRef}
                        icon="document-pdf"
                        secondary
                    >
                        Joindre un fichier
                    </ButtonUpload>
                </div>
                <TextArea
                    // label="Intitulé du compte-rendu"
                    placeholder="Saisissez un commentaire si vous le souhaitez"
                    error={errors.content}
                    value={content}
                    onChange={setContent}
                    required
                />
                {partners.length > 0 ? <Select label="Choisir le partenaire*" onChange={setPartner} options={partnersOptions} required /> : null}
                <div className={`modal__btnGroup modal__btnGroup--small ${partners.length > 0 ? "addNoteForm__submit" : ""}`}>
                    <Button className="button__primaryButton" type="submit" isLoading={isLoading}>
                        Enregistrer la note
                    </Button>
                </div>
            </form>
            {partners.length > 0 ? (
                <ModalSelectPractitioner
                    buttonClassName="addNoteForm__selectPractitionerButton modal__btnGroup--small"
                    searchAction={(params) => API.searchInRpps(params)}
                    onChange={setPerformedByTmp}
                    onSubmit={(e) => {
                        e.preventDefault();
                        setPerformedBy();
                    }}
                    labels={{
                        title: "Voulez-vous sélectionner ce médecin généraliste ?",
                        buttonModal: (
                            <span>
                                Sélectionner le professionnel de santé <Icon name="arrow-right" size={20} style={{ position: "relative", top: "4px" }} />
                            </span>
                        ),
                        buttonSave: "Sélectionner",
                    }}
                />
            ) : null}
        </div>
    );
};

PatientNoteForm.propTypes = {
    patientId: PropTypes.number.isRequired,
    note: PropTypes.shape({
        content: PropTypes.string.isRequired,
        attachment: PropTypes.shape({
            "@id": PropTypes.string.isRequired,
            filename: PropTypes.string.isRequired,
        }),
    }),
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    partners: PropTypes.arrayOf(
        PropTypes.shape({
            "@id": PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            createdAt: PropTypes.string.isRequired,
            updatedAt: PropTypes.string,
        })
    ),
    access_token: PropTypes.string.isRequired,
};

const mapStateToProps = ({ auth: { access_token, user } }) => ({ access_token, user });

export default connect(mapStateToProps)(PatientNoteForm);
