import * as types from "./types";
import { toast } from "react-toastify";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import store from "../store";

export const tryCreateQuestionnairePatient = (data) => (dispatch) => {
    const {
        auth: { accessToken },
    } = store.getState();

    dispatch({ type: types.ADD_QUESTIONNAIRE_PATIENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/questionnaires" : "/dap-admin/api/v1/customers/patients/questionnaires",
        token: accessToken,
        method: "POST",
        data: data,
    })
        .then((response) => {
            toast.success(`Le questionnaire a bien été créé`);
            dispatch({
                type: types.ADD_QUESTIONNAIRE_PATIENT_SUCCESS,
                data: response,
            });
            return response;
        })
        .catch((error) => {
            toast.error(`Une erreur est survenue lors de la création du questionnaire`);
            dispatch({
                type: types.ADD_QUESTIONNAIRE_PATIENT_FAILURE,
                data: error,
            });
            throw error;
        });
};

export const tryQuestionnairePatient = (data) => (dispatch) => {
    const {
        auth: { accessToken },
    } = store.getState();

    const actionPrefix = `${data.type.toUpperCase()}_ANSWERS`;
    dispatch({ type: `${actionPrefix}_FETCH_REQUESTED` });

    return API.request({
        path: isLocalAPI ? "/questionnaires" : "/dap-admin/api/v1/customers/patients/questionnaires",
        token: accessToken,
        method: "GET",
        queryParams: {
            "order[createdAt]": "desc",
            "patient.id": data.patientId,
            type: data.type,
            itemsPerPage: 1,
            completed: 1,
        },
    })
        .then((response) => {
            const questionnaireList = response["hydra:member"];
            let data = [];
            if (questionnaireList[0]) {
                data = questionnaireList[0].answers;
            }
            dispatch({ type: `${actionPrefix}_FETCH_SUCCESS`, data: data });
            return response;
        })
        .catch((error) => {
            dispatch({ type: `${actionPrefix}_FETCH_FAILURE`, error });
            throw error;
        });
};

export const try30QuestionnairePatient = (data) => (dispatch) => {
    const {
        auth: { accessToken },
    } = store.getState();

    const actionPrefix = `${data.type.toUpperCase()}_ANSWERS`;
    dispatch({ type: `${actionPrefix}_FETCH_REQUESTED` });

    return API.request({
        path: isLocalAPI ? "/questionnaires" : "/dap-admin/api/v1/customers/patients/questionnaires",
        token: accessToken,
        method: "GET",
        queryParams: {
            "order[createdAt]": "desc",
            "patient.id": data.patientId,
            type: data.type,
            itemsPerPage: 30,
            completed: 1,
        },
    })
        .then((response) => {
            const questionnaireList = response["hydra:member"];
            let data = [];
            if (questionnaireList.length > 0) {
                for (let i = 0; i < questionnaireList.length; i++) {
                    data.push(questionnaireList[i].answers);
                }
            }
            dispatch({ type: `${actionPrefix}_FETCH_SUCCESS`, data: data });
            return response;
        })
        .catch((error) => {
            dispatch({ type: `${actionPrefix}_FETCH_FAILURE`, error });
            throw error;
        });
};

export const tryDeleteQuestionnairePatient = (questionnaireId) => (dispatch) => {
    const {
        auth: { accessToken },
    } = store.getState();

    dispatch({ type: types.DELETE_QUESTIONNAIRE_PATIENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/questionnaires/{id}" : "/dap-admin/api/v1/customers/patients/questionnaires/{id}",
        token: accessToken,
        method: "DELETE",
        uriParams: {
            id: questionnaireId,
        },
    })
        .then((response) => {
            toast.success(`Le questionnaire a bien été supprimé`);
            return response;
        })
        .catch((error) => {
            toast.error(`Une erreur est survenue lors de la suppression du questionnaire`);
            throw error;
        });
};
