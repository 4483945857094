import * as types from "../actions/types";
import Base64 from "base-64";
import { toast } from "react-toastify";
import store from "../store";

export const allRoles = ["ROLE_ADMIN", "ROLE_MODERATOR", "ROLE_COORDINATOR"];

const initialState = {
    accessToken: localStorage.getItem("access_token") || null,
    refreshToken: localStorage.getItem("refresh_token") || null,
    refreshTokenLoading: false,
    user: parseJwt(localStorage.getItem("access_token")),
    loginLoading: false,
    loginError: false,
    loginCodeLoading: false,
    loginCodeError: false,
    requestPasswordLoading: false,
    requestPasswordSent: false,
    requestPasswordError: false,
    resetPasswordLoading: false,
    resetPasswordError: false,
    resetPasswordSaved: false,
    searchBySsnLoading: false,
    searchBySsnResult: null
};

export function parseJwt(token) {
    try {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        const user = JSON.parse(Base64.decode(base64));

        // Check if token expired
        // if (Math.floor(new Date().getTime() / 1000) > user.exp) return null;

        return allRoles.filter(role => user.roles.includes(role)).length > 0 ? user : null;
    } catch (error) {
        return null;
    }
}

export const userCan = roles => {
    const rolesArray = roles instanceof String ? [roles] : roles;
    const userRoles = store.getState().auth.user ? store.getState().auth.user.roles : [];
    for (let i = 0; i < userRoles.length; i++) {
        if (rolesArray.includes(userRoles[i])) return true;
    }
    return false;
};

function auth(state = initialState, action) {
    switch (action.type) {
        // LOGIN
        case types.LOGIN_REQUESTED: {
            return Object.assign({}, state, {
                loginLoading: true,
                loginError: false
            });
        }
        case types.LOGIN_SUCCESS: {
            return Object.assign({}, state, {
                loginLoading: false
            });
        }
        case types.LOGIN_FAILURE: {
            return Object.assign({}, state, {
                loginLoading: false,
                loginError: action.data
            });
        }
        // LOGIN CODE
        case types.LOGIN_CODE_REQUESTED: {
            return Object.assign({}, state, {
                loginCodeLoading: true,
                loginCodeError: false
            });
        }
        case types.LOGIN_CODE_SUCCESS: {
            return Object.assign({}, state, {
                loginCodeLoading: false,
                accessToken: action.data.access_token,
                refreshToken: action.data.refresh_token,
                user: action.data.user
            });
        }
        case types.LOGIN_CODE_FAILURE: {
            return Object.assign({}, state, {
                loginCodeLoading: false,
                accessToken: null,
                refreshToken: null,
                user: null,
                loginCodeError: action.data
            });
        }
        // Regenerate token
        case types.REFRESH_TOKEN_REQUESTED: {
            return Object.assign({}, state, {
                refreshTokenLoading: true
            });
        }
        case types.REFRESH_TOKEN_SUCCESS: {
            return Object.assign({}, state, {
                accessToken: action.data.access_token,
                refreshToken: action.data.refresh_token,
                user: action.data.user,
                refreshTokenLoading: false
            });
        }
        case types.REFRESH_TOKEN_FAILURE: {
            return Object.assign({}, state, {
                refreshTokenLoading: false
            });
        }
        // RESET PASSWORD
        case types.REQUEST_PASSWORD_REQUESTED: {
            return Object.assign({}, state, {
                requestPasswordLoading: true,
                requestPasswordSent: false,
                requestPasswordError: false
            });
        }
        case types.REQUEST_PASSWORD_SUCCESS: {
            toast.success("Un lien de réinitialisation vous a été envoyé par email");
            return Object.assign({}, state, {
                requestPasswordLoading: false,
                requestPasswordSent: true,
                requestPasswordError: false
            });
        }
        case types.REQUEST_PASSWORD_FAILURE: {
            return Object.assign({}, state, {
                accessToken: null,
                refreshToken: null,
                requestPasswordLoading: false,
                requestPasswordSent: false,
                requestPasswordError: action.data
            });
        }
        // NEW PASSWORD
        case types.RESET_PASSWORD_REQUESTED: {
            return Object.assign({}, state, {
                resetPasswordLoading: true,
                resetPasswordError: false
            });
        }
        case types.RESET_PASSWORD_SUCCESS: {
            toast.success("Nouveau mot de passe enregistré");
            return Object.assign({}, state, {
                resetPasswordLoading: false,
                resetPasswordSaved: true,
                resetPasswordError: false
            });
        }
        case types.RESET_PASSWORD_FAILURE: {
            return Object.assign({}, state, {
                accessToken: null,
                refreshToken: null,
                resetPasswordLoading: false,
                resetPasswordSaved: false,
                resetPasswordError: action.data
            });
        }
        // Search patient by ssn
        case types.GIGYA_SEARCH_BY_SSN_REQUESTED: {
            return Object.assign({}, state, {
                searchBySsnLoading: true,
                searchBySsnResult: null
            });
        }
        case types.GIGYA_SEARCH_BY_SSN_SUCCESS: {
            return Object.assign({}, state, {
                searchBySsnLoading: false,
                searchBySsnResult: action.data
            });
        }
        case types.GIGYA_SEARCH_BY_SSN_FAILURE: {
            return Object.assign({}, state, {
                searchBySsnLoading: false
            });
        }
        // LOGOUT
        case types.LOGOUT_REQUESTED: {
            return Object.assign({}, state, { loading: true });
        }
        case types.LOGOUT_SUCCESS: {
            toast.info("Vous avez été déconnecté");
            return Object.assign({}, state, {
                accessToken: null,
                refreshToken: null,
                user: null,
                loading: false
            });
        }
        default:
            return state;
    }
}

export default auth;
