import moment from 'moment';

export const parseMissingSegments = (currentSegments, segments, step = 1) => {
  const [cBegin, cEnd] = currentSegments;
  const missingSegments = [];

  if (segments.length === 0) {
    missingSegments.push(currentSegments);
  }
  else {
    let i = 1;

    if (cBegin < segments[0][0]) {
      missingSegments.push([cBegin, segments[0][0] - step]);
    }
    while (i < segments.length) {
      missingSegments.push([segments[i - 1][1] + step, segments[i][0] - step]);
      ++i;
    }
    if (cEnd > segments[i - 1][1]) {
      missingSegments.push([segments[i - 1][1], cEnd - step]);
    }
  }
  return missingSegments;
};

export const hasIntersection = (seg1, seg2, step = 1) => {
    if (seg1[0] <= seg2[0]) {
        return Boolean((seg1[1] + step) >= seg2[0]);
    }
    return Boolean((seg1[0] - step) <= seg2[1]);
}

export const mergeSegments = (oldList, cDatas, cBegin, cEnd, step = 1) => {
  const list = [];
  const newMergeItem = { datas: cDatas, begin: cBegin, end: cEnd };
  
  for (const oldItem of oldList) {
    if (hasIntersection([oldItem.begin, oldItem.end], [newMergeItem.begin, newMergeItem.end], step)) {
      newMergeItem.datas = [...newMergeItem.datas, ...oldItem.datas];
      newMergeItem.begin = Math.min(oldItem.begin, newMergeItem.begin);
      newMergeItem.end = Math.max(oldItem.end, newMergeItem.end);
    }
    else {
      list.push(oldItem);
    }
  }
  newMergeItem.datas.sort((a, b) => moment(b.createdAt) - moment(a.createdAt));
  list.push(newMergeItem);
  list.sort((a, b) => a.begin - b.begin);
  return [list, newMergeItem.datas];
}