/* AUTH */
export const LOGIN_REQUESTED = "LOGIN_REQUESTED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_CODE_REQUESTED = "LOGIN_CODE_REQUESTED";
export const LOGIN_CODE_SUCCESS = "LOGIN_CODE_SUCCESS";
export const LOGIN_CODE_FAILURE = "LOGIN_CODE_FAILURE";

export const REFRESH_TOKEN_REQUESTED = "REFRESH_TOKEN_REQUESTED";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";

export const LOGOUT_REQUESTED = "LOGOUT_REQUESTED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const REQUEST_PASSWORD_REQUESTED = "REQUEST_PASSWORD_REQUESTED";
export const REQUEST_PASSWORD_SUCCESS = "REQUEST_PASSWORD_SUCCESS";
export const REQUEST_PASSWORD_FAILURE = "REQUEST_PASSWORD_FAILURE";

export const RESET_PASSWORD_REQUESTED = "RESET_PASSWORD_REQUESTED";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const GIGYA_SEARCH_BY_SSN_REQUESTED = "GIGYA_SEARCH_BY_SSN_REQUESTED";
export const GIGYA_SEARCH_BY_SSN_SUCCESS = "GIGYA_SEARCH_BY_SSN_SUCCESS";
export const GIGYA_SEARCH_BY_SSN_FAILURE = "GIGYA_SEARCH_BY_SSN_FAILURE";

/* RECOMMENDATIONS */
export const RECOMMENDATIONS_FETCH_REQUESTED = "RECOMMENDATIONS_FETCH_REQUESTED";
export const RECOMMENDATIONS_FETCH_SUCCESS = "RECOMMENDATIONS_FETCH_SUCCESS";
export const RECOMMENDATIONS_FETCH_FAILURE = "RECOMMENDATIONS_FETCH_FAILURE";

export const RECOMMENDATIONS_CREATE_REQUESTED = "RECOMMENDATIONS_CREATE_REQUESTED";
export const RECOMMENDATIONS_CREATE_SUCCESS = "RECOMMENDATIONS_CREATE_SUCCESS";
export const RECOMMENDATIONS_CREATE_FAILURE = "RECOMMENDATIONS_CREATE_FAILURE";
export const RECOMMENDATIONS_CREATE_CLEAR = "RECOMMENDATIONS_CREATE_CLEAR";

export const RECOMMENDATIONS_EDIT_REQUESTED = "RECOMMENDATIONS_EDIT_REQUESTED";
export const RECOMMENDATIONS_EDIT_SUCCESS = "RECOMMENDATIONS_EDIT_SUCCESS";
export const RECOMMENDATIONS_EDIT_FAILURE = "RECOMMENDATIONS_EDIT_FAILURE";
export const RECOMMENDATIONS_EDIT_CLEAR = "RECOMMENDATIONS_EDIT_CLEAR";

export const RECOMMENDATIONS_DELETE_REQUESTED = "RECOMMENDATIONS_DELETE_REQUESTED";
export const RECOMMENDATIONS_DELETE_SUCCESS = "RECOMMENDATIONS_DELETE_SUCCESS";
export const RECOMMENDATIONS_DELETE_FAILURE = "RECOMMENDATIONS_DELETE_FAILURE";

export const RECOMMENDATIONS_DISABLE_SUCCESS = "RECOMMENDATIONS_DISABLE_SUCCESS";
export const RECOMMENDATIONS_DISABLE_FAILURE = "RECOMMENDATIONS_DISABLE_FAILURE";

export const RECOMMENDATIONS_ENABLE_SUCCESS = "RECOMMENDATIONS_ENABLE_SUCCESS";
export const RECOMMENDATIONS_ENABLE_FAILURE = "RECOMMENDATIONS_ENABLE_FAILURE";

export const RECOMMENDATIONS_ALL_DEFAULT_FETCH_SUCCESS = "RECOMMENDATIONS_ALL_DEFAULT_FETCH_SUCCESS";
export const RECOMMENDATIONS_ALL_DEFAULT_FETCH_FAILURE = "RECOMMENDATIONS_ALL_DEFAULT_FETCH_FAILURE";

export const RECOMMENDATIONS_ALL_DEFAULT_DISABLE_SUCCESS = "RECOMMENDATIONS_ALL_DEFAULT_DISABLE_SUCCESS";
export const RECOMMENDATIONS_ALL_DEFAULT_DISABLE_FAILURE = "RECOMMENDATIONS_ALL_DEFAULT_DISABLE_FAILURE";

export const RECOMMENDATIONS_ALL_DEFAULT_ENABLE_SUCCESS = "RECOMMENDATIONS_ALL_DEFAULT_ENABLE_SUCCESS";
export const RECOMMENDATIONS_ALL_DEFAULT_ENABLE_FAILURE = "RECOMMENDATIONS_ALL_DEFAULT_ENABLE_FAILURE";

/* TREATMENTS */
export const TREATMENT_GROUPS_FETCH_REQUESTED = "TREATMENT_GROUPS_FETCH_REQUESTED";
export const TREATMENT_GROUPS_FETCH_SUCCESS = "TREATMENT_GROUPS_FETCH_SUCCESS";
export const TREATMENT_GROUPS_FETCH_FAILURE = "TREATMENT_GROUPS_FETCH_FAILURE";

export const TREATMENTS_FETCH_REQUESTED = "TREATMENTS_FETCH_REQUESTED";
export const TREATMENTS_FETCH_SUCCESS = "TREATMENTS_FETCH_SUCCESS";
export const TREATMENTS_FETCH_FAILURE = "TREATMENTS_FETCH_FAILURE";

export const ADD_TREATMENT_GROUP_REQUESTED = "ADD_TREATMENT_GROUP_REQUESTED";
export const ADD_TREATMENT_GROUP_SUCCESS = "ADD_TREATMENT_GROUP_SUCCESS";
export const ADD_TREATMENT_GROUP_FAILURE = "ADD_TREATMENT_GROUP_FAILURE";

export const EDIT_TREATMENT_GROUP_REQUESTED = "EDIT_TREATMENT_GROUP_REQUESTED";
export const EDIT_TREATMENT_GROUP_SUCCESS = "EDIT_TREATMENT_GROUP_SUCCESS";
export const EDIT_TREATMENT_GROUP_FAILURE = "EDIT_TREATMENT_GROUP_FAILURE";

export const ACTIVATE_TREATMENT_REQUESTED = "ACTIVATE_TREATMENT_REQUESTED";
export const ACTIVATE_TREATMENT_SUCCESS = "ACTIVATE_TREATMENT_SUCCESS";
export const ACTIVATE_TREATMENT_FAILURE = "ACTIVATE_TREATMENT_FAILURE";

export const SWAP_TREATMENT_ORDER_REQUESTED = "SWAP_TREATMENT_ORDER_REQUESTED";
export const SWAP_TREATMENT_ORDER_SUCCESS = "SWAP_TREATMENT_ORDER_SUCCESS";
export const SWAP_TREATMENT_ORDER_FAILURE = "SWAP_TREATMENT_ORDER_FAILURE";

/* INFORMATIONS QUESTIONS */
export const INFORMATIONS_QUESTIONS_FETCH_REQUESTED = "INFORMATIONS_QUESTIONS_FETCH_REQUESTED";
export const INFORMATIONS_QUESTIONS_FETCH_SUCCESS = "INFORMATIONS_QUESTIONS_FETCH_SUCCESS";
export const INFORMATIONS_QUESTIONS_FETCH_FAILURE = "INFORMATIONS_QUESTIONS_FETCH_FAILURE";

export const ADD_INFORMATIONS_QUESTIONS_REQUESTED = "ADD_INFORMATIONS_QUESTIONS_REQUESTED";
export const ADD_INFORMATIONS_QUESTIONS_SUCCESS = "ADD_INFORMATIONS_QUESTIONS_SUCCESS";
export const ADD_INFORMATIONS_QUESTIONS_FAILURE = "ADD_INFORMATIONS_QUESTIONS_FAILURE";

export const EDIT_INFORMATIONS_QUESTIONS_REQUESTED = "EDIT_INFORMATIONS_QUESTIONS_REQUESTED";
export const EDIT_INFORMATIONS_QUESTIONS_SUCCESS = "EDIT_INFORMATIONS_QUESTIONS_SUCCESS";
export const EDIT_INFORMATIONS_QUESTIONS_FAILURE = "EDIT_INFORMATIONS_QUESTIONS_FAILURE";

export const ACTIVATE_INFORMATIONS_QUESTION_REQUESTED = "ACTIVATE_INFORMATIONS_QUESTION_REQUESTED";
export const ACTIVATE_INFORMATIONS_QUESTION_SUCCESS = "ACTIVATE_INFORMATIONS_QUESTION_SUCCESS";
export const ACTIVATE_INFORMATIONS_QUESTION_FAILURE = "ACTIVATE_INFORMATIONS_QUESTION_FAILURE";

export const SWAP_INFORMATIONS_QUESTIONS_ORDER_REQUESTED = "SWAP_INFORMATIONS_QUESTIONS_ORDER_REQUESTED";
export const SWAP_INFORMATIONS_QUESTIONS_ORDER_SUCCESS = "SWAP_INFORMATIONS_QUESTIONS_ORDER_SUCCESS";
export const SWAP_INFORMATIONS_QUESTIONS_ORDER_FAILURE = "SWAP_INFORMATIONS_QUESTIONS_ORDER_FAILURE";

/* COMPLICATIONS */
export const COMPLICATIONS_FETCH_REQUESTED = "COMPLICATIONS_FETCH_REQUESTED";
export const COMPLICATIONS_FETCH_SUCCESS = "COMPLICATIONS_FETCH_SUCCESS";
export const COMPLICATIONS_FETCH_FAILURE = "COMPLICATIONS_FETCH_FAILURE";

export const ADD_COMPLICATION_REQUESTED = "ADD_COMPLICATION_REQUESTED";
export const ADD_COMPLICATION_SUCCESS = "ADD_COMPLICATION_SUCCESS";
export const ADD_COMPLICATION_FAILURE = "ADD_COMPLICATION_FAILURE";

export const EDIT_COMPLICATION_REQUESTED = "EDIT_COMPLICATION_REQUESTED";
export const EDIT_COMPLICATION_SUCCESS = "EDIT_COMPLICATION_SUCCESS";
export const EDIT_COMPLICATION_FAILURE = "EDIT_COMPLICATION_FAILURE";

export const ACTIVATE_COMPLICATION_REQUESTED = "ACTIVATE_COMPLICATION_REQUESTED";
export const ACTIVATE_COMPLICATION_SUCCESS = "ACTIVATE_COMPLICATION_SUCCESS";
export const ACTIVATE_COMPLICATION_FAILURE = "ACTIVATE_COMPLICATION_FAILURE";

export const SWAP_COMPLICATION_ORDER_REQUESTED = "SWAP_COMPLICATION_ORDER_REQUESTED";
export const SWAP_COMPLICATION_ORDER_SUCCESS = "SWAP_COMPLICATION_ORDER_SUCCESS";
export const SWAP_COMPLICATION_ORDER_FAILURE = "SWAP_COMPLICATION_ORDER_FAILURE";

/* ADMINS */
export const ADMINS_FETCH_REQUESTED = "ADMINS_FETCH_REQUESTED";
export const ADMINS_FETCH_SUCCESS = "ADMINS_FETCH_SUCCESS";
export const ADMINS_FETCH_FAILURE = "ADMINS_FETCH_FAILURE";

export const ADD_ADMIN_REQUESTED = "ADD_ADMIN_REQUESTED";
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS";
export const ADD_ADMIN_FAILURE = "ADD_ADMIN_FAILURE";

export const EDIT_ADMIN_REQUESTED = "EDIT_ADMIN_REQUESTED";
export const EDIT_ADMIN_SUCCESS = "EDIT_ADMIN_SUCCESS";
export const EDIT_ADMIN_FAILURE = "EDIT_ADMIN_FAILURE";

export const REMOVE_ADMIN_REQUESTED = "REMOVE_ADMIN_REQUESTED";
export const REMOVE_ADMIN_SUCCESS = "REMOVE_ADMIN_SUCCESS";
export const REMOVE_ADMIN_FAILURE = "REMOVE_ADMIN_FAILURE";

/* MODERATORS */
export const MODERATORS_FETCH_REQUESTED = "MODERATORS_FETCH_REQUESTED";
export const MODERATORS_FETCH_SUCCESS = "MODERATORS_FETCH_SUCCESS";
export const MODERATORS_FETCH_FAILURE = "MODERATORS_FETCH_FAILURE";

export const ADD_MODERATOR_REQUESTED = "ADD_MODERATOR_REQUESTED";
export const ADD_MODERATOR_SUCCESS = "ADD_MODERATOR_SUCCESS";
export const ADD_MODERATOR_FAILURE = "ADD_MODERATOR_FAILURE";

export const EDIT_MODERATOR_REQUESTED = "EDIT_MODERATOR_REQUESTED";
export const EDIT_MODERATOR_SUCCESS = "EDIT_MODERATOR_SUCCESS";
export const EDIT_MODERATOR_FAILURE = "EDIT_MODERATOR_FAILURE";

export const REMOVE_MODERATOR_REQUESTED = "REMOVE_MODERATOR_REQUESTED";
export const REMOVE_MODERATOR_SUCCESS = "REMOVE_MODERATOR_SUCCESS";
export const REMOVE_MODERATOR_FAILURE = "REMOVE_MODERATOR_FAILURE";

/* PARTNERS */
export const PARTNERS_FETCH_REQUESTED = "PARTNERS_FETCH_REQUESTED";
export const PARTNERS_FETCH_SUCCESS = "PARTNERS_FETCH_SUCCESS";
export const PARTNERS_FETCH_FAILURE = "PARTNERS_FETCH_FAILURE";

export const ADD_PARTNER_REQUESTED = "ADD_PARTNER_REQUESTED";
export const ADD_PARTNER_SUCCESS = "ADD_PARTNER_SUCCESS";
export const ADD_PARTNER_FAILURE = "ADD_PARTNER_FAILURE";

export const EDIT_PARTNER_REQUESTED = "EDIT_PARTNER_REQUESTED";
export const EDIT_PARTNER_SUCCESS = "EDIT_PARTNER_SUCCESS";
export const EDIT_PARTNER_FAILURE = "EDIT_PARTNER_FAILURE";

export const REMOVE_PARTNER_REQUESTED = "REMOVE_PARTNER_REQUESTED";
export const REMOVE_PARTNER_SUCCESS = "REMOVE_PARTNER_SUCCESS";
export const REMOVE_PARTNER_FAILURE = "REMOVE_PARTNER_FAILURE";

/* PROFILE */
export const PROFILE_FETCH_REQUESTED = "PROFILE_FETCH_REQUESTED";
export const PROFILE_FETCH_SUCCESS = "PROFILE_FETCH_SUCCESS";
export const PROFILE_FETCH_FAILURE = "PROFILE_FETCH_FAILURE";

export const PROFILE_EDIT_REQUESTED = "PROFILE_EDIT_REQUESTED";
export const PROFILE_EDIT_SUCCESS = "PROFILE_EDIT_SUCCESS";
export const PROFILE_EDIT_FAILURE = "PROFILE_EDIT_FAILURE";

export const PROFILE_EDIT_UPDATE = "PROFILE_EDIT_UPDATE";

/* MEDICALS */
export const MEDICALS_FETCH_REQUESTED = "MEDICALS_FETCH_REQUESTED";
export const MEDICALS_FETCH_SUCCESS = "MEDICALS_FETCH_SUCCESS";
export const MEDICALS_FETCH_FAILURE = "MEDICALS_FETCH_FAILURE";

export const PRACTITIONERS_FETCH_REQUESTED = "PRACTITIONERS_FETCH_REQUESTED";
export const PRACTITIONERS_FETCH_SUCCESS = "PRACTITIONERS_FETCH_SUCCESS";
export const PRACTITIONERS_FETCH_FAILURE = "PRACTITIONERS_FETCH_FAILURE";

export const MEDICALS_EDIT_REQUESTED = "MEDICALS_EDIT_REQUESTED";
export const MEDICALS_EDIT_SUCCESS = "MEDICALS_EDIT_SUCCESS";
export const MEDICALS_EDIT_FAILURE = "MEDICALS_EDIT_FAILURE";

export const MEDICALS_DELETE_REQUESTED = "MEDICALS_DELETE_REQUESTED";
export const MEDICALS_DELETE_SUCCESS = "MEDICALS_DELETE_SUCCESS";
export const MEDICALS_DELETE_FAILURE = "MEDICALS_DELETE_FAILURE";

export const MEDICALS_BAN_REQUESTED = "MEDICALS_BAN_REQUESTED";
export const MEDICALS_BAN_SUCCESS = "MEDICALS_BAN_SUCCESS";
export const MEDICALS_BAN_FAILURE = "MEDICALS_BAN_FAILURE";

export const MEDICALS_CREATE_FROM_RPPS_REQUESTED = "MEDICALS_CREATE_FROM_RPPS_REQUESTED";
export const MEDICALS_CREATE_FROM_RPPS_SUCCESS = "MEDICALS_CREATE_FROM_RPPS_SUCCESS";
export const MEDICALS_CREATE_FROM_RPPS_FAILURE = "MEDICALS_CREATE_FROM_RPPS_FAILURE";

/* MEASURES */
export const MEASURES_FETCH_REQUESTED = "MEASURES_FETCH_REQUESTED";
export const MEASURES_FETCH_SUCCESS = "MEASURES_FETCH_SUCCESS";
export const MEASURES_FETCH_FAILURE = "MEASURES_FETCH_FAILURE";

export const MEASURES_CREATE_REQUESTED = "MEASURES_CREATE_REQUESTED";
export const MEASURES_CREATE_SUCCESS = "MEASURES_CREATE_SUCCESS";
export const MEASURES_CREATE_FAILURE = "MEASURES_CREATE_FAILURE";

/* MEASURE TYPES */
export const MEASURE_TYPES_FETCH_REQUESTED = "MEASURE_TYPES_FETCH_REQUESTED";
export const MEASURE_TYPES_FETCH_SUCCESS = "MEASURE_TYPES_FETCH_SUCCESS";
export const MEASURE_TYPES_FETCH_FAILURE = "MEASURE_TYPES_FETCH_FAILURE";

/* STATISTICS */
export const STATISTICS_FETCH_REQUESTED = "STATISTICS_FETCH_REQUESTED";
export const STATISTICS_FETCH_SUCCESS = "STATISTICS_FETCH_SUCCESS";
export const STATISTICS_FETCH_FAILURE = "STATISTICS_FETCH_FAILURE";

export const TRACKING_BILLING_FETCH_REQUESTED = "TRACKING_BILLING_FETCH_REQUESTED";
export const TRACKING_BILLING_FETCH_SUCCESS = "TRACKING_BILLING_FETCH_SUCCESS";
export const TRACKING_BILLING_FETCH_FAILURE = "TRACKING_BILLING_FETCH_FAILURE";

/* COORDINATORS */
export const COORDINATORS_FETCH_REQUESTED = "COORDINATORS_FETCH_REQUESTED";
export const COORDINATORS_FETCH_SUCCESS = "COORDINATORS_FETCH_SUCCESS";
export const COORDINATORS_FETCH_FAILURE = "COORDINATORS_FETCH_FAILURE";

export const ADD_COORDINATOR_REQUESTED = "ADD_COORDINATOR_REQUESTED";
export const ADD_COORDINATOR_SUCCESS = "ADD_COORDINATOR_SUCCESS";
export const ADD_COORDINATOR_FAILURE = "ADD_COORDINATOR_FAILURE";

export const EDIT_COORDINATOR_REQUESTED = "EDIT_COORDINATOR_REQUESTED";
export const EDIT_COORDINATOR_SUCCESS = "EDIT_COORDINATOR_SUCCESS";
export const EDIT_COORDINATOR_FAILURE = "EDIT_COORDINATOR_FAILURE";

export const REMOVE_COORDINATOR_REQUESTED = "REMOVE_COORDINATOR_REQUESTED";
export const REMOVE_COORDINATOR_SUCCESS = "REMOVE_COORDINATOR_SUCCESS";
export const REMOVE_COORDINATOR_FAILURE = "REMOVE_COORDINATOR_FAILURE";

export const COORDINATORS_DEPARTMENTS_FETCH_REQUESTED = "COORDINATORS_DEPARTMENTS_FETCH_REQUESTED";
export const COORDINATORS_DEPARTMENTS_FETCH_SUCCESS = "COORDINATORS_DEPARTMENTS_FETCH_SUCCESS";
export const COORDINATORS_DEPARTMENTS_FETCH_FAILURE = "COORDINATORS_DEPARTMENTS_FETCH_FAILURE";

/* PATIENTS */
export const PATIENTS_FETCH_REQUESTED = "PATIENTS_FETCH_REQUESTED";
export const PATIENTS_FETCH_SUCCESS = "PATIENTS_FETCH_SUCCESS";
export const PATIENTS_FETCH_FAILURE = "PATIENTS_FETCH_FAILURE";

export const CREATE_PATIENT_REQUESTED = "CREATE_PATIENT_REQUESTED";
export const CREATE_PATIENT_SUCCESS = "CREATE_PATIENT_SUCCESS";
export const CREATE_PATIENT_FAILURE = "CREATE_PATIENT_FAILURE";

export const PATIENTS_EDIT_REQUESTED = "PATIENTS_EDIT_REQUESTED";
export const PATIENTS_EDIT_SUCCESS = "PATIENTS_EDIT_SUCCESS";
export const PATIENTS_EDIT_FAILURE = "PATIENTS_EDIT_FAILURE";

export const CREATE_PATIENT_APPOINTMENT_REQUESTED = "CREATE_PATIENT_APPOINTMENT_REQUESTED";
export const CREATE_PATIENT_APPOINTMENT_SUCCESS = "CREATE_PATIENT_APPOINTMENT_SUCCESS";
export const CREATE_PATIENT_APPOINTMENT_FAILURE = "CREATE_PATIENT_APPOINTMENT_FAILURE";

export const FETCH_PATIENT_APPOINTMENTS_REQUESTED = "FETCH_PATIENT_APPOINTMENTS_REQUESTED";
export const FETCH_PATIENT_APPOINTMENTS_SUCCESS = "FETCH_PATIENT_APPOINTMENTS_SUCCESS";
export const FETCH_PATIENT_APPOINTMENTS_FAILURE = "FETCH_PATIENT_APPOINTMENTS_FAILURE";

export const DELETE_PATIENT_APPOINTMENT_REQUESTED = "DELETE_PATIENT_APPOINTMENT_REQUESTED";
export const DELETE_PATIENT_APPOINTMENT_SUCCESS = "DELETE_PATIENT_APPOINTMENT_SUCCESS";
export const DELETE_PATIENT_APPOINTMENT_FAILURE = "DELETE_PATIENT_APPOINTMENT_FAILURE";

export const EDIT_PATIENT_APPOINTMENT_REQUESTED = "EDIT_PATIENT_APPOINTMENT_REQUESTED";
export const EDIT_PATIENT_APPOINTMENT_SUCCESS = "EDIT_PATIENT_APPOINTMENT_SUCCESS";
export const EDIT_PATIENT_APPOINTMENT_FAILURE = "EDIT_PATIENT_APPOINTMENT_FAILURE";

export const SINGLE_PATIENT_REQUESTED = "SINGLE_PATIENT_REQUESTED";
export const SINGLE_PATIENT_SUCCESS = "SINGLE_PATIENT_SUCCESS";
export const SINGLE_PATIENT_FAILURE = "SINGLE_PATIENT_FAILURE";

export const PATIENT_LAST_DIAGNOSIS_REQUESTED = "PATIENT_LAST_DIAGNOSIS_REQUESTED";
export const PATIENT_LAST_DIAGNOSIS_SUCCESS = "PATIENT_LAST_DIAGNOSIS_SUCCESS";
export const PATIENT_LAST_DIAGNOSIS_FAILURE = "PATIENT_LAST_DIAGNOSIS_FAILURE";

export const DIAGNOSTIC_RECOMMENDATIONS_REQUESTED = "DIAGNOSTIC_RECOMMENDATIONS_REQUESTED";
export const DIAGNOSTIC_RECOMMENDATIONS_SUCCESS = "DIAGNOSTIC_RECOMMENDATIONS_SUCCESS";
export const DIAGNOSTIC_RECOMMENDATIONS_FAILURE = "DIAGNOSTIC_RECOMMENDATIONS_FAILURE";

export const ADD_PRACTITIONER_REQUESTED = "ADD_PRACTITIONER_REQUESTED";
export const ADD_PRACTITIONER_SUCCESS = "ADD_PRACTITIONER_SUCCESS";
export const ADD_PRACTITIONER_FAILURE = "ADD_PRACTITIONER_FAILURE";

export const POTENTIAL_PATIENTS_FETCH_REQUESTED = "POTENTIAL_PATIENTS_FETCH_REQUESTED";
export const POTENTIAL_PATIENTS_FETCH_SUCCESS = "POTENTIAL_PATIENTS_FETCH_SUCCESS";
export const POTENTIAL_PATIENTS_FETCH_FAILURE = "POTENTIAL_PATIENTS_FETCH_FAILURE";

export const POTENTIAL_PATIENT_FETCH_REQUESTED = "POTENTIAL_PATIENT_FETCH_REQUESTED";
export const POTENTIAL_PATIENT_FETCH_SUCCESS = "POTENTIAL_PATIENT_FETCH_SUCCESS";
export const POTENTIAL_PATIENT_FETCH_FAILURE = "POTENTIAL_PATIENT_FETCH_FAILURE";

export const EDIT_POTENTIAL_PATIENT_REQUESTED = "EDIT_POTENTIAL_PATIENT_REQUESTED";
export const EDIT_POTENTIAL_PATIENT_SUCCESS = "EDIT_POTENTIAL_PATIENT_SUCCESS";
export const EDIT_POTENTIAL_PATIENT_FAILURE = "EDIT_POTENTIAL_PATIENT_FAILURE";

export const PARTNERS_EMAIL_SEND_REQUESTED = "PARTNERS_EMAIL_SEND_REQUESTED";
export const PARTNERS_EMAIL_SEND_SUCCESS = "PARTNERS_EMAIL_SEND_SUCCESS";
export const PARTNERS_EMAIL_SEND_FAILURE = "PARTNERS_EMAIL_SEND_FAILURE";

export const PARTNER_CHECK_UPS_FETCH_REQUESTED = "PARTNER_CHECK_UPS_FETCH_REQUESTED";
export const PARTNER_CHECK_UPS_FETCH_SUCCESS = "PARTNER_CHECK_UPS_FETCH_SUCCESS";
export const PARTNER_CHECK_UPS_FETCH_FAILURE = "PARTNER_CHECK_UPS_FETCH_FAILURE";

export const PATIENT_ARCHIVE_REQUESTED = "PATIENT_ARCHIVE_REQUESTED";
export const PATIENT_ARCHIVE_SUCCESS = "PATIENT_ARCHIVE_SUCCESS";
export const PATIENT_ARCHIVE_FAILURE = "PATIENT_ARCHIVE_FAILURE";

/* NOTES */
export const NOTES_SINGLE_PATIENT_FETCH_REQUESTED = "NOTES_SINGLE_PATIENT_FETCH_REQUESTED";
export const NOTES_SINGLE_PATIENT_FETCH_SUCCESS = "NOTES_SINGLE_PATIENT_FETCH_SUCCESS";
export const NOTES_SINGLE_PATIENT_FETCH_FAILURE = "NOTES_SINGLE_PATIENT_FETCH_FAILURE";

export const NOTES_FETCH_REQUESTED = "NOTES_FETCH_REQUESTED";
export const NOTES_FETCH_SUCCESS = "NOTES_FETCH_SUCCESS";
export const NOTES_FETCH_FAILURE = "NOTES_FETCH_FAILURE";

export const NOTES_CREATE_REQUESTED = "NOTES_CREATE_REQUESTED";
export const NOTES_CREATE_SUCCESS = "NOTES_CREATE_SUCCESS";
export const NOTES_CREATE_FAILURE = "NOTES_CREATE_FAILURE";

export const EDIT_NOTE_REQUESTED = "EDIT_NOTE_REQUESTED";
export const EDIT_NOTE_SUCCESS = "EDIT_NOTE_SUCCESS";
export const EDIT_NOTE_FAILURE = "EDIT_NOTE_FAILURE";

export const NOTE_REMOVE_REQUESTED = "NOTE_REMOVE_REQUESTED";
export const NOTE_REMOVE_SUCCESS = "NOTE_REMOVE_SUCCESS";
export const NOTE_REMOVE_FAILURE = "NOTE_REMOVE_FAILURE";

/* TASK_LABELS */
export const TASK_LABELS_FETCH_REQUESTED = "TASK_LABELS_FETCH_REQUESTED";
export const TASK_LABELS_FETCH_SUCCESS = "TASK_LABELS_FETCH_SUCCESS";
export const TASK_LABELS_FETCH_FAILURE = "TASK_LABELS_FETCH_FAILURE";

export const ADD_TASK_LABEL_REQUESTED = "ADD_TASK_LABEL_REQUESTED";
export const ADD_TASK_LABEL_SUCCESS = "ADD_TASK_LABEL_SUCCESS";
export const ADD_TASK_LABEL_FAILURE = "ADD_TASK_LABEL_FAILURE";

export const EDIT_TASK_LABEL_REQUESTED = "EDIT_TASK_LABEL_REQUESTED";
export const EDIT_TASK_LABEL_SUCCESS = "EDIT_TASK_LABEL_SUCCESS";
export const EDIT_TASK_LABEL_FAILURE = "EDIT_TASK_LABEL_FAILURE";

export const DELETE_TASK_LABEL_REQUESTED = "DELETE_TASK_LABEL_REQUESTED";
export const DELETE_TASK_LABEL_SUCCESS = "DELETE_TASK_LABEL_SUCCESS";
export const DELETE_TASK_LABEL_FAILURE = "DELETE_TASK_LABEL_FAILURE";

export const SWAP_TASK_LABEL_ORDER_REQUESTED = "SWAP_TASK_LABEL_ORDER_REQUESTED";
export const SWAP_TASK_LABEL_ORDER_SUCCESS = "SWAP_TASK_LABEL_ORDER_SUCCESS";
export const SWAP_TASK_LABEL_ORDER_FAILURE = "SWAP_TASK_LABEL_ORDER_FAILURE";

export const FETCH_PATIENT_TASKS_REQUESTED = "FETCH_PATIENT_TASKS_REQUESTED";
export const FETCH_PATIENT_TASKS_SUCCESS = "FETCH_PATIENT_TASKS_SUCCESS";
export const FETCH_PATIENT_TASKS_FAILURE = "FETCH_PATIENT_TASKS_FAILURE";

export const CREATE_PATIENT_TASK_REQUESTED = "CREATE_PATIENT_TASK_REQUESTED";
export const CREATE_PATIENT_TASK_SUCCESS = "CREATE_PATIENT_TASK_SUCCESS";
export const CREATE_PATIENT_TASK_FAILURE = "CREATE_PATIENT_TASK_FAILURE";

export const EDIT_PATIENT_TASK_REQUESTED = "EDIT_PATIENT_TASK_REQUESTED";
export const EDIT_PATIENT_TASK_SUCCESS = "EDIT_PATIENT_TASK_SUCCESS";
export const EDIT_PATIENT_TASK_FAILURE = "EDIT_PATIENT_TASK_FAILURE";

export const PATIENTS_UPDATE_STATUS_REQUESTED = "PATIENTS_UPDATE_STATUS_REQUESTED";
export const PATIENTS_UPDATE_STATUS_SUCCESS = "PATIENTS_UPDATE_STATUS_SUCCESS";
export const PATIENTS_UPDATE_STATUS_FAILURE = "PATIENTS_UPDATE_STATUS_FAILURE";

export const PATIENTS_UPDATE_BGM_REQUESTED = "PATIENTS_UPDATE_BGM_REQUESTED";
export const PATIENTS_UPDATE_BGM_SUCCESS = "PATIENTS_UPDATE_BGM_SUCCESS";
export const PATIENTS_UPDATE_BGM_FAILURE = "PATIENTS_UPDATE_BGM_FAILURE";

export const DIAGNOSIS_CONSULTATION_ANSWERS_REQUESTED = "DIAGNOSIS_CONSULTATION_ANSWERS_REQUESTED";
export const DIAGNOSIS_CONSULTATION_ANSWERS_SUCCESS = "DIAGNOSIS_CONSULTATION_ANSWERS_SUCCESS";
export const DIAGNOSIS_CONSULTATION_ANSWERS_FAILURE = "DIAGNOSIS_CONSULTATION_ANSWERS_FAILURE";

/* QUESTIONS */

export const QUESTIONNAIRE_TYPES = ["study", "diagnostic", "break-score", "break-score-no-insulin", "informations", "beck", "epices"];

export const QUESTIONNAIRE_ANSWERS_TYPES = [QUESTIONNAIRE_TYPES[2], QUESTIONNAIRE_TYPES[3], QUESTIONNAIRE_TYPES[5], QUESTIONNAIRE_TYPES[6]];

export const QUESTIONNAIRE_NAME = ["d'étude", "", "de rupture", "de rupture", "", "beck", "epices"];

export const QUESTIONNAIRES = {};

for (const QUESTIONNAIRE_TYPE of QUESTIONNAIRE_TYPES) {
    const _type = QUESTIONNAIRE_TYPE.toUpperCase().replace(/-/g, "_");

    QUESTIONNAIRES[`${_type}_QUESTIONS_FETCH_REQUESTED`] = `${_type}_QUESTIONS_FETCH_REQUESTED`;
    QUESTIONNAIRES[`${_type}_QUESTIONS_FETCH_SUCCESS`] = `${_type}_QUESTIONS_FETCH_SUCCESS`;
    QUESTIONNAIRES[`${_type}_QUESTIONS_FETCH_FAILURE`] = `${_type}_QUESTIONS_FETCH_FAILURE`;

    QUESTIONNAIRES[`${_type}_QUESTIONS_ADD_REQUESTED`] = `${_type}_QUESTIONS_ADD_REQUESTED`;
    QUESTIONNAIRES[`${_type}_QUESTIONS_ADD_SUCCESS`] = `${_type}_QUESTIONS_ADD_SUCCESS`;
    QUESTIONNAIRES[`${_type}_QUESTIONS_ADD_FAILURE`] = `${_type}_QUESTIONS_ADD_FAILURE`;

    QUESTIONNAIRES[`${_type}_QUESTIONS_EDIT_REQUESTED`] = `${_type}_QUESTIONS_EDIT_REQUESTED`;
    QUESTIONNAIRES[`${_type}_QUESTIONS_EDIT_SUCCESS`] = `${_type}_QUESTIONS_EDIT_SUCCESS`;
    QUESTIONNAIRES[`${_type}_QUESTIONS_EDIT_FAILURE`] = `${_type}_QUESTIONS_EDIT_FAILURE`;

    QUESTIONNAIRES[`${_type}_QUESTIONS_ACTIVATE_REQUESTED`] = `${_type}_QUESTIONS_ACTIVATE_REQUESTED`;
    QUESTIONNAIRES[`${_type}_QUESTIONS_ACTIVATE_SUCCESS`] = `${_type}_QUESTIONS_ACTIVATE_SUCCESS`;
    QUESTIONNAIRES[`${_type}_QUESTIONS_ACTIVATE_FAILURE`] = `${_type}_QUESTIONS_ACTIVATE_FAILURE`;

    QUESTIONNAIRES[`${_type}_QUESTIONS_SWAP_REQUESTED`] = `${_type}_QUESTIONS_SWAP_REQUESTED`;
    QUESTIONNAIRES[`${_type}_QUESTIONS_SWAP_SUCCESS`] = `${_type}_QUESTIONS_SWAP_SUCCESS`;
    QUESTIONNAIRES[`${_type}_QUESTIONS_SWAP_FAILURE`] = `${_type}_QUESTIONS_SWAP_FAILURE`;
}

for (const QUESTIONNAIRE_ANSWERS_TYPE of QUESTIONNAIRE_ANSWERS_TYPES) {
    const _type = QUESTIONNAIRE_ANSWERS_TYPE.toUpperCase().replace(/-/g, "_");

    QUESTIONNAIRES[`${_type}_ANSWERS_FETCH_REQUESTED`] = `${_type}_ANSWERS_FETCH_REQUESTED`;
    QUESTIONNAIRES[`${_type}_ANSWERS_FETCH_SUCCESS`] = `${_type}_ANSWERS_FETCH_SUCCESS`;
    QUESTIONNAIRES[`${_type}_ANSWERS_FETCH_FAILURE`] = `${_type}_ANSWERS_FETCH_FAILURE`;
}

export const ADD_QUESTIONNAIRE_PATIENT_REQUESTED = "ADD_QUESTIONNAIRE_PATIENT_REQUESTED";
export const ADD_QUESTIONNAIRE_PATIENT_SUCCESS = "ADD_QUESTIONNAIRE_PATIENT_SUCCESS";
export const ADD_QUESTIONNAIRE_PATIENT_FAILURE = "ADD_QUESTIONNAIRE_PATIENT_FAILURE";

export const DELETE_QUESTIONNAIRE_PATIENT_REQUESTED = "DELETE_QUESTIONNAIRE_PATIENT_REQUESTED";

export const POINTS_OF_ATTENTION_FETCH_REQUESTED = "POINTS_OF_ATTENTION_FETCH_REQUESTED";
export const POINTS_OF_ATTENTION_FETCH_SUCCESS = "POINTS_OF_ATTENTION_FETCH_SUCCESS";
export const POINTS_OF_ATTENTION_FETCH_FAILURE = "POINTS_OF_ATTENTION_FETCH_FAILURE";

export const POINTS_OF_ATTENTION_CREATE_REQUESTED = "POINTS_OF_ATTENTION_CREATE_REQUESTED";
export const POINTS_OF_ATTENTION_CREATE_SUCCESS = "POINTS_OF_ATTENTION_CREATE_SUCCESS";
export const POINTS_OF_ATTENTION_CREATE_FAILURE = "POINTS_OF_ATTENTION_CREATE_FAILURE";

export const POINTS_OF_ATTENTION_EDIT_REQUESTED = "POINTS_OF_ATTENTION_EDIT_REQUESTED";
export const POINTS_OF_ATTENTION_EDIT_SUCCESS = "POINTS_OF_ATTENTION_EDIT_SUCCESS";
export const POINTS_OF_ATTENTION_EDIT_FAILURE = "POINTS_OF_ATTENTION_EDIT_FAILURE";

export const POINTS_OF_ATTENTION_DELETE_REQUESTED = "POINTS_OF_ATTENTION_DELETE_REQUESTED";
export const POINTS_OF_ATTENTION_DELETE_SUCCESS = "POINTS_OF_ATTENTION_DELETE_SUCCESS";
export const POINTS_OF_ATTENTION_DELETE_FAILURE = "POINTS_OF_ATTENTION_DELETE_FAILURE";

export const THEMATICS_FETCH_REQUESTED = "THEMATICS_FETCH_REQUESTED";
export const THEMATICS_FETCH_SUCCESS = "THEMATICS_FETCH_SUCCESS";
export const THEMATICS_FETCH_FAILURE = "THEMATICS_FETCH_FAILURE";

export const THEMATICS_CREATE_REQUESTED = "THEMATICS_CREATE_REQUESTED";
export const THEMATICS_CREATE_SUCCESS = "THEMATICS_CREATE_SUCCESS";
export const THEMATICS_CREATE_FAILURE = "THEMATICS_CREATE_FAILURE";

export const THEMATICS_EDIT_REQUESTED = "THEMATICS_EDIT_REQUESTED";
export const THEMATICS_EDIT_SUCCESS = "THEMATICS_EDIT_SUCCESS";
export const THEMATICS_EDIT_FAILURE = "THEMATICS_EDIT_FAILURE";

export const THEMATICS_DELETE_REQUESTED = "THEMATICS_DELETE_REQUESTED";
export const THEMATICS_DELETE_SUCCESS = "THEMATICS_DELETE_SUCCESS";
export const THEMATICS_DELETE_FAILURE = "THEMATICS_DELETE_FAILURE";

export const ADD_POTENTIAL_PATIENT_REQUESTED = "ADD_POTENTIAL_PATIENTS_REQUESTED";
export const ADD_POTENTIAL_PATIENT_SUCCESS = "ADD_POTENTIAL_PATIENTS_SUCCESS";
export const ADD_POTENTIAL_PATIENT_FAILURE = "ADD_POTENTIAL_PATIENTS_FAILURE";

export const DELETE_POTENTIAL_PATIENT_REQUESTED = "DELETE_POTENTIAL_PATIENTS_REQUESTED";
export const DELETE_POTENTIAL_PATIENT_SUCCESS = "DELETE_POTENTIAL_PATIENTS_SUCCESS";
export const DELETE_POTENTIAL_PATIENT_FAILURE = "DELETE_POTENTIAL_PATIENTS_FAILURE";

/* DIAGNOSIS */
export const DIAGNOSIS_CREATE_REQUESTED = "DIAGNOSIS_CREATE_REQUESTED";
export const DIAGNOSIS_CREATE_SUCCESS = "DIAGNOSIS_CREATE_SUCCESS";
export const DIAGNOSIS_CREATE_FAILURE = "DIAGNOSIS_CREATE_FAILURE";

export const DIAGNOSIS_EDIT_REQUESTED = "DIAGNOSIS_EDIT_REQUESTED";
export const DIAGNOSIS_EDIT_SUCCESS = "DIAGNOSIS_EDIT_SUCCESS";
export const DIAGNOSIS_EDIT_FAILURE = "DIAGNOSIS_EDIT_FAILURE";
export const PATIENT_UNCOMPLETED_DIAGNOSIS_REQUESTED = "PATIENT_UNCOMPLETED_DIAGNOSIS_REQUESTED";
export const PATIENT_UNCOMPLETED_DIAGNOSIS_SUCCESS = "PATIENT_UNCOMPLETED_DIAGNOSIS_SUCCESS";
export const PATIENT_UNCOMPLETED_DIAGNOSIS_FAILURE = "PATIENT_UNCOMPLETED_DIAGNOSIS_FAILURE";

export const DIAGNOSIS_CHECKUP_QUESTIONS_REQUESTED = "DIAGNOSIS_CHECKUP_QUESTIONS_REQUESTED";
export const DIAGNOSIS_CHECKUP_QUESTIONS_SUCCESS = "DIAGNOSIS_CHECKUP_QUESTIONS_SUCCESS";
export const DIAGNOSIS_CHECKUP_QUESTIONS_FAILURE = "DIAGNOSIS_CHECKUP_QUESTIONS_FAILURE";

export const DIAGNOSIS_CONSULTATION_QUESTIONS_REQUESTED = "DIAGNOSIS_CONSULTATION_QUESTIONS_REQUESTED";
export const DIAGNOSIS_CONSULTATION_QUESTIONS_SUCCESS = "DIAGNOSIS_CONSULTATION_QUESTIONS_SUCCESS";
export const DIAGNOSIS_CONSULTATION_QUESTIONS_FAILURE = "DIAGNOSIS_CONSULTATION_QUESTIONS_FAILURE";

export const DIAGNOSIS_RECO_AVAILABILITY_REQUESTED = "DIAGNOSIS_RECO_AVAILABILITY_REQUESTED";
export const DIAGNOSIS_RECO_AVAILABILITY_SUCCESS = "DIAGNOSIS_RECO_AVAILABILITY_SUCCESS";
export const DIAGNOSIS_RECO_AVAILABILITY_FAILURE = "DIAGNOSIS_RECO_AVAILABILITY_FAILURE";

/* DIAGNOSIS RECOMMANDATIONS */
export const DIAGNOSIS_RECOS_FETCH_REQUESTED = "DIAGNOSIS_RECOS_FETCH_REQUESTED";
export const DIAGNOSIS_RECOS_FETCH_SUCCESS = "DIAGNOSIS_RECOS_FETCH_SUCCESS";
export const DIAGNOSIS_RECOS_FETCH_FAILURE = "DIAGNOSIS_RECOS_FETCH_FAILURE";

/* REPORTS */
export const REPORTS_FETCH_REQUESTED = "REPORTS_FETCH_REQUESTED";
export const REPORTS_FETCH_SUCCESS = "REPORTS_FETCH_SUCCESS";
export const REPORTS_FETCH_FAILURE = "REPORTS_FETCH_FAILURE";

export const REPORTS_CREATE_REQUESTED = "REPORTS_CREATE_REQUESTED";
export const REPORTS_CREATE_SUCCESS = "REPORTS_CREATE_SUCCESS";
export const REPORTS_CREATE_FAILURE = "REPORTS_CREATE_FAILURE";

export const EDIT_REPORT_REQUESTED = "EDIT_REPORT_REQUESTED";
export const EDIT_REPORT_SUCCESS = "EDIT_REPORT_SUCCESS";
export const EDIT_REPORT_FAILURE = "EDIT_REPORT_FAILURE";

export const REPORT_REMOVE_REQUESTED = "REPORT_REMOVE_REQUESTED";
export const REPORT_REMOVE_SUCCESS = "REPORT_REMOVE_SUCCESS";
export const REPORT_REMOVE_FAILURE = "REPORT_REMOVE_FAILURE";

export const MEDICAL_PATIENT_REQUESTED = "MEDICAL_PATIENT_REQUESTED";
export const MEDICAL_PATIENT_SUCCESS = "MEDICAL_PATIENT_SUCCESS";
export const MEDICAL_PATIENT_FAILURE = "MEDICAL_PATIENT_FAILURE";
