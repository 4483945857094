import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { ModerationListItem } from "@rdcs/dap-front-library";

import SettingsTabLayout from "../../../components/Settings/SettingsTabLayout";

const AdminTab = ({ loading, admins, onFetch, delete: onDelete, auth }) => (
    <SettingsTabLayout
        title="Administrateurs"
        icon="informations"
        addButton={{
            link: "/configuration/ajouter-administrateur",
            label: "Ajouter un administrateur"
        }}
        isLoading={loading}
    >
        {admins.map(admin => (
            <ModerationListItem
                key={admin["@id"]}
                label={`${admin.firstName} ${admin.lastName}`}
                info={admin.email}
                modifyLink={`/configuration/modifier-administrateur/${admin.id}`}
                onDelete={() => onDelete(auth, admin.id).then(() => onFetch(auth))}
                deleteText="Supprimer l'utilisateur"
            />
        ))}
    </SettingsTabLayout>
);

AdminTab.propTypes = {
    loading: PropTypes.bool.isRequired,
    admins: PropTypes.arrayOf(PropTypes.object).isRequired,
    onFetch: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps)(AdminTab);
