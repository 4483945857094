import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import Questions from "../../../actions/questions";

import BreakScoreQuestionForm from "../../../components/Settings/Forms/BreakScoreQuestionForm";

import SettingsFormScreen from "../../../components/Settings/SettingsFormScreen";

const { tryAddBreakScoreQuestion } = Questions;

const AddBreakScoreQuestionScreen = ({ questions: { createLoading, createError }, tryAddBreakScoreQuestion }) => (
    <SettingsFormScreen
        /* Document and page titles */
        documentTitle="Ajouter question de rupture pour patient insulinotraité"
        pageTitle="Ajouter une question de rupture pour patient insulinotraité"
        /* Redux request callback use to add on BDD */
        formRequest={tryAddBreakScoreQuestion}
        /* Forms */
        itemForm={BreakScoreQuestionForm}
        itemFormRequestLoading={createLoading}
        itemFormRequestError={createError}
        /* Configuration tab name for redirection */
        tab="break-score-questions"
    />
);

AddBreakScoreQuestionScreen.propTypes = {
    questions: PropTypes.shape({
        createLoading: PropTypes.bool.isRequired,
        createError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object
            }),
            PropTypes.bool
        ]).isRequired
    }).isRequired,
    tryAddBreakScoreQuestion: PropTypes.func.isRequired
};

const mapStateToProps = ({ questions }) => ({ questions });

const mapDispatchToProps = { tryAddBreakScoreQuestion };

export default connect(mapStateToProps, mapDispatchToProps)(AddBreakScoreQuestionScreen);
