import moment from "moment/moment";

export const getInitials = (firstName, lastName) => firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();

export const getViolations = (error) => (error instanceof Object && "violations" in error ? error.violations : {});

/**
* Usage: each argument is the next key in constants object
* Example: constant('roles', 'ROLE_PATIENT') or constant('recommendation', 'ageRanges', 'all')
* Example to loop in constants array :
    <select defaultValue={profession}>
         {constant('professions').map(profession =>
             <option key={profession} defaultValue={profession}>
                 {constant('professions', profession)}
             </option>
         )}
     </select>
*/
export const constant = (...parts) => {
    const name = parts.join(".");
    let val = window.CONSTANTS || {};

    for (let i = 0; i < parts.length; ++i) {
        if (parts[i] in val) {
            const valPartI = val[parts[i]];

            if (i === parts.length - 1) {
                if (typeof valPartI === "string" || valPartI instanceof String) {
                    return valPartI;
                } else if (valPartI instanceof Object) {
                    return Object.keys(valPartI);
                } else {
                    console.error("Could not find constant: " + name);
                    return name;
                }
            } else {
                val = valPartI;
            }
        } else {
            console.error("Could not find constant: " + name);
            return name;
        }
    }
};

export const ucFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const getLastSixMonths = () => {
    const months = [];

    for (let m = moment(); m.diff(moment().subtract(12, "months")) > 0; m.subtract(1, "months")) {
        months.push(moment(m));
    }
    return months;
};

export const getConsultDateFromRecurrence = (diagnosisDate, recommendationRecurrence) => {
    const baseDate = moment(diagnosisDate);
    switch (true) {
        case recommendationRecurrence === "none":
            return baseDate.add(1, "year");
        case recommendationRecurrence === "every_two_years":
            return baseDate.add(2, "years");
        case recommendationRecurrence === "annually":
            return baseDate.add(1, "year");
        case recommendationRecurrence === "every_six_months":
            return baseDate.add(6, "months");
        case recommendationRecurrence === "quarterly":
            return baseDate.add(3, "months");
        case recommendationRecurrence === "monthly":
            return baseDate.add(1, "months");
        case recommendationRecurrence === "weekly":
            return baseDate.add(7, "days");
        case recommendationRecurrence === "daily":
            return baseDate.add(1, "day");
        default:
            console.error("Unknown recommendation recurrence");
            return baseDate;
    }
};

export const diffInDays = (endDate, startDate) => {
    const momentEndDate = moment(moment(endDate).format("YYYY-MM-DD"));
    const momentStartDate = moment(moment(startDate).format("YYYY-MM-DD"));
    return momentEndDate.diff(momentStartDate, "days");
};

export const diffInMonths = (endDate, startDate) => {
    const momentEndDate = moment(moment(endDate).format("YYYY-MM-DD"));
    const momentStartDate = moment(moment(startDate).format("YYYY-MM-DD"));
    return momentEndDate.diff(momentStartDate, "months");
};

export const formatHBA1C = (val) => {
    if (!val) return "";
    val = val.toString();
    val = val.indexOf(".") === -1 ? val + ".0" : val;
    return val;
};

export const isLocalAPI = process.env.REACT_APP_USE_LOCAL_API === "true";

export const formatDate = (dateAndTime) => {
    const date = new Date(dateAndTime);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat("fr", { month: "long" }).format(date);
    const year = date.getFullYear();

    const formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
};
