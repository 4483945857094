import * as types from "../actions/types";
import { toast } from "react-toastify";

const initialState = {
    list: [],
    listLoading: false,
    createLoading: false,
    createError: false,
    editLoading: false,
    editError: false,
    deleteLoading: false,
    deleteError: false,
};

function recommendations(state = initialState, action) {
    switch (action.type) {
        // FETCH
        case types.RECOMMENDATIONS_FETCH_REQUESTED: {
            return Object.assign({}, state, { listLoading: true });
        }
        case types.RECOMMENDATIONS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                listLoading: false,
                list: action.data,
            });
        }
        case types.RECOMMENDATIONS_FETCH_FAILURE: {
            return Object.assign({}, state, { listLoading: false, list: [] });
        }
        // CREATE
        case types.RECOMMENDATIONS_CREATE_REQUESTED: {
            return Object.assign({}, state, {
                createLoading: true,
                createError: false,
            });
        }
        case types.RECOMMENDATIONS_CREATE_SUCCESS: {
            toast.success("La recommandation a été créée");
            return Object.assign({}, state, {
                createLoading: false,
                createError: false,
                list: [action.data, ...state.list],
            });
        }
        case types.RECOMMENDATIONS_CREATE_FAILURE: {
            toast.error("La recommandation n'a pas pu être créée");
            return Object.assign({}, state, {
                createLoading: false,
                createError: action.data,
            });
        }
        case types.RECOMMENDATIONS_CREATE_CLEAR: {
            return Object.assign({}, state, {
                createLoading: false,
                createError: false,
            });
        }
        // EDIT
        case types.RECOMMENDATIONS_EDIT_REQUESTED: {
            return Object.assign({}, state, {
                editLoading: true,
                editError: false,
            });
        }
        case types.RECOMMENDATIONS_EDIT_SUCCESS: {
            toast.success("La recommandation a été modifiée");
            return Object.assign({}, state, {
                editLoading: false,
                editError: false,
                list: [action.data, ...state.list.filter((reco) => reco["@id"] !== action.data["@id"])],
            });
        }
        case types.RECOMMENDATIONS_EDIT_FAILURE: {
            toast.error("La recommandation n'a pas pu être modifiée");
            return Object.assign({}, state, {
                editLoading: false,
                editError: action.data,
            });
        }
        case types.RECOMMENDATIONS_EDIT_CLEAR: {
            return Object.assign({}, state, {
                editLoading: false,
                editError: false,
            });
        }
        // DELETE
        case types.RECOMMENDATIONS_DELETE_REQUESTED: {
            return Object.assign({}, state, {
                deleteLoading: true,
                deleteError: false,
            });
        }
        case types.RECOMMENDATIONS_DELETE_SUCCESS: {
            toast.success("La recommandation a été supprimée");
            return Object.assign({}, state, {
                deleteLoading: false,
                deleteError: false,
                list: state.list.filter((reco) => reco.id !== action.data),
            });
        }
        case types.RECOMMENDATIONS_DELETE_FAILURE: {
            toast.error("La recommandation n'a pas pu être supprimée");
            return Object.assign({}, state, {
                deleteLoading: false,
                deleteError: action.data,
            });
        }

        // ENABLE
        case types.RECOMMENDATIONS_ENABLE_SUCCESS: {
            toast.success("La recommandation a été activée");
            return Object.assign({}, state, {
                list: [action.data, ...state.list.filter((reco) => reco["@id"] !== action.data["@id"])],
            });
        }
        case types.RECOMMENDATIONS_ENABLE_FAILURE: {
            toast.error("La recommandation n'a pas pu être activée");
            return Object.assign({}, state, {});
        }

        // DISABLE
        case types.RECOMMENDATIONS_DISABLE_SUCCESS: {
            toast.success("La recommandation a été désactivée");
            return Object.assign({}, state, {
                list: [action.data, ...state.list.filter((reco) => reco["@id"] !== action.data["@id"])],
            });
        }
        case types.RECOMMENDATIONS_DISABLE_FAILURE: {
            toast.error("La recommandation n'a pas pu être désactivée");
            return Object.assign({}, state, {});
        }

        // DEFAULT FETCH
        case types.RECOMMENDATIONS_ALL_DEFAULT_FETCH_SUCCESS: {
            return Object.assign({}, state, { defaultValue: action.data });
        }
        case types.RECOMMENDATIONS_ALL_DEFAULT_FETCH_FAILURE: {
            return Object.assign({}, state, {});
        }

        // DEFAULT ENABLE
        case types.RECOMMENDATIONS_ALL_DEFAULT_ENABLE_SUCCESS: {
            toast.success("Les recommandations optionnelles ont été activées par défaut");
            return Object.assign({}, state, { defaultValue: true });
        }
        case types.RECOMMENDATIONS_ALL_DEFAULT_ENABLE_FAILURE: {
            toast.error("Les recommandations optionnelles n'ont pas pu être activées par défaut");
            return Object.assign({}, state, {});
        }

        // DEFAULT DISABLE
        case types.RECOMMENDATIONS_ALL_DEFAULT_DISABLE_SUCCESS: {
            toast.success("Les recommandations optionnelles ont été désactivées par défaut");
            return Object.assign({}, state, { defaultValue: false });
        }
        case types.RECOMMENDATIONS_ALL_DEFAULT_DISABLE_FAILURE: {
            toast.error("Les recommandations optionnelles n'ont pas pu être désactivées par défaut");
            return Object.assign({}, state, {});
        }

        default:
            return state;
    }
}

export default recommendations;
