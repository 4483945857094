import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { tryEditComplication, tryFetchComplications } from "../../../actions/complications";

import ComplicationForm from "../../../components/Settings/Forms/ComplicationForm";
import SettingsEditFormScreen from "../../../components/Settings/SettingsEditFormScreen";

const EditComplicationScreen = ({
    complications: { list: complications, listLoading, editLoading, editError },
    tryEditComplication,
    tryFetchComplications
}) => (
    <SettingsEditFormScreen
        /* Document and page titles */
        documentTitle="Modifier complication"
        pageTitle="Modifier une complication"
        /* Redux request callback use to add on BDD */
        formRequest={tryEditComplication}
        fetchRequests={tryFetchComplications}
        testFetchRequestsNeeded={complications.length === 0}
        /* Forms */
        itemForm={ComplicationForm}
        itemFormRequestLoading={editLoading}
        itemFormRequestError={editError}
        itemFormExtraProps={{ complications }}
        itemFormIsLoading={listLoading}
        itemFormEditedItemPropName="editedComplication"
        /* Items lists to retrieve the current item */
        items={complications}
        /* Configuration tab name for redirection */
        tab="complications"
    />
);

EditComplicationScreen.propTypes = {
    complications: PropTypes.shape({
        list: PropTypes.arrayOf(PropTypes.object).isRequired,
        listLoading: PropTypes.bool.isRequired,
        editLoading: PropTypes.bool.isRequired,
        editError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object
            }),
            PropTypes.bool
        ]).isRequired
    }).isRequired,
    tryEditComplication: PropTypes.func.isRequired,
    tryFetchComplications: PropTypes.func.isRequired
};

const mapStateToProps = ({ complications }) => ({ complications });

const mapDispatchToProps = { tryEditComplication, tryFetchComplications };

export default connect(mapStateToProps, mapDispatchToProps)(EditComplicationScreen);
