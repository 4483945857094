import React from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";

import { connect } from "react-redux";
import { tryFetchPatients, tryArchivePatient } from "../../../actions/patients";
import { tryFetchCoordinators } from "../../../actions/coordinators";

import moment from "moment";
import { DefaultLoader, AntdTooltip, AntdPagination, PageTitle, ImagePreview, constant, Icon, Modal, Button } from "@rdcs/dap-front-library";

import AppLayout from "../../../components/Layout/AppLayout";
import HeaderCoordinator from "../../../components/Coordinator/HeaderCoordinator";

import usePatientsList from "../../../hooks/usePatientsList";
import defaultUserImage from "@rdcs/dap-front-library/static/images/default-user.png";

const breadcrumbs = [{ label: "Facilitateurs" }];

const CoordinatorScreen = ({ auth, patients, coordinators, tryFetchPatients, tryFetchCoordinators, tryArchivePatient }) => {
    const [{ page, patientsList, coordinatorIsLoading }, { load, changePage, setPatientsList }, { patientsListShouldUpdate }] = usePatientsList();

    const modalRef = React.useRef();

    const retrievePatients = React.useCallback(
        (filter) => {
            if (patientsListShouldUpdate(filter)) {
                tryFetchPatients(auth, { page, ...filter }).then(setPatientsList);
            }
        },
        [page, patientsListShouldUpdate]
    );

    React.useEffect(() => {
        if (coordinators.length === 0) {
            tryFetchCoordinators(auth).then(load);
        } else {
            load();
        }
    }, []);

    const handleArchivePatient = React.useCallback((patient) => {
        tryArchivePatient(auth, patient).then(modalRef.current.toggleModal());
    }, []);

    return (
        <PageTitle title="Timkl - Facilitateurs">
            <AppLayout navActive={0} breadcrumbs={breadcrumbs}>
                <HeaderCoordinator
                    title="Patients à accompagner"
                    loading={coordinatorIsLoading}
                    currentCoordinatorId={auth.user.id}
                    coordinators={coordinators}
                    onChange={retrievePatients}
                />
                {patients.listLoading ? (
                    <DefaultLoader color="#CCC" />
                ) : patientsList.length > 0 ? (
                    <>
                        <div className="moderationList form__group flex">
                            {patientsList.map((patient) => (
                                <div className="d-flex align-items-center mb-1" key={patient.id}>
                                    <Link
                                        to={`/facilitateurs/patient/${patient.id}/dashboard`}
                                        key={patient.id}
                                        className="patientsListItem"
                                        style={{ width: "95%" }}
                                    >
                                        <AntdTooltip placement="right" title={patient.statusReason}>
                                            <div className={`patientsListItem__statusDot statusDot statusDot--${patient.statusColor}`} />
                                        </AntdTooltip>
                                        <ImagePreview
                                            user={patient}
                                            className="patientsListItem__visuel"
                                            filename={patient.avatar && patient.avatar.filename}
                                            fallbackImageUrl={defaultUserImage}
                                        />
                                        <div className="patientsListItem__infos ml-2">
                                            <div className="patientsListItem__name">
                                                {patient.firstName} {patient.lastName}
                                            </div>
                                            {patient.lateRecommendations > 0 && (
                                                <div>
                                                    {patient.lateRecommendations} recommandation
                                                    {patient.lateRecommendations > 1 && "s"} en retard
                                                </div>
                                            )}
                                            <div className="patientsListItem__infos-list">
                                                <span className="patientsListItem__infos-item">
                                                    {patient.lastDiagnostic ? constant("pathologies", patient.lastDiagnostic.pathology) : "Diabète inconnu"}
                                                </span>
                                                {/* <span className="patientsListItem__infos-item patientsListItem__infos-item--bigger">
                                                    {patient.lastDiagnostic && patient.lastDiagnostic.treatmentGroup
                                                        ? getTreatmentName(patient.lastDiagnostic.treatmentGroup)
                                                        : "Aucun traitement"}
                                                </span> */}
                                                <span className="patientsListItem__infos-item">
                                                    {constant("sexes", patient.sex)}, {moment().diff(patient.dateOfBirth, "years")} ans
                                                </span>
                                                <span className="patientsListItem__infos-item">
                                                    {patient.tasksTodo !== 0
                                                        ? patient.tasksTodo + " tâche" + (patient.tasksTodo > 1 ? "s" : "")
                                                        : "Aucune tâche"}
                                                </span>
                                                <span className="patientsListItem__infos-item patientsListItem__infos-item--bigger">
                                                    {patient.lastDiagnostic
                                                        ? "Dernière consultation le " + moment(patient.lastDiagnostic.createdAt).format("DD/MM/YYYY")
                                                        : "Aucune consultation"}
                                                </span>
                                            </div>
                                        </div>
                                        <Icon name="stats" className="patientsListItem__icon" />
                                    </Link>
                                    <Modal buttonClass="ml-4" buttonContent={<Icon name="trash" size="25px" />} ref={modalRef}>
                                        <div className="text-center">
                                            Voulez vous archiver ce patient : {patient.firstName} {patient.lastName} ?
                                            <div className="d-flex align-items-center justify-center mt-4">
                                                <Button
                                                    className="button__primaryButton"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleArchivePatient(patient);
                                                    }}
                                                >
                                                    Oui
                                                </Button>
                                                <Button
                                                    className="button__primaryButton backgroundColor__red"
                                                    onClick={() => {
                                                        modalRef.current.toggleModal();
                                                    }}
                                                >
                                                    Non
                                                </Button>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                            ))}
                        </div>
                        {patients.totalItems > 30 && (
                            <div className="mb-2 mt-2 text-center">
                                <AntdPagination simple current={page} onChange={changePage} pageSize={30} total={patients.totalItems} />
                            </div>
                        )}
                    </>
                ) : (
                    <p>Aucun résultat</p>
                )}
            </AppLayout>
        </PageTitle>
    );
};

CoordinatorScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    patients: PropTypes.object.isRequired,
    coordinators: PropTypes.arrayOf(PropTypes.object).isRequired,
    tryFetchPatients: PropTypes.func.isRequired,
    tryFetchCoordinators: PropTypes.func.isRequired,
    tryArchivePatient: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, patients, coordinators: { list } }) => ({ auth, patients, coordinators: list });

const mapDispatchToProps = {
    tryFetchPatients,
    tryFetchCoordinators,
    tryArchivePatient,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CoordinatorScreen));
