import React from "react";
import PropTypes from "prop-types";

import { withRouter, useParams } from "react-router-dom";

import useRedirectIfItemNotExist from "../../hooks/useRedirectIfItemNotExist";

import SettingsFormScreen from "./SettingsFormScreen";

const SettingsEditFormScreen = ({
    documentTitle,
    pageTitle,
    formRequest,
    fetchRequests,
    testFetchRequestsNeeded = true,
    onFetchRequestEnded = undefined,
    itemForm,
    itemFormRequestLoading,
    itemFormRequestError,
    itemFormExtraProps = undefined,
    itemFormIsLoading = false,
    itemFormEditedItemPropName,
    items,
    onItemEditedFound = undefined,
    tab
}) => {
    const { id } = useParams();
    const globalScreenDestinations = React.useMemo(() => ({ pathname: "/configuration", tab }), [tab]);
    const itemIdValue = React.useMemo(() => parseInt(id), [id]);
    const editedItem = React.useMemo(() => {
        const item = items.find(itemIt => itemIt.id === itemIdValue);

        return item && onItemEditedFound ? onItemEditedFound(item) || item : item;
    }, [items, itemIdValue, onItemEditedFound]);
    const signalRequestEnded = useRedirectIfItemNotExist(globalScreenDestinations, editedItem);
    const itemFormExtraPropsEdited = React.useMemo(
        () => ({ ...itemFormExtraProps, [itemFormEditedItemPropName]: editedItem }),
        [itemFormExtraProps, editedItem]
    );
    const onFetchRequestEndedEdited = React.useCallback(() => {
        signalRequestEnded();
        onFetchRequestEnded && onFetchRequestEnded();
    }, [signalRequestEnded]);

    return (
        <SettingsFormScreen
            documentTitle={documentTitle}
            pageTitle={pageTitle}
            formRequest={formRequest}
            fetchRequests={fetchRequests}
            testFetchRequestsNeeded={testFetchRequestsNeeded}
            onFetchRequestEnded={onFetchRequestEndedEdited}
            itemForm={itemForm}
            itemFormRequestLoading={itemFormRequestLoading}
            itemFormRequestError={itemFormRequestError}
            itemFormExtraProps={itemFormExtraPropsEdited}
            itemFormIsLoading={itemFormIsLoading}
            itemFormExtraSubmitParams={[editedItem && editedItem.id]}
            tab={tab}
        />
    );
};

SettingsEditFormScreen.propTypes = {
    /* Document and page titles */
    documentTitle: PropTypes.string.isRequired,
    pageTitle: PropTypes.string.isRequired,

    /* Redux request callback use to add on BDD */
    formRequest: PropTypes.func.isRequired,
    fetchRequests: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.func), PropTypes.func]),
    testFetchRequestsNeeded: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.bool), PropTypes.bool]),
    onFetchRequestEnded: PropTypes.func,

    /* Forms */
    itemForm: PropTypes.any.isRequired,
    itemFormRequestLoading: PropTypes.bool.isRequired,
    itemFormRequestError: PropTypes.oneOfType([PropTypes.shape({ violations: PropTypes.object }), PropTypes.bool])
        .isRequired,
    itemFormExtraProps: PropTypes.object,
    itemFormIsLoading: PropTypes.bool,
    itemFormEditedItemPropName: PropTypes.string.isRequired,

    /* Items lists to retrieve the current item */
    items: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired })
    ),

    /* Callback event call when edited item is found allowing to modity it before sending it into forms */
    onItemEditedFound: PropTypes.func,

    /* Configuration tab name for redirection */
    tab: PropTypes.string.isRequired
};

export default withRouter(SettingsEditFormScreen);
