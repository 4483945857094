import React from "react";
import PropTypes from "prop-types";

import { Select, InputText } from "@rdcs/dap-front-library";

const CoordinatorFilter = ({ coordinators, currentCoordinatorId, onChange, order: orderProp = false }) => {
    const [name, setName] = React.useState("");
    const [coordinator, setCoordinator] = React.useState(currentCoordinatorId);
    const [order, setOrder] = React.useState("statusColor");

    const coordinatorOptions = React.useMemo(
        () => [
            ...coordinators.map((c) => ({
                label: c.firstName + " " + c.lastName,
                value: c.id,
            })),
            { label: "Afficher tout", value: "" },
        ],
        [coordinators]
    );

    React.useEffect(() => {
        if (onChange) {
            const filter = {};

            if (name !== "") {
                filter.fullName = name.toLowerCase();
            }
            if (coordinator !== "") {
                filter.coordinator = parseInt(coordinator);
            }
            filter.archived = false;
            onChange(filter);
        }
    }, [name, coordinator, order, onChange]);

    return (
        <>
            <Select label="Gérés par" options={coordinatorOptions} defaultValue={coordinator} onChange={setCoordinator} />
            <div className="headerTop__search-wrapper mx-2">
                <InputText label="Nom du patient" onChange={setName} />
            </div>
            {orderProp && (
                <Select
                    label="Tri"
                    options={[
                        { label: "Par priorité", value: "statusColor" },
                        { label: "Par date de mise à jour", value: "lastModifiedDate" },
                    ]}
                    defaultValue={order}
                    onChange={setOrder}
                />
            )}
        </>
    );
};

CoordinatorFilter.propTypes = {
    currentCoordinatorId: PropTypes.number.isRequired,
    coordinators: PropTypes.arrayOf(
        PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            departments: PropTypes.arrayOf(
                PropTypes.shape({
                    code: PropTypes.string.isRequired,
                }).isRequired
            ).isRequired,
        }).isRequired
    ).isRequired,
    onChange: PropTypes.func,
    order: PropTypes.bool,
};

export default CoordinatorFilter;
