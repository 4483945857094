import React from "react";
import PropTypes from "prop-types";

import { InputRadio, InputText, InputDateTime, InputNumber, InputEmail, Button, InputTel } from "@rdcs/dap-front-library";

const CoordinatorPatientCoordonneesForm = ({ submit, profile, profileEditLoading, violations }) => {
    const [firstName, setFirstName] = React.useState(profile.firstName);
    const [lastName, setLastName] = React.useState(profile.lastName);
    const [dateOfBirth, setDateOfBirth] = React.useState(profile.dateOfBirth);
    const [sex, setSex] = React.useState(profile.sex);
    const [ssn, setSsn] = React.useState(profile.ssn ? parseInt(profile.ssn) : null);
    const [phoneNumber, setPhoneNumber] = React.useState(profile.phoneNumber);
    const [email, setEmail] = React.useState(profile.email);

    const handleSubmit = (e) => {
        e.preventDefault();
        submit({
            firstName,
            lastName,
            dateOfBirth,
            sex,
            ssn: ssn.toString(),
            phoneNumber,
            email
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <InputRadio
                label="Sexe"
                error={violations.sex}
                defaultValue={sex}
                options={[{ label: "Homme", value: "male" }, { label: "Femme", value: "female" }]}
                onChange={setSex}
                required
            />
            <InputText
                label="Prénom"
                name="firstName"
                error={violations.firstName}
                placeholder="Saisissez votre prénom"
                defaultValue={firstName}
                onChange={setFirstName}
                required
            />
            <InputText
                label="Nom"
                name="lastName"
                error={violations.lastName}
                placeholder="Saisissez votre nom"
                defaultValue={lastName}
                onChange={setLastName}
                required
            />
            <InputDateTime
                label="Date de naissance"
                placeholder="jj/mm/aaaa"
                displayFormat="dd-MM-yyyy"
                defaultValue={new Date(dateOfBirth)}
                error={violations.dateOfBirth}
                onChange={setDateOfBirth}
                required
            />
            <InputNumber
                label="N° de sécurité sociale"
                name="ssn"
                error={violations.ssn}
                placeholder="Saisissez votre numéro de sécurité sociale"
                defaultValue={ssn}
                onChange={setSsn}
                required
            />
            <InputTel
                label="Téléphone portable"
                name="phone"
                error={violations.phoneNumber}
                placeholder="Saisissez votre numéro de téléphone portable"
                defaultValue={phoneNumber}
                onChange={setPhoneNumber}
                required
            />
            <InputEmail
                label="Email"
                error={violations.email}
                placeholder="Saisissez votre email"
                defaultValue={email}
                disabled={true}
                onChange={setEmail}
                required
            />

            <div className="form__group">
                <div className="form__footer text-center">
                    <Button
                        className="button__primaryButton"
                        type="submit"
                        isLoading={profileEditLoading}
                    >
                        Mettre à jour les coordonnées
                    </Button>
                </div>
            </div>
        </form>
    );
}

CoordinatorPatientCoordonneesForm.propTypes = {
    submit: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    profileEditLoading: PropTypes.bool.isRequired,
    violations: PropTypes.object.isRequired,
};

export default CoordinatorPatientCoordonneesForm;
