import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { tryFetchPointsOfAttention, tryEditPointsOfAttention } from "../../../actions/pointsOfAttention";

import PointOfAttentionForm from "../../../components/Settings/Forms/PointOfAttentionForm";

import SettingsEditFormScreen from "../../../components/Settings/SettingsEditFormScreen";

const EditPointOfAttentionScreen = ({
    pointsOfAttention: { datas, fetchLoading, editLoading, editError },
    tryFetchPointsOfAttention,
    tryEditPointsOfAttention,
}) => {
    const formRequest = React.useCallback((id, data) => tryEditPointsOfAttention(id, data), [tryEditPointsOfAttention]);

    return (
        <SettingsEditFormScreen
            /* Document and page titles */
            documentTitle="Modifier point de vigilance"
            pageTitle="Modifier un point de vigilance"
            /* Redux request callback use to add on BDD */
            formRequest={formRequest}
            fetchRequests={tryFetchPointsOfAttention}
            testFetchRequestsNeeded={datas.length === 0}
            /* Forms */
            itemForm={PointOfAttentionForm}
            itemFormRequestLoading={editLoading}
            itemFormRequestError={editError}
            itemFormExtraProps={{ pointsOfAttention: datas }}
            itemFormIsLoading={fetchLoading}
            itemFormEditedItemPropName="editedPointOfAttention"
            /* Items lists to retrieve the current item */
            items={datas}
            /* Configuration tab name for redirection */
            tab="points-of-attention"
        />
    );
};

EditPointOfAttentionScreen.propTypes = {
    pointsOfAttention: PropTypes.shape({
        datas: PropTypes.arrayOf(PropTypes.object).isRequired,
        fetchLoading: PropTypes.bool.isRequired,
        editLoading: PropTypes.bool.isRequired,
        editError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object,
            }),
            PropTypes.bool,
        ]).isRequired,
    }).isRequired,
    tryFetchPointsOfAttention: PropTypes.func.isRequired,
    tryEditPointsOfAttention: PropTypes.func.isRequired,
};

const mapStateToProps = ({ pointsOfAttention }) => ({ pointsOfAttention });

const mapDispatchToProps = { tryFetchPointsOfAttention, tryEditPointsOfAttention };

export default connect(mapStateToProps, mapDispatchToProps)(EditPointOfAttentionScreen);
