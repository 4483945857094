import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { tryCreatePatientAppointment, tryFetchPatientAppointments, tryEditPatientAppointment } from "../../../../actions/patients";
import { tryFetchPractitioners } from "../../../../actions/medicals";

import { DefaultLoader, DashboardHeaderTitle } from "@rdcs/dap-front-library";

import LookLink from "../../LookLink";

import ExamensCards from "./ExamensCards";

const RecommendedExams = ({
    isLoading,
    datas,
    patientId,
    patientIri,
    //userCanMakeAppointment,
    createAppointmentLoading,
    tryFetchPractitioners,
    tryEditPatientAppointment,
    tryCreatePatientAppointment,
    tryFetchPatientAppointments
}) => (
    <section className="dashboardSection">
        <DashboardHeaderTitle title="Examens recommandés" icon="complications-hypertension" className="mt-4 mb-2" />
        {isLoading ? (
            <DefaultLoader color="#CCC" />
        ) : (
            <>
                {datas.length > 0 ? (
                    <>
                        <ExamensCards
                            examens={datas}
                            patientIri={patientIri}
                            searchPractitionerAction={tryFetchPractitioners}
                            createAppointmentLoading={createAppointmentLoading}
                            createAppointmentAction={(appointment) =>
                                tryCreatePatientAppointment(appointment).then(() => tryFetchPatientAppointments(patientId))}
                            //disableCreateAppointment={userCanMakeAppointment}
                            confirmAppointmentAction={tryEditPatientAppointment}
                        />
                        <p className="mt-2">
                            <LookLink link={`/facilitateurs/patient/${patientId}/examens`}>Voir tous les examens recommandés.</LookLink>
                        </p>
                    </>
                ) : (
                    <p>Aucune examen recommandé</p>
                )}
            </>
        )}
    </section>
);

RecommendedExams.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    datas: PropTypes.arrayOf(PropTypes.object).isRequired,
    patientId: PropTypes.number.isRequired,
    patientIri: PropTypes.string.isRequired,
    //userCanMakeAppointment: PropTypes.bool.isRequired,
    createAppointmentLoading: PropTypes.bool.isRequired,
    tryFetchPractitioners: PropTypes.func.isRequired,
    tryEditPatientAppointment: PropTypes.func.isRequired,
    tryCreatePatientAppointment: PropTypes.func.isRequired,
    tryFetchPatientAppointments: PropTypes.func.isRequired,
};

const mapStateToProps = ({ patients: { createAppointmentLoading } }) => ({
    createAppointmentLoading,
});

const mapDispatchToProps = {
    tryFetchPractitioners,
    tryEditPatientAppointment,
    tryCreatePatientAppointment,
    tryFetchPatientAppointments
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(RecommendedExams));
