import React from "react";
import PropTypes from "prop-types";

import { InputText, InputEmail, InputImage, Button } from "@rdcs/dap-front-library";

import API from "../../utils/API";

const ProfileForm = ({
    profile,
    submit,
    violations,
    profileEditLoading
}) => {
    const [firstName, setFirstName] = React.useState(profile.firstName);
    const [lastName, setLastName] = React.useState(profile.lastName);
    const [email, setEmail] = React.useState(profile.email);
    const [avatar, setAvatar] = React.useState(profile.avatar ? profile.avatar["@id"] : null);
    
    const uploadAvatarAction = (file) => (
        API.uploadFile(file)
        .then(item => setAvatar(item["@id"]))
        .catch(() => {
            setAvatar(null);
            throw new Error("L'upload a échoué pour une raison inconnue");
        })
    );

    const handleSubmit = (e) => {
        e.preventDefault();
        submit({ firstName, lastName, email, avatar });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="grid grid__padding-10">
                <div className="col-xs-50">
                    <InputText
                        label="Votre nom"
                        placeholder="Saisissez votre nom"
                        name="lastName"
                        onChange={setLastName}
                        error={violations.lastName}
                        defaultValue={lastName}
                        required
                    />
                </div>
                <div className="col-xs-50">
                    <InputText
                        label="Votre prénom"
                        placeholder="Saisissez votre prénom"
                        name="firstName"
                        onChange={setFirstName}
                        error={violations.firstName}
                        defaultValue={firstName}
                        required
                    />
                </div>
            </div>
            <InputEmail
                label="Votre email"
                legend="Cette adresse nous sert à vous communiquer des informations importantes. Nous ne la communiquerons à aucun tiers."
                disabled
                onChange={setEmail}
                defaultValue={email}
                required
            />
            <InputImage
                label="Votre photo de profil"
                uploadAction={uploadAvatarAction}
                error={violations.avatar}
                defaultFilename={avatar}
                text="Télécharger"
            />
            <div className="credentialsMain__validate">
                <Button type="submit" className="button__primaryButton" isLoading={profileEditLoading}>
                    Enregistrer
                </Button>
            </div>
        </form>
    );
}

ProfileForm.propTypes = {
    profile: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        avatar: PropTypes.shape({
            "@id": PropTypes.string.isRequired,
        }),
    }).isRequired,
    submit: PropTypes.func.isRequired,
    violations: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    profileEditLoading: PropTypes.bool.isRequired
};

export default ProfileForm;
