import * as types from "./types";
import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import store from "../store";

export const tryFetchCoordinators = (auth) => (dispatch) => {
    dispatch({ type: types.COORDINATORS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/coordinators" : "/dap-admin/api/v1/customers/admins/coordinators",
        queryParams: {
            pagination: false,
        },
        token: auth.accessToken,
    })
        .then((response) => dispatch({ type: types.COORDINATORS_FETCH_SUCCESS, data: response["hydra:member"] }))
        .catch((error) => dispatch({ type: types.COORDINATORS_FETCH_FAILURE, data: error }));
};

export const tryAddCoordinator = (coordinator) => (dispatch) => {
    const { auth } = store.getState();

    dispatch({ type: types.ADD_COORDINATOR_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/coordinators" : "/dap-admin/api/v1/customers/admins/coordinators",
        token: auth.accessToken,
        method: "POST",
        data: {
            ...coordinator,
            enable: true,
        },
    })
        .then((response) => dispatch({ type: types.ADD_COORDINATOR_SUCCESS, data: response }))
        .catch((error) => {
            dispatch({ type: types.ADD_COORDINATOR_FAILURE, data: error });
            throw error;
        });
};

export const tryRemoveCoordinator = (auth, coordinatorId) => (dispatch) => {
    dispatch({ type: types.REMOVE_COORDINATOR_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/coordinators/{id}" : "/dap-admin/api/v1/customers/admins/coordinators/{id}",
        uriParams: {
            id: coordinatorId,
        },
        method: "DELETE",
        token: auth.accessToken,
    })
        .then(() => dispatch({ type: types.REMOVE_COORDINATOR_SUCCESS, data: { pathId: "/coordinators/" + coordinatorId } }))
        .catch((error) => dispatch({ type: types.REMOVE_COORDINATOR_FAILURE, data: error }));
};

export const tryEditCoordinator = (coordinatorId, form) => (dispatch) => {
    const { auth } = store.getState();

    dispatch({ type: types.EDIT_COORDINATOR_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/coordinators/{id}" : "/dap-admin/api/v1/customers/admins/coordinators/{id}",
        uriParams: {
            id: coordinatorId,
        },
        token: auth.accessToken,
        method: "PUT",
        data: form,
    })
        .then((response) => dispatch({ type: types.EDIT_COORDINATOR_SUCCESS, data: response }))
        .catch((error) => {
            dispatch({ type: types.EDIT_COORDINATOR_FAILURE, data: error });
            throw error;
        });
};

export const tryFetchDepartments = (auth) => (dispatch) => {
    dispatch({ type: types.COORDINATORS_DEPARTMENTS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/departments" : "/dap-admin/api/v1/organizations/departments",
        token: auth.accessToken,
    })
        .then((response) => dispatch({ type: types.COORDINATORS_DEPARTMENTS_FETCH_SUCCESS, data: response["hydra:member"] }))
        .catch((error) => dispatch({ type: types.COORDINATORS_DEPARTMENTS_FETCH_FAILURE, data: error }));
};
