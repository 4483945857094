import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { tryEditTreatmentGroup, tryFetchTreatmentGroups, tryFetchTreatments } from "../../../actions/treatments";

import TreatmentGroupForm from "../../../components/Settings/Forms/TreatmentGroupForm";
import SettingsEditFormScreen from "../../../components/Settings/SettingsEditFormScreen";

const EditTreatmentGroupScreen = ({
    treatments: { treatmentGroups, treatmentGroupsLoading, treatments, treatmentsLoading, editLoading, editError },
    tryEditTreatmentGroup,
    tryFetchTreatmentGroups,
    tryFetchTreatments
}) => (
    <SettingsEditFormScreen
        /* Document and page titles */
        documentTitle="Modifier un groupe de traitement"
        pageTitle="Modifier un groupe de traitement"
        /* Redux request callback use to add on BDD */
        formRequest={tryEditTreatmentGroup}
        fetchRequests={[tryFetchTreatmentGroups, tryFetchTreatments]}
        testFetchRequestsNeeded={[treatmentGroups.length === 0, treatments.length === 0]}
        /* Forms */
        itemForm={TreatmentGroupForm}
        itemFormRequestLoading={editLoading}
        itemFormRequestError={editError}
        itemFormExtraProps={{ treatments }}
        itemFormIsLoading={treatmentGroupsLoading || treatmentsLoading}
        itemFormEditedItemPropName="editedTreatmentGroup"
        /* Items lists to retrieve the current item */
        items={treatmentGroups}
        /* Configuration tab name for redirection */
        tab="treatment-groups"
    />
);

EditTreatmentGroupScreen.propTypes = {
    treatments: PropTypes.shape({
        treatmentGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
        treatmentGroupsLoading: PropTypes.bool.isRequired,
        treatments: PropTypes.arrayOf(PropTypes.object).isRequired,
        treatmentsLoading: PropTypes.bool.isRequired,
        editLoading: PropTypes.bool.isRequired,
        editError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object
            }),
            PropTypes.bool
        ]).isRequired
    }).isRequired,
    tryEditTreatmentGroup: PropTypes.func.isRequired,
    tryFetchTreatmentGroups: PropTypes.func.isRequired,
    tryFetchTreatments: PropTypes.func.isRequired
};

const mapStateToProps = ({ treatments }) => ({ treatments });

const mapDispatchToProps = { tryEditTreatmentGroup, tryFetchTreatmentGroups, tryFetchTreatments };

export default connect(mapStateToProps, mapDispatchToProps)(EditTreatmentGroupScreen);
