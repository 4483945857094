import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import {
    tryAcceptMedicalJustificatory,
    tryEditMedical,
    tryRefuseMedicalJustificatory,
    tryBanMedical
} from "../../actions/medicals";

import Moment from "moment";

import { DownloadPDF, Icon, Modal, Button, ImagePreview } from "@rdcs/dap-front-library";

import { constant } from "../../utils/utils";

const MedicalCard = ({
    auth,
    tryEditMedical,
    tryAcceptMedicalJustificatory,
    tryRefuseMedicalJustificatory,
    tryBanMedical,
    medical,
    fetchPractitioners
}) => {
    const modalRef = React.useRef();

    const banMedical = () =>
        tryBanMedical(auth, medical).then(() => {
            fetchPractitioners();
            modalRef && modalRef.current && modalRef.current.toggleModal();
        });

    const status = React.useMemo(() => {
        if (medical.justificatoryValid === null && medical.justificatory !== null) {
            return "waiting";
        } else if (medical.justificatoryValid === false) {
            return "rejected";
        } else if (medical.justificatoryValid === true) {
            return "valid";
        } else if (medical.justificatory === null) {
            return "empty";
        }
        return "";
    }, [medical]);

    const submitJustificatoryValidity = isAccepted =>
        (isAccepted
            ? tryAcceptMedicalJustificatory(auth, medical)
            : tryRefuseMedicalJustificatory(auth, medical)
        ).then(() => fetchPractitioners());

    const toggleActivateAccount = () =>
        tryEditMedical(auth, medical, { enable: !medical.enable }).then(() => fetchPractitioners());

    return (
        <div className={`moderationListItem moderationListItem--${medical.enable === false ? "disabled" : "enabled"}`}>
            {medical.avatar && (
                <ImagePreview filename={medical.avatar.filename} className="moderationListItem__visuel" />
            )}
            <div className="moderationListItem__infos">
                <div className="moderationListItem__name">
                    {medical.titre ? constant("titres", medical.titre) + " " : ""}{" "}
                    {medical.firstName.toUpperCase() + " " + medical.lastName}
                </div>
                <div className="moderationListItem__infos-list">
                    {medical.profession && <span className="moderationListItem__infos-item">{medical.profession}</span>}
                    {medical.establishment && (
                        <span className="moderationListItem__infos-item">{medical.establishment}</span>
                    )}
                    {medical.email && <span className="moderationListItem__infos-item">{medical.email}</span>}
                    {medical.address && (
                        <span className="moderationListItem__infos-item">
                            {medical.address + ", " + medical.zipcode + " " + medical.city}
                        </span>
                    )}
                    {medical.rppsNumber && (
                        <span className="moderationListItem__infos-item">RPPS: {medical.rppsNumber}</span>
                    )}
                    <span className="moderationListItem__infos-item">
                        Inscrit le {Moment(medical.createdAt).format("DD/MM/YYYY")}
                    </span>
                </div>
            </div>
            <div className={`moderationListItem__status moderationListItem__status--${status}`}>
                <div className="moderationListItem__status-infos">
                    <div className="moderationListItem__status-text">
                        {status === "waiting" && "En attente d'acceptation"}
                        {status === "rejected" && "Justificatif refusé"}
                        {status === "valid" && "Justificatif accepté"}
                    </div>
                    {medical.validationDate && (
                        <div className="moderationListItem__status-date">
                            Validé le {Moment(medical.validationDate).format("DD MMM YYYY")}
                        </div>
                    )}
                </div>
                {status === "waiting" && (
                    <Modal
                        buttonClass="button-reset"
                        buttonContent={<Icon name="waiting-file" className="moderationListItem__icon" />}
                    >
                        <div className="modal__title">Justificatif</div>
                        <DownloadPDF
                            name="justificatif-upload"
                            defaultFilename={medical.justificatory ? medical.justificatory.filename : ""}
                        />
                        <div className="modal__btnGroup">
                            <Button
                                className="button__secondaryButton"
                                onClick={() => submitJustificatoryValidity(false)}
                            >
                                Refuser le justificatif
                            </Button>
                            <Button className="button__primaryButton" onClick={() => submitJustificatoryValidity(true)}>
                                Valider le justificatif
                            </Button>
                        </div>
                    </Modal>
                )}
                {status === "rejected" && (
                    <Modal
                        buttonClass="button-reset"
                        buttonContent={<Icon name="rejected-file" className="moderationListItem__icon" />}
                    >
                        <div className="modal__title">Justificatif</div>
                        <DownloadPDF
                            name="justificatif-upload"
                            defaultFilename={medical.justificatory ? medical.justificatory.filename : ""}
                        />
                    </Modal>
                )}
                {status === "valid" && (
                    <Modal
                        buttonClass="button-reset"
                        buttonContent={<Icon name="valid-file" className="moderationListItem__icon" />}
                    >
                        <div className="modal__title">Justificatif</div>
                        <DownloadPDF
                            name="justificatif-upload"
                            defaultFilename={medical.justificatory ? medical.justificatory.filename : ""}
                        />
                    </Modal>
                )}
                {status === "empty" && <Icon name="add-file" className="moderationListItem__icon" />}
            </div>

            <div className="moderationListItem__actions">
                <div className="moderationListItem__actions-content">
                    {medical.enable === false && (
                        <button className="button-reset moderationListItem__action" onClick={toggleActivateAccount}>
                            <Icon name="check-circle" className="moderationListItem__action-icon" />
                            Activer le compte
                        </button>
                    )}
                    {medical.enable === true && (
                        <button className="button-reset moderationListItem__action" onClick={toggleActivateAccount}>
                            <Icon name="cross-circle" className="moderationListItem__action-icon" />
                            Désactiver le compte
                        </button>
                    )}

                    <Modal
                        buttonClass="button-reset moderationListItem__action text-center"
                        buttonContent={
                            <>
                                <Icon name="trash" className="moderationListItem__action-icon" />
                                Bloquer l'utilisateur
                            </>
                        }
                        ref={modalRef}
                    >
                        <div className="modal__title">Êtes-vous sûr de votre choix ?</div>
                        <div className="modal__btnGroup">
                            <Button
                                className="button__secondaryButton"
                                onClick={() => {
                                    modalRef.current.toggleModal();
                                }}
                            >
                                Refuser
                            </Button>
                            <Button className="button__primaryButton" onClick={banMedical}>
                                Bannir
                            </Button>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    );
};

MedicalCard.propTypes = {
    auth: PropTypes.object.isRequired,
    tryEditMedical: PropTypes.func.isRequired,
    tryAcceptMedicalJustificatory: PropTypes.func.isRequired,
    tryRefuseMedicalJustificatory: PropTypes.func.isRequired,
    tryBanMedical: PropTypes.func.isRequired,
    medical: PropTypes.shape({
        id: PropTypes.number.isRequired,
        enable: PropTypes.bool.isRequired,
        avatar: PropTypes.shape({
            filename: PropTypes.string.isRequired
        }),
        titre: PropTypes.string,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        profession: PropTypes.string,
        establishment: PropTypes.string,
        email: PropTypes.string,
        address: PropTypes.string,
        zipcode: PropTypes.string,
        city: PropTypes.string,
        rppsNumber: PropTypes.string,
        createdAt: PropTypes.string,
        validationDate: PropTypes.string,
        justificatory: PropTypes.shape({
            filename: PropTypes.string.isRequired
        }),
        justificatoryValid: PropTypes.bool
    }).isRequired,
    fetchPractitioners: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = {
    tryEditMedical,
    tryAcceptMedicalJustificatory,
    tryRefuseMedicalJustificatory,
    tryBanMedical
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicalCard);
