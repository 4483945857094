import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { tryCreatePointsOfAttention, tryFetchPointsOfAttention } from "../../../actions/pointsOfAttention";

import PointOfAttentionForm from "../../../components/Settings/Forms/PointOfAttentionForm";

import SettingsFormScreen from "../../../components/Settings/SettingsFormScreen";

const AddPointOfAttentionScreen = ({
    pointsOfAttention,
    pointsOfAttention: { fetchLoading, createLoading, createError },
    tryCreatePointsOfAttention,
    tryFetchPointsOfAttention,
}) => {
    const formRequest = React.useCallback((data) => tryCreatePointsOfAttention(data), [tryCreatePointsOfAttention]);

    return (
        <SettingsFormScreen
            /* Document and page titles */
            documentTitle="Ajouter point de vigilance"
            pageTitle="Ajouter un point de vigilance"
            /* Redux request callback use to add on BDD */
            formRequest={formRequest}
            fetchRequests={tryFetchPointsOfAttention}
            /* Forms */
            itemForm={PointOfAttentionForm}
            itemFormRequestLoading={createLoading}
            itemFormRequestError={createError}
            itemFormExtraProps={{ pointsOfAttention: pointsOfAttention.datas }}
            itemFormIsLoading={fetchLoading}
            /* Configuration tab name for redirection */
            tab="points-of-attention"
        />
    );
};

AddPointOfAttentionScreen.propTypes = {
    pointsOfAttention: PropTypes.shape({
        fetchLoading: PropTypes.bool.isRequired,
        createLoading: PropTypes.bool.isRequired,
        createError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object,
            }),
            PropTypes.bool,
        ]).isRequired,
        datas: PropTypes.object.isRequired,
    }).isRequired,
    tryCreatePointsOfAttention: PropTypes.func.isRequired,
    tryFetchPointsOfAttention: PropTypes.func.isRequired,
};

const mapStateToProps = ({ pointsOfAttention }) => ({ pointsOfAttention });

const mapDispatchToProps = { tryCreatePointsOfAttention, tryFetchPointsOfAttention };

export default connect(mapStateToProps, mapDispatchToProps)(AddPointOfAttentionScreen);
