import React from "react";
import PropTypes from "prop-types";

import { Button, CoordinatorHCPAgreement, CoordinatorConsent, AntdTooltip } from "@rdcs/dap-front-library";
import Moment from "moment";

const PotentialPatientsItem = ({ patient, index, consentPatient, consentPractitionner, createNewPatient }) => (
    <li key={patient.id} className="coordinatorListItem">
        <AntdTooltip placement="right" title={patient.statusReason}>
            <div className={`statusDot statusDot--${patient.statusColor} mr-2`} />
        </AntdTooltip>
        <div className="coordinatorListItem__infos coordinatorListItem__infos-list coordinatorListItem--Patient coordinatorListItem--blocInfos">
            <div className="coordinatorListItem--infos">
                <p className="coordinatorListItem__name--Patient">{`${patient.firstName} ${patient.lastName}`}</p>
                <p>{patient.phoneNumber}</p>
            </div>
            <div className="coordinatorListItem--infos">
                <p>{patient.filledBy.lastName.toUpperCase()} {patient.filledBy.firstName.toUpperCase()}{patient.filledBy.zipcode && ` (${patient.filledBy.zipcode})`}</p>
            </div>
            <div className="coordinatorListItem--infos">
                {patient.practitioner && (
                    <>
                        <p>
                            {`${
                                patient.practitioner.titre
                                    ? patient.practitioner.titre[0].toUpperCase() +
                                      patient.practitioner.titre.slice(1).toLowerCase()
                                    : ""
                            } ${patient.practitioner.firstName} ${patient.practitioner.lastName}
                                ${patient.practitioner.zipcode ? `(${patient.practitioner.zipcode})` : ''}
                            `}
                        </p>
                        <p className="coordinatorListItem__name">{patient.practitioner.phoneNumber1}</p>
                    </>
                )}
            </div>
            <div className="coordinatorListItem--infos">
                <div className="text-center">
                    {patient.consentPatientValidated !== null ? (
                        <p className="notice text-center txtBold">
                            Consentement vérifié le {Moment(patient.consentPatientValidationDate).format("DD/MM/YYYY")}
                        </p>
                    ) : (
                        <CoordinatorConsent
                            buttonClass="button__primaryButton button__smallButton"
                            filename={patient.consent ? patient.consent.filename : ""}
                            onCancel={() => consentPatient(index, patient, false)}
                            onSubmit={() => consentPatient(index, patient, true)}
                        />
                    )}
                </div>
                <div className="text-center">
                    {patient.consentPractitionnerValidated !== null ? (
                        <p className="notice text-center txtBold">
                            Le médecin a donné son accord le{" "}
                            {Moment(patient.consentPractitionnerDate).format("DD/MM/YYYY")}
                        </p>
                    ) : (
                        <CoordinatorHCPAgreement
                            buttonClass="button__primaryButton button__smallButton"
                            hcp={{
                                firstName: patient.practitioner ? patient.practitioner.firstName : "",
                                lastName: patient.practitioner ? patient.practitioner.lastName : "",
                                titre: patient.practitioner
                                    ? patient.practitioner.titre
                                        ? patient.practitioner.titre[0].toUpperCase() +
                                          patient.practitioner.titre.slice(1).toLowerCase() +
                                          "."
                                        : ""
                                    : "",
                                zipcode: patient.practitioner ? patient.practitioner.zipcode : "",
                                city: patient.practitioner ? patient.practitioner.city : "",
                                phoneNumber1: patient.practitioner ? patient.practitioner.phoneNumber1 : ""
                            }}
                            onCancel={() => consentPractitionner(index, patient, false)}
                            onSubmit={() => consentPractitionner(index, patient, true)}
                        />
                    )}
                </div>
                <p className="text-center">
                    <Button
                        className="button__primaryButton button__smallButton"
                        onClick={() => createNewPatient(patient)}
                        disabled={
                            !(
                                patient.consentPatientValidated === true &&
                                patient.consentPractitionnerValidated === true
                            )
                        }
                    >
                        Créer le compte patient
                    </Button>
                </p>
            </div>
        </div>
    </li>
);

PotentialPatientsItem.propTypes = {
    patient: PropTypes.shape({
        id: PropTypes.number.isRequired,
        statusReason: PropTypes.string,
        statusColor: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        phoneNumber: PropTypes.string,
        email: PropTypes.string,
        filledBy: PropTypes.shape({
            address: PropTypes.string,
            zipcode: PropTypes.string,
            createdAt: PropTypes.string
        }).isRequired,
        practitioner: PropTypes.shape({
            titre: PropTypes.string,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            zipcode: PropTypes.string,
            phoneNumber1: PropTypes.string,
            city: PropTypes.string
        }),
        consentPatientValidated: PropTypes.bool,
        consentPatientValidationDate: PropTypes.string,
        consentPractitionnerDate: PropTypes.string,
        consentPractitionnerValidated: PropTypes.bool
    }).isRequired,
    index: PropTypes.number.isRequired,
    consentPatient: PropTypes.func.isRequired,
    consentPractitionner: PropTypes.func.isRequired,
    createNewPatient: PropTypes.func.isRequired
};

export default PotentialPatientsItem;
