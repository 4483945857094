import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import ThematicForm from "../../../components/Settings/Forms/ThematicForm";
import SettingsFormScreen from "../../../components/Settings/SettingsFormScreen";
import { tryCreateThematic, tryFetchThematic } from "../../../actions/thematics";

const AddThematicsScreen = ({ thematic, thematic: { fetchLoading, createLoading, createError }, tryCreateThematic, tryFetchThematic }) => {
    const formRequest = React.useCallback((data) => tryCreateThematic(data), [tryCreateThematic]);

    return (
        <SettingsFormScreen
            /* Document and page titles */
            documentTitle="Ajouter une thématique"
            pageTitle="Ajouter une thématique"
            /* Redux request callback use to add on BDD */
            formRequest={formRequest}
            fetchRequests={tryFetchThematic}
            /* Forms */
            itemForm={ThematicForm}
            itemFormRequestLoading={createLoading}
            itemFormRequestError={createError}
            itemFormExtraProps={{ thematic: thematic.datas }}
            itemFormIsLoading={fetchLoading}
            /* Configuration tab name for redirection */
            tab="thematic"
        />
    );
};

AddThematicsScreen.propTypes = {
    thematic: PropTypes.shape({
        createLoading: PropTypes.bool.isRequired,
        createError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object,
            }),
            PropTypes.bool,
        ]).isRequired,
    }).isRequired,
    tryCreateThematic: PropTypes.func.isRequired,
    tryFetchThematic: PropTypes.func.isRequired,
};

const mapStateToProps = ({ thematic }) => ({ thematic });

const mapDispatchToProps = { tryCreateThematic, tryFetchThematic };

export default connect(mapStateToProps, mapDispatchToProps)(AddThematicsScreen);
