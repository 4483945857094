import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import Questions from "../../../actions/questions";

import BreakScoreQuestionForm from "../../../components/Settings/Forms/BreakScoreQuestionForm";

import SettingsFormScreen from "../../../components/Settings/SettingsFormScreen";

const { tryAddBreakScoreNoInsulinQuestion } = Questions;

const AddBreakScoreNoInsulinQuestionScreen = ({ questions: { createLoading, createError }, tryAddBreakScoreNoInsulinQuestion }) => (
    <SettingsFormScreen
        /* Document and page titles */
        documentTitle="Ajouter question de rupture pour patient non-insulinotraité"
        pageTitle="Ajouter une question de rupture pour patient non-insulinotraité"
        /* Redux request callback use to add on BDD */
        formRequest={tryAddBreakScoreNoInsulinQuestion}
        /* Forms */
        itemForm={BreakScoreQuestionForm}
        itemFormRequestLoading={createLoading}
        itemFormRequestError={createError}
        /* Configuration tab name for redirection */
        tab="break-score-no-insulin-questions"
    />
);

AddBreakScoreNoInsulinQuestionScreen.propTypes = {
    questions: PropTypes.shape({
        createLoading: PropTypes.bool.isRequired,
        createError: PropTypes.oneOfType([
            PropTypes.shape({
                violations: PropTypes.object
            }),
            PropTypes.bool
        ]).isRequired
    }).isRequired,
    tryAddBreakScoreNoInsulinQuestion: PropTypes.func.isRequired
};

const mapStateToProps = ({ questions }) => ({ questions });

const mapDispatchToProps = { tryAddBreakScoreNoInsulinQuestion };

export default connect(mapStateToProps, mapDispatchToProps)(AddBreakScoreNoInsulinQuestionScreen);
