import React from "react";
import PropTypes from "prop-types";
import { withRouter, useParams } from "react-router-dom";

import { connect } from "react-redux";
import { tryFetchLastDiagnosis, tryFetchConsultationAnswers } from "../../actions/patients";
import { tryFetchDiagnosisRecos } from "../../actions/diagnosis";
import { tryFetchComplications } from "../../actions/complications";

import moment from "moment";
import { DefaultLoader, Icon, SelectDiagnosticComplications, stringIdToId } from "@rdcs/dap-front-library";

import CoordinatorPatientLayout from "../../components/Coordinator/CoordinatorPatientLayout";
import PatientLastDiagnosticQuestionsItem from "../../components/Coordinator/PatientLastDiagnosticQuestionsItem";
import { formatHBA1C } from "../../utils/utils";
import PDFConsults from "../../components/Diagnosis/PDFConsults";
import sortArrayByDueAtAndPosition from "../../components/Diagnosis/DiagnosisStep4/sortArrayByDueAtAndPosition";

const CoordinatorPatientLastDiagnosticScreen = ({
    auth,
    diagnosis,
    patients,
    complications,
    tryFetchLastDiagnosis,
    tryFetchComplications,
    tryFetchConsultationAnswers,
    tryFetchDiagnosisRecos,
}) => {
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);

    const [dataLoading, setDataLoading] = React.useState(true);

    const referringDoctor = patients?.singlePatient?.accessedBy.reverse().find((hcp) => hcp.user["@type"] === "Doctor").user;

    React.useEffect(() => {
        document.title = "Timkl - Dernière consultation";

        Promise.all([tryFetchLastDiagnosis(patientId), tryFetchComplications(auth)])
            .then(([_lastDiagnostic]) => {
                if (_lastDiagnostic) {
                    return tryFetchConsultationAnswers(auth, _lastDiagnostic.id) && tryFetchDiagnosisRecos(_lastDiagnostic);
                }
            })
            .finally(() => {
                setDataLoading(false);
            });
    }, []);

    const diagnostics = React.useMemo(() => sortArrayByDueAtAndPosition(diagnosis.recos.filter((r) => r.active === true)), [diagnosis.recos]);

    const complicationsDisplaySelected = React.useMemo(
        () => diagnosis.patientLastDiagnosis?.complicationInstances.filter((c) => c.value === true).map((c) => stringIdToId(c.complication)),
        [diagnosis.patientLastDiagnosis?.complicationInstances]
    );

    const complicationsDisplay = React.useMemo(
        () =>
            complicationsDisplaySelected &&
            complications.list
                .filter((c) => complicationsDisplaySelected.includes(c.id))
                .map((c) => ({
                    id: c.id,
                    name: c.name,
                    description: c.description,
                    parent: c.parent,
                    icon: c.icon,
                })),
        [complications, complicationsDisplaySelected]
    );

    return (
        <CoordinatorPatientLayout activeNav="consultation">
            <div className="contentHolder__title">
                <Icon name="stats" className="contentHolder__title-icon" />
                <h1 className="title-1">Dernière consultation</h1>
                {diagnosis.patientLastDiagnosis && <p className="subtitle-1">le {moment(diagnosis.patientLastDiagnosis.createdAt).format("DD/MM/YYYY")}</p>}
            </div>

            {dataLoading ? (
                <DefaultLoader />
            ) : !diagnosis.patientLastDiagnosis ? (
                <div>Nous vous invitons à réaliser la première consultation du patient</div>
            ) : (
                <div className="informationsList">
                    <div className="informationsList__item">
                        <span className="informationsList__item-sourceLink">
                            <Icon name="puce-reco" />
                        </span>
                        Type de diabète : {diagnosis.patientLastDiagnosis.pathology}
                    </div>
                    <div className="informationsList__item">
                        <span className="informationsList__item-sourceLink">
                            <Icon name="puce-reco" />
                        </span>
                        Objectif : {formatHBA1C(diagnosis.patientLastDiagnosis.goal)}%
                    </div>
                    <div className="informationsList__item">
                        <span className="informationsList__item-sourceLink">
                            <Icon name="puce-reco" />
                        </span>
                        <div>Complications :</div>
                        <SelectDiagnosticComplications
                            complications={complicationsDisplay}
                            defaultSelected={complicationsDisplaySelected}
                            disabled={true}
                            singleColumnLayout={true}
                            onChange={() => {}}
                        />
                    </div>
                    {patients.consultationAnswers.length ? (
                        <div className="informationsList__item">
                            <span className="informationsList__item-sourceLink">
                                <Icon name="puce-reco" />
                            </span>
                            Questions de la dernière consultation :
                            <PatientLastDiagnosticQuestionsItem answers={patients.consultationAnswers} />
                        </div>
                    ) : null}
                    <div>
                        <PDFConsults diagnostics={diagnostics} patient={patients.singlePatient} hcp={referringDoctor} nbOfMonths={3} />
                        <PDFConsults diagnostics={diagnostics} patient={patients.singlePatient} hcp={referringDoctor} nbOfMonths={12} />
                    </div>
                </div>
            )}
        </CoordinatorPatientLayout>
    );
};

CoordinatorPatientLastDiagnosticScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    diagnosis: PropTypes.object.isRequired,
    patients: PropTypes.object.isRequired,
    complications: PropTypes.object.isRequired,
    tryFetchLastDiagnosis: PropTypes.func.isRequired,
    tryFetchComplications: PropTypes.func.isRequired,
    tryFetchConsultationAnswers: PropTypes.func.isRequired,
    tryFetchDiagnosisRecos: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, patients, complications, diagnosis }) => ({
    auth,
    diagnosis,
    patients,
    complications,
});

const mapDispatchToProps = {
    tryFetchLastDiagnosis,
    tryFetchComplications,
    tryFetchConsultationAnswers,
    tryFetchDiagnosisRecos,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CoordinatorPatientLastDiagnosticScreen));
