import * as types from "./types";
import API from "../utils/API";
import { tryRefreshToken } from "./auth";
import { userCan } from "../reducers/auth";
import { isLocalAPI } from "../utils/utils";

const getPathUrl = () => {
    if (userCan("ROLE_ADMIN")) {
        return isLocalAPI ? "/admins/{id}" : "/dap-admin/api/v1/customers/admins/{id}";
    } else if (userCan("ROLE_MODERATOR")) {
        return isLocalAPI ? "/moderators/{id}" : "/dap-admin/api/v1/customers/admins/moderators/{id}";
    } else if (userCan("ROLE_COORDINATOR")) {
        return isLocalAPI ? "/coordinators/{id}" : "/dap-admin/api/v1/customers/admins/coordinators/{id}";
    }
    return isLocalAPI ? "/admins/{id}" : "/dap-admin/api/v1/customers/admins/{id}";
};

export const tryFetchProfile = auth => dispatch => {
    dispatch({ type: types.PROFILE_FETCH_REQUESTED });
    return API.request({
        path: getPathUrl(),
        uriParams: {
            id: auth.user.id
        },
        token: auth.accessToken
    })
        .then(response => dispatch({ type: types.PROFILE_FETCH_SUCCESS, data: response }))
        .catch(error => dispatch({ type: types.PROFILE_FETCH_FAILURE, data: error }));
};

export const tryEditProfile = (auth, profile) => dispatch => {
    dispatch({ type: types.PROFILE_EDIT_REQUESTED });
    return API.request({
        path: getPathUrl(),
        uriParams: {
            id: auth.user.id
        },
        method: "PUT",
        token: auth.accessToken,
        data: profile
    })
        .then(response => {
            dispatch({ type: types.PROFILE_EDIT_SUCCESS, data: response });
            return tryRefreshToken(auth)(dispatch).then(() => response);

        })
        .catch(error => {
            dispatch({type: types.PROFILE_EDIT_FAILURE, data: error})
            throw error
        });
};

export const tryUpdateUserInfos = (avatar, firstName, lastName) => dispatch => {
    dispatch({ type: types.PROFILE_EDIT_UPDATE, avatar, firstName, lastName });
};
