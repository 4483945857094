import React, { Fragment, useCallback, useMemo } from "react";
import {
  DropDownItem,
  TextArea,
  toTree
} from "@rdcs/dap-front-library";

const CheckboxesItem = ({ checkboxes, item, defaultChecked, onChange, disabled }) => {
  const isItemInCheckboxes = useMemo(() => checkboxes.some(checkbox => checkbox["@id"] === item["@id"]), []);
  const _onChange = useCallback(e => onChange(e, item), []);

  return (
    <DropDownItem
        title={item.name}
        defaultChecked={isItemInCheckboxes || defaultChecked} 
        onChange={disabled || _onChange}
        disabled={disabled}
      />
  );
};

const CheckboxesList = ({ checkboxes, items, handleChangeConseilSante, handleChangeOther, comment, disabled, deep = 0 }) =>  
  items.map(item => (
    <Fragment key={item.key}>
      <div className={`${deep ? `ml-${deep * 4}` : undefined}`}>
        <CheckboxesItem
          checkboxes={checkboxes}
          item={item}
          defaultChecked={item.isOther === true && comment !== null}
          onChange={item.isOther === true ? handleChangeOther : handleChangeConseilSante}
          disabled={disabled}
        />
      </div>
      {item.children?.length ?
      <CheckboxesList
        checkboxes={checkboxes}
        items={item.children}
        handleChangeConseilSante={handleChangeConseilSante}
        handleChangeOther={handleChangeOther}
        comment={comment}
        disabled={disabled}
        deep={deep + 1}
      /> : null}
    </Fragment>
  ));

const CheckboxesReport = ({ checkboxes, question, pointsOfAttention, noPointComment, comment, disabled }) => {
  const _questions = useMemo(() => {
    const _questions = toTree((question ?? []).map(q => ({...q, parent: q.parent?.id ?? null})) );
    const otherItemExists = _questions.some(item => item.name === "Autres (à préciser)");
  
    if (!otherItemExists) {
      _questions.push({ name: "Autres (à préciser)", value: false, isOther: true});
    }

    return _questions;
  }, [question]);

  return (
    <div className="mt-2 ml-2" >
      <h2 className="mb-2 timkl__color--black"> {pointsOfAttention ? "Point(s) de vigilance(s) abordés lors de l’entretien de suivi" : "Thématique(s) abordé(s) lors de l’entretien de suivi"}</h2>
      {noPointComment === null &&
        <>
          <CheckboxesList
            checkboxes={checkboxes}
            items={_questions}
            comment={comment}
            disabled={disabled}
          />
          {comment && <TextArea placeholder={comment} className="textAreaBlueBorder"  />}

          <div className="mt-2 mb-2" style={{ width: "83px", borderBottom: "solid 1px #94BBE3" }}></div>
        </>
      }
      <DropDownItem title="Aucune des thématiques ci-dessus n’a été abordée" defaultChecked={noPointComment === null} disabled={disabled} ></DropDownItem>
      {noPointComment !== null ? <TextArea placeholder={noPointComment} className="textAreaBlueBorder" disabled={disabled} /> : null}

    </div>
  )

}


export default CheckboxesReport