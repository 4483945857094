import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import moment from "moment";

import { tryCreatePatientAppointment } from "../../../actions/patients";
import { isLocalAPI } from "../../../utils/utils";

import { TextArea, QuestionCloseInput, DropDown, Icon } from "@rdcs/dap-front-library";

import CheckboxesReport from "./checkboxesReport";

import RecoCard from "./recoCars";

import AddVisit from "./addVisit";

const g_listenObjectiveQuestions = [{
    question: 'Avez-vous des éléments d’aggravation de la situation du patient à signaler ?',
    name: 'aggravatingElements'
}, {
    question: 'Avez-vous repéré des éléments bloquant la réalisation du parcours de soin du patient ? ',
    name: 'blockingElements'
}, {
    question: "Avez-vous fixé un ou plusieurs objectifs avec le patient d'ici le prochain entretien ?",
    name: 'objective',
}];

const ContentDropdown = ({ pointsOfAttention, diagnosis, patients, thematic, actualReport, prevObjective = null }) => {
    const comment = React.useMemo(() => actualReport ? actualReport.comment : null, []);

    const listenObjectiveState = React.useMemo(() => g_listenObjectiveQuestions.reduce((result, { name }) => ({
        ...result,
        [name]: actualReport?.[name] || null,
    }), {}),
    [actualReport]);

    const checkboxesPointOfAttention = React.useMemo(() =>
        (actualReport?.patientPointOfAttentions ?? []).map((item) => item.pointOfAttention) ?? []
    , []);

    const consultAppointments = React.useMemo(
        () => patients.patientAppointments.filter(({ recommendations }) => recommendations.find(({ type }) => type === "consultation")),
        [patients.patientAppointments]
    );

    const recoConsults = React.useMemo(() => {
        const now = moment().unix();
        return patients.diagnosticRecommendations
            .filter(({ recommendation: { type } }) => type === "consultation")
            .map((reco) => {
                for (const consultAppointment of consultAppointments) {
                    if (consultAppointment.recommendations.find((recommendation) => recommendation.id === reco.recommendation.id)) {
                        return {
                            ...reco,
                            statusColor: "green",
                            appointment: consultAppointment,
                        };
                    }
                }
                return { ...reco, statusColor: now < moment(reco.dueAt).unix() ? "orange" : "red" };
            })
            .filter(({ appointment = null }) => !appointment?.doneAt)
            .sort((a, b) => {
                const codeA = a.statusColor.charCodeAt(0);
                const codeB = b.statusColor.charCodeAt(0);

                if (codeA !== codeB) {
                    return codeB - codeA;
                }
                return new Date(a.appointment?.date || a.dueAt) - new Date(b.appointment?.date || b.dueAt);
            });
    }, [diagnosis.recos, consultAppointments]);

    return (
        <div className="mx-4 mt-4 d-flex flex-column justify-space-between" style={{ height: "100%" }}>
            <div>
                {prevObjective && (
                    <div className="mb-2">
                        <p className="report_titlePrevObj">Rappel de l’objectif fixé au précédent entretien : </p>
                        <p className="report_prevObj"> {prevObjective} </p>
                    </div>
                )}

                <DropDown open={actualReport} title="Points de vigilances" isCompleted={false}>
                    <CheckboxesReport
                        checkboxes={checkboxesPointOfAttention}
                        question={pointsOfAttention}
                        pointsOfAttention
                        comment={actualReport?.pointOfAttentionsComment ?? null}
                        noPointComment={actualReport?.noPointOfAttentionsComment ?? null}
                        disabled
                    />
                </DropDown>
                <DropDown open={actualReport} title="informations & conseils de santé" isCompleted={false}>
                    <CheckboxesReport
                        checkboxes={actualReport?.thematics ?? []}
                        question={thematic}
                        comment={actualReport?.thematicsComment ?? null}
                        noPointComment={actualReport?.noThematicsComment ?? null}
                        disabled
                    />
                </DropDown>
                <DropDown title="Parcours de soin">
                    <RecoCard reco={recoConsults} />
                    <div className="d-flex align-items-center mt-2 ml-2">
                        <AddVisit />
                    </div>
                </DropDown>
                <DropDown
                    open={actualReport}
                    title="Ecoute & objectifs"
                >
                    <div className="d-flex flex-column pxy-2">
                        {g_listenObjectiveQuestions.map(({ question, name }) => {
                            const _state = listenObjectiveState[name];
                            const isSelected = _state !== null;

                            return (
                                <>
                                    <div className="d-flex align-items-center mb-2">
                                        {question}
                                        <div>
                                            <span className={`InputObjective  ${isSelected ? 'activeInputObjective' : null} ml-2 mr-2 cursorPointer`}>Oui</span>
                                            <span className={`InputObjective  ${!isSelected ? 'activeInputObjective' : null} cursorPointer `}>Non</span>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <div className="mt-1 mb-4 ml-7">{_state}</div>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </DropDown>
                <DropDown title="Documents associés">
                    <div className="mt-1">
                        {(actualReport ? actualReport.attachments : null)?.map((item, index) => {
                            return (
                                <div className="cardReportDocument mb-2" key={index}>
                                    <p className="timkl__color--blue-dark">{item.fileName}</p>
                                    <div>
                                        <Icon size={20} className="icon-eye mr-2" color="#0069b4" />
                                        <Icon size={20} className="icon-trash" color="#8ab5e1" />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </DropDown>
                <DropDown open={actualReport} title="Note générale" isCompleted={comment !== null}>
                    <p className="ml-4 mt-2">Saisissez un commentaire global sur l’entretien si vous le souhaitez</p>
                    <div className="d-flex flex-column">
                        <div className="mt-2 ml-7">{comment}</div>
                    </div>
                </DropDown>
            </div>
        </div>
    );
};

ContentDropdown.propTypes = {
    report: PropTypes.object,
    pointsOfAttention: PropTypes.array.isRequired,
    edition: PropTypes.bool,
    diagnosis: PropTypes.object.isRequired,
    patients: PropTypes.object.isRequired,
    tryCreatePatientAppointment: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    thematic: PropTypes.object.isRequired,
    actualReport: PropTypes.object,
    canModify: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth: { user, access_token }, pointsOfAttention: { list }, thematic: { datas }, diagnosis, patients }) => ({
    user,
    access_token,
    pointsOfAttention: list,
    diagnosis,
    patients,
    tryCreatePatientAppointment,
    thematic: datas,
});

export default connect(mapStateToProps)(ContentDropdown);
