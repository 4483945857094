import React from "react";
import PropTypes from "prop-types";

import { Animated } from "react-animated-css";
import { Icon, Card, InputCheckbox, TextArea, formatHBA1C, Form, InputMutable, StepButtons } from "@rdcs/dap-front-library";

import LeftImage from "@rdcs/dap-front-library/static/images/illu_doctors.svg";

import useState from "./useState";

const DiagnosisStep3 = ({ goal, prevStep, nextStep, errors, isLoading }) => {
    const defaultGoal = React.useMemo(() => formatHBA1C(goal), []);

    const [{ allowSubmit, modeEdit }, { setAllowSubmit, setModeEdit }] = useState();

    return (
        <Animated animationIn="fadeIn">
            <div className="diagnostic__container__step3">
                <div className="diagnostic__container__step3--leftImage">
                    <img src={LeftImage} />
                </div>
                <div>
                    <Form onSubmit={nextStep} className="diagnostic__container__step3--rightForm">
                        <div>
                            <div className="form__group">
                                <p className="title-4">
                                    Selon le profil du patient, <br />
                                    l’objectif d'HbA1c recommandé par la HAS est de
                                </p>
                            </div>
                            <Card className="diagnostic__container__step3--card">
                                <Icon name="blood-test" className="diagnostic__container__step3--icon" />
                                <InputMutable
                                    name="goal"
                                    type="number"
                                    suffix="%"
                                    className="diagnostic__container__step3--input"
                                    usePlaceholderIfEmpty
                                    error={errors.goal}
                                    step={0.01}
                                    defaultValue={defaultGoal}
                                    required
                                    onModeChange={setModeEdit}
                                    tooltipEdit="Modifier cet objectif"
                                    tooltipValid="Sauvegarder cet objectif"
                                />
                            </Card>
                            <InputCheckbox
                                className={modeEdit ? "spo__color--gray-5" : ""}
                                label="Je valide cet objectif glycémique"
                                onChange={setAllowSubmit}
                                checked={allowSubmit}
                                required
                                disabled={modeEdit}
                            />
                            {allowSubmit && <TextArea label="Justification (optionnelle)" name="goalJustification" className="mt-4" />}
                        </div>
                        <StepButtons
                            prevStep={prevStep}
                            disabledNext={!allowSubmit}
                            isLoadingNext={isLoading}
                            prevButtonClassName="text-uppercase"
                            nextButtonClassName="text-uppercase"
                        />
                    </Form>
                </div>
            </div>
        </Animated>
    );
};

DiagnosisStep3.propTypes = {
    goal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    errors: PropTypes.shape({
        goal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    }),
    isLoading: PropTypes.bool.isRequired,
};

export default DiagnosisStep3;
