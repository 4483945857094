import * as types from "../actions/types";

const initialState = {
    dataList: {
        patientsPerDoctor: 0,
        months: []
    },

    loading: false,

    trackingBillingList: [],
    trackingBillingListChunked: [],
    trackingBillingListLoaded: 0,
};

function statistics(state = initialState, action) {
    switch (action.type) {
        // FETCH
        case types.STATISTICS_FETCH_REQUESTED: {
            return Object.assign({}, state, { loading: true });
        }
        case types.STATISTICS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                loading: false,
                dataList: action.data.data
            });
        }
        case types.STATISTICS_FETCH_FAILURE: {
            return {
                ...state,
                dataList: initialState.dataList,
                loading: false,
            };
        }
        // TRACKING_BILLING
        case types.TRACKING_BILLING_FETCH_REQUESTED: {
            return Object.assign({}, state, {
                loading: true,
                trackingBillingListLoaded: 0,
            });
        }
        case types.TRACKING_BILLING_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                loading: false,
                trackingBillingList: action.data,
                trackingBillingListChunked: action.chunk,
                trackingBillingListLoaded: Date.now(),
            });
        }
        case types.TRACKING_BILLING_FETCH_FAILURE: {
            return {
                ...state,
                trackingBillingList: initialState.trackingBillingList,
                loading: false,
            };
        }
        // DEFAULT
        default:
            return state;
    }
}

export default statistics;
