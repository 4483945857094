import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { Icon, ImageStatus } from "@rdcs/dap-front-library";

import defaultUserImage from "@rdcs/dap-front-library/static/images/default-user.png";

const Header = ({ patient, lastDiagnosis }) => {
    return (
        <header className="headerPage">
            <div className="headerPage__title mt-2">
                <ImageStatus
                    className="mr-1"
                    filename={patient.avatar && patient.avatar.filename}
                    fallbackImageUrl={defaultUserImage}
                    title={patient.statusReason}
                    statusColor={patient.statusColor}
                    size={44}
                    user={patient}
                />
                <div>
                    <h1 className="title-1 inline">
                        {patient.firstName}&nbsp;
                        <span className="headerPage__statusDot-group">{patient.lastName}</span>
                    </h1>
                </div>
            </div>

            <div className="headerPage__actions">
                <Link
                    to={`/facilitateurs/patient/${patient.id}/nouvelle-consultation`}
                    className="headerPage__actions-item button button__primaryButton timkl__backgroundColor--orange-dark-important timkl__LinkBtn-header-coordinator"
                >
                    <Icon size={20} name="stethoscope" />
                    <span className="ml-1">{`${lastDiagnosis !== null ? "Revoir" : "Définir"} le parcours de soin`}</span>
                </Link>
            </div>
        </header>
    );
};

Header.propTypes = {
    patient: PropTypes.shape({
        id: PropTypes.number.isRequired,
        avatar: PropTypes.shape({
            filename: PropTypes.string.isRequired,
        }),
        statusReason: PropTypes.string.isRequired,
        statusColor: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        lastDiagnostic: PropTypes.object,
    }).isRequired,
    lastDiagnosis: PropTypes.object.isRequired,
};

export default React.memo(Header);
