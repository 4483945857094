import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { ComplicationTree } from "@rdcs/dap-front-library";

import SettingsTabLayout from "../../../components/Settings/SettingsTabLayout";


const ComplicationTab = ({ auth, complications, loading, activate, swapOrder, isSwapingOrder }) => {
    const activateCallback = React.useCallback((complication, active) => activate(auth, complication, active), []);
    const swapOrderCallback = React.useCallback((item1, item2) => swapOrder(auth, item1, item2), []);

    return (
        <SettingsTabLayout
            title="Complications"
            icon="complications-insuffisance-renale"
            addButton={{
                link: "/configuration/ajouter-complication",
                label: "Ajouter une complication"
            }}
            isLoading={loading}
        >   
            <ComplicationTree
                complications={complications}
                activate={activateCallback}
                swapOrder={swapOrderCallback}
                isSwapingOrder={isSwapingOrder}
            />
        </SettingsTabLayout>
    );
}

ComplicationTab.propTypes = {
    auth: PropTypes.object.isRequired,
    complications: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            icon: PropTypes.shape({
                filename: PropTypes.string.isRequired
            }),
            active: PropTypes.bool.isRequired,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
        })
    ).isRequired,
    loading: PropTypes.bool.isRequired,
    activate: PropTypes.func.isRequired,
    swapOrder: PropTypes.func.isRequired,
    isSwapingOrder: PropTypes.bool.isRequired
};

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps)(ComplicationTab);
