import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { ModerationListItemCard } from "@rdcs/dap-front-library";

import SettingsTabLayout from "../../../components/Settings/SettingsTabLayout";

const ThematicTab  = ({ loading, thematic, delete: onDelete, auth }) => (
    <SettingsTabLayout
        title="Liste des thématiques"
        icon="ic-health"
        addButton={{
            link: "/configuration/ajouter-thematique",
            label: "Ajouter une thématique",
        }}
        isLoading={loading}
    >
        {thematic && thematic
            // .sort((a, b) => (a.order < b.order ? -1 : 1))
            .map((thematic) => (
                <ModerationListItemCard
                    key={thematic["@id"]}
                    label={thematic.name}
                    modifyLink={`/configuration/modifier-thematique/${thematic.id}`}
                    onDelete={() => onDelete(thematic.id)}
                    deleteText="Supprimer la thématique"
                />
            ))}
    </SettingsTabLayout>
);

ThematicTab.propTypes = {
    auth: PropTypes.object.isRequired,
    thematic: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        }).isRequired
    ).isRequired,
    loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps)(ThematicTab);
