import React, {Fragment} from "react";
import PropTypes from "prop-types";

import { Button, InputText, Icon, Select, InputNumber, Form } from "@rdcs/dap-front-library";
import { Link } from "react-router-dom";

const NUMBER_RESPONSE = { min: 2, max: 10 };
const RANGE_LIMIT = { min: 0, max: 20 };
const WEIGHT_LIMIT = { min: 0, max: 100 };

const BreakScoreQuestionForm = ({
    editedBreakScoreQuestion: {
        label: breakScoreQuestionLabel = "",
        responses: breakScoreQuestionResponses = new Array(NUMBER_RESPONSE.min).fill(""),
        responsesWeight: breakScoreQuestionWeight = [],
        fieldType: breakScoreQuestionType = "",
        fieldLength: breakScoreQuestionLength = "short",
        minValue: breakScoreQuestionMinVal = 0,
        minLabel: breakScoreQuestionMinLabel = "",
        maxValue: breakScoreQuestionMaxVal = 10,
        maxLabel: breakScoreQuestionMaxLabel = "",
        forbiddenValue: breakScoreQuestionForbidden = null,
        scaleWeights: breakScoreQuestionScaleWeights = []
    } = {},
    onSubmit,
    errors: { name: nameError = "" },
    location,
    isLoading,
    redirectTab
}) => {
    const [fieldType, setFieldType] = React.useState(breakScoreQuestionType);
    const [numberResponse, setNumberResponse] = React.useState(breakScoreQuestionResponses.length);
    const [minValue, setMinVal] = React.useState(breakScoreQuestionMinVal);
    const [maxValue, setMaxVal] = React.useState(breakScoreQuestionMaxVal);

    const [minLimit, maxLimit, diffLimit] = React.useMemo(() => {
        let _minLimit = isNaN(minValue) ? RANGE_LIMIT.min : Math.min(Math.max(RANGE_LIMIT.min, minValue), RANGE_LIMIT.max - 1);
        let _maxLimit = isNaN(maxValue) ? RANGE_LIMIT.max : Math.min(RANGE_LIMIT.max, maxValue);

        if (_minLimit >= _maxLimit) {
            _maxLimit = _minLimit + 1;
        }

        return [
            _minLimit,
            _maxLimit,
            (_maxLimit - _minLimit) + 1
        ];
    }, [minValue, maxValue]);

    const constraints = React.useMemo(() => {
        const intError = 'La valeur doit être un nombre entier.';
        const minRangeError = `La valeur minimum doit être comprise entre ${RANGE_LIMIT.min} et ${maxLimit - 1}.`;
        const maxRangeError = `La valeur maximum doit être comprise entre ${minLimit + 1} et ${RANGE_LIMIT.max}.`;
        const scaleRangeError = `Le poids doit être compris entre ${WEIGHT_LIMIT.min} et ${WEIGHT_LIMIT.max}.`;
        const scaleRules = {};
        
        for (let i = minLimit; i <= maxLimit; ++i) {
            scaleRules[`scale${i}Weight`] = {
                numericality: {
                    onlyInteger: false,
                    greaterThanOrEqualTo: WEIGHT_LIMIT.min,
                    lessThanOrEqualTo: WEIGHT_LIMIT.max,
                    notGreaterThanOrEqualTo: scaleRangeError,
                    notLessThanOrEqualTo: scaleRangeError,
                }
            }
        }

        return ({
            fields: {},
            rules: {
                ...scaleRules,
                minValue: {
                    numericality: {
                        onlyInteger: true,
                        greaterThanOrEqualTo: RANGE_LIMIT.min,
                        lessThan: maxLimit,
                        notInteger: intError,
                        notGreaterThanOrEqualTo: minRangeError,
                        notLessThan: minRangeError,
                    },
                },
                maxValue: {
                    numericality: {
                        onlyInteger: true,
                        greaterThan: minLimit,
                        lessThanOrEqualTo: RANGE_LIMIT.max,
                        notInteger: intError,
                        notGreaterThan: maxRangeError,
                        notLessThanOrEqualTo: maxRangeError,
                    }
                },
            }
        });
    }, [minLimit, maxLimit]);

    const onSubmitCallback = form => {
        switch (form.fieldType) {
            case 'choices':
                // for (let i = 0; i <= numberResponse; ++i) {
                //     form[`answer${i}Weight`] = parseInt(form[`answer${i}Weight`]);
                // }
                break;
            case 'range':
                if (form.forbiddenValue !== '') {
                    form.forbiddenValue = parseInt(form.forbiddenValue);
                }
                else {
                    delete form.forbiddenValue;
                }
                form.minValue = parseInt(form.minValue);
                form.maxValue = parseInt(form.maxValue);
                // for (let i = minLimit; i <= maxLimit; ++i) {
                //     form[`scale${i}Weight`] = parseFloat(form[`scale${i}Weight`]);
                // }
                break;
            default:
                break;
        }
        onSubmit(form);
    };

    return (
        <Form onSubmit={onSubmitCallback} constraints={constraints}>
            <div className="grid grid__padding-10">
                <div className="col-100">
                    <Select
                        label="Type de question"
                        name='fieldType'
                        defaultValue={fieldType}
                        options={[
                            {value: "", label: "Choisissez un type de question"},
                            {value: "text", label: "Question à réponse libre"},
                            {value: "choices", label: "Question à choix multiples"},
                            {value: "range", label: "Question à échelle"}
                        ]}
                        onChange={setFieldType}
                        required
                    />
                </div>
                {fieldType && (
                    <div className="col-100">
                        <InputText
                            name='label'
                            label="Question"
                            error={nameError}
                            placeholder="Saisissez le contenu de votre question"
                            defaultValue={breakScoreQuestionLabel}
                            required
                        />
                    </div>
                )}
                {fieldType === "text" && (
                    <div className="col-100">
                        <Select
                            label="Type de réponse"
                            name='fieldLength'
                            defaultValue={breakScoreQuestionLength}
                            options={[
                                {value: "short", label: "Réponse courte (un mot)"},
                                {value: "long", label: "Réponse longue (une ou plusieurs phrases)"}
                            ]}
                            required
                        />
                    </div>
                )}
                {fieldType === "choices" && (
                    <Fragment>
                        <div className="col-100">
                            <Select
                                label="Nombre de réponses"
                                options={Array.from({ length: NUMBER_RESPONSE.max + 1 - NUMBER_RESPONSE.min }, (_, i) => ({
                                    label: "" + (i + NUMBER_RESPONSE.min),
                                    value: i + NUMBER_RESPONSE.min
                                }))}
                                defaultValue={"" + numberResponse}
                                onChange={setNumberResponse}
                                name='numberResponse'
                                hideFromForm
                            />
                        </div>
                        <p className="col-100 mt-2 text-3">
                            Pour chaque réponse, merci d'indiquer un chiffre entre 0 et 100 représentant son poids dans le calcul
                            d'éligibilité du patient. Pour rappel, si la somme des réponses du patient est supérieure à 100, il est
                            éligible.
                        </p>
                        {Array.from({ length: numberResponse }, (_, i) => (
                            <React.Fragment key={i}>
                                <div className="col-80">
                                    <InputText
                                        name={`answer${i + 1}`}
                                        label={`Réponse ${i + 1}`}
                                        placeholder={`Saisissez le contenu de la réponse ${i + 1}`}
                                        defaultValue={breakScoreQuestionResponses[i]}
                                        required
                                    />
                                </div>
                                <div className="col-20">
                                    <InputNumber
                                        label="Poids"
                                        name={`answer${i + 1}Weight`}
                                        defaultValue={parseFloat(breakScoreQuestionWeight[i])}
                                        required
                                        min={0}
                                        max={100}
                                    />
                                </div>
                            </React.Fragment>
                        ))}
                    </Fragment>
                )}
                {fieldType === "range" && (
                    <Fragment>
                        <p className="col-100 mt-2 text-3">
                            Pour chacune des valeurs min et max, merci d'indiquer un chiffre entre 0 et 100 représentant son poids dans le calcul
                            d'éligibilité du patient. Pour rappel, si la somme des réponses du patient est supérieure à 100, il est
                            éligible.
                        </p>
                        <div className="col-20">
                            <InputNumber
                                label="Valeur min"
                                placeholder="Saisissez la valeur minimum de l'échelle"
                                name='minValue'
                                defaultValue={minValue}
                                required
                                onChange={val => {
                                    setMinVal(parseInt(val));
                                }}
                                min={0}
                                max={maxLimit - 1}
                                step={1}
                            />
                        </div>
                        <div className="col-80">
                            <InputText
                                name='minLabel'
                                label="Label valeur min"
                                placeholder="Saisissez le texte de la valeur minimum de l'échelle"
                                defaultValue={breakScoreQuestionMinLabel}
                                required
                            />
                        </div>
                        <div className="col-20">
                            <InputNumber
                                name='maxValue'
                                label="Valeur max"
                                placeholder="Saisissez la valeur maximale de l'échelle"
                                defaultValue={maxValue}
                                required
                                onChange={val => {
                                    setMaxVal(parseInt(val));
                                }}
                                min={minLimit + 1}
                                max={RANGE_LIMIT.max}
                                step={1}
                            />
                        </div>
                        <div className="col-80">
                            <InputText
                                name='maxLabel'
                                label="Label valeur max"
                                placeholder="Saisissez le texte de la valeur maximale de l'échelle"
                                defaultValue={breakScoreQuestionMaxLabel}
                                required
                            />
                        </div>
                        {Array(diffLimit).fill().map((_, index) => (
                            <div className="col-20" key={minLimit + index}>
                                <InputNumber
                                    name={`scale${minLimit + index}Weight`}
                                    label={`Poids de la valeur ${minLimit + index}`}
                                    placeholder={`Saisissez le poids de la valeur ${minLimit + index}`}
                                    defaultValue={parseFloat(breakScoreQuestionScaleWeights[minLimit + index])}
                                    min={WEIGHT_LIMIT.min}
                                    max={WEIGHT_LIMIT.max}
                                    required
                                />
                            </div>
                        ))}
                        <div className="col-100">
                            <InputNumber
                                name='forbiddenValue'
                                label="Valeur interdite"
                                placeholder="Saisissez la valeur interdite de l'échelle"
                                defaultValue={breakScoreQuestionForbidden}
                                min={minLimit}
                                max={maxLimit}
                                step={1}
                            />
                        </div>
                    </Fragment>
                )}
                <div className="form__group col-100 text-center">
                    <Link
                        to={{
                            ...redirectTab,
                            state: { from: location },
                        }}
                    >
                        <Button className="button__secondaryButton">
                            <Icon name="arrow-left" className="mr-1" /> Retour
                        </Button>
                    </Link>
                    <Button type="submit" className="button__primaryButton" isLoading={isLoading}>
                        Enregistrer
                    </Button>
                </div>
            </div>
        </Form>
    );
};

BreakScoreQuestionForm.propTypes = {
    editedBreakScoreQuestion: PropTypes.shape({
        label: PropTypes.string,
        responses: PropTypes.arrayOf(PropTypes.string),
        responsesWeight: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
        scaleWeights: PropTypes.arrayOf(PropTypes.number),
    }),
    onSubmit: PropTypes.func.isRequired,
    errors: PropTypes.shape({
        name: PropTypes.string
    }).isRequired,
    location: PropTypes.any,
    isLoading: PropTypes.bool.isRequired,
    redirectTab: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        tab: PropTypes.string.isRequired,
    }).isRequired,
};

export default BreakScoreQuestionForm;
