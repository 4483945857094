import React from "react";
import PropTypes from 'prop-types';
import { Redirect, Route } from "react-router-dom";

import { connect } from "react-redux";
import { userCan } from "../../reducers/auth";

import AccessDeniedExceptionScreen from "../../screens/Exceptions/AccessDeniedException";

const PrivateRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = rest.auth.user !== null;
    const accessAllowed = userCan(rest.allowedRoles);

    return (
        <Route
            {...rest}
            render={props =>
                accessAllowed ? (
                    <Component {...props} />
                ) : isAuthenticated ? (
                    <AccessDeniedExceptionScreen />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/connexion",
                            state: { from: props.location },
                            afterAuthPath: props.location.pathname
                        }}
                    />
                )
            }
        />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.any.isRequired,
    auth: PropTypes.shape({
        user: PropTypes.object,
    }),
    allowedRoles: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
}

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps)(PrivateRoute);
