import * as types from "../actions/types";
import { toast } from "react-toastify";

const initialState = {
    list: [],
    listLoading: false,
    measureTypesList: [],
    measureTypesListLoading: false,
    created: false,
    createError: false,
    createLoading: false
};

function measures(state = initialState, action) {
    switch (action.type) {
        // FETCH MEASURE
        case types.MEASURES_FETCH_REQUESTED: {
            return Object.assign({}, state, { listLoading: true });
        }
        case types.MEASURES_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                listLoading: false,
                list: action.data,
            });
        }
        case types.MEASURES_FETCH_FAILURE: {
            return Object.assign({}, state, {
                listLoading: false,
                list: [],
            });
        }
        // CREATE MEASURE
        case types.MEASURES_CREATE_REQUESTED: {
            return Object.assign({}, state, {
                createLoading: true,
                created: false
            });
        }
        case types.MEASURES_CREATE_SUCCESS: {
            toast.success("La donnée a bien été créée", { autoClose: 5000 });
            return Object.assign({}, state, {
                createLoading: false,
                createError: false,
                created: action.data,
                list: [action.data, ...state.list]
            });
        }
        case types.MEASURES_CREATE_FAILURE: {
            toast.error("La donnée n'a pas pu être créée");
            return Object.assign({}, state, {
                createLoading: false,
                createError: action.data,
                created: false
            });
        }
        // FETCH MEASURE TYPES
        case types.MEASURE_TYPES_FETCH_REQUESTED: {
            return Object.assign({}, state, { measureTypesListLoading: true });
        }
        case types.MEASURE_TYPES_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                measureTypesListLoading: false,
                measureTypesList: action.data
            });
        }
        case types.MEASURE_TYPES_FETCH_FAILURE: {
            return Object.assign({}, state, {
                measureTypesListLoading: false,
                measureTypesList: []
            });
        }
        // DEFAULT
        default:
            return state;
    }
}

export default measures;
