import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { DefaultLoader } from "@rdcs/dap-front-library";

import useRequestFromFormAndRedirect from "../../hooks/useRequestFromFormAndRedirect";

import AppLayout from "../Layout/AppLayout";

const SettingsFormScreen = ({
    auth,
    documentTitle,
    pageTitle,
    formRequest,
    fetchRequests,
    testFetchRequestsNeeded = true,
    onFetchRequestEnded = undefined,
    itemForm,
    itemFormRequestLoading,
    itemFormRequestError,
    itemFormExtraProps = undefined,
    itemFormIsLoading = false,
    itemFormExtraSubmitParams = [],
    tab,
}) => {
    const redirectTab = React.useMemo(
        () => ({
            pathname: "/configuration",
            tab,
        }),
        [tab]
    );

    React.useEffect(() => {
        let promisefetchRequests = undefined;
        const isTestFetchRequestsNeeded =
            typeof testFetchRequestsNeeded === "boolean" ? () => testFetchRequestsNeeded : (i = 0) => testFetchRequestsNeeded[i] === true;

        if (typeof fetchRequests === "function") {
            if (isTestFetchRequestsNeeded()) {
                promisefetchRequests = fetchRequests(auth);
            }
        } else if (Array.isArray(fetchRequests)) {
            promisefetchRequests = Promise.all(fetchRequests.filter((_, i) => isTestFetchRequestsNeeded(i)).map((fetchRequest) => fetchRequest(auth)));
        }

        if (promisefetchRequests !== undefined && onFetchRequestEnded !== undefined) {
            promisefetchRequests.finally(onFetchRequestEnded);
        }

        document.title = `Timkl - ${documentTitle}`;
    }, []);

    const onSubmit = useRequestFromFormAndRedirect(redirectTab, formRequest, ...itemFormExtraSubmitParams);

    const itemFormProps = React.useMemo(
        () => ({
            isLoading: itemFormRequestLoading,
            errors: itemFormRequestError.violations || {},
            onSubmit,
            ...itemFormExtraProps,
            redirectTab,
        }),
        [itemFormRequestLoading, itemFormRequestError, onSubmit, itemFormExtraProps, redirectTab]
    );

    const breadcrumbs = React.useMemo(() => [{ label: "configuration", link: redirectTab }, { label: pageTitle }], [pageTitle, redirectTab]);

    return (
        <AppLayout navActive={2} breadcrumbs={breadcrumbs}>
            <h1 className="title-1">{pageTitle}</h1>
            <div className="form__group">{itemFormIsLoading ? <DefaultLoader color="#CCC" /> : React.createElement(itemForm, itemFormProps)}</div>
        </AppLayout>
    );
};

SettingsFormScreen.propTypes = {
    auth: PropTypes.object.isRequired,

    /* Document and page titles */
    documentTitle: PropTypes.string.isRequired,
    pageTitle: PropTypes.string.isRequired,

    /* Redux request callback use to add on BDD */
    formRequest: PropTypes.func.isRequired,
    fetchRequests: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.func), PropTypes.func]),
    testFetchRequestsNeeded: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.bool), PropTypes.bool]),
    onFetchRequestEnded: PropTypes.func,

    /* Forms */
    itemForm: PropTypes.any.isRequired,
    itemFormRequestLoading: PropTypes.bool.isRequired,
    itemFormRequestError: PropTypes.oneOfType([PropTypes.shape({ violations: PropTypes.object }), PropTypes.bool]).isRequired,
    itemFormExtraProps: PropTypes.object,
    itemFormIsLoading: PropTypes.bool,
    itemFormExtraSubmitParams: PropTypes.arrayOf(PropTypes.any),

    /* Configuration tab name for redirection */
    tab: PropTypes.string.isRequired,
};

export default connect(({ auth }) => ({ auth }))(SettingsFormScreen);
