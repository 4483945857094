import React from "react";

import Moment from "moment";

const defaultState = {
    loading: true,
    measures: [],
    // One state for main categories of measures
    mainMeasures: {
        measureTypes: [], // An array which list all types of measures
        showMoreItem: [], // An array which contains all showMore (int) to slice the array when render
        showAddItem: [], // An array which list if the form to add an item should be shown or not (bool)
        addMeasureData: [] // An array with necessary datas to add a measure
    },
    // One state for others categories of measures
    othersMeasures: {
        measureTypes: [],
        showMoreItem: 3,
        showAddItem: false,
        showFilterItem: false,
        addMeasureData: {},
        filter: {
            category: "", // Default category to all
            startDate: Moment(new Date("1970-01-01T00:00:00"))
                .subtract(1, "Y")
                .startOf("month")
                .format("YYYY-MM-DD[T]HH:mm:ssZ"), // Default startDate filter to start of month + (year - 1)
            endDate: Moment(new Date())
                .add(1, "Y")
                .endOf("month")
                .format("YYYY-MM-DD[T]HH:mm:ssZ") // Default endDate filter to end of month + (year + 1)
        }
    }
};

const reducer = (prevState, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return {
                ...prevState,
                loading: action.loading,
            }
        case 'SET_MESURE':
            return {
                ...prevState,
                loading: false,
                measures: action.measures,
                mainMeasures: action.mainMeasures,
                othersMeasures: action.othersMeasures,
            }
        case 'ADD_MEASURE':
            return {
                ...prevState,
                mainMeasures: {
                    ...prevState.mainMeasures,
                    showAddItem: {
                        ...prevState.mainMeasures.showAddItem,
                        [action.index]: !prevState.mainMeasures.showAddItem[action.index]
                    },
                    addMeasureData: {
                        ...prevState.mainMeasures.addMeasureData,
                        [action.index]: {
                            ...prevState.mainMeasures.addMeasureData[action.index],
                            type: action.item
                        }
                    }
                }
            }
        case 'CREATE_MEASURE_SET_DATE':
            return {
                ...prevState,
                mainMeasures: {
                    ...prevState.mainMeasures,
                    addMeasureData: {
                        ...prevState.mainMeasures.addMeasureData,
                        [action.index]: {
                            ...prevState.mainMeasures.addMeasureData[action.index],
                            date: Moment(action.date).format("YYYY-MM-DD[T]HH:mm:ssZ")
                        }
                    }
                }
            }
        case 'CREATE_MEASURE_SET_VALUE':
            return {
                ...prevState,
                mainMeasures: {
                    ...prevState.mainMeasures,
                    addMeasureData: {
                        ...prevState.mainMeasures.addMeasureData,
                        [action.index]: {
                            ...prevState.mainMeasures.addMeasureData[action.index],
                            value: action.value
                        }
                    }
                }
            }
        case 'MAIN_MEASURES_PREVIOUS_MEASURE':
            return {
                ...prevState,
                mainMeasures: {
                    ...prevState.mainMeasures,
                    showMoreItem: {
                        ...prevState.mainMeasures.showMoreItem,
                        [action.index]: mainMeasures.showMoreItem[action.index] + 3
                    }
                }
            }
        case 'OTHER_MEASURES_TOGGLE_FILTER':
            return {
                ...prevState,
                othersMeasures: {
                    ...prevState.othersMeasures,
                    showFilterItem: !prevState.othersMeasures.showFilterItem
                }
            }
            case 'OTHER_MEASURES_ADD_NEW_ITEM':
                return {
                    ...prevState,
                    othersMeasures: {
                        ...prevState.othersMeasures,
                        showAddItem: !prevState.othersMeasures.showAddItem
                    }
                }
        case 'OTHER_MEASURES_FILTER_CATEGORY':
            return {
                ...prevState,
                othersMeasures: {
                    ...prevState.othersMeasures,
                    filter: {
                        ...prevState.othersMeasures.filter,
                        category: action.category
                    }
                }
            }
            case 'OTHER_MEASURES_ADD_NEW_ITEM':
                return {
                    ...prevState,
                    othersMeasures: {
                        ...prevState.othersMeasures,
                        showAddItem: !prevState.othersMeasures.showAddItem
                    }
                }
        case 'OTHER_MEASURES_FILTER_START_DATE':
            return {
                ...prevState,
                othersMeasures: {
                    ...prevState.othersMeasures,
                    filter: {
                        ...prevState.othersMeasures.filter,
                        startDate: Moment(action.date).startOf("month").format("YYYY-MM-DD[T]HH:mm:ssZ")
                    }
                }
            }
        case 'OTHER_MEASURES_FILTER_END_DATE':
            return {
                ...prevState,
                othersMeasures: {
                    ...prevState.othersMeasures,
                    filter: {
                        ...prevState.othersMeasures.filter,
                        endDate: Moment(action.date).endOf("month").format("YYYY-MM-DD[T]HH:mm:ssZ")
                    }
                }
            }
        case 'OTHER_MEASURES_SET_TYPE':
            return {
                ...prevState,
                othersMeasures: {
                    ...prevState.othersMeasures,
                    addMeasureData: {
                        ...prevState.othersMeasures.addMeasureData,
                        type: action.measureType,
                        unit: prevState.othersMeasures.measureTypes.find(
                            measureType => measureType["@id"] === action.measureType
                        ).unit
                    }
                }
            }
            case 'OTHER_MEASURES_SET_DATE':
                return {
                    ...prevState,
                    othersMeasures: {
                        ...prevState.othersMeasures,
                        addMeasureData: {
                            ...prevState.othersMeasures.addMeasureData,
                            date: Moment(action.date).format("YYYY-MM-DD[T]HH:mm:ssZ")
                        }
                    }
                }
            case 'OTHER_MEASURES_SET_VALUE':
                return {
                    ...prevState,
                    othersMeasures: {
                        ...prevState.othersMeasures,
                        addMeasureData: {
                            ...prevState.othersMeasures.addMeasureData,
                            value: action.value
                        }
                    }
                }
            case 'OTHER_MEASURES_PREVIOUS_MEASURE':
                return {
                    ...prevState,
                    othersMeasures: {
                        ...prevState.othersMeasures,
                        showMoreItem: prevState.othersMeasures.showMoreItem + 3
                    }
                }
        default:
            return prevState;
    }
}

const useState = () => React.useReducer(reducer, defaultState);

export default useState;