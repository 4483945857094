import * as types from "./types";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import store from "../store";
import { toast } from "react-toastify";

export const tryFetchDiagnosisRecos =
    (diagnosis, active = 1) =>
    (dispatch) => {
        const {
            auth: { accessToken },
            diagnosis: { recos, recosDiagnosisId, recosLoaded },
        } = store.getState();

        if (diagnosis === null) {
            dispatch({ type: types.DIAGNOSIS_RECOS_FETCH_FAILURE });
            return Promise.resolve([]);
        }

        if (recosLoaded && recosDiagnosisId === diagnosis["@id"]) {
            return Promise.resolve(recos);
        }
        dispatch({ type: types.DIAGNOSIS_RECOS_FETCH_REQUESTED });
        return API.request({
            path: isLocalAPI ? "/diagnostic_recommendations" : "/dap-admin/api/v1/clinical-data/diagnostics-recommendations",
            queryParams: {
                diagnostic: diagnosis["@id"],
                ...(active === null ? {} : { active }),
            },
            token: accessToken,
        })
            .then((response) =>
                dispatch({
                    type: types.DIAGNOSIS_RECOS_FETCH_SUCCESS,
                    data: response["hydra:member"],
                    diagnosisId: diagnosis["@id"],
                })
            )
            .catch(() => dispatch({ type: types.DIAGNOSIS_RECOS_FETCH_FAILURE }));
    };

export const tryCreateDiagnosis = (data) => (dispatch) => {
    const {
        auth: { accessToken },
    } = store.getState();

    dispatch({ type: types.DIAGNOSIS_CREATE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/diagnostics" : "/dap-admin/api/v1/clinical-data/diagnostics",
        method: "POST",
        token: accessToken,
        data: data,
    })
        .then((response) => {
            toast.success("Le parcours de soin a été créé avec succès.");
            dispatch({ type: types.DIAGNOSIS_CREATE_SUCCESS, data: response });
            return response;
        })
        .catch((error) => {
            toast.error("Une erreur est survenue lors de la création du parcours de soin.");
            dispatch({ type: types.DIAGNOSIS_CREATE_FAILURE, data: error });
            throw error;
        });
};

export const tryEditDiagnosis = (diagnosisId, form) => (dispatch) => {
    const {
        auth: { accessToken },
    } = store.getState();

    dispatch({ type: types.DIAGNOSIS_EDIT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/diagnostics/{id}" : "/dap-admin/api/v1/clinical-data/diagnostics/{id}",
        uriParams: {
            id: diagnosisId,
        },
        method: "PUT",
        token: accessToken,
        data: form,
    })
        .then((response) => {
            dispatch({ type: types.DIAGNOSIS_EDIT_SUCCESS, data: response });
            return response;
        })
        .catch((error) => {
            dispatch({ type: types.DIAGNOSIS_EDIT_FAILURE, data: error });
            throw error;
        });
};

export const tryFetchCheckUpQuestions = () => (dispatch) => {
    const {
        auth: { accessToken },
        diagnosis: { checkUpQuestions, checkUpQuestionsLoaded },
    } = store.getState();

    if (checkUpQuestionsLoaded) {
        return Promise.resolve(checkUpQuestions);
    }

    dispatch({ type: types.DIAGNOSIS_CHECKUP_QUESTIONS_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/check_up_questions" : "/dap-admin/api/v1/customers/patients/closed-questions",
        method: "GET",
        token: accessToken,
    })
        .then((response) => {
            dispatch({
                type: types.DIAGNOSIS_CHECKUP_QUESTIONS_SUCCESS,
                data: response["hydra:member"],
            });
            return response["hydra:member"];
        })
        .catch((error) =>
            dispatch({
                type: types.DIAGNOSIS_CHECKUP_QUESTIONS_FAILURE,
                data: error,
            })
        );
};

export const tryFetchConsultationQuestions = () => (dispatch) => {
    const {
        auth: { accessToken },
        diagnosis: { consultationQuestions, consultationQuestionsLoaded },
    } = store.getState();

    if (consultationQuestionsLoaded) {
        return Promise.resolve(consultationQuestions);
    }

    dispatch({ type: types.DIAGNOSIS_CONSULTATION_QUESTIONS_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/consultation_questions" : "/dap-admin/api/v1/customers/patients/consultation-questions",
        method: "GET",
        token: accessToken,
    })
        .then((response) => {
            dispatch({
                type: types.DIAGNOSIS_CONSULTATION_QUESTIONS_SUCCESS,
                data: response["hydra:member"],
            });
            return response["hydra:member"];
        })
        .catch((error) =>
            dispatch({
                type: types.DIAGNOSIS_CONSULTATION_QUESTIONS_FAILURE,
                data: error,
            })
        );
};

export const tryFetchConsultationAnswers = (diagnosticId) => (dispatch) => {
    const {
        auth: { accessToken },
        diagnosis: { consultationAnswers, consultationAnswersLoaded, consultationAnswersDiagnosticId },
    } = store.getState();

    if (consultationAnswersLoaded && consultationAnswersDiagnosticId === diagnosticId) {
        return Promise.resolve(consultationAnswers);
    }

    dispatch({ type: types.DIAGNOSIS_CONSULTATION_ANSWERS_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/consultation_answers" : "/dap-admin/api/v1/customers/patients/consultation-answers",
        queryParams: {
            pagination: false,
            "diagnostic.id": diagnosticId,
        },
        method: "GET",
        token: accessToken,
    })
        .then((response) =>
            dispatch({
                type: types.DIAGNOSIS_CONSULTATION_ANSWERS_SUCCESS,
                data: response["hydra:member"],
                diagnosticId,
            })
        )
        .catch((error) =>
            dispatch({
                type: types.DIAGNOSIS_CONSULTATION_ANSWERS_FAILURE,
                data: error,
            })
        );
};

export const trySetDiagnosisRecommendationAvailability = (id, val) => (dispatch) => {
    const {
        auth: { accessToken },
    } = store.getState();

    dispatch({ type: types.DIAGNOSIS_RECO_AVAILABILITY_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/diagnostic_recommendations/{id}" : "/dap-admin/api/v1/clinical-data/diagnostics-recommendations/{id}",
        uriParams: {
            id,
        },
        method: "PUT",
        token: accessToken,
        data: {
            active: val,
        },
    })
        .then((response) =>
            dispatch({
                type: types.DIAGNOSIS_RECO_AVAILABILITY_SUCCESS,
                data: response,
            })
        )
        .catch((error) =>
            dispatch({
                type: types.DIAGNOSIS_RECO_AVAILABILITY_FAILURE,
                data: error,
            })
        );
};
